import React, {useState, useEffect} from "react";
import moment from "moment";
import {
  Input,
  Select,
  Table,
  Pagination,
  DatePicker,
  Form,
  Button,
} from "antd";
import "./facebook-scan.scss";
import {apiData} from "api/data";
import {useSelector} from "react-redux";
import {reverseDate, formatId} from "utils/utils-format";

import {InputAutoComplete} from "components/input-autocomplete/input-autocomplete";

import IconFilter from "assets/icons/icon-filter.svg";
import IconRefresh from "assets/icons/icon-refresh.svg";
import {showMessage} from "../../utils/utils-show-message";
import {validateText} from "../../utils/utils-validation";

const {Option} = Select;
const {RangePicker} = DatePicker;

export const FacebookScan = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(10);
  const [dataHistories, setDataHistories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scanning, setScanning] = useState(false);
  const [textScan, setTextScan] = useState({value: "", error: "", help: ""});
  const [state] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchMethod, setSearchMethod] = useState("url");
  const [scanMethod, setScanMethod] = useState("url");
  const [isFilter, setIsFilter] = useState(false);
  const [url, setUrl] = useState("");
  const [phone, setPhone] = useState("");
  const [facebookName, setFacebookName] = useState("");
  const [suggestionName, setSuggestionName] = useState([]);
  const [suggestionUrl, setSuggestionUrl] = useState([]);
  const [suggestionPhone, setSuggestionPhone] = useState([]);

  useSelector((state) => state.account.role);

  const columnsCampaign = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 125,
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      className: "width-150-px-in-1200-column",
    },
    {
      title: "Facebook Name",
      dataIndex: "facebookName",
      key: "facebookName",
      className: "width-150-px-in-1200-column",
      render: (value, row, index) => {
        return (
          <a href={row.url} target="_blank">
            {" "}
            {value}
          </a>
        );
      },
    },
    // {
    //   title: "Facebook URL",
    //   dataIndex: "url",
    //   key: "url",
    //   className: "width-150-px-in-1200-column",
    //   render: (value, row, index) => {
    //     return (
    //       <a href={value} target="_blank">
    //         {value}
    //       </a>
    //     );
    //   },
    // },
    {
      title: "Người Convert",
      dataIndex: "convertBy",
      key: "convertBy",
      className: "width-150-px-in-1200-column",
      ellipsis: true,
    },
    {
      title: "Ngày Convert",
      dataIndex: "convertTime",
      key: "convertTime",
      className: "width-150-px-in-1200-column",
      ellipsis: true,
    },
  ];

  const renderPlaceholder = () => {
    switch (searchMethod) {
      case "phone":
        return "Nhập phone cần tìm";
      case "name":
        return "Nhập Tên cần tìm";
      case "url":
        return "Nhập Url cần tìm";
      default:
        return "";
    }
  };

  const renderPlaceholderScanInput = () => {
    switch (scanMethod) {
      case "phone":
        return "Nhập số điện thoại";
      case "uid":
        return "Nhập UID Facebook";
      case "url":
        return "Nhập URL Facebook";
      default:
        return "";
    }
  };

  const renderTooltipScanInput = () => {
    switch (scanMethod) {
      case "phone":
        return "Số điện thoại ngăn cách nhau bằng dấu phẩy";
      case "uid":
        return "UID ngăn cách nhau bằng dấu phẩy";
      case "url":
        return "Duy nhất một URL Facebook";
      default:
        return "";
    }
  };

  const validateTextScanField = (target) => {
    if (!validateText(target.value)) {
      setTextScan({
        ...target,
        error: "error",
        help: "Vui lòng nhập số điện thoại hoặc facebook cá nhân cần Scan",
      });
      return false;
    }
    setTextScan({value: target.value, error: "", help: ""});
    return true;
  };

  const resetSearchValue = () => {
    setUrl("");
    setFacebookName("");
    setPhone("");
  };

  const checkIsFilter = () => {
    if (url !== "" || facebookName !== "" || phone !== "") {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  };

  const deleteFilter = async () => {
    setUrl("");
    setFacebookName("");
    setPhone("");
    setPageIndex(1);
    setIsFilter(false);
    setSearchMethod("url");

    await getAllHistories();
  };

  const handleDatePicker = (date, dateString) => {
    const startDate = dateString[0];
    const endDate = dateString[1];
    setPageIndex(1);
    setFromDate(startDate);
    setToDate(endDate);
  };

  const getAllSuggestion = async () => {
    const res = await apiData.getFacebookScanAll({
      pageIndex: 1,
      pageSize: 100,
    });
    if (res?.status?.code === "SUCCESS") {
      const data = res.data;
      const suggestionUrl = [];
      const suggestionName = [];
      const suggestionPhone = [];
      data.forEach((item) => {
        suggestionUrl.push(item.url);
        suggestionName.push(item.facebookName);
        suggestionPhone.push(item.phone);
      });
      setSuggestionUrl(suggestionUrl);
      setSuggestionName(suggestionName);
      setSuggestionPhone(suggestionPhone);
    }
  };

  const renderSuggestion = () => {
    switch (searchMethod) {
      case "url":
        return suggestionUrl;
      case "name":
        return suggestionName;
      case "phone":
        return suggestionPhone;
      default:
        return [];
    }
  };

  useEffect(async () => {
    await getAllSuggestion();
  }, []);

  useEffect(async () => {
    await getAllSuggestion();
  }, [searchMethod]);

  useEffect(async () => {
    await getAllHistories();
  }, [phone, facebookName, url]);

  const onSearch = (value) => {
    setPageIndex(1);
    if (searchMethod === "url") {
      setPhone("");
      setFacebookName("");
      setUrl(value);
    }
    if (searchMethod === "name") {
      setPhone("");
      setUrl("");
      setFacebookName(value);
    }
    if (searchMethod === "phone") {
      setUrl("");
      setFacebookName("");
      setPhone(value);
    }
  };

  const onScan = async () => {
    if (textScan.error) {
      getAllHistories();
      return;
    }
    setScanning(true);
    await apiData
      .facebookScan({scanText: textScan.value, typeScan: scanMethod})
      .then((res) => {
        if (res?.status?.code === "SUCCESS") {
          showMessage(
            "success",
            "Quét thành công " + res?.totalItem + " dữ liệu"
          );
          onSearchSuccess(res);
        } else {
          showMessage(
            "error",
            res?.status?.message || "Có lỗi xảy ra. Vui lòng thử lại sau!"
          );
        }
        setScanning(false);
      });
  };

  useEffect(async () => {
    await getAllHistories();
  }, [pageIndex, pageSize, state, toDate]);

  useEffect(() => {
    renderPlaceholder();
    checkIsFilter();
  }, [searchMethod, isFilter]);

  const getAllHistories = async () => {
    checkIsFilter();
    setLoading(true);
    const res = await apiData.getFacebookScanAll({
      pageIndex,
      pageSize,
      state,
      url,
      phone,
      facebookName,
      fromDate,
      toDate,
    });
    setLoading(false);
    if (res?.status?.code === "SUCCESS") {
      onSearchSuccess(res);
    }
  };

  const onSearchSuccess = (res) => {
    const data = [];
    res.data.forEach((item) => {
      const createdDate = new Date(reverseDate(item.convertTime));
      const record = {
        id: formatId(createdDate, item.id),
        historyId: item.id,
        url: item.url,
        facebookName: item.facebookName,
        facebookUid: item.facebookUid,
        phone: item.phone,
        convertTime: item.convertTime,
        convertBy: item.convertBy,
      };
      data.push(record);
    });
    setDataHistories(data);
    setTotalPage(res.totalItem);
  };

  return (
    <div className="facebook-scan-page position-relative min-height-100vh">
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <Form className="form-inline">
                  <button
                    className="btn btn-secondary btn-sm ml-2"
                    onClick={async () => {
                      resetSearchValue();
                      setFromDate("");
                      setToDate("");
                      await getAllHistories();
                    }}
                  >
                    <img src={IconRefresh} alt="" width="11"/>
                  </button>
                </Form>
              </div>
              <h4 className="page-title">Facebook Scan</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card-box">
              <h4 className="header-title d-flex align-items-center flex-wrap justify-content-between">
                <span className="mb-2">Danh sách kết quả tìm kiếm</span>
                <div className="d-flex flex-wrap align-items-center">
                  {isFilter && (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger waves-effect waves-light mb-2 mr-2 d-flex align-items-center"
                      onClick={() => deleteFilter()}
                    >
                      <img
                        src={IconFilter}
                        width="12"
                        className="mr-1"
                        alt=""
                      />{" "}
                      Xoá bộ lọc
                    </button>
                  )}
                </div>
              </h4>

              <div className="table-tools d-flex align-items-center justify-content-between mb-3">
                <div className="tool-item filter d-flex align-items-center">
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                    Kiểu dữ liệu:
                    <Select
                      value={scanMethod}
                      onSelect={(value) => {
                        setScanMethod(value);
                      }}
                    >
                      <Option value="url">URL Facebook</Option>
                      {/* <Option value="uid">UID Facebook</Option> */}
                      <Option value="phone">Số điện thoại</Option>
                    </Select>
                  </div>
                  <div className="tool-item search d-flex align-items-center text-nowrap">
                    <Input
                      className="form-control col-lg-12"
                      title={renderTooltipScanInput()}
                      placeholder={renderPlaceholderScanInput()}
                      value={textScan.value}
                      onChange={(e) =>
                        setTextScan({
                          ...textScan,
                          value: e.target.value,
                        })
                      }
                      onBlur={() => validateTextScanField(textScan)}
                    />
                    <Button
                      className="btn btn-primary btn-block w-auto"
                      type="submit"
                      loading={scanning}
                      onClick={() => onScan()}
                    >
                      {" "}
                      Scan{" "}
                    </Button>
                  </div>
                </div>
                <div className="tool-item flex-wrap d-flex align-items-center text-nowrap">
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                    Kiểu tìm kiếm
                    <Select
                      value={searchMethod}
                      onSelect={(value) => {
                        resetSearchValue();
                        setSearchMethod(value);
                      }}
                    >
                      <Option value="url">Facebook URL</Option>
                      <Option value="name">Facebook Name</Option>
                      <Option value="phone">Số điện thoại</Option>
                    </Select>
                  </div>
                  <div className="tool-item search d-flex align-items-center text-nowrap">
                    Tìm kiếm:
                    <InputAutoComplete
                      isFilter={isFilter}
                      placeholder={renderPlaceholder()}
                      dataSource={renderSuggestion()}
                      onSearch={(value) => onSearch(value)}
                    />
                  </div>
                </div>
              </div>

              <Table
                loading={loading}
                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                dataSource={dataHistories}
                columns={columnsCampaign}
                pagination={false}
              />

              {dataHistories.length !== 0 && (
                <div className="table-panigate d-flex align-items-center justify-content-between mt-3">
                  <div className="dataTables_info">
                    Trang {pageIndex} trong tổng số{" "}
                    {Math.ceil(totalPage / pageSize)} trang
                  </div>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="tool-item select d-flex align-items-center pagesize-select">
                      Hiển thị
                      <Select
                        value={pageSize}
                        onSelect={(value) => setPageSize(value)}
                      >
                        <Option value="10">10</Option>
                        <Option value="30">30</Option>
                        <Option value="50">50</Option>
                        <Option value="70">70</Option>
                        <Option value="100">100</Option>
                      </Select>
                      dữ liệu
                    </div>
                    <div className="table-pagination">
                      <Pagination
                        current={pageIndex}
                        pageSize={pageSize}
                        total={totalPage}
                        showSizeChanger={false}
                        onChange={(page) => setPageIndex(page)}
                        hideOnSinglePage={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
