import React, { useState, useEffect } from "react";
import "./footer.scss";
export const Footer = () => {
  const [year, setYear] = useState(2020);

  useEffect(() => {
    const date = new Date();
    setYear(date.getFullYear());
  }, []);
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            {year} {" - "}
            &copy; Bản quyển thuộc về Telegamez.org
          </div>
        </div>
      </div>
    </footer>
  );
};
