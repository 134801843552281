export const validateText = (value) => {
  return value?.trim() !== "";
};

export const validateTextLength = (value, length) => {
  return value?.trim().length >= length;
};

export const validateEmail = (value) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
    value
  );
};

export const validateVietnamPhoneNumber = (value) => {
  return /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/.test(value);
};

export const isHttpsWww = (value) => {
  return /^(http|https):\/\//g.test(value) || /www\./g.test(value);
};

export const isWebUrl = (value) => {
  return /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/g.test(
    value
  );
};

export const isWebUrl2 = (value) => {
  return /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)?(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm.test(
    value
  );
};

export const validateOnlyNumber = (value) => {
  return /^\d+$/g.test(value);
};

export const isFacebookUrl = (value) => {
  return /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/g.test(
    value
  );
};
