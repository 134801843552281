import React, { useState } from "react";
import { Modal, Button, Input, Form, Select } from "antd";
import { apiOrder } from "api/order";

import "./them-ngay-su-dung-modal.scss";
import ImageModal from "assets/images/image-order-date-modal.svg";
import { showMessage } from "utils/utils-show-message";

const { Option } = Select;

export const ThemNgaySuDungModal = ({
  visibleModal,
  onCancel,
  onSuccess,
  orderId,
  typeOrder,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [numberExpire, setNumberExpire] = useState(30);

  const initModal = () => {
    setNumberExpire(30);
    setBtnLoading(false);
  };

  const addExpiredDate = async () => {
    setBtnLoading(true);
    if (typeOrder === "LIMIT") {
      const res = await apiOrder.addExpiredDate(orderId.id, numberExpire);
      setBtnLoading(false);
      if (res?.status?.code === "SUCCESS") {
        onCancel();
        showMessage("success", "Tạo đơn hàng thành công");
        onSuccess();
      }
    }
    if (typeOrder === "UNLIMIT") {
      const res = await apiOrder.addUnlimitedExpiredDate(
        orderId.id,
        numberExpire
      );
      setBtnLoading(false);
      if (res?.status?.code === "SUCCESS") {
        onCancel();
        showMessage("success", "Tạo đơn hàng thành công");
        onSuccess();
      }
    } else {
      const res = await apiOrder.addFbCommentExpiredDate(
        orderId.id,
        numberExpire
      );
      setBtnLoading(false);
      if (res?.status?.code === "SUCCESS") {
        onCancel();
        showMessage("success", "Tạo đơn hàng thành công");
        onSuccess();
      }
    }
  };

  return (
    <Modal
      title="Thêm mới ngày sử dụng"
      footer={null}
      visible={visibleModal}
      wrapClassName="them-chien-dich-modal them-don-hang-modal"
      onCancel={() => {
        onCancel();
        initModal();
      }}
    >
      <div className="modal-image mb-4">
        <img src={ImageModal} alt="" />
      </div>
      <p className="text-center">
        Thao tác này sẽ thêm ngày sử dụng cho tài khoản{" "}
        <strong>{orderId.username}</strong>
      </p>
      <Form className="modal-form dark mt-3 mb-2">
        <div className="row">
          <div className="col-12">
            <div className="form-group mb-2">
              <label className="d-block">Số ngày sử dụng</label>
              <Select
                className="w-100"
                value={numberExpire}
                onChange={(value) => setNumberExpire(value)}
              >
                <Option value={1}>1</Option>
                <Option value={3}>3</Option>
                <Option value={5}>5</Option>
                <Option value={10}>10</Option>
                <Option value={15}>15</Option>
                <Option value={20}>20</Option>
                <Option value={25}>25</Option>
                <Option value={30}>30</Option>
                <Option value={40}>40</Option>
                <Option value={50}>50</Option>
                <Option value={60}>60</Option>
                <Option value={90}>90</Option>
              </Select>
            </div>
          </div>
        </div>
      </Form>
      <div className="d-flex justify-content-center">
        <Button
          className="btn btn-success btn-block w-auto"
          type="submit"
          loading={btnLoading}
          onClick={() => addExpiredDate()}
        >
          {" "}
          Thanh toán{" "}
        </Button>
      </div>
    </Modal>
  );
};
