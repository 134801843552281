import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Form, Select, DatePicker, Tabs } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { apiOrder } from "api/order";

import "./them-don-hang-tracking-fb.scss";
import ImageModal from "assets/images/image-data-modal.png";
import { showMessage } from "utils/utils-show-message";
import { validateText, validateOnlyNumber } from "utils/utils-validation";
import moment from "moment";

export const ThemDonHangTrackingFb = ({
  onCancel,
  onSuccess,
  userId,
  userName,
  oldExpiredDate,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [expiredDate, setExpiredDate] = useState(moment());
  const [numberData, setNumberData] = useState({
    value: "",
    error: "",
    help: "",
  });
  const [numberExpire, setNumberExpire] = useState({
    value: "",
    error: "",
    help: "",
  });

  useEffect(() => {
    initModal();
  }, [userId]);

  const initModal = () => {
    setIsOpen(false);
    setBtnLoading(false);
    setNumberData({ value: "", error: "", help: "" });
    setNumberExpire({ value: "", error: "", help: "" });
    if (
      oldExpiredDate &&
      moment(oldExpiredDate, "DD-MM-YYYY").isAfter(moment())
    ) {
      setExpiredDate(moment(oldExpiredDate, "DD-MM-YYYY"));
    } else {
      setExpiredDate(moment());
    }
  };

  const pricingCart = async () => {
    setIsOpen(false);
    if (
      validateNumberDataField(numberData) &&
      validateNumberExpireField(numberExpire)
    ) {
      setBtnLoading(true);
      const res = await apiOrder.createTrackingFb(
        numberData.value,
        numberExpire.value,
        userId
      );
      setBtnLoading(false);
      if (res?.status?.code === "SUCCESS") {
        onCancel();
        showMessage("success", "Tạo đơn hàng thành công");
        initModal();
        onSuccess();
      } else {
        showMessage("error", res?.status?.message);
      }
    }
  };
  const validateNumberDataField = (target) => {
    if (!validateText(target.value) || !validateOnlyNumber(target.value)) {
      setNumberData({
        ...target,
        error: "error",
        help: "Vui lòng nhập chính xác số lượng link",
      });
      return false;
    }
    setNumberData({ value: target.value, error: "", help: "" });
    return true;
  };

  const validateNumberExpireField = (target) => {
    if (!validateText(target.value) || !validateOnlyNumber(target.value)) {
      setNumberExpire({
        ...target,
        error: "error",
        help: "Vui lòng nhập chính xác số lượng ngày sử dụng",
      });
      return false;
    }
    const currentDate = moment().set({
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
    if (
      oldExpiredDate &&
      moment(oldExpiredDate, "DD-MM-YYYY")
        .add(target.value, "days")
        .isSameOrBefore(currentDate)
    ) {
      const expiredDateNew = moment(oldExpiredDate, "DD-MM-YYYY").add(
        target.value,
        "days"
      );
      setNumberExpire({
        ...target,
        error: "error",
        help: `Vui lòng thêm ${currentDate.diff(
          expiredDateNew,
          "days"
        )} ngày sử dụng để đến ngày hiện tại`,
      });
      return false;
    }
    setNumberExpire({ value: target.value, error: "", help: "" });
    return true;
  };

  const disabledDate = (current) => {
    const currentDate = moment();
    if (!oldExpiredDate) {
      return current && current < currentDate.endOf("day").add(-1, "days");
    }
    const expiredDateMoment = moment(oldExpiredDate, "DD-MM-YYYY");
    if (expiredDateMoment.isAfter(currentDate)) {
      return (
        current && current < expiredDateMoment.endOf("day").add(-1, "days")
      );
    }

    return current && current < currentDate.endOf("day").add(-1, "days");
  };

  const onDatePickerChange = (date) => {
    setIsOpen(false);
    // let sinceDate = moment().set({
    //   hours: 0,
    //   minutes: 0,
    //   seconds: 0,
    //   milliseconds: 0,
    // });
    date = moment(date).set({
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
    const oldExpiredDateMoment = moment(oldExpiredDate, "DD-MM-YYYY");
    // if (oldExpiredDateMoment.isBefore(sinceDate)) {
    //   sinceDate = oldExpiredDateMoment;
    // }
    const diffDate =
      moment.duration(date.diff(oldExpiredDateMoment)).asDays() + "";
    setExpiredDate(date);
    setNumberExpire({ value: diffDate, error: "", help: "" });
  };

  return (
    <>
      <div className="modal-image">
        <img src={ImageModal} alt="" />
      </div>
      <p className="text-center">
        Thao tác này sẽ tạo cho tài khoản <strong>{userName}</strong> đơn hàng
      </p>
      <Form className="modal-form dark mt-3 mb-2">
        <div className="row">
          <div className="col-6">
            <div className="form-group mb-2">
              <label className="d-block">Số lượng link</label>
              <Form.Item
                validateStatus={numberData.error}
                help={numberData.help}
              >
                <Input
                  className="form-control"
                  placeholder="Nhập số lượng link"
                  value={numberData.value}
                  onChange={(e) =>
                    setNumberData({ ...numberData, value: e.target.value })
                  }
                  onBlur={() => validateNumberDataField(numberData)}
                />
              </Form.Item>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group mb-2 control-number-expired">
              <label className="d-block">Số ngày sử dụng</label>
              <Form.Item
                validateStatus={numberExpire.error}
                help={numberExpire.help}
              >
                <Input
                  className="form-control w-75"
                  placeholder="Nhập số ngày sử dụng"
                  value={numberExpire.value}
                  onClick={() => setIsOpen(false)}
                  onChange={(e) =>
                    setNumberExpire({
                      ...numberExpire,
                      value: e.target.value,
                    })
                  }
                  onBlur={() => validateNumberExpireField(numberExpire)}
                />
                <Button
                  className="btn btn-blue w-auto btn-expired-picker"
                  type="button"
                  onClick={() => setIsOpen(!isOpen)}
                  disabled={["data-03", "data-06", "data-12"].includes(
                    numberData.value
                  )}
                  icon={<CalendarOutlined />}
                />
                <DatePicker
                  className={"hide-datepicker"}
                  disabledDate={disabledDate}
                  open={isOpen}
                  value={expiredDate}
                  onChange={onDatePickerChange}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
      <div className="d-flex justify-content-center">
        <Button
          className="btn btn-success btn-block w-auto"
          type="submit"
          loading={btnLoading}
          onClick={() => pricingCart()}
        >
          {" "}
          Thanh toán{" "}
        </Button>
      </div>
    </>
  );
};
