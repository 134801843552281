import React, { useState, useEffect } from "react";
import { Select, Table, Pagination, DatePicker, Form, Tooltip } from "antd";
import "./contact-detail.scss";
import { apiContact } from "api/contact";
import { useSelector } from "react-redux";
import { numberWithCommas } from "utils/utils-format";

import IconPlusFill from "assets/icons/icon-plus-fill.svg";
import IconPhoneCall from "assets/icons/icon-phone-call.svg";
import IconCancel from "assets/icons/icon-cancel.svg";
import IconEdit from "assets/icons/icon-edit.svg";
import { showMessage } from "utils/utils-show-message";
import { ThemDanhBaModal } from "../../components/them-danh-ba-modal/them-danh-ba-modal";
import { ModalConfirm } from "../../components/modal-confirm/modal-confirm";
import { Link, useParams } from "react-router-dom";
import IconLeftArrow from "../../assets/icons/icon-left-arrow.svg";
import { AddHistoryContactModal } from "../../components/add-history-contact-modal/add-history-contact-modal";
import {
  STATUS_ACCEPTED,
  STATUS_CONFIRMED,
  STATUS_ENDED,
  STATUS_FAILED,
  STATUS_PROGRESS,
  UtilsPhoneNet,
} from "../../utils/utils-phonenet";
import { CallModal } from "../../components/call-modal/call-modal";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const ContactDetail = () => {
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [visibleModalDeleteContact, setVisibleModalDeleteContact] = useState(
    false
  );
  const [visibleModalUpdate, setVisibleModalUpdate] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [modeCreate, setModeCreate] = useState(true);
  const [isEditable, setIsEditable] = useState(false);

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(10);

  const [dataContactHistory, setDataContactHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const [contact, setContact] = useState(null);
  const [contactHistory, setContactHistory] = useState(null);

  const roleIdUser = useSelector((state) => state.account.role);

  const [phonenet, setPhonenet] = useState(false);
  const [visibleCallModal, setVisibleCallModal] = useState(false);
  const [statusCall, setStatusCall] = useState(null);
  const [time, setTime] = useState("00:00");
  const [intervalCount, setIntervalCount] = useState(null);
  const [isActiveButtonCall, setIsActiveButtonCall] = useState(false);
  const userName = useSelector((state) => state.account.account?.userName);
  const stateCallio = useSelector(
    (state) => state.account.account?.stateCallio
  );
  const passExt = useSelector((state) => state.account.account?.passExt);
  const extCallio = useSelector((state) => state.account.account?.extCallio);

  const contactId = useParams().id;

  /*const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    // getCheckboxProps: (record: any) => ({
    //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
    //   name: record.name,
    // }),
  };*/

  const columns = [
    {
      title: "Ngày",
      dataIndex: "careTime",
      key: "careTime",
      width: 125,
    },
    {
      title: "Kết quả",
      dataIndex: "result",
      key: "result",
      className: "width-150-px-in-1200-column",
      ellipsis: true,
    },
    {
      title: "Tiến độ",
      dataIndex: "progress",
      key: "progress",
      className: "width-150-px-in-1200-column",
      ellipsis: true,
    },
    {
      title: "Giá trị hợp đồng",
      dataIndex: "price",
      key: "price",
      className: "width-150-px-in-1200-column",
      render: (value) => (value ? numberWithCommas(value) : ""),
      ellipsis: true,
    },
    {
      title: "Sản phẩm",
      dataIndex: "productItem",
      key: "productItem",
      className: "width-150-px-in-1200-column",
      ellipsis: true,
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
      className: "width-150-px-in-1200-column",
      ellipsis: true,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (value, row, index) => (
        <div className="row">
          {isEditable && (
            <div className="row">
              <Tooltip placement="top" title="Sửa">
                <button
                  type="button"
                  className="btn btn-md btn-rounded btn-success waves-effect waves-light d-flex align-items-center btn-icon-edit action"
                  onClick={() => editContactHistory(row)}
                >
                  <img src={IconEdit} width="12" alt="" />
                </button>
              </Tooltip>

              <Tooltip placement="top" title="Xóa">
                <button
                  type="button"
                  className="btn btn-md btn-rounded btn-danger waves-effect waves-light d-flex align-items-center ml-2 btn-icon-delete action"
                  onClick={() => openConfirmDelete(row)}
                >
                  <img src={IconCancel} width="12" alt="" />
                </button>
              </Tooltip>
            </div>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (stateCallio !== "active") {
      return;
    }
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        const phonenet = new UtilsPhoneNet(`${extCallio}`, passExt);
        phonenet.login(
          (e) => {
            setPhonenet(phonenet);
            setIsActiveButtonCall(true);
            // showMessage('success', 'Kết nối tổng đài thành công')
          },
          (e) => console.log(e)
        );
      })
      .catch((e) => {
        console.log(e);
        showMessage(
          "error",
          "Máy tính không có mic hoặc không được cấp quyền dùng mic!"
        );
      });
  }, [stateCallio]);

  const handleCall = async (row) => {
    phonenet.makeACall(
      row.phone,
      onProgress,
      onAccepted,
      onConfirmed,
      onEnded,
      onFailed
    );
  };

  const onProgress = () => {
    setStatusCall(STATUS_PROGRESS);
    setVisibleCallModal(true);
  };

  const onAccepted = () => {
    setStatusCall(STATUS_ACCEPTED);
  };

  const onConfirmed = () => {
    setStatusCall(STATUS_CONFIRMED);
    let minute = 0,
      second = 0;
    const timeInterval = setInterval(() => {
      ++second;
      if (second === 59) {
        second = 0;
        ++minute;
      }
      setTime(
        `${minute.toString().padStart(2, "0")}:${second
          .toString()
          .padStart(2, "0")}`
      );
    }, 1000);
    setIntervalCount(timeInterval);
  };

  useEffect(() => {
    if (statusCall === STATUS_ENDED) {
      clearInterval(intervalCount);
      // setStatusCall(null);
    }
  }, [intervalCount, statusCall]);

  const onEnded = () => {
    setStatusCall(STATUS_ENDED);
    // setTimeout(() => setVisibleCallModal(false), 1000);
  };

  const onFailed = (e) => {
    setStatusCall(STATUS_FAILED);
    // setTimeout(() => setVisibleCallModal(false), 1000);
  };

  const cancelCall = () => {
    setVisibleCallModal(false);
    setTime("00:00");
    phonenet.cancelCall();
  };

  const openConfirmDelete = async (row) => {
    setContactHistory(row);
    setVisibleModalDelete(true);
  };

  const deleteContact = async () => {
    const res = await apiContact.delete(contactId);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Xóa lịch sử chăm sóc khách hàng thành công");
      setVisibleModalDeleteContact(false);
      history.back();
    }
  };

  const deleteContactHistory = async (contactHistory) => {
    const res = await apiContact.deleteCareHistoryOfContact(
      contactId,
      contactHistory.id
    );
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Xóa lịch sử chăm sóc khách hàng thành công");
      setVisibleModalDelete(false);
      await getAllContactHistory();
    }
  };

  useEffect(() => {
    getDataContact();
    getAllContactHistory();
  }, [contactId]);

  const getDataContact = async () => {
    setLoading(true);
    const res = await apiContact.getById(contactId, false);
    setLoading(false);
    if (res.status.code === "SUCCESS") {
      const data = res.data;
      setContact(data);
      setIsEditable(data.owner === userName);
    } else {
      showMessage("error", "Danh bạ không tồn tại");
      setTimeout(() => (window.location.href = "/contact"), 2000);
    }
  };

  const getAllContactHistory = async () => {
    setLoading(true);
    const res = await apiContact.getAllCareHistoryOfContact(
      contactId,
      pageIndex,
      pageSize
    );
    setLoading(false);
    if (res?.status?.code === "SUCCESS") {
      const data = res.data;
      data.forEach(
        (item, index) =>
          (item.rowIndex = (pageIndex - 1) * pageSize + index + 1)
      );
      setDataContactHistory(data);
      setTotalPage(res.data.length);
    }
  };

  const editContactHistory = (row) => {
    setModeCreate(false);
    setContactHistory(row);
    setVisibleModal(true);
  };

  return (
    <div className="contact-detail-page position-relative min-height-100vh">
      <CallModal
        visibleModal={visibleCallModal}
        status={statusCall}
        time={time}
        onCancel={() => setVisibleCallModal(false)}
        onCancelCall={() => cancelCall()}
      />
      <ThemDanhBaModal
        isCreate={!visibleModalUpdate}
        contact={contact}
        visibleModal={visibleModalUpdate}
        onCancel={() => setVisibleModalUpdate(false)}
        onSuccess={() => getDataContact()}
      />
      <AddHistoryContactModal
        isCreate={modeCreate}
        contactId={contactId}
        contactHistory={contactHistory}
        visibleModal={visibleModal}
        onCancel={() => {
          setContactHistory(null);
          setVisibleModal(false);
          setModeCreate(true);
        }}
        onSuccess={() => getAllContactHistory()}
      />
      <ModalConfirm
        title={"Xóa lịch sử chăm sóc"}
        messageConfirm={"Bạn có chắc chắn muốn xóa lịch sử chăm sóc này không?"}
        visibleModal={visibleModalDelete}
        onCancel={() => setVisibleModalDelete(false)}
        onOk={() => deleteContactHistory(contactHistory)}
      />
      <ModalConfirm
        title={"Xóa danh bạ"}
        messageConfirm={"Bạn có chắc chắn muốn xóa danh bạ này không?"}
        visibleModal={visibleModalDeleteContact}
        onCancel={() => setVisibleModalDeleteContact(false)}
        onOk={() => deleteContact()}
      />
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <Form className="form-inline">
                  <Tooltip placement="top" title={"Quay lại"}>
                    <Link to={"/contact"}>
                      <button className="btn btn-secondary btn-sm ml-2">
                        <img src={IconLeftArrow} alt="" width="11" />
                      </button>
                    </Link>
                  </Tooltip>
                </Form>
              </div>
              <h4 className="page-title">Chi Tiết Danh Bạ</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card-box">
              <h4 className="header-title d-flex align-items-center flex-wrap justify-content-between">
                <div className="col-3">
                  <span className="mb-2">Thông tin khách hàng</span>
                </div>
                <div className="col-9">
                  <span className="mb-2">Thông tin lịch sử chăm sóc</span>
                </div>
              </h4>
              <div className="row">
                <div className="col-3">
                  <div className="col-12">
                    <label>Tên khách hàng: {contact?.fullName}</label>
                  </div>
                  <div className="col-12">
                    <label>Số điện thoại: {contact?.phone}</label>
                  </div>
                  <div className="col-12">
                    <label>Zalo: {contact?.zalo}</label>
                  </div>
                  <div className="col-12">
                    <label>Website: {contact?.website}</label>
                  </div>
                  <div className="col-12">
                    <label>Công ty: {contact?.company}</label>
                  </div>
                  <div className="col-12">
                    <label>Chức vụ: {contact?.position}</label>
                  </div>
                  <div className="col-12">
                    <label>Lĩnh vực: {contact?.work}</label>
                  </div>
                  <div className="col-12">
                    <label>Tỉnh/ thành phố: {contact?.location}</label>
                  </div>
                  <div className="col-12">
                    <label>Trạng thái: {contact?.status}</label>
                  </div>
                  <div className="col-12">
                    <label>Ghi chú: {contact?.note}</label>
                  </div>
                  {isEditable && (
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-md btn-success waves-effect waves-light text-white"
                        onClick={() => {
                          setContact(contact);
                          setVisibleModalUpdate(true);
                        }}
                      >
                        Chỉnh sửa
                      </button>
                      <button
                        type="button"
                        className="btn btn-md btn-danger waves-effect waves-light ml-2 text-white"
                        onClick={() => {
                          setVisibleModalDeleteContact(true);
                        }}
                      >
                        Xóa
                      </button>
                    </div>
                  )}
                </div>
                <div className="col-9">
                  <div className="row d-flex justify-content-end">
                    {isActiveButtonCall && (
                      <button
                        type="button"
                        className="btn btn-sm btn-success waves-effect mb-2 mr-2 waves-light float-right"
                        onClick={() => handleCall(contact)}
                        disabled={stateCallio !== "active"}
                      >
                        <img
                          src={IconPhoneCall}
                          width="12"
                          className="mr-1"
                          alt=""
                        />{" "}
                        Gọi điện
                      </button>
                    )}
                    {isEditable && (
                      <button
                        type="button"
                        className="btn btn-sm btn-blue waves-effect mb-2 mr-2 waves-light float-right"
                        onClick={() => {
                          setContactHistory(null);
                          setModeCreate(true);
                          setVisibleModal(true);
                        }}
                      >
                        <img
                          src={IconPlusFill}
                          width="12"
                          className="mr-1"
                          alt=""
                        />{" "}
                        Thêm mới
                      </button>
                    )}
                  </div>
                  <Table
                    loading={loading}
                    className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                    dataSource={dataContactHistory}
                    columns={columns}
                    pagination={false}
                  />

                  {dataContactHistory.length !== 0 && (
                    <div className="table-panigate d-flex align-items-center justify-content-between mt-3">
                      <div className="dataTables_info">
                        Trang {pageIndex} trong tổng số{" "}
                        {Math.ceil(totalPage / pageSize)} trang
                      </div>
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="tool-item select d-flex align-items-center pagesize-select">
                          Hiển thị
                          <Select
                            value={pageSize}
                            onSelect={(value) => setPageSize(value)}
                          >
                            <Option value="10">10</Option>
                            <Option value="30">30</Option>
                            <Option value="50">50</Option>
                            <Option value="70">70</Option>
                            <Option value="100">100</Option>
                          </Select>
                          dữ liệu
                        </div>
                        <div className="table-pagination">
                          <Pagination
                            current={pageIndex}
                            pageSize={pageSize}
                            total={totalPage}
                            showSizeChanger={false}
                            onChange={(page) => setPageIndex(page)}
                            hideOnSinglePage={true}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
