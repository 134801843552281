import React, { useState } from "react";
import moment from "moment";
import { Modal, Button, DatePicker, Form } from "antd";
import { apiCampaign } from "api/campaign";
import { reverseDate } from "utils/utils-format";

import "./xuat-excel-data-keyword-modal.scss";
import ImageModal from "assets/images/image-export-modal.png";
import { showMessage } from "utils/utils-show-message";

const { RangePicker } = DatePicker;

export const ExportDataKeywordModal = ({
  visibleModal,
  onCancel,
  onSuccess,
  campaignId,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [error, setError] = useState("");
  const [help, setHelp] = useState("");

  const initModal = () => {
    setError("");
    setHelp("");
    setFromDate("");
    setToDate("");
  };

  const exportExcel = async () => {
    if (validateExportDate()) {
      setBtnLoading(true);
      const res = await apiCampaign.exportDataKeyword(
        campaignId,
        fromDate,
        toDate
      );
      setBtnLoading(false);
      if (res?.status?.code === "SUCCESS") {
        showMessage(
          "success",
          "Xuất Excel thành công. Kiểm tra hộp thư của bạn"
        );
        onSuccess();
      } else {
        showMessage("error", res?.status?.message ?? "Đã xảy ra lỗi");
      }
    }
  };

  const validateExportDate = () => {
    if (!fromDate || !toDate) {
      setError("error");
      setHelp("Yêu cầu chọn khoảng ngày muốn xuất dữ liệu");
      return false;
    }
    setError("");
    setHelp("");
    return true;
  };

  const handleDatePicker = (date, dateString) => {
    const startDate = dateString[0];
    const endDate = dateString[1];
    setFromDate(startDate);
    setToDate(endDate);
  };

  return (
    <Modal
      title="Xuất Excel"
      footer={null}
      visible={visibleModal}
      wrapClassName="them-chien-dich-modal them-don-hang-modal xuat-excel-data-keyword-modal"
      onCancel={() => {
        initModal();
        onCancel();
      }}
    >
      <div className="modal-image">
        <img src={ImageModal} alt="" />
      </div>
      <p className="text-center">
        Chọn khoảng ngày muốn xuất dữ liệu. <br />
        Hệ thống sẽ gửi qua tin nhắn Telegram
      </p>
      <Form className="modal-form dark mt-3 mb-0">
        <div className="form-group mb=0">
          <label>Chọn ngày</label>
          <Form.Item validateStatus={error} help={help}>
            <RangePicker
              className="w-100"
              format="DD-MM-YYYY"
              value={[
                fromDate ? moment(reverseDate(fromDate)) : null,
                toDate ? moment(reverseDate(toDate)) : null,
              ]}
              placeholder={["Từ ngày", "Đến ngày"]}
              onChange={(date, dateString) =>
                handleDatePicker(date, dateString)
              }
            />
          </Form.Item>
        </div>
      </Form>
      <div className="d-flex justify-content-center">
        <Button
          className="btn btn-success btn-block w-auto"
          type="submit"
          loading={btnLoading}
          onClick={() => exportExcel()}
        >
          {" "}
          Xuất Excel{" "}
        </Button>
      </div>
    </Modal>
  );
};
