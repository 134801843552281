import React, { useState, useEffect } from "react";
import moment from "moment";
import "./lich-su-mua-hang.scss";
import {
  Input,
  Select,
  Table,
  Pagination,
  DatePicker,
  Form,
  Dropdown,
} from "antd";
import { apiOrder } from "api/order";
import { numberWithCommas, reverseDate } from "utils/utils-format";
import { InputAutoComplete } from "components/input-autocomplete/input-autocomplete";

import IconFilter from "assets/icons/icon-filter.svg";
import IconRefresh from "assets/icons/icon-refresh.svg";
import IconEdit from "../../assets/icons/icon-edit.svg";
import IconVerticalDots from "../../assets/icons/icon-vertical-dots.svg";
import { ThemNgaySuDungModal } from "../../components/them-ngay-su-dung-modal/them-ngay-su-dung-modal";
import { ThemDonHangModal } from "../../components/them-don-hang-modal/them-don-hang-modal";
import { useSelector } from "react-redux";
import { SelectAgency } from "components/select-dai-ly/select-dai-ly";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const LichSuMuaHang = () => {
  const roleIdUser = useSelector((state) => state.account.role);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [dataOrder, setDataOrder] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchMethod, setSearchMethod] = useState("userName");
  const [userName, setUserName] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [visibleOrderModal, setVisibleOrderModal] = useState(false);
  const [suggestionAccount, setSuggestionAccount] = useState([]);
  const [orderId, setOrderId] = useState({});
  const [typeOrder, setTypeOrder] = useState("LIMIT");
  const [agencyIdSelected, setAgencyIdSelected] = useState(0);

  const actionTableDropdown = (value, row, index) => {
    return (
      <div className="order-dropdown">
        <div
          key="them-ngay-su-dung"
          className="dropdown-item d-flex align-items-center"
          onClick={() => {
            setOrderId({ id: row.id, username: row.nguoiMua });
            setVisibleOrderModal(true);
          }}
        >
          <img src={IconEdit} alt="" />
          Thêm ngày sử dụng
        </div>
      </div>
    );
  };

  const columnsOrder = [
    {
      title: "#",
      dataIndex: "stt",
      key: "stt",
      width: 50,
    },
    {
      title: "Gói mua",
      dataIndex: "soLuong",
      key: "soLuong",
      className: "text-nowrap width-125-px-in-768-column",
    },
    {
      title: "Ngày mua",
      dataIndex: "ngayMua",
      key: "ngayMua",
      className: "text-nowrap width-125-px-in-768-column",
    },
    {
      title: "Ngày hết hạn",
      dataIndex: "ngayHetHan",
      key: "ngayHetHan",
      className: "text-nowrap width-125-px-in-768-column",
    },
    {
      title: "Người mua",
      dataIndex: "nguoiMua",
      key: "nguoiMua",
      className: "text-nowrap",
    },
    {
      title: ["ROOT_ADMIN", "ADMIN", "AGENCY"].includes(roleIdUser)
        ? "Tổng tiền"
        : "",
      dataIndex: "tongTien",
      key: "tongTien",
      className: "text-nowrap width-125-px-in-768-column",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (value, row, index) =>
        ["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) && (
          <Dropdown
            overlay={actionTableDropdown(value, row, index)}
            trigger={["click"]}
            placement="bottomRight"
            className="table-action-btn dropdown-toggle arrow-none btn btn-light btn-sm"
          >
            <div className="table-action-icon">
              <img width="13" src={IconVerticalDots} alt="" />
            </div>
          </Dropdown>
        ),
    },
  ];

  const getAllSuggestion = async () => {
    const res = await apiOrder.getAll({
      pageIndex: 1,
      pageSize: 100,
      userName: null,
      url: null,
      agencyId: agencyIdSelected,
    });
    if (res?.status?.code === "SUCCESS") {
      const data = res.data;
      const suggestionAccount = [];
      data.forEach((item) => {
        suggestionAccount.push(item.user.userName);
      });
      setSuggestionAccount(suggestionAccount);
    }
  };

  const renderSuggestion = () => {
    if (searchMethod === "userName") {
      return suggestionAccount;
    }
    return [];
  };

  useEffect(() => {
    getAllSuggestion();
  }, []);

  useEffect(() => {
    getDataOrder();
  }, [userName, agencyIdSelected]);

  const onSearch = (value) => {
    setPageIndex(1);
    if (searchMethod === "userName") {
      setUserName(value);
    }
  };

  useEffect(() => {
    getDataOrder();
  }, [pageIndex, pageSize, toDate]);

  useEffect(() => {
    renderPlaceholder();
    checkIsFilter();
  }, [searchMethod, isFilter]);

  const handleDatePicker = (date, dateString) => {
    const startDate = dateString[0];
    const endDate = dateString[1];
    setPageIndex(1);
    setFromDate(startDate);
    setToDate(endDate);
  };

  const resetSearchValue = () => {
    setUserName("");
  };

  const renderPlaceholder = () => {
    switch (searchMethod) {
      case "userName":
        return "Nhập Username cần tìm";
      default:
        return "";
    }
  };

  const checkIsFilter = () => {
    if (
      userName !== "" ||
      searchMethod !== "userName" ||
      fromDate !== "" ||
      toDate !== ""
    ) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  };

  const deleteFilter = () => {
    setUserName("");
    setFromDate("");
    setToDate("");
    setPageIndex(1);
    setSearchMethod("userName");
    getDataOrder();
  };

  useEffect(() => {
    getDataOrder();
  }, [typeOrder]);

  const getDataOrder = () => {
    if (typeOrder === "LIMIT") getAllOrder();
    if (typeOrder === "UNLIMIT") getAllUnlimitedOrder();
    if (typeOrder === "FACEBOOK_COMMENT") getAllFacebookTrackingOrder();
  };

  const getAllOrder = async () => {
    checkIsFilter();
    setLoading(true);
    const res = await apiOrder.getAll({
      pageIndex,
      pageSize,
      fromDate,
      toDate,
      userName,
      agencyId: agencyIdSelected,
    });
    setLoading(false);
    if (res?.status?.code === "SUCCESS") {
      const data = [];
      res.data.forEach((item, index) => {
        const getData = {
          stt: index + 1,
          id: item.id,
          soLuong: item.numberOfData,
          ngayMua: item.registerDate.slice(0, 10),
          ngayHetHan: item.expiredDate,
          nguoiMua: item.user.userName,
          tongTien: ["ROOT_ADMIN", "ADMIN", "AGENCY"].includes(roleIdUser)
            ? numberWithCommas(item.total)
            : "",
        };
        data.push(getData);
      });
      setTotalPage(res.totalItem);
      setDataOrder(data);
    }
  };

  const getAllUnlimitedOrder = async () => {
    checkIsFilter();
    setLoading(true);
    const res = await apiOrder.getAllUnlimited({
      pageIndex,
      pageSize,
      fromDate,
      toDate,
      userName,
      agencyId: agencyIdSelected,
    });
    setLoading(false);
    if (res?.status?.code === "SUCCESS") {
      const data = [];
      res.data.forEach((item, index) => {
        const getData = {
          stt: index + 1,
          id: item.id,
          soLuong: "Không giới hạn",
          ngayMua: item.createdDate.slice(0, 10),
          ngayHetHan: item.finallyDate,
          nguoiMua: item.user?.userName,
          tongTien: ["ROOT_ADMIN", "ADMIN", "AGENCY"].includes(roleIdUser)
            ? numberWithCommas(item.price)
            : "",
        };
        data.push(getData);
      });
      setTotalPage(res.totalItem);
      setDataOrder(data);
    }
  };

  const getAllFacebookTrackingOrder = async () => {
    checkIsFilter();
    setLoading(true);
    const res = await apiOrder.getAllFacebookTracking({
      pageIndex,
      pageSize,
      fromDate,
      toDate,
      userName,
      agencyId: agencyIdSelected,
    });
    setLoading(false);
    if (res?.status?.code === "SUCCESS") {
      const data = [];
      res.data.forEach((item, index) => {
        const getData = {
          stt: index + 1,
          id: item.id,
          soLuong: item.numberOfData,
          ngayMua: item.registerDate.slice(0, 10),
          ngayHetHan: item.expiredDate,
          nguoiMua: item.user?.userName,
          tongTien: ["ROOT_ADMIN", "ADMIN", "AGENCY"].includes(roleIdUser)
            ? numberWithCommas(item.price)
            : "",
        };
        data.push(getData);
      });
      setTotalPage(res.totalItem);
      setDataOrder(data);
    }
  };

  const handleAgencyChange = (agencyId) => {
    setAgencyIdSelected(agencyId);
  };

  return (
    <div className="lich-su-mua-hang-page min-height-100vh">
      <ThemNgaySuDungModal
        orderId={orderId}
        visibleModal={visibleOrderModal}
        typeOrder={typeOrder}
        userName={userName}
        onSuccess={() => getDataOrder()}
        onCancel={() => {
          setVisibleOrderModal(false);
          setOrderId({});
        }}
      />
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <Form className="form-inline">
                  <button
                    className="btn btn-secondary btn-sm ml-2"
                    onClick={() => {
                      resetSearchValue();
                      setFromDate("");
                      setToDate("");
                      getDataOrder();
                    }}
                  >
                    <img src={IconRefresh} alt="" width="11" />
                  </button>
                </Form>
              </div>
              <h4 className="page-title">Lịch sử mua hàng</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card-box">
              <h4 className="header-title d-flex align-items-center flex-wrap justify-content-between">
                <span className="mb-2">Quản lý đơn hàng</span>
                <div className="d-flex align-items-center flex-wrap">
                  {isFilter && (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger waves-effect waves-light mb-2 d-flex align-items-center"
                      onClick={() => deleteFilter()}
                    >
                      <img
                        src={IconFilter}
                        width="12"
                        className="mr-1"
                        alt=""
                      />{" "}
                      Xoá bộ lọc
                    </button>
                  )}
                </div>
              </h4>

              {["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) && (
                <div className="table-tools d-flex align-items-center justify-content-between mb-3">
                  <div className="tool-item filter d-flex align-items-center">
                    <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                      Đại lý:
                      <SelectAgency
                        placeholder="Tất cả"
                        onChange={(value) => handleAgencyChange(value)}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="table-tools d-flex align-items-center justify-content-between mb-3">
                <div className="tool-item filter d-flex align-items-center">
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                    Kiểu tìm kiếm
                    <Select
                      value={searchMethod}
                      onSelect={(value) => {
                        resetSearchValue();
                        setSearchMethod(value);
                      }}
                    >
                      <Option value="userName">User name</Option>
                    </Select>
                  </div>
                  <div className="tool-item search d-flex align-items-center text-nowrap mr-3">
                    Tìm kiếm:
                    <InputAutoComplete
                      isFilter={isFilter}
                      dataSource={renderSuggestion()}
                      placeholder={renderPlaceholder()}
                      onSearch={(value) => onSearch(value)}
                    />
                  </div>
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                    Loại đơn hàng:
                    <Select
                      value={typeOrder}
                      onSelect={(value) => {
                        resetSearchValue();
                        setTypeOrder(value);
                      }}
                    >
                      <Option value="LIMIT">Loại giới hạn</Option>
                      <Option value="UNLIMIT">Loại không giới hạn</Option>
                      <Option value="FACEBOOK_COMMENT">
                        Loại facebook comment
                      </Option>
                    </Select>
                  </div>
                </div>
                <div className="tool-item search d-flex align-items-center text-nowrap">
                  <RangePicker
                    format="DD-MM-YYYY"
                    value={[
                      fromDate ? moment(reverseDate(fromDate)) : null,
                      toDate ? moment(reverseDate(toDate)) : null,
                    ]}
                    placeholder={["Từ ngày", "Đến ngày"]}
                    onChange={(date, dateString) =>
                      handleDatePicker(date, dateString)
                    }
                  />
                </div>
              </div>

              <Table
                loading={loading}
                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                dataSource={dataOrder}
                columns={columnsOrder}
                pagination={false}
              />

              {dataOrder.length !== 0 && (
                <div className="table-panigate d-flex align-items-center justify-content-between mt-3">
                  <div className="dataTables_info">
                    Trang {pageIndex} trong tổng số{" "}
                    {Math.ceil(totalPage / pageSize)} trang
                  </div>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="tool-item select d-flex align-items-center pagesize-select">
                      Hiển thị
                      <Select
                        value={pageSize}
                        onSelect={(value) => setPageSize(value)}
                      >
                        <Option value="10">10</Option>
                        <Option value="30">30</Option>
                        <Option value="50">50</Option>
                        <Option value="70">70</Option>
                        <Option value="100">100</Option>
                      </Select>
                      dữ liệu
                    </div>
                    <div className="table-pagination">
                      <Pagination
                        current={pageIndex}
                        pageSize={pageSize}
                        total={totalPage}
                        showSizeChanger={false}
                        onChange={(page) => setPageIndex(page)}
                        hideOnSinglePage={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
