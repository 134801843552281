import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Form, Select } from "antd";
import { apiCampaign } from "api/campaign";

import ImageModal from "assets/images/image-approve-campaign.svg";
import { showMessage } from "utils/utils-show-message";
import { validateText, validateOnlyNumber } from "utils/utils-validation";

const { Option } = Select;

export const DuyetChienDichModal = ({
  visibleModal,
  onCancel,
  onSuccess,
  domainUrl,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [price, setPrice] = useState({
    value: "",
    error: "",
    help: "",
  });
  const [numberExpire, setNumberExpire] = useState({
    value: "",
    error: "",
    help: "",
  });

  const initModal = () => {
    setBtnLoading(false);
    setNumberExpire({ value: "", error: "", help: "" });
    setPrice({ value: "", error: "", help: "" });
  };

  const approveDomain = async () => {
    if (validateNumberExpireField(numberExpire) && validatePriceField(price)) {
      setBtnLoading(true);
      const res = await apiCampaign.approveCampaign(
        domainUrl,
        numberExpire.value,
        price.value
      );
      if (res?.status?.code === "SUCCESS") {
        showMessage("success", "Duyệt chiến dịch thành công");
        onSuccess();
        initModal();
      } else {
        showMessage("error", res.status.message);
      }
    }
  };
  const validatePriceField = (target) => {
    if (!validateText(target.value) || !validateOnlyNumber(target.value)) {
      setPrice({
        ...target,
        error: "error",
        help: "Vui lòng nhập chính xác đơn giá",
      });
      return false;
    }
    setPrice({ value: target.value, error: "", help: "" });
    return true;
  };
  const validateNumberExpireField = (target) => {
    if (!validateText(target.value) || !validateOnlyNumber(target.value)) {
      setNumberExpire({
        ...target,
        error: "error",
        help: "Vui lòng nhập chính xác số lượng ngày sử dụng",
      });
      return false;
    }
    setNumberExpire({ value: target.value, error: "", help: "" });
    return true;
  };
  return (
    <Modal
      title="Duyệt chiến dịch"
      footer={null}
      visible={visibleModal}
      wrapClassName="them-chien-dich-modal them-don-hang-modal"
      onCancel={() => {
        onCancel();
        initModal();
      }}
    >
      <div className="modal-image">
        <img src={ImageModal} alt="" />
      </div>
      <Form className="modal-form dark mt-3 mb-2">
        <div className="row">
          <div className="col-12">
            <div className="form-group mb-2">
              <label className="d-block">Domain</label>
              <Form.Item validateStatus="" help="">
                <Input
                  disabled={true}
                  className="form-control"
                  value={domainUrl}
                />
              </Form.Item>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group mb-2">
              <label className="d-block">Giá</label>
              <Form.Item validateStatus={price.error} help={price.help}>
                <Input
                  onBlur={() => validatePriceField(price)}
                  placeholder="Nhập giá"
                  className="form-control"
                  value={price.value}
                  onChange={(e) =>
                    setPrice({ ...price, value: e.target.value })
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group mb-2">
              <label className="d-block">Số ngày sử dụng</label>
              <Form.Item
                validateStatus={numberExpire.error}
                help={numberExpire.help}
              >
                <Input
                  className="form-control"
                  placeholder="Nhập Số ngày sử dụng"
                  value={numberExpire.value}
                  onChange={(e) =>
                    setNumberExpire({
                      ...numberExpire,
                      value: e.target.value,
                    })
                  }
                  onBlur={() => validateNumberExpireField(numberExpire)}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
      <div className="d-flex justify-content-center">
        <Button
          className="btn btn-success btn-block w-auto"
          type="submit"
          loading={btnLoading}
          onClick={() => approveDomain()}
        >
          {" "}
          Duyệt{" "}
        </Button>
      </div>
    </Modal>
  );
};
