import React from "react";
import { Link } from "react-router-dom";
import Logo from "assets/images/logo.png";

export const PageError = () => {
  return (
    <div className="page-not-found">
      <div className="authentication-bg authentication-bg-pattern pt-5">
        <div className="account-pages">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card bg-pattern">
                  <div className="card-body p-4">
                    <div className="auth-logo">
                      <a
                        href="index.html"
                        className="data-tuoi-logo light mb-2"
                      >
                        <img src={Logo} alt="" height="22" />
                        Telegamez.org
                      </a>
                    </div>

                    <div className="text-center mt-4">
                      <h1 className="text-error">500</h1>
                      <h3 className="mt-3 mb-2">Internal Server Error</h3>
                      <p className="text-muted mb-3">
                        Có vẻ như đã xảy ra một lỗi gì đó. Hãy liên hệ với{" "}
                        <a href="" className="text-dark">
                          <b>Chúng tôi</b>
                        </a>
                      </p>

                      <Link
                        to="/"
                        className="btn btn-success waves-effect waves-light"
                      >
                        Quay trở lại trang chủ
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
