import "./App.css";
import "antd/dist/antd.css";
import "assets/css/bootstrap-creative-dark.css";
import "assets/css/app-creative-dark.css";
import "assets/css/global.scss";
import "assets/css/icons.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthLayout } from "layout/auth-layout/auth-layout";
import { DangNhap } from "pages/dang-nhap/dang-nhap";
import { DangKy } from "pages/dang-ky/dang-ky";
import { QuenMatKhau } from "pages/quen-mat-khau/quen-mat-khau";
import { DoiMatKhau } from "pages/doi-mat-khau/doi-mat-khau";
import { XacThuc } from "pages/xac-thuc/xac-thuc";
import { TongQuan } from "pages/tong-quan/tong-quan";
import { PageError } from "pages/500/500";
import { PageNotFound } from "pages/404/404";
import { ChienDich } from "pages/chien-dich/chien-dich";
import { Telesales } from "pages/telesales/telesales";
import { KhachHang } from "pages/khach-hang/khach-hang";
import { TaiKhoan } from "pages/tai-khoan/tai-khoan";
import { LichSuMuaHang } from "pages/lich-su-mua-hang/lich-su-mua-hang";
import { ThanhVien } from "pages/thanh-vien/thanh-vien";
import { WebsiteStop } from "pages/website-stop/website-stop";
import { FacebookScan } from "./pages/facebook-scan/facebook-scan";
import { ThanhVienCallio } from "./pages/thanh-vien-callio/thanh-vien-callio";
import { KhachHangDetail } from "./pages/khach-hang-detail/khach-hang-detail";
import { ChienDichTuKhoa } from "pages/tu-khoa/tu-khoa";
import { Contact } from "pages/contact/contact";
import { ContactDetail } from "pages/contact-detail/contact-detail";
import { FacebookTracking } from "pages/facebook-tracking/facebook-tracking";
import { FacebookCommentTracking } from "pages/facebook-comment-tracking/facebook-comment-tracking";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/dang-nhap" exact component={DangNhap} />
          {/*<Route path="/dang-ky" exact component={DangKy} />*/}
          <Route path="/quen-mat-khau" exact component={QuenMatKhau} />
          <Route path="/doi-mat-khau/:id" exact component={DoiMatKhau} />
          <Route path="/xac-thuc" exact component={XacThuc} />
          <Route path="/500" exact component={PageError} />
          <Route path="/404" exact component={PageNotFound} />
          {/* Auth */}
          <AuthLayout>
            <Route path="/" exact component={TongQuan} />
            <Route path="/khach-hang" exact component={KhachHang} />
            <Route path="/khach-hang/:id" exact component={KhachHangDetail} />
            <Route path="/facebook-scan" exact component={FacebookScan} />
            <Route path="/chien-dich" exact component={ChienDich} />
            <Route
              path="/chien-dich-tu-khoa"
              exact
              component={ChienDichTuKhoa}
            />
            <Route path="/telesales" exact component={Telesales} />
            <Route path="/lich-su-mua-hang" exact component={LichSuMuaHang} />
            <Route
              path="/thanh-vien-callio"
              exact
              component={ThanhVienCallio}
            />
            <Route path="/thanh-vien" exact component={ThanhVien} />
            <Route path="/tai-khoan" exact component={TaiKhoan} />
            <Route path="/website-stop" exact component={WebsiteStop} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/contact/:id" exact component={ContactDetail} />
            <Route
              path="/facebook-tracking"
              exact
              component={FacebookTracking}
            />
            <Route
              path="/facebook-tracking/:id"
              exact
              component={FacebookCommentTracking}
            />
            {/*<Route component={PageNotFound} />*/}
          </AuthLayout>

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
