import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Input,
  Select,
  Table,
  Dropdown,
  Pagination,
  DatePicker,
  Form,
  Modal,
  Button,
} from "antd";
import "./tu-khoa.scss";
import { apiCampaign } from "api/campaign";
import { useSelector } from "react-redux";
import { reverseDate, formatId } from "utils/utils-format";

import { DuyetTuKhoaModal } from "components/duyet-tu-khoa-modal/duyet-tu-khoa-modal";
import { ThemTuKhoaModal } from "components/them-tu-khoa-modal/them-tu-khoa-modal";
import { ExportDataKeywordModal } from "components/xuat-excel-data-keyword-modal/xuat-excel-data-keyword-modal";
import { InputAutoComplete } from "components/input-autocomplete/input-autocomplete";

import IconCheckCircleGray from "assets/icons/icon-check-circle-gray.svg";
import IconPlusFill from "assets/icons/icon-plus-fill.svg";
import IconVerticalDots from "assets/icons/icon-vertical-dots.svg";
import IconExcel from "assets/icons/icon-excel.svg";
import IconEdit from "assets/icons/icon-edit.svg";
import IconFilter from "assets/icons/icon-filter.svg";
import IconRefresh from "assets/icons/icon-refresh.svg";
import IconTrash from "assets/icons/icon-trash.svg";
import IconLink from "assets/icons/icon-link.svg";

import { showMessage } from "utils/utils-show-message";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const ChienDichTuKhoa = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalData, setVisibleModalData] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(10);
  const [dataCampaign, setDataCampaign] = useState([]);
  const [loading, setLoading] = useState(false);
  const [domainUrl, setDomainUrl] = useState("");
  const [keywords, setKeywords] = useState([]);

  const [stateApprove, setStateApprove] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchMethod, setSearchMethod] = useState("campaignName");
  const [isFilter, setIsFilter] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [userName, setUserName] = useState("");
  const [suggestionAccount, setSuggestionAccount] = useState([]);
  const [suggestionCampaignName, setSuggestionCampaignName] = useState([]);
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [visibleModalAddUrl, setVisibleModalAddUrl] = useState(false);
  const [recordSelected, setRecordSelected] = useState(null);
  const [currentTypeForm, setCurrentTypeForm] = useState("create");
  const [visibleExportModal, setVisibleExportModal] = useState(false);
  const [dataUrl, setDataUrl] = useState("");

  const roleIdUser = useSelector((state) => state.account.role);

  const actionTableDropdown = (value, row, index) => {
    return (
      <div className="account-dropdown">
        {["ADMIN", "ROOT_ADMIN"].includes(roleIdUser) &&
          row.trangThai === "pending" && (
            <div
              key="4"
              className="dropdown-item d-flex align-items-center"
              onClick={() => {
                // setDomainUrl(row.chienDich);
                // setKeywords(row.keywords);
                setRecordSelected(row);
                setVisibleModalData(true);
              }}
            >
              <img src={IconCheckCircleGray} alt="" />
              Phê duyệt
            </div>
          )}
        <div
          key="5"
          className="dropdown-item d-flex align-items-center"
          onClick={() => {
            // console.log("Row: " + JSON.stringify(row));
            setRecordSelected(row);
            setCurrentTypeForm("edit");
            setVisibleModal(true);
          }}
        >
          <img src={IconEdit} alt="" />
          Chỉnh sửa
        </div>
        <div
          key="6"
          className="dropdown-item d-flex align-items-center"
          onClick={() => {
            setRecordSelected(row);
            setVisibleExportModal(true);
          }}
        >
          <img src={IconExcel} style={{ background: "green" }} alt="" />
          Xuất Excel
        </div>
        <div
          key="7"
          className="dropdown-item d-flex align-items-center"
          onClick={() => openConfirmDelete(row)}
        >
          <img src={IconTrash} alt="" />
          Xóa chiến dịch
        </div>
        {["ADMIN", "ROOT_ADMIN"].includes(roleIdUser) && (
          <div
            key="8"
            className="dropdown-item d-flex align-items-center"
            onClick={() => openModalAddUrl(row)}
          >
            <img src={IconLink} alt="" />
            Thêm nguồn dữ liệu
          </div>
        )}
      </div>
    );
  };

  const columnsCampaign = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      className: "width-125-px",
    },
    {
      title: "Chiến dịch",
      dataIndex: "chienDich",
      key: "chienDich",
      className: "width-150-px",
      ellipsis: true,
    },
    {
      title: "Tài khoản",
      dataIndex: "taiKhoan",
      key: "taiKhoan",
      className: "width-125-px",
      hidden: ["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) ? false : true,
    },
    {
      title: "Sản lượng",
      dataIndex: "sanLuong",
      key: "sanLuong",
      className: "width-125-px",
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      className: "width-125-px",
      render: (value, row, index) => {
        return (
          <div className="d-flex">
            {/* {row.trangThai === "ACTIVE" && (
              <span className="badge mr-1 badge-success">Hoạt động</span>
            )}
            {row.trangThai === "INACTIVE" && (
              <span className="badge mr-1 bg-soft-danger text-danger">
                Tạm dừng
              </span>
            )} */}
            {row.trangThai === "pending" && (
              <span className="badge mr-1 badge-warning">Chờ duyệt</span>
            )}
            {row.trangThai === "approve" && (
              <span className="badge mr-1 badge-primary">Đã duyệt</span>
            )}
            {row.trangThai === "reject" && (
              <span className="badge mr-1 badge-danger">Không duyệt</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "ngayTao",
      key: "ngayTao",
      className: "width-125-px",
    },
    {
      title: "Ngày hết hạn",
      dataIndex: "ngayHetHan",
      key: "ngayHetHan",
      className: "width-125-px",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (value, row, index) => (
        <Dropdown
          overlay={actionTableDropdown(value, row, index)}
          trigger={["click"]}
          placement="bottomRight"
          className="table-action-btn dropdown-toggle arrow-none btn btn-light btn-sm"
        >
          <div className="table-action-icon">
            <img width="13" src={IconVerticalDots} alt="" />
          </div>
        </Dropdown>
      ),
    },
  ].filter((item) => !item.hidden);

  const renderPlaceholder = () => {
    switch (searchMethod) {
      case "campaignName":
        return "Nhập tên chiến dịch cần tìm";
      case "userName":
        return "Nhập tên tài khoản cần tìm";
      default:
        return "";
    }
  };

  const resetSearchValue = () => {
    setCampaignName("");
    setUserName("");
  };

  const checkIsFilter = () => {
    if (
      campaignName !== "" ||
      searchMethod !== "campaignName" ||
      fromDate !== "" ||
      toDate !== "" ||
      stateApprove !== ""
    ) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  };

  const deleteFilter = () => {
    setCampaignName("");
    setStateApprove("");
    setFromDate("");
    setToDate("");
    setUserName("");
    setPageIndex(1);
    setIsFilter(false);
    setSearchMethod("campaignName");
    getAllCampaign();
  };

  const openConfirmDelete = (row) => {
    setVisibleModalDelete(true);
    setRecordSelected(row);
  };

  const openModalAddUrl = (row) => {
    setVisibleModalAddUrl(true);
    setRecordSelected(row);
  };

  const deleteCampaign = async () => {
    let res;
    if (["ROOT_ADMIN", "ADMIN"].includes(roleIdUser)) {
      res = await apiCampaign.deleteKeywordCampaign(recordSelected.chienDichId);
    } else {
      res = await apiCampaign.deleteKeywordCampaign(recordSelected.chienDichId);
    }
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Xóa chiến dịch thành công");
      setVisibleModalDelete(false);
      setRecordSelected(null);
      getAllCampaign();
    } else {
      showMessage("error", res?.status?.message);
    }
  };

  const updateDataUrl = async () => {
    let res;
    res = await apiCampaign.addDataId(recordSelected.chienDichId, dataUrl);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", res?.status?.message);
      setVisibleModalAddUrl(false);
      setRecordSelected(null);
      setDataUrl("");
    } else {
      showMessage("error", res?.status?.message);
    }
  };

  const handleDatePicker = (date, dateString) => {
    const startDate = dateString[0];
    const endDate = dateString[1];
    setPageIndex(1);
    setFromDate(startDate);
    setToDate(endDate);
  };

  const getAllSuggestion = async () => {
    const res = await apiCampaign.getAllKeywordCampaign({
      pageIndex: 1,
      pageSize: 100,
    });
    if (res?.status?.code === "SUCCESS") {
      const data = res.data;
      const suggestionCampaignName = [];
      const suggestionAccount = [];
      data.forEach((item) => {
        // console.log(item);
        suggestionCampaignName.push(item.campaignName);
        suggestionAccount.push(item.userName);
      });
      // console.log(suggestionAccount);
      setSuggestionCampaignName(suggestionCampaignName);
      setSuggestionAccount(suggestionAccount);
    }
  };

  const renderSuggestion = () => {
    if (searchMethod === "campaignName") {
      return suggestionCampaignName;
    }
    if (searchMethod === "userName") {
      return suggestionAccount;
    }
    return [];
  };

  useEffect(() => {
    getAllSuggestion();
  }, []);

  useEffect(() => {
    getAllCampaign();
  }, [campaignName, userName]);

  const onSearch = (value) => {
    setPageIndex(1);
    if (searchMethod === "campaignName") {
      setUserName("");
      setCampaignName(value);
    }
    if (searchMethod === "userName") {
      setCampaignName("");
      setUserName(value);
    }
  };

  useEffect(() => {
    getAllCampaign();
  }, [pageIndex, pageSize, stateApprove, toDate]);

  useEffect(() => {
    renderPlaceholder();
    checkIsFilter();
  }, [searchMethod, isFilter]);

  const getAllCampaign = async () => {
    checkIsFilter();
    setLoading(true);
    const res = await apiCampaign.getAllKeywordCampaign({
      pageIndex,
      pageSize,
      stateApprove,
      campaignName,
      fromDate,
      toDate,
      userName,
    });
    setLoading(false);
    if (res?.status?.code === "SUCCESS") {
      const data = [];
      res.data.forEach((item, index) => {
        const createdDate = new Date(reverseDate(item.startedTime));
        const record = {
          key: item.id,
          id: formatId(createdDate, item.id),
          chienDichId: item.id,
          chienDich: item.campaignName,
          trangThai: item.stateApprove,
          ngayTao: item.startedTime?.slice(0, 10),
          ngayHetHan: item.expiredTime?.slice(0, 10),
          keywords: item.keywords,
          taiKhoan: item.userName,
          dataId: item.dataId,
          sanLuong:
            (item?.keywordOrders[item?.keywordOrders?.length - 1]
              ?.numberOfDataUsed ?? "0") +
            "/" +
            (item?.keywordOrders[item?.keywordOrders?.length - 1]
              ?.numberOfData ?? "0"),
        };
        // if (["ROOT_ADMIN", "ADMIN"].includes(roleIdUser)) {
        //   record.taiKhoan = item.userName;
        // }
        data.push(record);
      });
      setDataCampaign(data);
      setTotalPage(res.totalItem);
    }
  };

  return (
    <div className="tu-khoa-page position-relative min-height-100vh">
      <DuyetTuKhoaModal
        visibleModal={visibleModalData}
        campaign={recordSelected}
        onSuccess={() => {
          setVisibleModalData(false);
          setRecordSelected(null);
          getAllCampaign();
        }}
        onCancel={() => {
          setRecordSelected(null);
          setVisibleModalData(false);
        }}
      />

      <ThemTuKhoaModal
        visibleModal={visibleModal}
        onCancel={() => {
          setVisibleModal(false);
          setCurrentTypeForm("create");
          setRecordSelected(null);
        }}
        onSuccess={() => getAllCampaign()}
        typeForm={currentTypeForm}
        campaignInfoEdit={recordSelected}
      />

      <ExportDataKeywordModal
        visibleModal={visibleExportModal}
        campaignId={recordSelected?.chienDichId}
        onCancel={() => setVisibleExportModal(false)}
        onSuccess={() => setVisibleExportModal(false)}
      />
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <Form className="form-inline">
                  <button
                    className="btn btn-secondary btn-sm ml-2"
                    onClick={() => {
                      resetSearchValue();
                      setFromDate("");
                      setToDate("");
                      getAllCampaign();
                    }}
                  >
                    <img src={IconRefresh} alt="" width="11" />
                  </button>
                </Form>
              </div>
              <h4 className="page-title">Chiến dịch</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card-box">
              <h4 className="header-title d-flex align-items-center flex-wrap justify-content-between">
                <span className="mb-2">Danh sách chiến dịch</span>
                <div className="d-flex flex-wrap align-items-center">
                  {isFilter && (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger waves-effect waves-light mb-2 mr-2 d-flex align-items-center"
                      onClick={() => deleteFilter()}
                    >
                      <img
                        src={IconFilter}
                        width="12"
                        className="mr-1"
                        alt=""
                      />{" "}
                      Xoá bộ lọc
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-sm btn-blue waves-effect mb-2 waves-light float-right d-flex align-items-center"
                    onClick={() => setVisibleModal(true)}
                  >
                    <img
                      src={IconPlusFill}
                      width="12"
                      className="mr-1"
                      alt=""
                    />{" "}
                    Tạo chiến dịch
                  </button>
                </div>
              </h4>

              <div className="table-tools d-flex align-items-center justify-content-between mb-3">
                <div className="tool-item filter d-flex align-items-center">
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                    Kiểu tìm kiếm
                    <Select
                      value={searchMethod}
                      onSelect={(value) => {
                        resetSearchValue();
                        // console.log(value);
                        setSearchMethod(value);
                      }}
                    >
                      <Option value="campaignName">Tên chiến dịch</Option>
                      {["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) && (
                        <Option value="userName">Tài khoản</Option>
                      )}
                    </Select>
                  </div>
                  <div className="tool-item search d-flex align-items-center text-nowrap">
                    Tìm kiếm:
                    <InputAutoComplete
                      isFilter={isFilter}
                      dataSource={renderSuggestion()}
                      placeholder={renderPlaceholder()}
                      onSearch={(value) => onSearch(value)}
                    />
                  </div>
                </div>
                <div className="tool-item flex-wrap d-flex align-items-center text-nowrap">
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select mr-2">
                    Trạng thái
                    <Select
                      value={stateApprove}
                      onSelect={(value) => {
                        setStateApprove(value);
                      }}
                    >
                      <Option value="">Tất cả</Option>
                      <Option value="approve">Đã duyệt</Option>
                      <Option value="pending">Chờ duyệt</Option>
                      <Option value="reject">Không duyệt</Option>
                    </Select>
                  </div>
                  <RangePicker
                    className="tool-item search"
                    format="DD-MM-YYYY"
                    value={[
                      fromDate ? moment(reverseDate(fromDate)) : null,
                      toDate ? moment(reverseDate(toDate)) : null,
                    ]}
                    placeholder={["Từ ngày", "Đến ngày"]}
                    onChange={(date, dateString) =>
                      handleDatePicker(date, dateString)
                    }
                  />
                </div>
              </div>

              <Table
                loading={loading}
                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                dataSource={dataCampaign}
                columns={columnsCampaign}
                pagination={false}
              />

              {dataCampaign.length !== 0 && (
                <div className="table-panigate d-flex align-items-center justify-content-between mt-3">
                  <div className="dataTables_info">
                    Trang {pageIndex} trong tổng số{" "}
                    {Math.ceil(totalPage / pageSize)} trang
                  </div>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="tool-item select d-flex align-items-center pagesize-select">
                      Hiển thị
                      <Select
                        value={pageSize}
                        onSelect={(value) => setPageSize(value)}
                      >
                        <Option value="10">10</Option>
                        <Option value="30">30</Option>
                        <Option value="50">50</Option>
                        <Option value="70">70</Option>
                        <Option value="100">100</Option>
                      </Select>
                      dữ liệu
                    </div>
                    <div className="table-pagination">
                      <Pagination
                        current={pageIndex}
                        pageSize={pageSize}
                        total={totalPage}
                        showSizeChanger={false}
                        onChange={(page) => setPageIndex(page)}
                        hideOnSinglePage={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
      <Modal
        title={"Xác nhận xóa chiến dịch?"}
        footer={null}
        visible={visibleModalDelete}
        onOk={() => deleteCampaign()}
        okText={"Xác nhận"}
        cancelText={"Hủy"}
        onCancel={() => {
          setVisibleModalDelete(false);
          setRecordSelected(null);
        }}
        wrapClassName="them-tu-khoa-modal them-don-hang-modal them-user-modal"
      >
        <p>{"Bạn có chắc chắn muốn xóa chiến dịch này không?"}</p>
        <div className="btn-step-group d-flex justify-content-end">
          <Button
            className="btn btn-default btn-block"
            type="link"
            onClick={() => {
              setVisibleModalDelete(false);
              setRecordSelected(null);
            }}
          >
            {" Hủy "}
          </Button>
          <Button
            className="btn btn-danger btn-block"
            type="button"
            onClick={() => deleteCampaign()}
          >
            {" Xác nhận "}
          </Button>
        </div>
      </Modal>

      <Modal
        title={"Thêm nguồn dữ liệu"}
        footer={null}
        visible={visibleModalAddUrl}
        onOk={() => updateDataUrl()}
        okText={"Cập nhật"}
        cancelText={"Hủy"}
        onCancel={() => {
          setVisibleModalAddUrl(false);
          setRecordSelected(null);
          setDataUrl("");
        }}
        wrapClassName="them-tu-khoa-modal them-don-hang-modal them-user-modal"
      >
        <p>{`Thêm nguồn dữ liệu cho chiến dịch: ${recordSelected?.chienDich}`}</p>
        {recordSelected?.dataId?.length > 0 && (
          <p>
            *{" "}
            <em>{`Chiến dịch đã được thêm nguồn: ${recordSelected?.dataId}`}</em>
          </p>
        )}
        <div className="btn-step-group d-flex justify-content-end">
          <Input
            className="form-control"
            value={dataUrl}
            autoComplete="off"
            placeholder="Nhập đường dẫn đến nguồn cấp dữ liệu"
            onChange={(evt) => {
              setDataUrl(evt.target.value);
            }}
          />
        </div>

        <div className="btn-step-group d-flex justify-content-end">
          <Button
            className="btn btn-default btn-block"
            type="link"
            onClick={() => {
              setVisibleModalAddUrl(false);
              setRecordSelected(null);
              setDataUrl("");
            }}
          >
            {" Hủy "}
          </Button>
          <Button
            disabled={recordSelected?.dataId?.length > 0}
            className="btn btn-primary btn-block"
            type="button"
            onClick={() => updateDataUrl()}
          >
            {" Xác nhận "}
          </Button>
        </div>
      </Modal>
    </div>
  );
};
