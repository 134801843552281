import React, { useState } from "react";
import moment from "moment";
import { Modal, Button, DatePicker, Form } from "antd";
import { apiData } from "api/data";
import { reverseDate } from "utils/utils-format";

import "./call-modal.scss";
import ImageModal from "assets/images/image-export-modal.png";

import {
  STATUS_PROGRESS,
  STATUS_ACCEPTED,
  STATUS_CONFIRMED,
  STATUS_ENDED,
  STATUS_FAILED,
} from "../../utils/utils-phonenet";
import { showMessage } from "../../utils/utils-show-message";

const { RangePicker } = DatePicker;

export const CallModal = ({
  visibleModal,
  onCancel,
  onCancelCall,
  status,
  time,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [error, setError] = useState("");
  const [help, setHelp] = useState("");

  const initModal = () => {
    setError("");
    setHelp("");
    setFromDate("");
    setToDate("");
  };

  const validateExportDate = () => {
    if (!fromDate || !toDate) {
      setError("error");
      setHelp("Yêu cầu chọn khoảng ngày muốn xuất dữ liệu");
      return false;
    }
    setError("");
    setHelp("");
    return true;
  };

  const handleDatePicker = (date, dateString) => {
    const startDate = dateString[0];
    const endDate = dateString[1];
    setFromDate(startDate);
    setToDate(endDate);
  };

  return (
    <Modal
      title="Gọi điện"
      footer={null}
      visible={visibleModal}
      wrapClassName="them-chien-dich-modal them-don-hang-modal xuat-excel-modal"
      onCancel={() => {
        initModal();
        onCancel();
      }}
    >
      {status === STATUS_PROGRESS && (
        <p className="text-center"> Đang đổ chuông</p>
      )}
      {status === STATUS_ACCEPTED && (
        <p className="text-center"> Đầu khách trả lời</p>
      )}
      {status === STATUS_CONFIRMED && (
        <p className="text-center">
          {" "}
          Đầu khách trả lời
          <br />
          {time}
        </p>
      )}
      {status === STATUS_ENDED && (
        <p className="text-center">
          {" "}
          Cuộc gọi dừng
          <br />
          {time}
        </p>
      )}
      {status === STATUS_FAILED && (
        <p className="text-center">
          {" "}
          Đầu khách không bắt máy hoặc không liên lạc được
        </p>
      )}
      <div className="d-flex justify-content-center">
        {![STATUS_ENDED, STATUS_FAILED].includes(status) && (
          <Button
            className="btn btn-danger btn-block w-auto"
            type="submit"
            loading={btnLoading}
            onClick={() => onCancelCall()}
          >
            {" "}
            Dừng cuộc gọi{" "}
          </Button>
        )}

        {[STATUS_ENDED, STATUS_FAILED].includes(status) && (
          <Button
            className="btn btn-primary btn-block w-auto"
            type="button"
            loading={btnLoading}
            onClick={() => onCancel()}
          >
            {" "}
            Đóng{" "}
          </Button>
        )}
      </div>
    </Modal>
  );
};
