import { rest } from "./rest";

export const apiOrder = {
  getAll: (pagination) => rest.get("/orders", pagination, null, true),
  getAllUnlimited: (pagination) =>
    rest.get("/newOrders", pagination, null, true),
  getAllFacebookTracking: (pagination) =>
    rest.get("/facebook-tracking/order", pagination, null, true),
  create: (numberOfData, expiredDate, pricePerNumber, userId) =>
    rest.post(
      "/order",
      { numberOfData, expiredDate, pricePerNumber, userId },
      null,
      true
    ),
  createTrackingFb: (numberOfData, expiredDate, userId) =>
    rest.post(
      "/order-tracking-fb",
      { numberOfData, expiredDate, userId },
      null,
      true
    ),
  addExpiredDate: (orderId, expiredDate) =>
    rest.put(
      "/order/" + orderId + "?expiredDate=" + expiredDate,
      null,
      null,
      true
    ),
  addUnlimitedExpiredDate: (orderId, expiredDate) =>
    rest.put(
      "/updateExpiredDateNewOrder/" + orderId + "?expiredDate=" + expiredDate,
      null,
      null,
      true
    ),
  addFbCommentExpiredDate: (orderId, expiredDate) =>
    rest.put(
      "/facebook-tracking/order/" + orderId + "?expiredDate=" + expiredDate,
      null,
      null,
      true
    ),
};
