import {Button, Modal} from "antd";
import React from "react";

export const ModalConfirm = (
  {
    visibleModal,
    title,
    messageConfirm,
    onCancel,
    onOk
  }
) => {
  return (
    <Modal
      title={title}
      footer={null}
      visible={visibleModal}
      onOk={() => onOk()}
      okText={"Xác nhận"}
      cancelText={"Hủy"}
      onCancel={() => {
        onCancel();
      }}
      wrapClassName="them-chien-dich-modal them-don-hang-modal them-user-modal"
    >
      <p>{messageConfirm}</p>
      <div className="btn-step-group d-flex justify-content-end">
        <Button
          className="btn btn-default btn-block"
          type="button"
          onClick={() => onCancel()}
        >
          {" Hủy "}
        </Button>
        <Button
          className="btn btn-danger btn-block"
          type="button"
          onClick={() => onOk()}
        >
          {" Xác nhận "}
        </Button>
      </div>
    </Modal>
  );
}
