import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Form, Select, Steps } from "antd";

import "./them-tu-khoa-modal.scss";

import { isHttpsWww, isWebUrl, validateText } from "utils/utils-validation";
import { showMessage } from "utils/utils-show-message";
import { apiCampaign } from "api/campaign";
import { apiAccount } from "api/account";
import ImageModal from "assets/images/laptop-ui-image.png";
import { InputAutoComplete } from "components/input-autocomplete/input-autocomplete";

import { parseToken } from "../../utils/utils-parse-token";
import { setAccountRole } from "../../store/account";
import { useDispatch, useSelector } from "react-redux";

const { Option } = Select;
const { Step } = Steps;

export const ThemTuKhoaModal = ({
  visibleModal,
  onCancel,
  onSuccess,
  campaignInfoEdit,
  userId,
  typeForm,
}) => {
  const dispatch = useDispatch();
  const [keywords, setKeywords] = useState([
    {
      value: "",
      error: "",
      help: "",
    },
  ]);
  const [campaignName, setCampaignName] = useState({
    value: "",
    error: "",
    help: "",
  });
  const [userName, setUserName] = useState("");
  const [listUserName, setListUserName] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const roleIdUser = useSelector((state) => state.account.role);
  const getUserRole = () => {
    const role =
      parseToken(localStorage.getItem("token-datatuoi"))?.role || null;
    dispatch(setAccountRole(role));
  };

  const getAllUser = async () => {
    const res = await apiAccount.getAll({
      userName,
    });
    if (res?.status?.code === "SUCCESS") {
      const data = [];
      res.data.map((value, index) => {
        data.push(value.userName);
        setListUserName(data);
      });
    }
  };

  const onSearch = (value) => {
    setUserName(value);
  };

  useEffect(() => {
    getAllUser();
  }, [userName]);

  useEffect(() => {
    getUserRole();
  }, []);

  useEffect(() => {
    // console.log("campaignInfoEdit " + JSON.stringify(campaignInfoEdit));
    if (typeForm === "edit" && campaignInfoEdit !== null) {
      setCampaignName({
        ...campaignName,
        value: campaignInfoEdit.chienDich,
      });
      let keywordObjects = [];
      campaignInfoEdit.keywords.map((item) => {
        // console.log("item " + JSON.stringify(item.keyword));
        keywordObjects.push({
          value: item.keyword,
          error: "",
          help: "",
        });
      });
      // console.log(JSON.stringify(keywordObjects));
      setKeywords([...keywordObjects]);
    }
  }, [campaignInfoEdit]);

  const initModal = () => {
    setCampaignName({ value: "", error: "", help: "" });
    setKeywords([{ value: "", error: "", help: "" }]);
    setUserName("");

    setBtnLoading(false);
  };

  const validateForm = () => {
    let validated = true;

    if (!validateText(campaignName.value)) {
      validated = false;
      setCampaignName({
        ...campaignName,
        error: "error",
        help: "Tên chiến dịch không được để trống",
      });
    } else {
      validated = true;
    }

    keywords.map((keyword) => {
      if (keyword.error === "error") {
        validated = false;
      }
    });

    return validated;
  };

  const addChienDich = async () => {
    let postCampaignName = campaignName.value;
    let postKeywords = [];
    keywords.map((keyword) => postKeywords.push(keyword.value));

    let res = null;
    if (validateForm()) {
      // showMessage("success", "Validated form");
      setBtnLoading(true);
      if (typeForm === "create") {
        res = await apiCampaign.createKeywordCampaign(
          userName,
          postCampaignName,
          postKeywords
        );
      }
      if (typeForm === "edit") {
        res = await apiCampaign.updateKeywordCampaign(
          campaignInfoEdit.chienDichId,
          postCampaignName,
          postKeywords
        );
      }

      if (res?.status?.code === "SUCCESS") {
        showMessage(
          "success",
          typeForm === "create"
            ? "Tạo chiến dịch thành công"
            : "Cập nhật chiến dịch thành công"
        );
        onSuccess();
        onCancel();
        initModal();
      } else {
        onCancel();
        initModal();
      }

      setBtnLoading(false);
    } else {
      showMessage("error", "Vui lòng kiểm tra lại thông tin!");
    }
  };

  return (
    <Modal
      title={
        typeForm === "create" ? "Tạo mới chiến dịch" : "Chỉnh sửa chiến dịch"
      }
      footer={null}
      visible={visibleModal}
      wrapClassName="them-tu-khoa-modal"
      onCancel={() => {
        onCancel();
        initModal();
        setUserName("");
      }}
    >
      <div className="modal-image">
        <img src={ImageModal} alt="" />
      </div>
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        className="modal-form dark mt-3 mb-2"
      >
        <div className="form-group input-name-campaign">
          {["ROOT_ADMIN"].includes(roleIdUser) && (
            <Form.Item label="Chọn tài khoản" validateStatus={""} help={""}>
              <InputAutoComplete
                isFilter={true}
                dataSource={listUserName}
                placeholder={`Nhập tài khoản`}
                onSearch={(value) => onSearch(value)}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Tên chiến dịch"
            validateStatus={campaignName.error}
            help={campaignName.help}
          >
            <Input
              className="form-control"
              name="tenCD"
              placeholder="Chiến dịch từ khóa"
              value={campaignName.value}
              onChange={(e) => {
                if (!validateText(e.target.value)) {
                  setCampaignName({
                    value: e.target.value,
                    error: "error",
                    help: "Tên chiến dịch không được để trống",
                  });
                } else {
                  setCampaignName({
                    value: e.target.value,
                    error: "",
                    help: "",
                  });
                }
                // setCampaignName({ ...campaignName, value: e.target.value });
              }}
            />
          </Form.Item>
          <Form.Item label={`Từ khóa`}>
            {Array.isArray(keywords) ? (
              keywords.map((keyword, index) => (
                <div className="row single-keyword" key={index}>
                  <div className="col-lg-10">
                    <Form.Item
                      validateStatus={keyword.error}
                      help={keyword.help}
                    >
                      <Input
                        name={`input-${index}`}
                        className="form-control"
                        placeholder="example.com"
                        autoComplete="off"
                        value={keywords[index].value}
                        onChange={(e) => {
                          let newValue = e.target.value;
                          let items = [...keywords];
                          items[index].value = newValue;

                          if (isHttpsWww(e.target.value)) {
                            items[index].error = "error";
                            items[index].help =
                              "Từ khóa không bao gồm http://, https:// và www.";
                          } else {
                            items[index].error = "";
                            items[index].help = "";
                          }
                          setKeywords([...items]);
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-2">
                    <Form.Item>
                      <button
                        type="button"
                        className="btn btn-danger editable-cancel btn-sm waves-effect"
                        onClick={() => {
                          let items = [...keywords];
                          items.splice(index, 1); // remove 1 element
                          setKeywords([...items]);
                        }}
                      >
                        <i className="mdi mdi-close"></i>
                      </button>
                    </Form.Item>
                  </div>
                </div>
              ))
            ) : (
              <div></div>
            )}

            <div className="add-input-keyword">
              <Form.Item>
                <button
                  className="btn btn-primary editable-submit btn-sm waves-effect waves-light"
                  onClick={(e) =>
                    setKeywords([
                      ...keywords,
                      { value: "", error: "", help: "" },
                    ])
                  }
                >
                  <i className="mdi mdi-plus"></i> Thêm
                </button>
              </Form.Item>
            </div>
          </Form.Item>
          <div className="btn-action-group d-flex justify-content-center">
            <Button
              loading={btnLoading}
              className="btn btn-primary btn-block"
              htmlType="submit"
              onClick={() => {
                addChienDich();
              }}
            >
              {typeForm === "create" ? "Tạo mới" : "Cập nhật"}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
