import React, { useEffect, useState } from "react";
import { Form, Input, Pagination, Select, Table, Tooltip } from "antd";
import "./facebook-tracking.scss";
import { apiFacebookTracking } from "api/facebook-tracking";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import IconPlusFill from "assets/icons/icon-plus-fill.svg";
import IconCancel from "assets/icons/icon-cancel.svg";
import IconInfo from "assets/icons/icon-info.svg";
import IconFilter from "assets/icons/icon-filter.svg";
import IconRefresh from "assets/icons/icon-refresh.svg";
import { showMessage } from "utils/utils-show-message";
import { ModalConfirm } from "../../components/modal-confirm/modal-confirm";
import { Link } from "react-router-dom";
import { GROUP_ADMIN } from "../../constant/constant";
import { AddFacebookTrackingModal } from "../../components/add-facebook-tracking-modal/add-facebook-tracking-modal";
import IconPlay from "../../assets/icons/icon-play.svg";
import IconStop from "../../assets/icons/icon-stop.svg";

const { Option } = Select;

export const FacebookTracking = () => {
  const [visibleModalCreate, setVisibleModalCreate] = useState(false);
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(10);
  const [facebookTrackingData, setFacebookTrackingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [facebookTracking, setFacebookTracking] = useState(null);

  const [searchMethod, setSearchMethod] = useState("");
  const [isFilter, setIsFilter] = useState(false);

  const [linkPost, setLinkPost] = useState("");
  const [description, setDescription] = useState("");

  const userName = useSelector((state) => state.account.account?.userName);
  const roleIdUser = useSelector((state) => state.account.role);
  let history = useHistory();

  let columns = [
    {
      title: "STT",
      dataIndex: "rowIndex",
      key: "rowIndex",
      width: 125,
    },
    {
      title: "Bài viết",
      dataIndex: "linkPost",
      key: "linkPost",
      className: "width-200-px-in-1200-column",
      render: (value) => (
        <a target="_blank" href={value}>
          {value}
        </a>
      ),
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
      className: "width-150-px-in-1200-column",
      ellipsis: true,
    },
    {
      title: "Người tạo",
      dataIndex: "createdBy",
      key: "createdBy",
      width: 180,
      ellipsis: true,
    },
    {
      title: "Số comment",
      dataIndex: "totalComment",
      key: "totalComment",
      width: 180,
      ellipsis: true,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      className: "width-150-px",
      render: (value, row) => {
        return (
          <div className="d-flex">
            {row.status === 1 && (
              <span className="badge mr-1 badge-success">Hoạt động</span>
            )}
            {row.status === 0 && (
              <span className="badge mr-1 bg-soft-danger text-danger">
                Tạm dừng
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (value, row) => (
        <div className="row">
          {row.status === 0 && (
            <Tooltip placement="top" title="Mở chiến dịch">
              <button
                type="button"
                className="btn btn-md btn-rounded btn-success waves-effect waves-light d-flex align-items-center btn-icon-active action"
                onClick={() => activeOrDisableCampaign(row, 1)}
              >
                <img src={IconPlay} width="12" alt="" />
              </button>
            </Tooltip>
          )}
          {row.status === 1 && (
            <Tooltip placement="top" title="Ngưng chiến dịch">
              <button
                type="button"
                className="btn btn-md btn-rounded btn-danger waves-effect waves-light d-flex align-items-center btn-icon-disable action"
                onClick={() => activeOrDisableCampaign(row, 0)}
              >
                <img src={IconStop} width="12" alt="" />
              </button>
            </Tooltip>
          )}
          <Tooltip placement="top" title="Chi tiết">
            <button
              type="button"
              className="btn btn-md btn-rounded btn-info waves-effect waves-light d-flex align-items-center btn-icon-edit action"
              onClick={() => history.push(`/facebook-tracking/${row.id}`)}
            >
              <img src={IconInfo} width="12" alt="" />
            </button>
          </Tooltip>
          <Tooltip placement="top" title="Xóa">
            <button
              type="button"
              className="btn btn-md btn-rounded btn-danger waves-effect waves-light d-flex align-items-center ml-2 btn-icon-delete action"
              onClick={() => openConfirmDelete(row)}
            >
              <img src={IconCancel} width="12" alt="" />
            </button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const openConfirmDelete = async (row) => {
    setFacebookTracking(row);
    setVisibleModalDelete(true);
  };

  const deletePost = async (post) => {
    const res = await apiFacebookTracking.delete(post.id);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Xóa link bài viết thành công thành công");
      setVisibleModalDelete(false);
      await getAllPostTracking();
    } else {
      showMessage("error", res?.status?.message);
    }
  };

  const activeOrDisableCampaign = async (post, status) => {
    post.status = status;
    const res = await apiFacebookTracking.updateStatus(post);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Cập nhật trạng thái thành công thành công");
      await getAllPostTracking();
    } else {
      showMessage("error", res?.status?.message);
    }
  };

  const resetSearchValue = () => {
    setLinkPost("");
    setDescription("");
  };

  const checkIsFilter = () => {
    if (linkPost !== "" || searchMethod !== "" || description !== "") {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  };

  const deleteFilter = async () => {
    setDescription("");
    setLinkPost("");
    setSearchMethod("");
    setPageSize(10);
    setPageIndex(1);
    setIsFilter(false);
    await getAllPostTracking();
  };

  useEffect(async () => {
    setPageIndex(1);
    await getAllPostTracking();
  }, [linkPost, description, searchMethod]);

  useEffect(async () => {
    await getAllPostTracking();
  }, [pageIndex, pageSize]);

  useEffect(() => {
    checkIsFilter();
  }, [isFilter]);

  const getAllPostTracking = async () => {
    checkIsFilter();
    setLoading(true);
    const res = await apiFacebookTracking.getAll({
      pageIndex,
      pageSize,
      linkPost,
      description,
      searchMethod,
    });
    setLoading(false);
    if (res?.status?.code === "SUCCESS") {
      const data = res.data;
      data.forEach(
        (item, index) =>
          (item.rowIndex = (pageIndex - 1) * pageSize + index + 1)
      );
      setFacebookTrackingData(data);
      setTotalPage(res.totalItem);
    } else {
      setFacebookTrackingData([]);
      setTotalPage(0);
      showMessage("error", res.status.message);
    }
  };

  return (
    <div className="contact-page position-relative min-height-100vh">
      <AddFacebookTrackingModal
        isCreate={true}
        visibleModal={visibleModalCreate}
        onCancel={() => setVisibleModalCreate(false)}
        onSuccess={() => getAllPostTracking()}
      />
      <ModalConfirm
        title={"Xóa link bài viết"}
        messageConfirm={"Bạn có chắc chắn muốn xóa link bài viết này không?"}
        visibleModal={visibleModalDelete}
        onCancel={() => setVisibleModalDelete(false)}
        onOk={() => deletePost(facebookTracking)}
      />
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <Form className="form-inline">
                  <button
                    className="btn btn-secondary btn-sm ml-2"
                    onClick={async () => {
                      resetSearchValue();
                      await getAllPostTracking();
                    }}
                  >
                    <img src={IconRefresh} alt="" width="11" />
                  </button>
                </Form>
              </div>
              <h4 className="page-title">Facebook tracking</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card-box">
              <h4 className="header-title d-flex align-items-center flex-wrap justify-content-between">
                <span className="mb-2">Danh sách bài viết</span>
                <div className="d-flex flex-wrap align-items-center">
                  {isFilter && (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger waves-effect waves-light mb-2 mr-2 d-flex align-items-center"
                      onClick={() => deleteFilter()}
                    >
                      <img
                        src={IconFilter}
                        width="12"
                        className="mr-1"
                        alt=""
                      />{" "}
                      Xoá bộ lọc
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-sm btn-blue waves-effect mb-2 waves-light float-right d-flex align-items-center"
                    onClick={() => setVisibleModalCreate(true)}
                  >
                    <img
                      src={IconPlusFill}
                      width="12"
                      className="mr-1"
                      alt=""
                    />{" "}
                    Thêm mới
                  </button>
                </div>
              </h4>
              <div className="table-tools d-flex align-items-center justify-content-between mb-3">
                <div className="tool-item filter d-flex align-items-center">
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                    Link bài viết:
                    <Input
                      className="form-control"
                      placeholder="Nhập link bài viết"
                      onChange={(e) => setLinkPost(e.target.value)}
                      value={linkPost}
                    />
                  </div>
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                    Mô tả:
                    <Input
                      className="form-control"
                      placeholder="Nhập mô tả"
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                    />
                  </div>
                </div>
              </div>

              <Table
                loading={loading}
                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                dataSource={facebookTrackingData}
                columns={
                  GROUP_ADMIN.includes(roleIdUser)
                    ? columns
                    : columns.filter((item) => item.key !== "createdBy")
                }
                pagination={false}
              />

              {facebookTrackingData.length !== 0 && (
                <div className="table-panigate d-flex align-items-center justify-content-between mt-3">
                  <div className="dataTables_info">
                    Trang {pageIndex} trong tổng số{" "}
                    {Math.ceil(totalPage / pageSize)} trang
                  </div>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="tool-item select d-flex align-items-center pagesize-select">
                      Hiển thị
                      <Select
                        value={pageSize}
                        onSelect={(value) => setPageSize(value)}
                      >
                        <Option value="10">10</Option>
                        <Option value="30">30</Option>
                        <Option value="50">50</Option>
                        <Option value="70">70</Option>
                        <Option value="100">100</Option>
                      </Select>
                      dữ liệu
                    </div>
                    <div className="table-pagination">
                      <Pagination
                        current={pageIndex}
                        pageSize={pageSize}
                        total={totalPage}
                        showSizeChanger={false}
                        onChange={(page) => setPageIndex(page)}
                        hideOnSinglePage={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
