import React from "react";
import { GuestLayout } from "layout/guest-layout/guest-layout";
import { DangNhapForm } from "components/dang-nhap-form/dang-nhap-form";

export const DangNhap = () => {
  return (
    <GuestLayout>
      <DangNhapForm />
    </GuestLayout>
  );
};
