const monthsText = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const reverseDate = (dateString) => {
  if (dateString) {
    const date = dateString.slice(0, 10).split("-").reverse().join("-");
    const time = dateString.slice(11);
    return `${date} ${time}`;
  }
};

export const addZero = (number) => {
  return Number(number) > 9 ? number.toString() : `0${number}`;
};

export const formatId = (date, id) => {
  const month = addZero(Number(date.getMonth() + 1));
  const year = date.getFullYear();
  return `#${month}${year}${id}`;
};

export const shortDate = (date) => {
  if (date) {
    const newDate = new Date(date);
    const month = monthsText[newDate.getMonth()];
    const day = newDate.getDate();
    return `${month} ${day}`;
  }
};

export const numberWithCommas = (number) => {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "0";
  }
};

export const formatHomeNetwork = (value) => {
  if (value) {
    const target = value.toLowerCase();
    switch (target) {
      case "viettel":
        return "VT";
      case "vinaphone":
        return "VN";
      case "mobiphone":
        return "MB";
      default:
        return "MK";
    }
  }
};

export const secondsFormatString = (seconds) => {
  if (!seconds) {
    return "00:00";
  }
  const second = seconds % 60;
  const minute = Math.floor(seconds / 60);
  return `${minute.toString().padStart(2, "0")}:${second
    .toString()
    .padStart(2, "0")}`;
};

export const formatDomain = (value) => {
  if (!value) {
    return "";
  }
  value = value.toLowerCase();
  return value
    .replace("https://", "")
    .replace("http://", "")
    .replace("www.", "")
    .split("/")[0];
};
