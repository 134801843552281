import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Form, Select } from "antd";
import { apiCampaign } from "api/campaign";

import ImageModal from "assets/images/image-approve-campaign.svg";
import { showMessage } from "utils/utils-show-message";
import { validateText, validateOnlyNumber } from "utils/utils-validation";
import { reverseDate, formatId } from "utils/utils-format";

const { Option } = Select;

export const DuyetTuKhoaModal = ({
  visibleModal,
  onCancel,
  onSuccess,
  campaign,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnRejectLoading, setBtnRejectLoading] = useState(false);
  const [price, setPrice] = useState({
    value: "",
    error: "",
    help: "",
  });
  const [numberExpire, setNumberExpire] = useState({
    value: "",
    error: "",
    help: "",
  });
  const [totalData, setTotalData] = useState({
    value: "",
    error: "",
    help: "",
  });

  const initModal = () => {
    setBtnLoading(false);
    setBtnRejectLoading(false);
    setNumberExpire({ value: "", error: "", help: "" });
    setPrice({ value: "", error: "", help: "" });
  };

  const approveCampaign = async () => {
    if (validateNumberExpireField(numberExpire) && validatePriceField(price)) {
      setBtnLoading(true);
      const res = await apiCampaign.approveKeywordCampaign(
        campaign?.chienDichId,
        numberExpire.value,
        price.value,
        totalData.value
      );
      if (res?.status?.code === "SUCCESS") {
        showMessage("success", "Duyệt chiến dịch thành công");
        onSuccess();
        initModal();
      } else {
        showMessage("error", res.status.message);
      }
    }
  };

  const approveKeywords = async () => {
    setBtnLoading(true);
    const res = await apiCampaign.approveKeywords(campaign?.chienDichId);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Duyệt từ khóa thành công");
      onSuccess();
      initModal();
    } else {
      showMessage("error", res.status.message);
    }
  };

  const rejectKeywordCampaign = async () => {
    setBtnRejectLoading(true);
    const res = await apiCampaign.rejectKeywordCampaign(campaign?.chienDichId);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Đã hủy bỏ thành công");
      onSuccess();
      initModal();
    } else {
      showMessage("error", res.status.message);
    }
  };

  const validatePriceField = (target) => {
    if (!validateText(target.value) || !validateOnlyNumber(target.value)) {
      setPrice({
        ...target,
        error: "error",
        help: "Vui lòng nhập chính xác đơn giá",
      });
      return false;
    }
    setPrice({ value: target.value, error: "", help: "" });
    return true;
  };
  const validateNumberExpireField = (target) => {
    if (!validateText(target.value) || !validateOnlyNumber(target.value)) {
      setNumberExpire({
        ...target,
        error: "error",
        help: "Vui lòng nhập chính xác số lượng ngày sử dụng",
      });
      return false;
    }
    setNumberExpire({ value: target.value, error: "", help: "" });
    return true;
  };
  const validateTotalDataField = (target) => {
    if (
      target.value !== 1000 &&
      target.value !== 5000 &&
      target.value !== 10000
    ) {
      setTotalData({
        ...target,
        error: "error",
        help: "Vui lòng nhập chính xác số lượng data",
      });
      return false;
    }
    setTotalData({ value: target.value, error: "", help: "" });
    return true;
  };

  const isUpdateKeyword = () => {
    let createdDate = new Date(reverseDate(campaign?.ngayTao));
    let expiredDate = new Date(reverseDate(campaign?.ngayHetHan));
    return expiredDate > createdDate;
  };

  return (
    <Modal
      title="Duyệt chiến dịch"
      footer={null}
      visible={visibleModal}
      wrapClassName="them-chien-dich-modal them-don-hang-modal"
      onCancel={() => {
        onCancel();
        initModal();
      }}
    >
      <div className="modal-image">
        <img src={ImageModal} alt="" />
      </div>
      <Form className="modal-form dark mt-3 mb-2">
        <div className="row">
          <div className="col-12">
            <div className="form-group mb-2">
              <label className="d-block">Tên Chiến dịch</label>
              <Form.Item validateStatus="" help="">
                <Input
                  disabled={true}
                  className="form-control"
                  value={campaign?.chienDich}
                />
              </Form.Item>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group mb-2">
              <label className="d-block">Từ khóa</label>
              <Form.Item validateStatus="" help="">
                <Input.TextArea
                  rows={5}
                  disabled={true}
                  className="form-control"
                  value={campaign?.keywords
                    .map((item) => item.keyword)
                    .join("\r\n")}
                />
              </Form.Item>
            </div>
          </div>
          {!isUpdateKeyword() && (
            <>
              <div className="col-6">
                <div className="form-group mb-2">
                  <label className="d-block">Giá</label>
                  <Form.Item validateStatus={price.error} help={price.help}>
                    <Input
                      onBlur={() => validatePriceField(price)}
                      placeholder="Nhập giá"
                      className="form-control"
                      value={price.value}
                      onChange={(e) =>
                        setPrice({ ...price, value: e.target.value })
                      }
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-2">
                  <label className="d-block">Số lượng data</label>
                  <Form.Item
                    validateStatus={totalData.error}
                    help={totalData.help}
                  >
                    {/* <Input
                  className="form-control"
                  placeholder="Nhập Số lượng data"
                  value={totalData.value}
                  onChange={(e) =>
                    setNumberExpire({
                      ...totalData,
                      value: e.target.value,
                    })
                  }
                  onBlur={() => validateTotalDataField(totalData)}
                /> */}
                    <Select
                      value={totalData.value}
                      onChange={(value) =>
                        setTotalData({ ...totalData, value })
                      }
                      onBlur={() => validateTotalDataField(totalData)}
                    >
                      <Option value={1000}>1000 số</Option>
                      <Option value={5000}>5000 số</Option>
                      <Option value={10000}>10000 số</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-2">
                  <label className="d-block">Số ngày sử dụng</label>
                  <Form.Item
                    validateStatus={numberExpire.error}
                    help={numberExpire.help}
                  >
                    <Input
                      className="form-control"
                      placeholder="Nhập Số ngày sử dụng"
                      value={numberExpire.value}
                      onChange={(e) =>
                        setNumberExpire({
                          ...numberExpire,
                          value: e.target.value,
                        })
                      }
                      onBlur={() => validateNumberExpireField(numberExpire)}
                    />
                  </Form.Item>
                </div>
              </div>
            </>
          )}
        </div>
      </Form>
      <div className="btn-step-group d-flex justify-content-center">
        <Button
          className="btn btn-success btn-block w-auto"
          type="submit"
          loading={btnLoading}
          onClick={() =>
            isUpdateKeyword() ? approveKeywords() : approveCampaign()
          }
        >
          {" "}
          Duyệt{" "}
        </Button>
        <Button
          className="btn btn-primary btn-block w-auto"
          type="submit"
          loading={btnRejectLoading}
          onClick={() => rejectKeywordCampaign()}
        >
          {" "}
          Không duyệt{" "}
        </Button>
      </div>
    </Modal>
  );
};
