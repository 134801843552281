import { rest } from "./rest";

export const apiAuth = {
  login: (userName, password) => rest.post("/auth", { userName, password }),
  register: (name, userName, password, email, phone) =>
    rest.post("/register", { name, userName, password, email, phone }),
  forgotPassword: (email) => rest.post("/forgotPassword", { email }),
  resetPassword: (changePassToken, newPassword) =>
    rest.put("/resetPassword", { changePassToken, newPassword }),
  // activeUser: (activationKey) => rest.post("", { activationKey }),
  resendActiveUser: (email) => rest.post("/resendActive", { email }),
  checkUserResetPassword: (tokenKey) => rest.get("/forgotPassword", tokenKey),
};
