import React, {useEffect, useState} from "react";
import "./tai-khoan.scss";
import {apiAccount} from "api/account";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import {Input, Form, Button} from "antd";
import AvatarDefault from "assets/images/avatar-default.png";
import LogoTelegram from "assets/images/telegram-logo.svg";
import IconLink from "assets/icons/icon-link.svg";
import IconUserLight from "assets/icons/icon-user-light.svg";
import IconSettingsLight from "assets/icons/icon-settings-light.svg";
import IconPassword from "assets/icons/icon-password-light.svg";
import {setAccountInfo} from "store/account";

import {
  validateText,
  validateTextLength,
  validateEmail,
  validateVietnamPhoneNumber,
} from "utils/utils-validation";
import {showMessage} from "utils/utils-show-message";

export const TaiKhoan = () => {
  const dispatch = useDispatch();
  const accountState = useSelector((state) => state.account.account);
  const [account, setAccount] = useState({});
  const [tab, setTab] = useState(1);
  const [btnLoading, setBtnLoading] = useState(false);

  const [userName, setUserName] = useState({value: "", error: "", help: ""});
  const [name, setName] = useState({value: "", error: "", help: ""});
  const [email, setEmail] = useState({value: "", error: "", help: ""});
  const [phone, setPhone] = useState({value: "", error: "", help: ""});
  const [telegramId, setTelegramId] = useState({
    value: "",
    error: "",
    help: "",
  });

  const [oldPassword, setOldPassword] = useState({
    value: "",
    error: "",
    help: "",
  });
  const [newPassword, setNewPassword] = useState({
    value: "",
    error: "",
    help: "",
  });
  const [confirmNewPassword, setConfirmNewPassword] = useState({
    value: "",
    error: "",
    help: "",
  });

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (tab === 1 || tab === 2) updateValueUser();
  }, [tab, account]);

  const updateValueUser = () => {
    setUserName({...userName, value: account.userName});
    setEmail({...email, value: account.email});
    setPhone({...phone, value: account.phone});
    setTelegramId({
      ...telegramId,
      value: [-1, 0, 1].includes(account.telegramId) ? "" : account.telegramId,
    });
    setName({...name, value: account.name});
  };

  const getUserInfo = async () => {
    const res = await apiAccount.getOne();
    if (res?.status?.code === "SUCCESS") {
      setAccount(res.data);
    }
  };

  const validateUserNameField = (target) => {
    if (!validateText(target.value)) {
      setUserName({
        ...target,
        error: "error",
        help: "Vui lòng nhập tên tài khoản",
      });
      return false;
    }
    setUserName({value: target.value, error: "", help: ""});
    return true;
  };

  const validateNameField = (target) => {
    if (!validateText(target.value)) {
      setName({...target, error: "error", help: "Vui lòng nhập đầy đủ tên"});
      return false;
    }
    setName({value: target.value, error: "", help: ""});
    return true;
  };

  const validatePhoneField = (target) => {
    if (!validateVietnamPhoneNumber(target.value)) {
      setPhone({
        ...target,
        error: "error",
        help: "Số điện thoại không hợp lệ",
      });
      return false;
    }
    setPhone({value: target.value, error: "", help: ""});
    return true;
  };

  const validateEmailField = (target) => {
    if (!validateEmail(target.value)) {
      setEmail({...target, error: "error", help: "Email không hợp lệ"});
      return false;
    }
    setEmail({value: target.value, error: "", help: ""});
    return true;
  };

  const validatePasswordField = (type, target) => {
    if (!validateTextLength(target.value, 8)) {
      if (type === "old") {
        setOldPassword({
          ...target,
          error: "error",
          help: "Mật khẩu phải lớn hơn hoặc bằng 8 ký tự",
        });
        return false;
      }
      if (type === "new") {
        setNewPassword({
          ...target,
          error: "error",
          help: "Mật khẩu phải lớn hơn hoặc bằng 8 ký tự",
        });
        return false;
      }
    }
    if (type === "new") {
      if (oldPassword.value === newPassword.value) {
        setNewPassword({
          ...target,
          error: "error",
          help: "Mật khẩu mới không được trùng mật khẩu cũ",
        });
        return false;
      }
    }
    if (type === "old")
      setOldPassword({value: target.value, error: "", help: ""});
    if (type === "new")
      setNewPassword({value: target.value, error: "", help: ""});
    return true;
  };

  const validateConfirmPasswordField = (target) => {
    if (newPassword.value !== target.value) {
      setConfirmNewPassword({
        ...target,
        error: "error",
        help: "Nhập lại mật khẩu không trùng khớp",
      });
      return false;
    }
    setConfirmNewPassword({value: target.value, error: "", help: ""});
    return true;
  };

  const disableTelegramId = async () => {
    setBtnLoading(true);
    const res = await apiAccount.updateUser(
      account.id,
      userName.value,
      email.value,
      phone.value,
      0,
      name.value
    );
    setBtnLoading(false);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Huỷ kết nối thành công");
    }
  };

  const updateNewPassword = async () => {
    if (
      validatePasswordField("old", oldPassword) &&
      validatePasswordField("new", newPassword) &&
      validateConfirmPasswordField(confirmNewPassword)
    ) {
      setBtnLoading(true);
      const res = await apiAccount.changePassword(
        oldPassword.value,
        newPassword.value
      );
      setBtnLoading(false);
      if (res?.status?.code === "SUCCESS") {
        showMessage("success", "Cập mật mật khẩu thành công");
      } else if (res?.status?.code === "PASSWORD-01") {
        showMessage("error", res.status.message);
      } else {
        showMessage("error", "Mật khẩu cũ không trùng khớp");
      }
    }
  };

  const updateUser = async () => {
    if (
      validateUserNameField(userName) &&
      validatePhoneField(phone) &&
      validateEmailField(email)
    ) {
      setBtnLoading(true);

      const res = await apiAccount.updateUser(
        account.id,
        userName.value,
        email.value,
        phone.value,
        telegramId.value ? telegramId.value : 0,
        name.value
      );
      setBtnLoading(false);
      if (res?.status?.code === "SUCCESS") {
        showMessage("success", "Cập nhật thông tin thành công");
        dispatch(
          setAccountInfo({
            ...accountState,
            name: name.value,
            userName: userName.value,
          })
        );
        getUserInfo();
      } else {
        showMessage("error", res.status.message);
      }
    }
  };

  return (
    <div className="account-page position-relative">
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h4 className="page-title">Quản lý tài khoản</h4>
            </div>
          </div>
        </div>
        <div className="row tai-khoan-items">
          <div className="col-lg-4 col-xl-4">
            <div className="card-box text-center h-100">
              <h4 className="header-title mb-4 text-left d-flex align-items-center">
                <img
                  width="12"
                  className="mr-1"
                  src={IconUserLight}
                  alt=""
                />
                Thông tin chung
              </h4>
              <div className="avatar-user rounded-circle avatar-lg img-thumbnail">
                <img className="" src={AvatarDefault} alt=""/>
              </div>
              <h4 className="mb-0 text-center">{account.name}</h4>
              <p className="text-muted text-center">
                @{account.userName}
              </p>
              <div className="text-muted mb-2 font-13">
                <strong>Email:</strong>
                <span className="ml-1">{account.email}</span>
              </div>
              <div className="text-muted mb-2 font-13">
                <strong>Số điện thoại:</strong>
                <span className="ml-1">{account.phone}</span>
              </div>
              <div className="text-muted mb-2 font-13">
                <strong>Telegram ID:</strong>
                <span className="ml-1">
                        {[-1, 0, 1].includes(account.telegramId)
                          ? "Chưa có"
                          : account.telegramId}
                      </span>
              </div>
              <div className="text-muted mb-2 font-13">
                <strong>Mã kết nối Telegram:</strong>
                <span className="ml-1">{account.accountId}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-8 col-xl-8">
            <div className="card-box h-100">
              <ul className="nav nav-pills navtab-bg nav-justified mb-3">
                <li className="nav-item" onClick={() => setTab(1)}>
                        <span
                          className={classNames("nav-link", {
                            active: tab === 1,
                          })}
                        >
                          Thiết lập Telegram
                        </span>
                </li>
                <li className="nav-item" onClick={() => setTab(2)}>
                        <span
                          className={classNames("nav-link", {
                            active: tab === 2,
                          })}
                        >
                          Cập nhật thông tin
                        </span>
                </li>
                <li className="nav-item" onClick={() => setTab(3)}>
                        <span
                          className={classNames("nav-link", {
                            active: tab === 3,
                          })}
                        >
                          Đổi mật khẩu
                        </span>
                </li>
              </ul>

              {tab === 1 && (
                <div className="account-tab-item text-center">
                  <h4 className="header-title mb-4 text-left d-flex align-items-center">
                    <img
                      width="12"
                      className="mr-1"
                      src={IconLink}
                      alt=""
                    />
                    Kết nối Telegram
                  </h4>
                  <img
                    className="telegram-logo"
                    src={LogoTelegram}
                    alt=""
                  />
                  <p className="text-center">
                    Kết nối với Telegram để nhận được những thông báo trực
                    tiếp từ hệ thống
                  </p>
                  <div className="form-group d-flex justify-content-center mt-3">
                    {[-1, 0, 1].includes(account.telegramId) ? (
                      <a
                        href="https://t.me/grabcall_bot"
                        target="_blank"
                        className="btn btn-block w-auto btn-connect"
                        type="submit"
                      >
                        {" "}
                        Kết nối với Telegram{" "}
                      </a>
                    ) : (
                      <Button
                        className="btn btn-block w-auto btn-danger text-white"
                        type="submit"
                        loading={btnLoading}
                        onClick={() => disableTelegramId()}
                      >
                        {" "}
                        Huỷ kết nối Telegram{" "}
                      </Button>
                    )}
                  </div>
                </div>
              )}

              {tab === 2 && (
                <div className="account-tab-item">
                  <h4 className="header-title mb-4 text-left d-flex align-items-center">
                    <img
                      width="12"
                      className="mr-1"
                      src={IconSettingsLight}
                      alt=""
                    />
                    Thiết lập
                  </h4>
                  <Form className="dark">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Tài khoản</label>
                          <Form.Item
                            validateStatus={userName.error}
                            help={userName.help}
                          >
                            <Input
                              className="form-control"
                              placeholder="Nhập Username"
                              value={userName.value}
                              onChange={(e) =>
                                setUserName({
                                  ...phone,
                                  value: e.target.value,
                                })
                              }
                              onBlur={() =>
                                validateUserNameField(userName)
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Tên đầy đủ</label>
                          <Form.Item
                            validateStatus={name.error}
                            help={name.help}
                          >
                            <Input
                              className="form-control"
                              placeholder="Nhập Tên đầy đủ"
                              value={name.value}
                              onChange={(e) =>
                                setName({
                                  ...name,
                                  value: e.target.value,
                                })
                              }
                              onBlur={() => validateNameField(name)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Email</label>
                          <Form.Item
                            validateStatus={email.error}
                            help={email.help}
                          >
                            <Input
                              className="form-control"
                              placeholder="Nhập Email"
                              value={email.value}
                              onChange={(e) =>
                                setEmail({
                                  ...phone,
                                  value: e.target.value,
                                })
                              }
                              onBlur={() => validateEmailField(email)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Số điện thoại</label>
                          <Form.Item
                            validateStatus={phone.error}
                            help={phone.help}
                          >
                            <Input
                              className="form-control"
                              placeholder="Nhập Số điện thoại"
                              value={phone.value}
                              onChange={(e) =>
                                setPhone({
                                  ...phone,
                                  value: e.target.value,
                                })
                              }
                              onBlur={() => validatePhoneField(phone)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Telegram ID</label>
                          <Form.Item
                            validateStatus={telegramId.error}
                            help={telegramId.help}
                          >
                            <Input
                              className="form-control"
                              placeholder="Nhập Telegram ID"
                              onChange={(e) =>
                                setTelegramId({
                                  ...telegramId,
                                  value: e.target.value,
                                })
                              }
                              value={telegramId.value}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="form-group d-flex justify-content-end">
                      <Button
                        className="btn btn-primary btn-block w-auto"
                        type="submit"
                        onClick={() => updateUser()}
                        loading={btnLoading}
                      >
                        {" "}
                        Cập nhật{" "}
                      </Button>
                    </div>
                  </Form>
                </div>
              )}
              {tab === 3 && (
                <div className="account-tab-item">
                  <h4 className="header-title mb-4 text-left d-flex align-items-center">
                    <img
                      width="12"
                      className="mr-1"
                      src={IconPassword}
                      alt=""
                    />
                    Đổi mật khẩu
                  </h4>
                  <Form className="dark">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Mật khẩu cũ</label>
                          <Form.Item
                            validateStatus={oldPassword.error}
                            help={oldPassword.help}
                          >
                            <Input.Password
                              value={oldPassword.value}
                              onBlur={() =>
                                validatePasswordField("old", oldPassword)
                              }
                              placeholder="Nhập mật khẩu cũ"
                              onChange={(e) =>
                                setOldPassword({
                                  ...oldPassword,
                                  value: e.target.value,
                                })
                              }
                              className="form-control-password"
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Mật khẩu mới</label>
                          <Form.Item
                            validateStatus={newPassword.error}
                            help={newPassword.help}
                          >
                            <Input.Password
                              value={newPassword.value}
                              onBlur={() =>
                                validatePasswordField("new", newPassword)
                              }
                              placeholder="Nhập mật khẩu mới"
                              onChange={(e) =>
                                setNewPassword({
                                  ...newPassword,
                                  value: e.target.value,
                                })
                              }
                              className="form-control-password"
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Nhập lại mật khẩu mới</label>
                          <Form.Item
                            validateStatus={confirmNewPassword.error}
                            help={confirmNewPassword.help}
                          >
                            <Input.Password
                              value={confirmNewPassword.value}
                              onBlur={() =>
                                validateConfirmPasswordField(
                                  confirmNewPassword
                                )
                              }
                              placeholder="Nhập lại mật khẩu mới"
                              onChange={(e) =>
                                setConfirmNewPassword({
                                  ...confirmNewPassword,
                                  value: e.target.value,
                                })
                              }
                              className="form-control-password"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="form-group d-flex justify-content-end">
                      <Button
                        className="btn btn-primary btn-block w-auto"
                        type="submit"
                        loading={btnLoading}
                        onClick={() => updateNewPassword()}
                      >
                        {" "}
                        Cập nhật{" "}
                      </Button>
                    </div>
                  </Form>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
