import React from "react";
import "./404.scss";

import Ghost from "assets/icons/icon-ghost.svg";
import { Link } from "react-router-dom";

export const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <div className="authentication-bg authentication-bg-pattern pt-5">
        <div className="account-pages">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="card">
                  <div className="card-body p-4">
                    <div className="error-ghost text-center">
                      <img src={Ghost} alt="" />
                    </div>

                    <div className="text-center">
                      <h3 className="mt-4">Không tìm thấy trang </h3>
                      <p className="text-muted mb-0">
                        Có vẻ địa chỉ của bạn truy cập không tồn tại. Vui lòng
                        bấm liên kết phía dưới để quay lại màn hình chính.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12 text-center">
                    <p className="text-white-50">
                      Quay lại{" "}
                      <Link to="/" className="text-white ml-1">
                        <b>Trang chủ</b>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
