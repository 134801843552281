import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Tooltip, Select, Table, Pagination, DatePicker, Form } from "antd";
import "./khach-hang.scss";
import { apiData } from "api/data";
import {
  formatDomain,
  formatHomeNetwork,
  reverseDate,
} from "utils/utils-format";
import { showMessage } from "../../utils/utils-show-message";
import { XuatExcelModal } from "components/xuat-excel-modal/xuat-excel-modal";
import { CallModal } from "../../components/call-modal/call-modal";
import { InputAutoComplete } from "components/input-autocomplete/input-autocomplete";
import { LichSuTruyCapPopover } from "components/lich-su-truy-cap-popover/lich-su-truy-cap-popover";

import IconExcel from "assets/icons/icon-excel.svg";
import IconFilter from "assets/icons/icon-filter.svg";
import IconRefresh from "assets/icons/icon-refresh.svg";

import IconPhoneCall from "../../assets/icons/icon-phone-call.svg";
import IconInfo from "../../assets/icons/icon-info.svg";
import { SelectAgency } from "components/select-dai-ly/select-dai-ly";

const { Option } = Select;
const { RangePicker } = DatePicker;

import {
  UtilsPhoneNet,
  STATUS_PROGRESS,
  STATUS_ACCEPTED,
  STATUS_CONFIRMED,
  STATUS_ENDED,
  STATUS_FAILED,
} from "../../utils/utils-phonenet";
import { Link } from "react-router-dom";

export const KhachHang = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(10);

  const [url, setUrl] = useState("");
  const [accountName, setAccountName] = useState("");
  const [phone, setPhone] = useState("");
  const [visitorName, setVisitorName] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [searchMethod, setSearchMethod] = useState("uid");
  const [isFilter, setIsFilter] = useState(false);

  const [dataData, setDataData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const [suggestionPhone, setSuggestionPhone] = useState([]);
  const [suggestionDomain, setSuggestionDomain] = useState([]);
  const [suggestionAccount, setSuggestionAccount] = useState([]);
  const [suggestionData, setSuggestionData] = useState([]);

  const [visibleExportModal, setvisibleExportModal] = useState(false);
  const [phonenet, setPhonenet] = useState(false);
  const [visibleCallModal, setVisibleCallModal] = useState(false);
  const [statusCall, setStatusCall] = useState(null);
  const [time, setTime] = useState("00:00");
  const [intervalCount, setIntervalCount] = useState(null);
  const [isActiveButtonCall, setIsActiveButtonCall] = useState(false);
  const roleIdUser = useSelector((state) => state.account.role);
  const stateCallio = useSelector(
    (state) => state.account.account?.stateCallio
  );
  const passExt = useSelector((state) => state.account.account?.passExt);
  const extCallio = useSelector((state) => state.account.account?.extCallio);

  const [agencyIdSelected, setAgencyIdSelected] = useState(0);

  const columnsData = [
    {
      title: "#",
      dataIndex: "stt",
      key: "stt",
      width: 50,
    },
    {
      title: "UID",
      dataIndex: "uid",
      key: "uid",
      width: 120,
      className: "text-nowrap",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 60,
      className: "text-nowrap",
    },
    {
      title: "Tên miền",
      dataIndex: "domain",
      key: "domain",
      ellipsis: true,
      className: "width-125-px",
    },
    {
      title: "Khách hàng",
      dataIndex: "khachHang",
      key: "khachHang",
      className: "text-nowrap width-125-px",
      render: (value, row, index) => (
        <a
          // className="table-link"
          href={`https://www.facebook.com/${row.visitorFb}`}
          target="_blank"
        >
          {value}
        </a>
      ),
    },
    // {
    //   title: "Ngày truy cập",
    //   dataIndex: "ngayTruyCap",
    //   key: "ngayTruyCap",
    //   width: 150,
    // },

    // {
    //   title: "IP",
    //   dataIndex: "ip",
    //   key: "ip",
    //   ellipsis: true,
    //   className: "width-125-px",
    // },
    {
      title: "Lượt truy cập",
      dataIndex: "visitCount",
      key: "visitCount",
      width: 65,
      render: (value, row, index) => <LichSuTruyCapPopover data={row} />,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      ellipsis: true,
      // className: "width-125-px",
      width: 100,
    },
    {
      title: "Ngày giờ truy cập",
      dataIndex: "visitTime",
      key: "visitTime",
      width: 120,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      className: "actions",
      width: 95,
      render: (value, row) => {
        return (
          <div className="row">
            {isActiveButtonCall && (
              <Tooltip placement="top" title={"Gọi điện"}>
                <button
                  type="button"
                  className="btn btn-md btn-rounded btn-success waves-effect waves-light d-flex align-items-center btn-icon action"
                  onClick={() => handleCall(row)}
                  disabled={stateCallio !== "active"}
                >
                  <img src={IconPhoneCall} width="12" alt="" />
                </button>
              </Tooltip>
            )}

            <Tooltip placement="top" title={"Chi tiết"}>
              <Link to={`/khach-hang/${row.dataId}`} className={"action"}>
                <button
                  type="button"
                  className="btn btn-md btn-rounded btn-info waves-effect waves-light d-flex align-items-center ml-2 btn-link-icon"
                >
                  <img src={IconInfo} width="12" alt="" />
                </button>
              </Link>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    getAllData();
  }, [pageIndex, pageSize, toDate]);

  useEffect(() => {
    renderPlaceholder();
    checkIsFilter();
  }, [searchMethod, isFilter]);

  const getAllData = async () => {
    checkIsFilter();
    setLoading(true);
    const res = await apiData.getAll({
      pageIndex,
      pageSize,
      url,
      username: accountName,
      phone,
      fromDate,
      toDate,
      visitorName,
      agencyId: agencyIdSelected,
    });
    setLoading(false);
    if (res?.status?.code === "SUCCESS") {
      const data = res.data;
      data.forEach((item, index) => {
        item.stt = index + 1;
        item.uid = item.phone;
        item.dataId = item.id;
        item.domain = formatDomain(item.url);
        item.type = formatHomeNetwork(item.type);
        item.khachHang = item.visitorName;
        item.ngayTruyCap = item.visitTime?.slice(0, 10);
        item.gioTruyCap = item.visitTime?.slice(11);
      });
      console.log(data);
      setDataData(data);
      setTotalPage(res.totalItem);
    }
  };

  const renderPlaceholder = () => {
    switch (searchMethod) {
      case "uid":
        return "Nhập UID cần tìm";
      case "accountName":
        return "Nhập Tên tài khoản cần tìm";
      case "url":
        return "Nhập Tên miền cần tìm";
      case "visitorName":
        return "Nhập Tên khách hàng cần tìm";
      default:
        return "";
    }
  };

  const resetSearchValue = () => {
    setAccountName("");
    setUrl("");
    setPhone("");
    setVisitorName("");
  };

  const getAllSuggestion = async () => {
    const res = await apiData.getAll({
      pageIndex: 1,
      pageSize: 100,
      agencyId: agencyIdSelected,
    });
    if (res?.status?.code === "SUCCESS") {
      const data = res.data;
      const suggestionDomain = [];
      const suggestionAccount = [];
      const suggestionData = [];
      const suggestionPhone = [];
      data.forEach((item) => {
        suggestionDomain.push(item.url);
        suggestionAccount.push(item?.user?.userName);
        suggestionData.push(item.visitorName);
        suggestionPhone.push(item.phone);
      });
      setSuggestionDomain(suggestionDomain);
      setSuggestionAccount(suggestionAccount);
      setSuggestionPhone(suggestionPhone);
      setSuggestionData(suggestionData);
    }
  };

  const renderSuggestion = () => {
    if (searchMethod === "uid") return suggestionPhone;
    if (searchMethod === "url") return suggestionDomain;
    if (searchMethod === "accountName") return suggestionAccount;
    if (searchMethod === "visitorName") return suggestionData;
    return [];
  };

  useEffect(() => {
    getAllSuggestion();
  }, []);

  useEffect(() => {
    if (stateCallio !== "active") {
      return;
    }
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        const phonenet = new UtilsPhoneNet(`${extCallio}`, passExt);
        phonenet.login(
          (e) => {
            setPhonenet(phonenet);
            setIsActiveButtonCall(true);
            // showMessage('success', 'Kết nối tổng đài thành công')
          },
          (e) => console.log(e)
        );
      })
      .catch(() =>
        showMessage(
          "error",
          "Máy tính không có mic hoặc không được cấp quyền dùng mic!"
        )
      );
  }, [stateCallio]);

  useEffect(() => {
    getAllData();
  }, [url, phone, accountName, visitorName, agencyIdSelected]);

  useEffect(() => {
    if (stateCallio !== "active") {
      return;
    }
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        const phonenet = new UtilsPhoneNet(`${extCallio}`, passExt);
        phonenet.login(
          (e) => {
            setPhonenet(phonenet);
            setIsActiveButtonCall(true);
            // showMessage("success", "Kết nối tổng đài thành công");
          },
          (e) => console.log(e)
        );
      })
      .catch(() =>
        showMessage(
          "error",
          "Máy tính không có mic hoặc không được cấp quyền dùng mic!"
        )
      );
  }, [stateCallio]);

  // useEffect(() => {
  //   getAllSuggestion();
  // }, []);

  const onSearch = (value) => {
    setPageIndex(1);
    setPhone("");
    setUrl("");
    setAccountName("");
    setVisitorName("");

    if (searchMethod === "uid") setPhone(value);
    if (searchMethod === "url") setUrl(value);
    if (searchMethod === "accountName") setAccountName(value);
    if (searchMethod === "visitorName") setVisitorName(value);
  };

  const checkIsFilter = () => {
    if (
      visitorName !== "" ||
      url !== "" ||
      accountName !== "" ||
      phone !== "" ||
      searchMethod !== "uid" ||
      fromDate !== "" ||
      toDate !== ""
    ) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  };

  const deleteFilter = () => {
    setUrl("");
    setAccountName("");
    setPhone("");
    setFromDate("");
    setVisitorName("");
    setToDate("");
    setPageIndex(1);
    setSearchMethod("uid");
    getAllData();
  };

  const handleDatePicker = (date, dateString) => {
    const startDate = dateString[0];
    const endDate = dateString[1];
    setPageIndex(1);
    setFromDate(startDate);
    setToDate(endDate);
  };

  const handleCall = async (row) => {
    if (["ROOT_ADMIN", "ADMIN"].includes(roleIdUser)) {
      phonenet.makeACall(
        row.uid,
        onProgress,
        onAccepted,
        onConfirmed,
        onEnded,
        onFailed
      );
      return;
    }
    const res = await apiData.getOne(row.id, true);
    if (res.status.code !== "SUCCESS") {
      showMessage("error", "Khách hàng không tồn tại");
      return;
    }
    setStatusCall(null);
    const data = res.data;
    phonenet.makeACall(
      data.phone,
      onProgress,
      onAccepted,
      onConfirmed,
      onEnded,
      onFailed
    );
  };

  const handleAgencyChange = (agencyId) => {
    setAgencyIdSelected(agencyId);
  };

  const showDetail = (row) => {};

  const onProgress = () => {
    setStatusCall(STATUS_PROGRESS);
    setVisibleCallModal(true);
  };

  const onAccepted = () => {
    setStatusCall(STATUS_ACCEPTED);
  };

  const onConfirmed = () => {
    setStatusCall(STATUS_CONFIRMED);
    let minute = 0,
      second = 0;
    const timeInterval = setInterval(() => {
      ++second;
      if (second === 59) {
        second = 0;
        ++minute;
      }
      setTime(
        `${minute.toString().padStart(2, "0")}:${second
          .toString()
          .padStart(2, "0")}`
      );
    }, 1000);
    setIntervalCount(timeInterval);
  };

  useEffect(() => {
    if (statusCall === STATUS_ENDED) {
      clearInterval(intervalCount);
      // setStatusCall(null);
    }
  }, [intervalCount, statusCall]);

  const onEnded = () => {
    setStatusCall(STATUS_ENDED);
    // setTimeout(() => setVisibleCallModal(false), 1000);
  };

  const onFailed = (e) => {
    setStatusCall(STATUS_FAILED);
    // setTimeout(() => setVisibleCallModal(false), 1000);
  };

  const cancelCalll = () => {
    setVisibleCallModal(false);
    setTime("00:00");
    phonenet.cancelCall();
  };

  return (
    <div className="khach-hang-page position-relative min-height-100vh">
      <XuatExcelModal
        visibleModal={visibleExportModal}
        onCancel={() => setvisibleExportModal(false)}
        onSuccess={() => setvisibleExportModal(false)}
      />
      <CallModal
        visibleModal={visibleCallModal}
        status={statusCall}
        time={time}
        onCancel={() => setVisibleCallModal(false)}
        onCancelCall={() => cancelCalll()}
      />
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <Form className="form-inline">
                  <button
                    className="btn btn-secondary btn-sm ml-2"
                    onClick={() => {
                      resetSearchValue();
                      setFromDate("");
                      setToDate("");
                      getAllData();
                    }}
                  >
                    <img src={IconRefresh} alt="" width="11" />
                  </button>
                </Form>
              </div>
              <h4 className="page-title">Khách hàng</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card-box">
              <div className="row">
                <div className="col-12">
                  <div className="card-box">
                    <h4 className="header-title d-flex align-items-center flex-wrap justify-content-between">
                      <span className="mb-2">Quản lý khách hàng</span>
                      <div className="d-flex flex-wrap align-items-center">
                        {isFilter && (
                          <button
                            type="button"
                            className="btn btn-sm btn-danger waves-effect waves-light d-flex align-items-center mr-2 mb-2"
                            onClick={() => deleteFilter()}
                          >
                            <img
                              src={IconFilter}
                              width="12"
                              className="mr-1"
                              alt=""
                            />{" "}
                            Xoá bộ lọc
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn btn-sm btn-success waves-effect waves-light d-flex align-items-center mb-2"
                          loading={btnLoading}
                          onClick={() => setvisibleExportModal(true)}
                        >
                          <img
                            src={IconExcel}
                            width="12"
                            className="mr-1"
                            alt=""
                          />{" "}
                          Xuất Excel
                        </button>
                      </div>
                    </h4>

                    {["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) && (
                      <div className="table-tools d-flex align-items-center justify-content-between mb-3">
                        <div className="tool-item filter d-flex align-items-center">
                          <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                            Đại lý:
                            <SelectAgency
                              placeholder="Tất cả"
                              onChange={(value) => handleAgencyChange(value)}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="table-tools d-flex align-items-center justify-content-between mb-3">
                      <div className="tool-item filter d-flex align-items-center">
                        <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                          Kiểu tìm kiếm
                          <Select
                            value={searchMethod}
                            onSelect={(value) => {
                              resetSearchValue();
                              setSearchMethod(value);
                            }}
                          >
                            <Option value="uid">UID</Option>
                            {/* { ['ROOT_ADMIN', 'ADMIN'].includes(roleIdUser) && <Option value="accountName">Tài khoản</Option> } */}
                            {["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) && (
                              <Option value="url">Tên miền</Option>
                            )}
                            {["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) && (
                              <Option value="visitorName">Khách hàng</Option>
                            )}
                          </Select>
                        </div>
                        <div className="tool-item search d-flex align-items-center text-nowrap">
                          Tìm kiếm:
                          <InputAutoComplete
                            isFilter={isFilter}
                            dataSource={renderSuggestion()}
                            placeholder={renderPlaceholder()}
                            onSearch={(value) => onSearch(value)}
                          />
                        </div>
                      </div>
                      <div className="tool-item search d-flex align-items-center text-nowrap">
                        <RangePicker
                          format="DD-MM-YYYY"
                          value={[
                            fromDate ? moment(reverseDate(fromDate)) : null,
                            toDate ? moment(reverseDate(toDate)) : null,
                          ]}
                          placeholder={["Từ ngày", "Đến ngày"]}
                          onChange={(date, dateString) =>
                            handleDatePicker(date, dateString)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Table
                loading={loading}
                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                dataSource={dataData}
                columns={columnsData}
                pagination={false}
                rowKey={(record) => `expandable-${record.stt}`}
              />
              {dataData.length !== 0 && (
                <div className="table-panigate d-flex align-items-center justify-content-between mt-3">
                  <div className="dataTables_info">
                    Trang {pageIndex} trong tổng số{" "}
                    {Math.ceil(totalPage / pageSize)} trang
                  </div>
                  <div className="show-panigate d-flex align-items-center">
                    <div className="tool-item select d-flex align-items-center pagesize-select">
                      Hiển thị
                      <Select
                        value={pageSize}
                        onSelect={(value) => setPageSize(value)}
                      >
                        <Option value="10">10</Option>
                        <Option value="30">30</Option>
                        <Option value="50">50</Option>
                        <Option value="70">70</Option>
                        <Option value="100">100</Option>
                      </Select>
                      dữ liệu
                    </div>
                    <div className="table-pagination">
                      <Pagination
                        current={pageIndex}
                        pageSize={pageSize}
                        total={totalPage}
                        showSizeChanger={false}
                        onChange={(page) => setPageIndex(page)}
                        hideOnSinglePage={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
