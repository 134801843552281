import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Form, Select, Steps } from "antd";

import "./add-facebook-tracking-modal.scss";

import { isFacebookUrl, validateText } from "utils/utils-validation";
import { showMessage } from "utils/utils-show-message";
import { apiFacebookTracking } from "api/facebook-tracking";
import { parseToken } from "../../utils/utils-parse-token";
import { setAccountRole } from "../../store/account";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/lib/input/TextArea";

export const AddFacebookTrackingModal = ({
  visibleModal,
  isCreate,
  post,
  onCancel,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const [linkPost, setLinkPost] = useState({ value: "", error: "", help: "" });
  const [description, setDescription] = useState({
    value: "",
    error: "",
    help: "",
  });

  const [btnLoading, setBtnLoading] = useState(false);
  const roleIdUser = useSelector((state) => state.account.role);
  const getUserRole = () => {
    const role =
      parseToken(localStorage.getItem("token-datatuoi"))?.role || null;
    dispatch(setAccountRole(role));
  };
  useEffect(() => {
    getUserRole();
  }, []);
  const initModal = () => {
    setLinkPost({ value: "", error: "", help: "" });
    setDescription({ value: "", error: "", help: "" });
    if (!isCreate && post) {
      setLinkPost({ value: post.linkPost, error: "", help: "" });
      setDescription({ value: post.description, error: "", help: "" });
    }
    setBtnLoading(false);
  };
  useEffect(() => {
    initModal();
  }, [isCreate]);

  const validateLinkPostField = (target) => {
    if (!validateText(target.value)) {
      setLinkPost({
        ...target,
        error: "error",
        help: "Vui lòng nhập link facebook",
      });
      return false;
    }
    if (!isFacebookUrl(target.value)) {
      setLinkPost({
        ...target,
        error: "error",
        help: "Vui lòng nhập đúng link facebook",
      });
      return false;
    }
    setLinkPost({ value: target.value, error: "", help: "" });
    return true;
  };

  const saveOrUpdateFacebookTracking = async () => {
    if (!validateLinkPostField(linkPost)) {
      return;
    }
    setBtnLoading(true);
    const body = {
      linkPost: linkPost.value,
      description: description.value,
    };
    let res;
    if (isCreate) {
      res = await apiFacebookTracking.create(body);
    } else {
      body.id = post.id;
      res = await apiFacebookTracking.update(body);
    }
    setBtnLoading(false);
    if (res?.status?.code === "SUCCESS") {
      showMessage(
        "success",
        isCreate
          ? "Thêm link tracking facebook thành công!"
          : "Cập nhật link tracking facebook thành công!"
      );
      onCancel();
      onSuccess();
      initModal();
    } else {
      showMessage("error", res.status.message);
    }
  };

  return (
    <Modal
      title={
        isCreate
          ? "Tạo mới link tracking facebook"
          : "Cập nhật link tracking facebook"
      }
      footer={null}
      visible={visibleModal}
      wrapClassName="add-facebook-tracking-modal"
      onCancel={() => {
        onCancel();
        initModal();
      }}
    >
      <Form className="modal-form dark mt-3 mb-2">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>Link facebook</label>
              <Form.Item validateStatus={linkPost.error} help={linkPost.help}>
                <Input
                  className="form-control"
                  placeholder="Nhập link facebook"
                  onChange={(e) =>
                    setLinkPost({ ...linkPost, value: e.target.value })
                  }
                  value={linkPost.value}
                  onBlur={() => validateLinkPostField(linkPost)}
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-12">
            <div className="form-group">
              <label>Mô tả</label>
              <Form.Item
                validateStatus={description.error}
                help={description.help}
              >
                <TextArea
                  className="form-control"
                  placeholder="Nhập mô tả link bài viết"
                  onChange={(e) =>
                    setDescription({ ...description, value: e.target.value })
                  }
                  value={description.value}
                  // onBlur={() => validatePhoneField(phone)}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
      <div className="btn-step-group d-flex justify-content-end">
        <Button
          className="btn btn-primary btn-block"
          type="button"
          onClick={() => {
            onCancel();
            initModal();
          }}
        >
          {" "}
          Hủy{" "}
        </Button>
        <Button
          className="btn btn-success btn-block"
          loading={btnLoading}
          type="submit"
          onClick={() => saveOrUpdateFacebookTracking()}
        >
          {" "}
          {isCreate ? "Tạo mới" : "Cập nhật"}{" "}
        </Button>
      </div>
    </Modal>
  );
};
