import { rest } from "./rest";

export const apiContact = {
  getAll: (params) => rest.get("/contacts", params, null, true),
  create: (contact) => rest.post("/contacts", contact, null, true),
  update: (contact) => rest.put(`/contacts/${contact.id}`, contact, null, true),
  delete: (id) => rest.delete(`/contacts/${id}`, null, null, true),
  getById: (id) => rest.get(`/contacts/${id}`, null, null, true),
  getTemplate: () => rest.getFile("/contacts/template", {}, null),
  importExcel: (formData) => rest.postFile("/contacts/import", formData, null),

  getAllCareHistoryOfContact: (id, pageIndex, pageSize) =>
    rest.get(`/contacts/${id}/history`, { pageIndex, pageSize }, null, true),

  getCareHistoryOfContact: (id, historyId) =>
    rest.get(`/contacts/${id}/history/${historyId}`, {}, null, true),

  deleteCareHistoryOfContact: (id, historyId) =>
    rest.delete(`/contacts/${id}/history/${historyId}`, {}, null, true),

  getAllHistoryCareCustomer: (pageIndex, pageSize) =>
    rest.get(`/historyCareCustomer`, { pageIndex, pageSize }, null, true),

  createCareHistory: (id, careHistory) =>
    rest.post(`/contacts/${id}/history`, careHistory, null, true),

  updateCareHistory: (id, careHistory) =>
    rest.put(
      `/contacts/${id}/history/${careHistory.id}`,
      careHistory,
      null,
      true
    ),

  addContactConfig: (contactConfig) =>
    rest.post("/addContactConfig", contactConfig, null, true),
  getContactConfig: () => rest.get("/contactConfig", {}, null, true),
};
