import React, { useEffect, useState } from "react";
import "./select-dai-ly.scss";
import { Select } from "antd";
import { apiAccount } from "api/account";
import { showMessage } from "utils/utils-show-message";

const { Option } = Select;

export const SelectAgency = ({ placeholder, onChange }) => {
  const [agencyList, setAgencyList] = useState([]);

  const getAllAgency = async () => {
    const msgError = "Có lỗi xảy ra với danh sách đại lý";

    // Get total of agencies
    const resCountAgency = await apiAccount.getUsersByRole({
      pageIndex: 1,
      pageSize: 1,
      roleName: "AGENCY",
    });
    if (resCountAgency?.status?.code === "SUCCESS") {
      const totalAgencies = resCountAgency?.totalItem;

      // Get all agencies
      const res = await apiAccount.getUsersByRole({
        pageIndex: 1,
        pageSize: totalAgencies,
        roleName: "AGENCY",
      });

      if (res?.status?.code === "SUCCESS") {
        setAgencyList(res.data);
      } else {
        showMessage("error", msgError);
      }
    } else {
      showMessage("error", msgError);
    }
  };

  useEffect(() => {
    getAllAgency();
  }, []);

  return (
    <div className="select-dai-ly">
      <Select
        showSearch
        defaultValue={0}
        placeholder={placeholder}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
        onChange={(agencyId) => onChange(agencyId)}
      >
        <Option key="all" value={-1}>
          Tất cả
        </Option>
        <Option key="main" value={0}>
          Telegamez.org
        </Option>
        {agencyList.map((item, index) => (
          <Option key={index} value={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    </div>
  );
};
