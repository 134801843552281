import React, { useEffect, useState } from "react";
import "./lich-su-truy-cap-popover.scss";
import { apiData } from "api/data";
import { Button, Popover, Timeline, Typography } from "antd";

import IconArrowDown from "assets/icons/icon-arrow-down.svg";
import IconEye from "assets/icons/icon-eye.svg";

const { Paragraph } = Typography;

export const LichSuTruyCapPopover = ({ data }) => {
  const { dataId, visitCount, uid, ngayTruyCap, gioTruyCap } = data;

  const [currentDataId, setCurrentDataId] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);

  const [visitorHistories, setVisitorHistories] = useState([]);
  const [pendingHistory, setPendingHistory] = useState(false);

  const VisitorHistoryContent = () => {
    const timelineItems = [];
    visitorHistories.forEach((item, index) => {
      timelineItems.push(
        <Timeline.Item
          label={`#${totalPage - index} : ${item.visitTime}`}
          key={index}
          color={
            item.dataId < dataId
              ? "green"
              : item.dataId == dataId
              ? "blue"
              : "gray"
          }
        >
          <Paragraph
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: <img src={IconArrowDown} alt="" width="11" />,
            }}
          >
            {item.url}
          </Paragraph>
        </Timeline.Item>
      );
    });

    return (
      <div>
        <Timeline
          wrapclassname="them-chien-dich-modal them-don-hang-modal xuat-excel-modal"
          mode="left"
          style={{ width: 500 }}
          pending={pendingHistory}
        >
          {timelineItems}
        </Timeline>
        <LoadMoreHistory />
      </div>
    );
  };

  const LoadMoreHistory = () => {
    if (visitorHistories.length >= totalPage) {
      return <></>;
    }
    return (
      <div className="d-flex justify-content-center">
        <Button
          className="btn btn-info btn-block w-auto"
          disabled={pendingHistory}
          onClick={() => {
            setPageIndex((pageIndex) => pageIndex + 1);
          }}
        >
          {" "}
          Xem thêm{" "}
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (pageIndex > 1) {
      let loadMore = true;
      getVisitorHistories(loadMore);
    }
  }, [pageIndex]);

  useEffect(() => {
    setCurrentDataId(dataId);
  }, [dataId]);

  const getVisitorHistories = async (isLoadMore) => {
    setPendingHistory(true);
    const res = await apiData.visitorHistory({
      dataId,
      pageIndex,
      pageSize,
    });

    if (res?.status?.code === "SUCCESS") {
      const histories = [];
      res.data.forEach((item, index) => {
        histories.push({
          stt: index + 1,
          dataId: item.id,
          phone: item.phone,
          url: item.url,
          visitTime: item.visitTime,
        });
      });

      setTotalPage(res.totalItem);
      if (isLoadMore) {
        setVisitorHistories([...visitorHistories, ...histories]);
      } else {
        setVisitorHistories(histories);
      }
      setPendingHistory(false);
    }
  };

  return (
    <Popover
      content={VisitorHistoryContent}
      title={`Số ${uid} đã truy cập ${
        totalPage > 0 ? totalPage : "?"
      } lần (Tính đến hiện tại)`}
      placement="left"
      trigger="click"
      onVisibleChange={(visible) => {
        if (visible) {
          getVisitorHistories();
        } else {
          setTotalPage(0);
          setVisitorHistories([]);
        }
      }}
    >
      {visitCount >= 1 ? <a href={`#`}>{visitCount}</a> : <a href={`#`}>-</a>}
    </Popover>
  );
};
