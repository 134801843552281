import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import classNames from "classnames";
import { Link, useLocation, useRouteMatch } from "react-router-dom";

import "./left-sidebar.scss";

export const LeftSidebar = () => {
  const dispatch = useDispatch();
  const locationRoute = useLocation().pathname;
  const roleIdUser = useSelector((state) => state.account.role);
  const account = useSelector((state) => state.account.account);

  useEffect(() => {
    setTimeout(() => {
      dynamicallyLoadScript("assets/js/vendor.js");
      setTimeout(() => {
        dynamicallyLoadScript("assets/js/app.js");
      }, 1000);
    }, 1000);
  }, []);

  const onLeftMenuClick = () => {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("sidebar-enable");
  };

  const dynamicallyLoadScript = (url) => {
    const script = document.createElement("script"); // create a script DOM node
    script.src = url; // set its src to the provided URL
    document.body.appendChild(script); // add it to the end of the head section of the page (could change 'head' to 'body' to add it to the end of the body section instead)
  };

  return (
    <div className={"left-sidebar-page"}>
      <div className="left-side-menu">
        <div className="h-100">
          {/* Sidemenu */}
          <div id="sidebar-menu">
            <ul id="side-menu">
              {/*<li>*/}
              {/*  <a href="#sidebarDashboards" data-toggle="collapse">*/}
              {/*    <i data-feather="airplay"></i>*/}
              {/*    <span className="badge badge-success badge-pill float-right">4</span>*/}
              {/*    <span> Dashboards </span>*/}
              {/*  </a>*/}
              {/*  <div className="collapse" id="sidebarDashboards">*/}
              {/*    <ul className="nav-second-level">*/}
              {/*      <li>*/}
              {/*        <a href="index.html">Dashboard 1</a>*/}
              {/*      </li>*/}
              {/*      <li>*/}
              {/*        <a href="dashboard-2.html">Dashboard 2</a>*/}
              {/*      </li>*/}
              {/*      <li>*/}
              {/*        <a href="dashboard-3.html">Dashboard 3</a>*/}
              {/*      </li>*/}
              {/*      <li>*/}
              {/*        <a href="dashboard-4.html">Dashboard 4</a>*/}
              {/*      </li>*/}
              {/*    </ul>*/}
              {/*  </div>*/}
              {/*</li>*/}
              <li className="nav-item dropdown">
                <Link
                  to="/"
                  onClick={() => onLeftMenuClick()}
                  className={classNames("nav-link dropdown-toggle arrow-none", {
                    active: locationRoute === "/",
                  })}
                >
                  <svg
                    height="512pt"
                    viewBox="0 0 512 512"
                    width="512pt"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m197.332031 170.667969h-160c-20.585937 0-37.332031-16.746094-37.332031-37.335938v-96c0-20.585937 16.746094-37.332031 37.332031-37.332031h160c20.589844 0 37.335938 16.746094 37.335938 37.332031v96c0 20.589844-16.746094 37.335938-37.335938 37.335938zm-160-138.667969c-2.941406 0-5.332031 2.390625-5.332031 5.332031v96c0 2.945313 2.390625 5.335938 5.332031 5.335938h160c2.945313 0 5.335938-2.390625 5.335938-5.335938v-96c0-2.941406-2.390625-5.332031-5.335938-5.332031zm0 0" />
                    <path d="m197.332031 512h-160c-20.585937 0-37.332031-16.746094-37.332031-37.332031v-224c0-20.589844 16.746094-37.335938 37.332031-37.335938h160c20.589844 0 37.335938 16.746094 37.335938 37.335938v224c0 20.585937-16.746094 37.332031-37.335938 37.332031zm-160-266.667969c-2.941406 0-5.332031 2.390625-5.332031 5.335938v224c0 2.941406 2.390625 5.332031 5.332031 5.332031h160c2.945313 0 5.335938-2.390625 5.335938-5.332031v-224c0-2.945313-2.390625-5.335938-5.335938-5.335938zm0 0" />
                    <path d="m474.667969 512h-160c-20.589844 0-37.335938-16.746094-37.335938-37.332031v-96c0-20.589844 16.746094-37.335938 37.335938-37.335938h160c20.585937 0 37.332031 16.746094 37.332031 37.335938v96c0 20.585937-16.746094 37.332031-37.332031 37.332031zm-160-138.667969c-2.945313 0-5.335938 2.390625-5.335938 5.335938v96c0 2.941406 2.390625 5.332031 5.335938 5.332031h160c2.941406 0 5.332031-2.390625 5.332031-5.332031v-96c0-2.945313-2.390625-5.335938-5.332031-5.335938zm0 0" />
                    <path d="m474.667969 298.667969h-160c-20.589844 0-37.335938-16.746094-37.335938-37.335938v-224c0-20.585937 16.746094-37.332031 37.335938-37.332031h160c20.585937 0 37.332031 16.746094 37.332031 37.332031v224c0 20.589844-16.746094 37.335938-37.332031 37.335938zm-160-266.667969c-2.945313 0-5.335938 2.390625-5.335938 5.332031v224c0 2.945313 2.390625 5.335938 5.335938 5.335938h160c2.941406 0 5.332031-2.390625 5.332031-5.335938v-224c0-2.941406-2.390625-5.332031-5.332031-5.332031zm0 0" />
                  </svg>
                  <span>Tổng quan</span>
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  to="/khach-hang"
                  onClick={() => onLeftMenuClick()}
                  className={classNames("nav-link dropdown-toggle arrow-none", {
                    active:
                      locationRoute === "/khach-hang" ||
                      useRouteMatch("/khach-hang/:id"),
                  })}
                >
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                  >
                    <path d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40c59.551,0,108,48.448,108,108S315.551,256,256,256z" />
                  </svg>
                  <span>Khách hàng</span>
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  to="/facebook-scan"
                  onClick={() => onLeftMenuClick()}
                  className={classNames("nav-link dropdown-toggle arrow-none", {
                    active: locationRoute === "/facebook-scan",
                  })}
                >
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                  >
                    <path d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40c59.551,0,108,48.448,108,108S315.551,256,256,256z" />
                  </svg>
                  <span>Facebook Scan</span>
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  to="/chien-dich"
                  onClick={() => onLeftMenuClick()}
                  className={classNames("nav-link dropdown-toggle arrow-none", {
                    active: locationRoute === "/chien-dich",
                  })}
                >
                  <svg
                    id="Capa_1"
                    enableBackground="new 0 0 512 512"
                    height="512"
                    viewBox="0 0 512 512"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m511.4 38.222c-1.109-20.338-17.284-36.511-37.622-37.621-41.038-2.242-121.342-.061-198.13 39.656-39.145 20.248-80.545 54.577-113.584 94.185-.407.488-.803.979-1.207 1.468l-74.98 5.792c-12.342.954-23.335 7.423-30.161 17.747l-51.154 77.372c-5.177 7.83-6 17.629-2.203 26.212 3.798 8.584 11.602 14.566 20.877 16.003l63.171 9.784c-.223 1.228-.447 2.455-.652 3.683-2.103 12.58 2.065 25.514 11.151 34.599l87.992 87.993c7.533 7.533 17.712 11.686 28.142 11.686 2.148 0 4.308-.177 6.458-.536 1.228-.205 2.455-.429 3.683-.652l9.784 63.172c1.437 9.275 7.419 17.08 16.001 20.877 3.571 1.58 7.35 2.36 11.112 2.36 5.283-.001 10.529-1.539 15.101-4.562l77.372-51.155c10.325-6.827 16.793-17.82 17.745-30.161l5.792-74.979c.489-.404.981-.8 1.469-1.207 39.609-33.039 73.939-74.439 94.186-113.585 39.719-76.791 41.896-157.096 39.657-198.131zm-175.394 393.037-74.011 48.933-9.536-61.565c31.28-9.197 62.223-23.927 91.702-43.66l-3.773 48.845c-.235 3.047-1.833 5.762-4.382 7.447zm-129.895-37.377-87.993-87.993c-2.245-2.246-3.283-5.401-2.774-8.44 2.616-15.643 6.681-30.534 11.713-44.562l132.028 132.028c-16.848 6.035-31.939 9.635-44.534 11.741-3.044.506-6.195-.529-8.44-2.774zm-117.923-222.269 48.844-3.773c-19.734 29.479-34.464 60.422-43.661 91.702l-61.564-9.535 48.934-74.012c1.686-2.55 4.401-4.147 7.447-4.382zm270.155 155.286c-24.233 20.213-47.756 34.833-69.438 45.412l-149.221-149.221c13.858-28.304 30.771-51.873 45.417-69.431 30.575-36.655 68.602-68.276 104.331-86.756 70.474-36.453 144.725-38.416 182.713-36.348 5.028.274 9.027 4.273 9.301 9.302 2.071 37.988.104 112.238-36.349 182.713-18.479 35.728-50.1 73.754-86.754 104.329z" />
                    <path d="m350.721 236.243c19.202-.002 38.412-7.312 53.031-21.931 14.166-14.165 21.966-32.999 21.966-53.031s-7.801-38.866-21.966-53.031c-29.242-29.243-76.822-29.241-106.062 0-14.166 14.165-21.967 32.999-21.967 53.031s7.802 38.866 21.967 53.031c14.622 14.622 33.822 21.933 53.031 21.931zm-31.82-106.781c8.772-8.773 20.295-13.159 31.818-13.159 11.524 0 23.047 4.386 31.819 13.159 8.499 8.499 13.179 19.799 13.179 31.818s-4.68 23.32-13.179 31.819c-17.544 17.545-46.093 17.544-63.638 0-8.499-8.499-13.18-19.799-13.18-31.818s4.682-23.32 13.181-31.819z" />
                    <path d="m15.301 421.938c3.839 0 7.678-1.464 10.606-4.394l48.973-48.973c5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.858-21.213 0l-48.972 48.973c-5.858 5.858-5.858 15.355 0 21.213 2.928 2.929 6.767 4.394 10.606 4.394z" />
                    <path d="m119.761 392.239c-5.857-5.858-15.355-5.858-21.213 0l-94.154 94.155c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.767 4.393 10.606 4.393s7.678-1.464 10.606-4.394l94.154-94.154c5.859-5.858 5.859-15.355.001-21.213z" />
                    <path d="m143.429 437.12-48.973 48.973c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.464 10.606-4.394l48.973-48.973c5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.858-21.212 0z" />
                  </svg>
                  <span>Chiến dịch</span>
                </Link>
              </li>

              {["ROOT_ADMIN"].includes(roleIdUser) && (
                <li className="nav-item dropdown">
                  <Link
                    to="/chien-dich-tu-khoa"
                    onClick={() => onLeftMenuClick()}
                    className={classNames(
                      "nav-link dropdown-toggle arrow-none",
                      {
                        active: locationRoute === "/chien-dich-tu-khoa",
                      }
                    )}
                  >
                    <svg
                      id="Capa_1"
                      enableBackground="new 0 0 512 512"
                      height="512"
                      viewBox="0 0 512 512"
                      width="512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m511.4 38.222c-1.109-20.338-17.284-36.511-37.622-37.621-41.038-2.242-121.342-.061-198.13 39.656-39.145 20.248-80.545 54.577-113.584 94.185-.407.488-.803.979-1.207 1.468l-74.98 5.792c-12.342.954-23.335 7.423-30.161 17.747l-51.154 77.372c-5.177 7.83-6 17.629-2.203 26.212 3.798 8.584 11.602 14.566 20.877 16.003l63.171 9.784c-.223 1.228-.447 2.455-.652 3.683-2.103 12.58 2.065 25.514 11.151 34.599l87.992 87.993c7.533 7.533 17.712 11.686 28.142 11.686 2.148 0 4.308-.177 6.458-.536 1.228-.205 2.455-.429 3.683-.652l9.784 63.172c1.437 9.275 7.419 17.08 16.001 20.877 3.571 1.58 7.35 2.36 11.112 2.36 5.283-.001 10.529-1.539 15.101-4.562l77.372-51.155c10.325-6.827 16.793-17.82 17.745-30.161l5.792-74.979c.489-.404.981-.8 1.469-1.207 39.609-33.039 73.939-74.439 94.186-113.585 39.719-76.791 41.896-157.096 39.657-198.131zm-175.394 393.037-74.011 48.933-9.536-61.565c31.28-9.197 62.223-23.927 91.702-43.66l-3.773 48.845c-.235 3.047-1.833 5.762-4.382 7.447zm-129.895-37.377-87.993-87.993c-2.245-2.246-3.283-5.401-2.774-8.44 2.616-15.643 6.681-30.534 11.713-44.562l132.028 132.028c-16.848 6.035-31.939 9.635-44.534 11.741-3.044.506-6.195-.529-8.44-2.774zm-117.923-222.269 48.844-3.773c-19.734 29.479-34.464 60.422-43.661 91.702l-61.564-9.535 48.934-74.012c1.686-2.55 4.401-4.147 7.447-4.382zm270.155 155.286c-24.233 20.213-47.756 34.833-69.438 45.412l-149.221-149.221c13.858-28.304 30.771-51.873 45.417-69.431 30.575-36.655 68.602-68.276 104.331-86.756 70.474-36.453 144.725-38.416 182.713-36.348 5.028.274 9.027 4.273 9.301 9.302 2.071 37.988.104 112.238-36.349 182.713-18.479 35.728-50.1 73.754-86.754 104.329z" />
                      <path d="m350.721 236.243c19.202-.002 38.412-7.312 53.031-21.931 14.166-14.165 21.966-32.999 21.966-53.031s-7.801-38.866-21.966-53.031c-29.242-29.243-76.822-29.241-106.062 0-14.166 14.165-21.967 32.999-21.967 53.031s7.802 38.866 21.967 53.031c14.622 14.622 33.822 21.933 53.031 21.931zm-31.82-106.781c8.772-8.773 20.295-13.159 31.818-13.159 11.524 0 23.047 4.386 31.819 13.159 8.499 8.499 13.179 19.799 13.179 31.818s-4.68 23.32-13.179 31.819c-17.544 17.545-46.093 17.544-63.638 0-8.499-8.499-13.18-19.799-13.18-31.818s4.682-23.32 13.181-31.819z" />
                      <path d="m15.301 421.938c3.839 0 7.678-1.464 10.606-4.394l48.973-48.973c5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.858-21.213 0l-48.972 48.973c-5.858 5.858-5.858 15.355 0 21.213 2.928 2.929 6.767 4.394 10.606 4.394z" />
                      <path d="m119.761 392.239c-5.857-5.858-15.355-5.858-21.213 0l-94.154 94.155c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.767 4.393 10.606 4.393s7.678-1.464 10.606-4.394l94.154-94.154c5.859-5.858 5.859-15.355.001-21.213z" />
                      <path d="m143.429 437.12-48.973 48.973c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.464 10.606-4.394l48.973-48.973c5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.858-21.212 0z" />
                    </svg>
                    <span>Từ khóa</span>
                  </Link>
                </li>
              )}

              <li className="nav-item dropdown">
                <Link
                  to="/telesales"
                  onClick={() => onLeftMenuClick()}
                  className={classNames("nav-link dropdown-toggle arrow-none", {
                    active: locationRoute === "/telesales",
                  })}
                >
                  <svg
                    id="Capa_1"
                    enableBackground="new 0 0 512 512"
                    height="512"
                    viewBox="0 0 512 512"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m511.4 38.222c-1.109-20.338-17.284-36.511-37.622-37.621-41.038-2.242-121.342-.061-198.13 39.656-39.145 20.248-80.545 54.577-113.584 94.185-.407.488-.803.979-1.207 1.468l-74.98 5.792c-12.342.954-23.335 7.423-30.161 17.747l-51.154 77.372c-5.177 7.83-6 17.629-2.203 26.212 3.798 8.584 11.602 14.566 20.877 16.003l63.171 9.784c-.223 1.228-.447 2.455-.652 3.683-2.103 12.58 2.065 25.514 11.151 34.599l87.992 87.993c7.533 7.533 17.712 11.686 28.142 11.686 2.148 0 4.308-.177 6.458-.536 1.228-.205 2.455-.429 3.683-.652l9.784 63.172c1.437 9.275 7.419 17.08 16.001 20.877 3.571 1.58 7.35 2.36 11.112 2.36 5.283-.001 10.529-1.539 15.101-4.562l77.372-51.155c10.325-6.827 16.793-17.82 17.745-30.161l5.792-74.979c.489-.404.981-.8 1.469-1.207 39.609-33.039 73.939-74.439 94.186-113.585 39.719-76.791 41.896-157.096 39.657-198.131zm-175.394 393.037-74.011 48.933-9.536-61.565c31.28-9.197 62.223-23.927 91.702-43.66l-3.773 48.845c-.235 3.047-1.833 5.762-4.382 7.447zm-129.895-37.377-87.993-87.993c-2.245-2.246-3.283-5.401-2.774-8.44 2.616-15.643 6.681-30.534 11.713-44.562l132.028 132.028c-16.848 6.035-31.939 9.635-44.534 11.741-3.044.506-6.195-.529-8.44-2.774zm-117.923-222.269 48.844-3.773c-19.734 29.479-34.464 60.422-43.661 91.702l-61.564-9.535 48.934-74.012c1.686-2.55 4.401-4.147 7.447-4.382zm270.155 155.286c-24.233 20.213-47.756 34.833-69.438 45.412l-149.221-149.221c13.858-28.304 30.771-51.873 45.417-69.431 30.575-36.655 68.602-68.276 104.331-86.756 70.474-36.453 144.725-38.416 182.713-36.348 5.028.274 9.027 4.273 9.301 9.302 2.071 37.988.104 112.238-36.349 182.713-18.479 35.728-50.1 73.754-86.754 104.329z" />
                    <path d="m350.721 236.243c19.202-.002 38.412-7.312 53.031-21.931 14.166-14.165 21.966-32.999 21.966-53.031s-7.801-38.866-21.966-53.031c-29.242-29.243-76.822-29.241-106.062 0-14.166 14.165-21.967 32.999-21.967 53.031s7.802 38.866 21.967 53.031c14.622 14.622 33.822 21.933 53.031 21.931zm-31.82-106.781c8.772-8.773 20.295-13.159 31.818-13.159 11.524 0 23.047 4.386 31.819 13.159 8.499 8.499 13.179 19.799 13.179 31.818s-4.68 23.32-13.179 31.819c-17.544 17.545-46.093 17.544-63.638 0-8.499-8.499-13.18-19.799-13.18-31.818s4.682-23.32 13.181-31.819z" />
                    <path d="m15.301 421.938c3.839 0 7.678-1.464 10.606-4.394l48.973-48.973c5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.858-21.213 0l-48.972 48.973c-5.858 5.858-5.858 15.355 0 21.213 2.928 2.929 6.767 4.394 10.606 4.394z" />
                    <path d="m119.761 392.239c-5.857-5.858-15.355-5.858-21.213 0l-94.154 94.155c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.767 4.393 10.606 4.393s7.678-1.464 10.606-4.394l94.154-94.154c5.859-5.858 5.859-15.355.001-21.213z" />
                    <path d="m143.429 437.12-48.973 48.973c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.464 10.606-4.394l48.973-48.973c5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.858-21.212 0z" />
                  </svg>
                  <span>Telesales</span>
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  to="/lich-su-mua-hang"
                  onClick={() => onLeftMenuClick()}
                  className={classNames("nav-link dropdown-toggle arrow-none", {
                    active: locationRoute === "/lich-su-mua-hang",
                  })}
                >
                  <svg
                    id="Capa_1"
                    enableBackground="new 0 0 551.13 551.13"
                    height="512"
                    viewBox="0 0 551.13 551.13"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m275.531 172.228-.05 120.493c0 4.575 1.816 8.948 5.046 12.177l86.198 86.181 24.354-24.354-81.153-81.136.05-113.361z" />
                    <path d="m310.011 34.445c-121.23 0-221.563 90.033-238.367 206.674h-71.644l86.114 86.114 86.114-86.114h-65.78c16.477-97.589 101.355-172.228 203.563-172.228 113.966 0 206.674 92.707 206.674 206.674s-92.707 206.674-206.674 206.674c-64.064 0-123.469-28.996-162.978-79.555l-27.146 21.192c46.084 58.968 115.379 92.808 190.124 92.808 132.955 0 241.119-108.181 241.119-241.119s-108.164-241.119-241.119-241.12z" />
                  </svg>
                  <span>Lịch sử mua hàng</span>
                </Link>
              </li>
              {["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) && (
                <>
                  <li className="nav-item dropdown">
                    <Link
                      to="/thanh-vien-callio"
                      onClick={() => onLeftMenuClick()}
                      className={classNames(
                        "nav-link dropdown-toggle arrow-none",
                        { active: locationRoute === "/thanh-vien-callio" }
                      )}
                    >
                      <svg
                        id="Capa_1"
                        enableBackground="new 0 0 512 512"
                        height="512"
                        viewBox="0 0 512 512"
                        width="512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <circle cx="256" cy="119.631" r="87" />
                          <circle cx="432" cy="151.63" r="55" />
                          <circle cx="80" cy="151.63" r="55" />
                          <path d="m134.19 256.021c-21.65-17.738-41.257-15.39-66.29-15.39-37.44 0-67.9 30.28-67.9 67.49v109.21c0 16.16 13.19 29.3 29.41 29.3 70.026 0 61.59 1.267 61.59-3.02 0-77.386-9.166-134.137 43.19-187.59z" />
                          <path d="m279.81 241.03c-43.724-3.647-81.729.042-114.51 27.1-54.857 43.94-44.3 103.103-44.3 175.48 0 19.149 15.58 35.02 35.02 35.02 211.082 0 219.483 6.809 232-20.91 4.105-9.374 2.98-6.395 2.98-96.07 0-71.226-61.673-120.62-111.19-120.62z" />
                          <path d="m444.1 240.63c-25.17 0-44.669-2.324-66.29 15.39 51.965 53.056 43.19 105.935 43.19 187.59 0 4.314-7.003 3.02 60.54 3.02 16.8 0 30.46-13.61 30.46-30.34v-108.17c0-37.21-30.46-67.49-67.9-67.49z" />
                        </g>
                      </svg>
                      <span>Thành viên Callio</span>
                    </Link>
                  </li>
                </>
              )}
              {["ROOT_ADMIN", "ADMIN", "AGENCY", "SALE"].includes(
                roleIdUser
              ) && (
                <>
                  <li className="nav-item dropdown">
                    <Link
                      to="/thanh-vien"
                      onClick={() => onLeftMenuClick()}
                      className={classNames(
                        "nav-link dropdown-toggle arrow-none",
                        {
                          active:
                            locationRoute === "/thanh-vien" ||
                            locationRoute.startsWith("/thanh-vien/"),
                        }
                      )}
                    >
                      <svg
                        id="Capa_1"
                        enableBackground="new 0 0 512 512"
                        height="512"
                        viewBox="0 0 512 512"
                        width="512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <circle cx="256" cy="119.631" r="87" />
                          <circle cx="432" cy="151.63" r="55" />
                          <circle cx="80" cy="151.63" r="55" />
                          <path d="m134.19 256.021c-21.65-17.738-41.257-15.39-66.29-15.39-37.44 0-67.9 30.28-67.9 67.49v109.21c0 16.16 13.19 29.3 29.41 29.3 70.026 0 61.59 1.267 61.59-3.02 0-77.386-9.166-134.137 43.19-187.59z" />
                          <path d="m279.81 241.03c-43.724-3.647-81.729.042-114.51 27.1-54.857 43.94-44.3 103.103-44.3 175.48 0 19.149 15.58 35.02 35.02 35.02 211.082 0 219.483 6.809 232-20.91 4.105-9.374 2.98-6.395 2.98-96.07 0-71.226-61.673-120.62-111.19-120.62z" />
                          <path d="m444.1 240.63c-25.17 0-44.669-2.324-66.29 15.39 51.965 53.056 43.19 105.935 43.19 187.59 0 4.314-7.003 3.02 60.54 3.02 16.8 0 30.46-13.61 30.46-30.34v-108.17c0-37.21-30.46-67.49-67.9-67.49z" />
                        </g>
                      </svg>
                      <span>Thành viên</span>
                    </Link>
                  </li>
                </>
              )}
              {["ROOT_ADMIN"].includes(roleIdUser) && (
                <li className="nav-item dropdown">
                  <Link
                    to="/website-stop"
                    onClick={() => onLeftMenuClick()}
                    className={classNames(
                      "nav-link dropdown-toggle arrow-none",
                      { active: locationRoute === "/website-stop" }
                    )}
                  >
                    <svg
                      id="Capa_1"
                      enableBackground="new 0 0 512 512"
                      height="512"
                      viewBox="0 0 512 512"
                      width="512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <circle cx="256" cy="119.631" r="87" />
                        <circle cx="432" cy="151.63" r="55" />
                        <circle cx="80" cy="151.63" r="55" />
                        <path d="m134.19 256.021c-21.65-17.738-41.257-15.39-66.29-15.39-37.44 0-67.9 30.28-67.9 67.49v109.21c0 16.16 13.19 29.3 29.41 29.3 70.026 0 61.59 1.267 61.59-3.02 0-77.386-9.166-134.137 43.19-187.59z" />
                        <path d="m279.81 241.03c-43.724-3.647-81.729.042-114.51 27.1-54.857 43.94-44.3 103.103-44.3 175.48 0 19.149 15.58 35.02 35.02 35.02 211.082 0 219.483 6.809 232-20.91 4.105-9.374 2.98-6.395 2.98-96.07 0-71.226-61.673-120.62-111.19-120.62z" />
                        <path d="m444.1 240.63c-25.17 0-44.669-2.324-66.29 15.39 51.965 53.056 43.19 105.935 43.19 187.59 0 4.314-7.003 3.02 60.54 3.02 16.8 0 30.46-13.61 30.46-30.34v-108.17c0-37.21-30.46-67.49-67.9-67.49z" />
                      </g>
                    </svg>
                    <span>Danh sách Web bị giới hạn</span>
                  </Link>
                </li>
              )}
              <li className="nav-item dropdown">
                <Link
                  to="/contact"
                  onClick={() => onLeftMenuClick()}
                  className={classNames("nav-link dropdown-toggle arrow-none", {
                    active: locationRoute === "/contact",
                  })}
                >
                  <svg
                    id="Capa_1"
                    enableBackground="new 0 0 512 512"
                    height="512"
                    viewBox="0 0 512 512"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <circle cx="256" cy="119.631" r="87" />
                      <circle cx="432" cy="151.63" r="55" />
                      <circle cx="80" cy="151.63" r="55" />
                      <path d="m134.19 256.021c-21.65-17.738-41.257-15.39-66.29-15.39-37.44 0-67.9 30.28-67.9 67.49v109.21c0 16.16 13.19 29.3 29.41 29.3 70.026 0 61.59 1.267 61.59-3.02 0-77.386-9.166-134.137 43.19-187.59z" />
                      <path d="m279.81 241.03c-43.724-3.647-81.729.042-114.51 27.1-54.857 43.94-44.3 103.103-44.3 175.48 0 19.149 15.58 35.02 35.02 35.02 211.082 0 219.483 6.809 232-20.91 4.105-9.374 2.98-6.395 2.98-96.07 0-71.226-61.673-120.62-111.19-120.62z" />
                      <path d="m444.1 240.63c-25.17 0-44.669-2.324-66.29 15.39 51.965 53.056 43.19 105.935 43.19 187.59 0 4.314-7.003 3.02 60.54 3.02 16.8 0 30.46-13.61 30.46-30.34v-108.17c0-37.21-30.46-67.49-67.9-67.49z" />
                    </g>
                  </svg>
                  <span>Danh bạ</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/facebook-tracking"
                  onClick={() => onLeftMenuClick()}
                  className={classNames("nav-link dropdown-toggle arrow-none", {
                    active: locationRoute === "/facebook-tracking",
                  })}
                >
                  <i data-feather="message-circle" />
                  <span> Facebook tracking </span>
                </Link>
              </li>
              {/*<li className="menu-title mt-2">Apps</li>

              <li>
                <a href="apps-calendar.html">
                  <i data-feather="calendar"></i>
                  <span> Calendar </span>
                </a>
              </li>

              <li>
                <a href="apps-chat.html">
                  <i data-feather="message-square"></i>
                  <span> Chat </span>
                </a>
              </li>

              <li>
                <a href="#sidebarEcommerce" data-toggle="" aria-expanded="true">
                  <i data-feather="shopping-cart"></i>
                  <span> Ecommerce </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse show" id="sidebarEcommerce">
                  <ul className="nav-second-level">
                    <li>
                      <a href="ecommerce-dashboard.html">Dashboard</a>
                    </li>
                    <li>
                      <a href="ecommerce-products.html">Products</a>
                    </li>
                    <li>
                      <a href="ecommerce-product-detail.html">Product Detail</a>
                    </li>
                    <li>
                      <a href="ecommerce-product-edit.html">Add Product</a>
                    </li>
                    <li>
                      <a href="ecommerce-customers.html">Customers</a>
                    </li>
                    <li>
                      <a href="ecommerce-orders.html">Orders</a>
                    </li>
                    <li>
                      <a href="ecommerce-order-detail.html">Order Detail</a>
                    </li>
                    <li>
                      <a href="ecommerce-sellers.html">Sellers</a>
                    </li>
                    <li>
                      <a href="ecommerce-cart.html">Shopping Cart</a>
                    </li>
                    <li>
                      <a href="ecommerce-checkout.html">Checkout</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="#sidebarCrm" data-toggle="collapse">
                  <i data-feather="users"></i>
                  <span> CRM </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse" id="sidebarCrm">
                  <ul className="nav-second-level">
                    <li>
                      <a href="crm-dashboard.html">Dashboard</a>
                    </li>
                    <li>
                      <a href="crm-contacts.html">Contacts</a>
                    </li>
                    <li>
                      <a href="crm-opportunities.html">Opportunities</a>
                    </li>
                    <li>
                      <a href="crm-leads.html">Leads</a>
                    </li>
                    <li>
                      <a href="crm-customers.html">Customers</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="#sidebarEmail" data-toggle="collapse">
                  <i data-feather="mail"></i>
                  <span> Email </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse" id="sidebarEmail">
                  <ul className="nav-second-level">
                    <li>
                      <a href="email-inbox.html">Inbox</a>
                    </li>
                    <li>
                      <a href="email-read.html">Read Email</a>
                    </li>
                    <li>
                      <a href="email-compose.html">Compose Email</a>
                    </li>
                    <li>
                      <a href="email-templates.html">Email Templates</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="apps-social-feed.html">
                  <span className="badge badge-pink float-right">Hot</span>
                  <i data-feather="rss"></i>
                  <span> Social Feed </span>
                </a>
              </li>

              <li>
                <a href="apps-companies.html">
                  <i data-feather="activity"></i>
                  <span> Companies </span>
                </a>
              </li>

              <li>
                <a href="#sidebarProjects" data-toggle="collapse">
                  <i data-feather="briefcase"></i>
                  <span> Projects </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse" id="sidebarProjects">
                  <ul className="nav-second-level">
                    <li>
                      <a href="project-list.html">List</a>
                    </li>
                    <li>
                      <a href="project-detail.html">Detail</a>
                    </li>
                    <li>
                      <a href="project-create.html">Create Project</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="#sidebarTasks" data-toggle="collapse">
                  <i data-feather="clipboard"></i>
                  <span> Tasks </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse" id="sidebarTasks">
                  <ul className="nav-second-level">
                    <li>
                      <a href="task-list.html">List</a>
                    </li>
                    <li>
                      <a href="task-details.html">Details</a>
                    </li>
                    <li>
                      <a href="task-kanban-board.html">Kanban Board</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="#sidebarContacts" data-toggle="collapse">
                  <i data-feather="book"></i>
                  <span> Contacts </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse" id="sidebarContacts">
                  <ul className="nav-second-level">
                    <li>
                      <a href="contacts-list.html">Members List</a>
                    </li>
                    <li>
                      <a href="contacts-profile.html">Profile</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="#sidebarTickets" data-toggle="collapse">
                  <i data-feather="aperture"></i>
                  <span> Tickets </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse" id="sidebarTickets">
                  <ul className="nav-second-level">
                    <li>
                      <a href="tickets-list.html">List</a>
                    </li>
                    <li>
                      <a href="tickets-detail.html">Detail</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="apps-file-manager.html">
                  <i data-feather="folder-plus"></i>
                  <span> File Manager </span>
                </a>
              </li>

              <li className="menu-title mt-2">Custom</li>

              <li>
                <a href="#sidebarAuth" data-toggle="collapse">
                  <i data-feather="file-text"></i>
                  <span> Auth Pages </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse" id="sidebarAuth">
                  <ul className="nav-second-level">
                    <li>
                      <a href="auth-login.html">Log In</a>
                    </li>
                    <li>
                      <a href="auth-login-2.html">Log In 2</a>
                    </li>
                    <li>
                      <a href="auth-register.html">Register</a>
                    </li>
                    <li>
                      <a href="auth-register-2.html">Register 2</a>
                    </li>
                    <li>
                      <a href="auth-signin-signup.html">Signin - Signup</a>
                    </li>
                    <li>
                      <a href="auth-signin-signup-2.html">Signin - Signup 2</a>
                    </li>
                    <li>
                      <a href="auth-recoverpw.html">Recover Password</a>
                    </li>
                    <li>
                      <a href="auth-recoverpw-2.html">Recover Password 2</a>
                    </li>
                    <li>
                      <a href="auth-lock-screen.html">Lock Screen</a>
                    </li>
                    <li>
                      <a href="auth-lock-screen-2.html">Lock Screen 2</a>
                    </li>
                    <li>
                      <a href="auth-logout.html">Logout</a>
                    </li>
                    <li>
                      <a href="auth-logout-2.html">Logout 2</a>
                    </li>
                    <li>
                      <a href="auth-confirm-mail.html">Confirm Mail</a>
                    </li>
                    <li>
                      <a href="auth-confirm-mail-2.html">Confirm Mail 2</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="#sidebarExpages" data-toggle="collapse">
                  <i data-feather="package"></i>
                  <span> Extra Pages </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse" id="sidebarExpages">
                  <ul className="nav-second-level">
                    <li>
                      <a href="pages-starter.html">Starter</a>
                    </li>
                    <li>
                      <a href="pages-timeline.html">Timeline</a>
                    </li>
                    <li>
                      <a href="pages-sitemap.html">Sitemap</a>
                    </li>
                    <li>
                      <a href="pages-invoice.html">Invoice</a>
                    </li>
                    <li>
                      <a href="pages-faqs.html">FAQs</a>
                    </li>
                    <li>
                      <a href="pages-search-results.html">Search Results</a>
                    </li>
                    <li>
                      <a href="pages-pricing.html">Pricing</a>
                    </li>
                    <li>
                      <a href="pages-maintenance.html">Maintenance</a>
                    </li>
                    <li>
                      <a href="pages-coming-soon.html">Coming Soon</a>
                    </li>
                    <li>
                      <a href="pages-gallery.html">Gallery</a>
                    </li>
                    <li>
                      <a href="pages-404.html">Error 404</a>
                    </li>
                    <li>
                      <a href="pages-404-two.html">Error 404 Two</a>
                    </li>
                    <li>
                      <a href="pages-404-alt.html">Error 404-alt</a>
                    </li>
                    <li>
                      <a href="pages-500.html">Error 500</a>
                    </li>
                    <li>
                      <a href="pages-500-two.html">Error 500 Two</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="#sidebarLayouts" data-toggle="collapse">
                  <i data-feather="layout"></i>
                  <span className="badge badge-blue float-right">New</span>
                  <span> Layouts </span>
                </a>
                <div className="collapse" id="sidebarLayouts">
                  <ul className="nav-second-level">
                    <li>
                      <a href="layouts-vertical.html">Vertical</a>
                    </li>
                    <li>
                      <a href="index.html">Horizontal</a>
                    </li>
                    <li>
                      <a href="layouts-detached.html">Detached</a>
                    </li>
                    <li>
                      <a href="layouts-two-column.html">Two Column Menu</a>
                    </li>
                    <li>
                      <a href="layouts-two-tone-icons.html">Two Tones Icons</a>
                    </li>
                    <li>
                      <a href="layouts-preloader.html">Preloader</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-title mt-2">Components</li>

              <li>
                <a href="#sidebarBaseui" data-toggle="collapse">
                  <i data-feather="pocket"></i>
                  <span> Base UI </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse" id="sidebarBaseui">
                  <ul className="nav-second-level">
                    <li>
                      <a href="ui-buttons.html">Buttons</a>
                    </li>
                    <li>
                      <a href="ui-cards.html">Cards</a>
                    </li>
                    <li>
                      <a href="ui-avatars.html">Avatars</a>
                    </li>
                    <li>
                      <a href="ui-portlets.html">Portlets</a>
                    </li>
                    <li>
                      <a href="ui-tabs-accordions.html">Tabs & Accordions</a>
                    </li>
                    <li>
                      <a href="ui-modals.html">Modals</a>
                    </li>
                    <li>
                      <a href="ui-progress.html">Progress</a>
                    </li>
                    <li>
                      <a href="ui-notifications.html">Notifications</a>
                    </li>
                    <li>
                      <a href="ui-spinners.html">Spinners</a>
                    </li>
                    <li>
                      <a href="ui-images.html">Images</a>
                    </li>
                    <li>
                      <a href="ui-carousel.html">Carousel</a>
                    </li>
                    <li>
                      <a href="ui-list-group.html">List Group</a>
                    </li>
                    <li>
                      <a href="ui-video.html">Embed Video</a>
                    </li>
                    <li>
                      <a href="ui-dropdowns.html">Dropdowns</a>
                    </li>
                    <li>
                      <a href="ui-ribbons.html">Ribbons</a>
                    </li>
                    <li>
                      <a href="ui-tooltips-popovers.html">Tooltips & Popovers</a>
                    </li>
                    <li>
                      <a href="ui-general.html">General UI</a>
                    </li>
                    <li>
                      <a href="ui-typography.html">Typography</a>
                    </li>
                    <li>
                      <a href="ui-grid.html">Grid</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="#sidebarExtendedui" data-toggle="collapse">
                  <i data-feather="layers"></i>
                  <span className="badge badge-info float-right">Hot</span>
                  <span> Extended UI </span>
                </a>
                <div className="collapse" id="sidebarExtendedui">
                  <ul className="nav-second-level">
                    <li>
                      <a href="extended-nestable.html">Nestable List</a>
                    </li>
                    <li>
                      <a href="extended-range-slider.html">Range Slider</a>
                    </li>
                    <li>
                      <a href="extended-dragula.html">Dragula</a>
                    </li>
                    <li>
                      <a href="extended-animation.html">Animation</a>
                    </li>
                    <li>
                      <a href="extended-sweet-alert.html">Sweet Alert</a>
                    </li>
                    <li>
                      <a href="extended-tour.html">Tour Page</a>
                    </li>
                    <li>
                      <a href="extended-scrollspy.html">Scrollspy</a>
                    </li>
                    <li>
                      <a href="extended-loading-buttons.html">Loading Buttons</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="widgets.html">
                  <i data-feather="gift"></i>
                  <span> Widgets </span>
                </a>
              </li>

              <li>
                <a href="#sidebarIcons" data-toggle="collapse">
                  <i data-feather="cpu"></i>
                  <span> Icons </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse" id="sidebarIcons">
                  <ul className="nav-second-level">
                    <li>
                      <a href="icons-two-tone.html">Two Tone Icons</a>
                    </li>
                    <li>
                      <a href="icons-feather.html">Feather Icons</a>
                    </li>
                    <li>
                      <a href="icons-mdi.html">Material Design Icons</a>
                    </li>
                    <li>
                      <a href="icons-dripicons.html">Dripicons</a>
                    </li>
                    <li>
                      <a href="icons-font-awesome.html">Font Awesome 5</a>
                    </li>
                    <li>
                      <a href="icons-themify.html">Themify</a>
                    </li>
                    <li>
                      <a href="icons-simple-line.html">Simple Line</a>
                    </li>
                    <li>
                      <a href="icons-weather.html">Weather</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="#sidebarForms" data-toggle="collapse">
                  <i data-feather="bookmark"></i>
                  <span> Forms </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse" id="sidebarForms">
                  <ul className="nav-second-level">
                    <li>
                      <a href="forms-elements.html">General Elements</a>
                    </li>
                    <li>
                      <a href="forms-advanced.html">Advanced</a>
                    </li>
                    <li>
                      <a href="forms-validation.html">Validation</a>
                    </li>
                    <li>
                      <a href="forms-pickers.html">Pickers</a>
                    </li>
                    <li>
                      <a href="forms-wizard.html">Wizard</a>
                    </li>
                    <li>
                      <a href="forms-masks.html">Masks</a>
                    </li>
                    <li>
                      <a href="forms-summernote.html">Summernote</a>
                    </li>
                    <li>
                      <a href="forms-quilljs.html">Quilljs Editor</a>
                    </li>
                    <li>
                      <a href="forms-file-uploads.html">File Uploads</a>
                    </li>
                    <li>
                      <a href="forms-x-editable.html">X Editable</a>
                    </li>
                    <li>
                      <a href="forms-image-crop.html">Image Crop</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="#sidebarTables" data-toggle="collapse">
                  <i data-feather="grid"></i>
                  <span> Tables </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse" id="sidebarTables">
                  <ul className="nav-second-level">
                    <li>
                      <a href="tables-basic.html">Basic Tables</a>
                    </li>
                    <li>
                      <a href="tables-datatables.html">Data Tables</a>
                    </li>
                    <li>
                      <a href="tables-editable.html">Editable Tables</a>
                    </li>
                    <li>
                      <a href="tables-responsive.html">Responsive Tables</a>
                    </li>
                    <li>
                      <a href="tables-footables.html">FooTable</a>
                    </li>
                    <li>
                      <a href="tables-bootstrap.html">Bootstrap Tables</a>
                    </li>
                    <li>
                      <a href="tables-tablesaw.html">Tablesaw Tables</a>
                    </li>
                    <li>
                      <a href="tables-jsgrid.html">JsGrid Tables</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="#sidebarCharts" data-toggle="collapse">
                  <i data-feather="bar-chart-2"></i>
                  <span> Charts </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse" id="sidebarCharts">
                  <ul className="nav-second-level">
                    <li>
                      <a href="charts-apex.html">Apex Charts</a>
                    </li>
                    <li>
                      <a href="charts-flot.html">Flot Charts</a>
                    </li>
                    <li>
                      <a href="charts-morris.html">Morris Charts</a>
                    </li>
                    <li>
                      <a href="charts-chartjs.html">Chartjs Charts</a>
                    </li>
                    <li>
                      <a href="charts-peity.html">Peity Charts</a>
                    </li>
                    <li>
                      <a href="charts-chartist.html">Chartist Charts</a>
                    </li>
                    <li>
                      <a href="charts-c3.html">C3 Charts</a>
                    </li>
                    <li>
                      <a href="charts-sparklines.html">Sparklines Charts</a>
                    </li>
                    <li>
                      <a href="charts-knob.html">Jquery Knob Charts</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="#sidebarMaps" data-toggle="collapse">
                  <i data-feather="map"></i>
                  <span> Maps </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse" id="sidebarMaps">
                  <ul className="nav-second-level">
                    <li>
                      <a href="maps-google.html">Google Maps</a>
                    </li>
                    <li>
                      <a href="maps-vector.html">Vector Maps</a>
                    </li>
                    <li>
                      <a href="maps-mapael.html">Mapael Maps</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="#sidebarMultilevel" data-toggle="collapse">
                  <i data-feather="share-2"></i>
                  <span> Multi Level </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse" id="sidebarMultilevel">
                  <ul className="nav-second-level">
                    <li>
                      <a href="#sidebarMultilevel2" data-toggle="collapse">
                        Second Level <span className="menu-arrow"></span>
                      </a>
                      <div className="collapse" id="sidebarMultilevel2">
                        <ul className="nav-second-level">
                          <li>
                            <a href="javascript: void(0);">Item 1</a>
                          </li>
                          <li>
                            <a href="javascript: void(0);">Item 2</a>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <a href="#sidebarMultilevel3" data-toggle="collapse">
                        Third Level <span className="menu-arrow"></span>
                      </a>
                      <div className="collapse" id="sidebarMultilevel3">
                        <ul className="nav-second-level">
                          <li>
                            <a href="javascript: void(0);">Item 1</a>
                          </li>
                          <li>
                            <a href="#sidebarMultilevel4" data-toggle="collapse">
                              Item 2 <span className="menu-arrow"></span>
                            </a>
                            <div className="collapse" id="sidebarMultilevel4">
                              <ul className="nav-second-level">
                                <li>
                                  <a href="javascript: void(0);">Item 1</a>
                                </li>
                                <li>
                                  <a href="javascript: void(0);">Item 2</a>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>*/}
            </ul>
          </div>
          {/* End Sidebar */}

          <div className="clearfix"></div>
        </div>
        {/* Sidebar -left */}
      </div>
    </div>
  );
};
