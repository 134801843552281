import React, {useState, useEffect} from "react";
import "./tong-quan.scss";
import {Skeleton, Dropdown, Table, Form} from "antd";
import {Column} from "@ant-design/charts";
import {Link} from "react-router-dom";
import Chart from "react-apexcharts";
import {apiDashboard} from "api/dashboard";
import {
  shortDate,
  numberWithCommas,
  formatHomeNetwork,
} from "utils/utils-format";
import {useSelector} from "react-redux";
import {apiCampaign} from "api/campaign";
import {apiData} from "api/data";
import {showMessage} from "utils/utils-show-message";

import {GetCodeModal} from "components/get-code-modal/get-code-modal";
import {GetStarted} from "components/get-started/get-started";

import IconWebsite from "assets/icons/icon-website.svg";
import IconCart from "assets/icons/icon-cart.svg";
import IconBarChart from "assets/icons/icon-diagram.svg";
import IconEye from "assets/icons/icon-eye.svg";
import IconVerticalDots from "assets/icons/icon-vertical-dots.svg";
import IconRefresh from "assets/icons/icon-refresh.svg";
import IconStop from "assets/icons/icon-stop.svg";
import IconPlay from "assets/icons/icon-play.svg";
import IconTrash from "assets/icons/icon-trash.svg";
import IconCoding from "assets/icons/icon-coding.svg";
import IconRedriect from "assets/icons/icon-redirect.svg";

export const TongQuan = () => {
  const accountState = useSelector((state) => state.account);
  const roleIdUser = useSelector((state) => state.account.role);
  const [isStarted, setIsStarted] = useState(false);

  const [overview, setOverview] = useState({});
  const [dataCampaign, setDataCampaign] = useState([]);
  const [dataData, setDataData] = useState([]);

  const [optionsRevenue, setOptionsRevenue] = useState({});
  const [seriesRevenue, setSeriesRevenue] = useState([0]);
  const [optionsSales, setOptionsSales] = useState({});
  const [seriesSales, setSeriesSales] = useState([]);
  const [chartLoading, setChartLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [scriptCode, setScriptCode] = useState("");
  const [reportDaily, setReportDaily] = useState([]);
  const actionTableDropdown = (value, row, index) => {
    return (
      <div className="account-dropdown">
        {row.trangThai === "INACTIVE" && (
          <div
            key="1"
            className="dropdown-item d-flex align-items-center"
            onClick={() => activeCampaign(row)}
          >
            <img src={IconPlay} alt=""/>
            Mở chiến dịch
          </div>
        )}
        {row.trangThai === "ACTIVE" && (
          <div
            key="2"
            className="dropdown-item d-flex align-items-center"
            onClick={() => disableCampaign(row)}
          >
            <img src={IconStop} alt=""/>
            Dừng chiến dịch
          </div>
        )}
        <div
          key="3"
          className="dropdown-item d-flex align-items-center"
          onClick={() => {
            setScriptCode(row.scriptCode);
            setVisibleModal(true);
          }}
        >
          <img src={IconCoding} alt=""/>
          Lấy mã nhúng
        </div>
      </div>
    );
  };

  const columnsCampaign = [
    {
      title: "Chiến dịch",
      dataIndex: "chienDich",
      key: "chienDich",
      ellipsis: true,
      width: 200,
      className: "width-125-px-in-768-column",
    },
    {
      title: "Ngày tạo",
      dataIndex: "ngayTao",
      key: "ngayTao",
      className: "text-nowrap width-125-px-in-768-column",
    },
    {
      title: "Ngày hết hạn",
      dataIndex: "ngayHetHan",
      key: "ngayHetHan",
      className: "text-nowrap width-125-px-in-768-column",
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      className: "width-125-px-in-768-column",
      render: (value, row, index) => {
        return (
          <div className="d-flex">
            {row.trangThai === "ACTIVE" && (
              <span className="badge mr-1 badge-success">Hoạt động</span>
            )}
            {row.trangThai === "INACTIVE" && (
              <span className="badge mr-1 bg-soft-danger text-danger">
                Tạm dừng
              </span>
            )}
            {/* {row.stateUnLimit === "pending" && (
              <span className="badge mr-1 badge-warning">Chờ duyệt</span>
            )}
            {row.stateUnLimit === "approve" && (
              <span className="badge mr-1 badge-primary">Đã duyệt</span>
            )} */}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 60,
      render: (value, row, index) => (
        <Dropdown
          overlay={actionTableDropdown(value, row, index)}
          trigger={["click"]}
          placement="bottomRight"
          className="table-action-btn dropdown-toggle arrow-none btn btn-light btn-sm"
        >
          <div className="table-action-icon">
            <img width="13" src={IconVerticalDots} alt=""/>
          </div>
        </Dropdown>
      ),
    },
  ];

  const columnsData = [
    {
      title: "#",
      dataIndex: "stt",
      key: "stt",
      render: (value, row, index) => index + 1,
      width: 30,
      className: "text-nowrap",
    },
    {
      title: "UID",
      dataIndex: "uid",
      key: "uid",
      className: "text-nowrap width-125-px-in-768-column",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 50,
      className: "text-nowrap",
    },
    {
      title: "Chiến dịch",
      dataIndex: "chienDich",
      key: "chienDich",
      ellipsis: true,
      width: 180,
    },
    {
      title: "Ngày truy cập",
      dataIndex: "ngayTruyCap",
      key: "ngayTruyCap",
      className: "text-nowrap width-125-px-in-768-column",
    },
    {
      title: "Giờ phút",
      dataIndex: "gioPhut",
      key: "gioPhut",
      className: "text-nowrap width-125-px-in-768-column",
    },
  ];

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = () => {
    getOverviewData();
    getAllCampaign();
    getAllData();
    if (["ROOT_ADMIN", "ADMIN"].includes(roleIdUser)) getReportDailyData();
  };

  useEffect(() => {
    updateRevenueChart();
    updateSalesChart();
  }, [overview]);

  const getOverviewData = async () => {
    setChartLoading(true);
    const res = await apiDashboard.getAll();
    setChartLoading(false);
    if (res?.status?.code === "SUCCESS") {
      setOverview(res.data);
    }
  };
  const getReportDailyData = async () => {
    setChartLoading(true);
    let date = new Date();
    let firstDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 15
    );
    let lastDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 1
    );
    const res = await apiDashboard.reportDaily({
      startTime: firstDay.toLocaleDateString(),
      endTime: lastDay.toLocaleDateString(),
      pageSize: 30,
    });
    setChartLoading(false);
    if (res?.status?.code === "SUCCESS") {
      setReportDaily(res.data);
    }
  };

  const getAllCampaign = async () => {
    setTableLoading(true);
    const res = await apiCampaign.getAll({
      pageIndex: 1,
      pageSize: 5,
    });
    setTableLoading(false);
    if (res?.status?.code === "SUCCESS") {
      const data = [];
      res.data.forEach((item, index) => {
        data.push({
          chienDichId: item.id,
          chienDich: item.url,
          sanLuong: `${item.usedData} / ${item.totalData}`,
          trangThai: item.state,
          stateUnLimit: item.stateUnLimit,
          ngayTao: item.createdDate?.slice(0, 10),
          ngayHetHan: item.expireDate?.slice(0, 10),
          scriptCode: item.scriptCode,
        });
      });
      setDataCampaign(data);
      if (dataCampaign.length === 0) setIsStarted(true);
    }
  };

  const activeCampaign = async (target) => {
    const res = await apiCampaign.activeStatus(target.chienDichId);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Kích hoạt chiến dịch thành công");
      getAllCampaign();
    }
  };

  const disableCampaign = async (target) => {
    const res = await apiCampaign.inactiveStatus(target.chienDichId);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Dừng chiến dịch thành công");
      getAllCampaign();
    }
  };

  const closeModal = () => {
    setVisibleModal(false);
    setScriptCode("");
  };

  const getAllData = async () => {
    setTableDataLoading(true);
    const res = await apiData.getAll({
      pageIndex: 1,
      pageSize: 5,
    });
    setTableDataLoading(false);
    if (res?.status?.code === "SUCCESS") {
      const data = [];
      res.data.forEach((item, index) => {
        data.push({
          uid: item.phone,
          type: formatHomeNetwork(item.type),
          chienDich: item.url,
          ngayTruyCap: item.visitTime?.slice(0, 10),
          gioPhut: item.visitTime?.slice(11),
        });
      });
      setDataData(data);
    }
  };

  const updateRevenueChart = () => {
    const usedData = accountState.account?.usedData;
    const totalData = accountState.account?.totalOfData;
    if (typeof usedData === "number") {
      configRevenueChart(((usedData / totalData) * 100).toFixed(2));
    }
  };

  const configRevenueChart = (revenueData) => {
    const series = [revenueData];
    const options = {
      chart: {
        height: 220,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "65%",
          },
        },
      },
      labels: ["Đã hoàn thành"],
      colors: ["#f1556c"],
    };
    setSeriesRevenue(series);
    setOptionsRevenue(options);
  };

  const configReportDaily = {
    data: reportDaily,
    xField: "time",
    yField: "total",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    meta: {
      time: {alias: "Ngày"},
      total: {alias: "Tổng sản lượng"},
    },
  };
  const updateSalesChart = () => {
    const staticsOrder = overview.staticsOrder;
    const staticsUser = overview.staticsUser;
    const labels = [];
    const series = [];
    // Get Label
    if (staticsUser?.length) {
      staticsUser.map((item, index) =>
        labels.push(shortDate(item.createdDate))
      );
    }
    // Get Statics Order
    if (staticsOrder?.length) {
      const data = {
        name: "Doanh thu",
        type: "column",
        data: [],
      };
      staticsOrder.map((item, index) => data.data.push(item.totalProfit));
      series.push(data);
    }
    // Get Statics User
    if (staticsUser?.length) {
      const data = {
        name: "Khách hàng",
        type: "line",
        data: [],
      };
      staticsUser.map((item, index) => data.data.push(item.total));
      series.push(data);
    }
    if (labels.length !== 0 && series.length !== 0) {
      configSalesChart(series, labels);
    }
  };

  const configSalesChart = (seriesData, labels) => {
    const series = seriesData;
    const options = {
      chart: {
        height: 220,
      },
      stroke: {
        width: [2, 3],
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      labels: labels,
      colors: ["#4a81d4", "#1abc9c"],
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      xaxis: {
        type: "datetime",
      },
      legend: {
        offsetY: 7,
      },
      grid: {
        padding: {
          bottom: 20,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.75,
          opacityTo: 0.75,
          stops: [0, 0, 0],
        },
      },
      yaxis: [
        {
          title: {
            text: "Doanh thu (VNĐ)",
          },
          labels: {
            formatter: function (val) {
              return numberWithCommas(val);
            },
          },
        },
        {
          opposite: true,
          title: {
            text: "Khách hàng",
          },
        },
      ],
    };
    setSeriesSales(series);
    setOptionsSales(options);
  };

  return (
    <div className="tong-quan-page" style={{position: "relative"}}>
      <GetCodeModal
        visible={visibleModal}
        scriptCode={scriptCode}
        onCancel={() => closeModal()}
      />
      {isStarted === 0 ? (
        <GetStarted userId={accountState?.account?.id}/>
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <div className="page-title-right">
                  <Form className="form-inline">
                    <button
                      className="btn btn-secondary btn-sm ml-2"
                      onClick={() => getDashboardData()}
                    >
                      <img src={IconRefresh} alt="" width="11"/>
                    </button>
                  </Form>
                </div>
                <h4 className="page-title">Tổng quan</h4>
              </div>
            </div>
          </div>

          <div className="row tong-quan-items">
            <div className="col-md-6 col-xl-3">
              <div className="widget-rounded-circle card-box">
                <div className="row">
                  <div className="col-6">
                    <div
                      className="avatar-lg rounded-circle bg-soft-primary border-primary border align-items-center d-flex justify-content-center">
                      <img src={IconWebsite} alt="" width="22"/>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="text-right">
                      <h3 className="mt-1">
                              <span data-plugin="counterup">
                                {overview.countWebsite}
                              </span>
                      </h3>
                      <p className="text-muted mb-1 text-truncate">
                        Website
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <div className="widget-rounded-circle card-box">
                <div className="row">
                  <div className="col-6">
                    <div
                      className="avatar-lg rounded-circle bg-soft-warning border-warning border align-items-center d-flex justify-content-center">
                      <img src={IconEye} alt="" width="22"/>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="text-right">
                      <h3 className="text-dark mt-1">
                              <span data-plugin="counterup">
                                {overview.countUser}
                              </span>
                      </h3>
                      <p className="text-muted mb-1 text-truncate">
                        Khách hàng
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <div className="widget-rounded-circle card-box">
                <div className="row">
                  <div className="col-6">
                    <div
                      className="avatar-lg rounded-circle bg-soft-info border-info border align-items-center d-flex justify-content-center">
                      <img src={IconBarChart} alt="" width="22"/>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="text-right">
                      <h3 className="text-dark mt-1">
                              <span data-plugin="counterup">
                                {overview.countData}
                              </span>
                      </h3>
                      <p className="text-muted mb-1 text-truncate">
                        Data
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <div className="widget-rounded-circle card-box">
                <div className="row">
                  <div className="col-6">
                    <div
                      className="avatar-lg rounded-circle bg-soft-success border-success border align-items-center d-flex justify-content-center">
                      <img src={IconCart} alt="" width="22"/>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="text-right">
                      <h3 className="text-dark mt-1">
                              <span data-plugin="counterup">
                                {overview.countOrder}
                              </span>
                      </h3>
                      <p className="text-muted mb-1 text-truncate">
                        Đơn hàng
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className={`mb-3 mt-3 ${
                ["ROOT_ADMIN", "ADMIN"].includes(roleIdUser)
                  ? "col-lg-4"
                  : "col-12"
              }`}
            >
              <div className="card-box">
                <h4 className="header-title mb-0">Tình trạng đơn</h4>
                {chartLoading ? (
                  <Skeleton
                    className="mt-3"
                    paragraph={{rows: 8}}
                    active
                  />
                ) : (
                  <div className="widget-chart text-center" dir="ltr">
                    <Chart
                      id="total-revenue"
                      options={optionsRevenue}
                      series={seriesRevenue}
                      type="radialBar"
                    />

                    <h5 className="text-muted mt-0">
                      Tổng Data sau lọc trùng
                    </h5>
                    <h2>
                      {accountState.account?.usedData} /{" "}
                      {accountState.account?.totalOfData}
                    </h2>

                    <p className="text-muted w-75 mx-auto">
                      Tài khoản của bạn có hiệu lực từ ngày{" "}
                      <strong className="text-nowrap">
                        {accountState.account?.registerDate
                          ? accountState.account.registerDate.slice(0, 10)
                          : "DD/MM/YYYY"}
                      </strong>{" "}
                      tới ngày{" "}
                      <strong className="text-nowrap">
                        {accountState.account?.expiredDate
                          ? accountState.account.expiredDate
                          : "DD/MM/YYYY"}
                      </strong>
                      . Sau thời gian này tài khoản sẽ tự động đóng lại,
                      trong trường hợp vẫn còn số dư Data trong tài khoản
                      vui lòng gia hạn đơn mới để sử dụng tiếp, trân trọng
                      !
                    </p>
                  </div>
                )}
              </div>
            </div>

            {["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) && (
              <div className="col-lg-8 mb-3 mt-3">
                <div className="card-box pb-2">
                  <div className="float-right d-none d-md-inline-block">
                    <div className="btn-group mb-2">
                      <button
                        type="button"
                        className="btn btn-xs btn-secondary"
                      >
                        Ngày
                      </button>
                    </div>
                  </div>

                  <h4 className="header-title mb-3">Thống kê</h4>

                  {chartLoading ? (
                    <Skeleton paragraph={{rows: 8}} active/>
                  ) : (
                    <div dir="ltr">
                      <Chart
                        id="sales-analytics"
                        options={optionsSales}
                        series={seriesSales}
                        type="line"
                      />
                    </div>
                  )}
                </div>
                <div className="row">
                  <div
                    className={`mb-3 mt-3 ${
                      ["ROOT_ADMIN", "ADMIN", "AGENCY"].includes(roleIdUser)
                        ? "col-lg-4"
                        : "col-12"
                    }`}
                  >
                    <div className="card-box">
                      <h4 className="header-title mb-0">Tình trạng đơn</h4>
                      {chartLoading ? (
                        <Skeleton
                          className="mt-3"
                          paragraph={{ rows: 8 }}
                          active
                        />
                      ) : (
                        <div className="widget-chart text-center" dir="ltr">
                          <Chart
                            id="total-revenue"
                            options={optionsRevenue}
                            series={seriesRevenue}
                            type="radialBar"
                          />

                          <h5 className="text-muted mt-0">
                            Tổng Data sau lọc trùng
                          </h5>
                          <h2>
                            {accountState.account?.usedData} /{" "}
                            {accountState.account?.totalOfData}
                          </h2>

                          <p className="text-muted w-75 mx-auto">
                            Tài khoản của bạn có hiệu lực từ ngày{" "}
                            <strong className="text-nowrap">
                              {accountState.account?.registerDate
                                ? accountState.account.registerDate.slice(0, 10)
                                : "DD/MM/YYYY"}
                            </strong>{" "}
                            tới ngày{" "}
                            <strong className="text-nowrap">
                              {accountState.account?.expiredDate
                                ? accountState.account.expiredDate
                                : "DD/MM/YYYY"}
                            </strong>
                            . Sau thời gian này tài khoản sẽ tự động đóng lại,
                            trong trường hợp vẫn còn số dư Data trong tài khoản
                            vui lòng gia hạn đơn mới để sử dụng tiếp, trân trọng
                            !
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  {["ROOT_ADMIN", "ADMIN", "AGENCY"].includes(roleIdUser) && (
                    <div className="col-lg-8 mb-3 mt-3">
                      <div className="card-box pb-2">
                        <div className="float-right d-none d-md-inline-block">
                          <div className="btn-group mb-2">
                            <button
                              type="button"
                              className="btn btn-xs btn-secondary"
                            >
                              Ngày
                            </button>
                          </div>
                        </div>

                        <h4 className="header-title mb-3">Thống kê</h4>

                        {chartLoading ? (
                          <Skeleton paragraph={{ rows: 8 }} active />
                        ) : (
                          <div dir="ltr">
                            <Chart
                              id="sales-analytics"
                              options={optionsSales}
                              series={seriesSales}
                              type="line"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="row">
            {["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) && (
              <div className="col-lg-12 mb-3 mt-3">
                <div className="card-box pb-2">
                  <div className="float-right d-none d-md-inline-block">
                    <div className="btn-group mb-2"></div>
                  </div>

                  <h4 className="header-title mb-3">
                    Thống kê sản lượng theo ngày
                  </h4>

                  {chartLoading ? (
                    <Skeleton paragraph={{rows: 8}} active/>
                  ) : (
                    <div dir="ltr">
                      <Column {...configReportDaily} />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="row tong-quan-tables">
            <div className="col-xl-6">
              <div className="card-box">
                <Link
                  to="/khach-hang"
                  className="btn btn-light btn-sm float-right"
                >
                  <img
                    width="10"
                    src={IconRedriect}
                    alt=""
                    style={{transform: "translate(-1px, -1px)"}}
                  />
                </Link>

                <h4 className="header-title mb-3 mt-1">
                  Truy cập gần đây
                </h4>

                <div className="table-responsive">
                  <Table
                    loading={tableDataLoading}
                    className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                    dataSource={dataData}
                    columns={columnsData}
                    pagination={false}
                  />
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="card-box">
                <Link
                  to="/chien-dich"
                  className="btn btn-light btn-sm float-right"
                >
                  <img
                    width="10"
                    src={IconRedriect}
                    alt=""
                    style={{transform: "translate(-1px, -1px)"}}
                  />
                </Link>

                <h4 className="header-title mb-3 mt-1">
                  Tình trạng chiến dịch
                </h4>

                <div className="table-responsive">
                  <Table
                    loading={tableLoading}
                    className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                    dataSource={dataCampaign}
                    columns={columnsCampaign}
                    pagination={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
