import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  DatePicker,
  Form,
  Pagination,
  Progress,
  Select,
  Table,
  Tooltip,
} from "antd";
import "./khach-hang-detail.scss";
import { apiData } from "api/data";
import {
  formatDomain,
  formatHomeNetwork,
  secondsFormatString,
} from "utils/utils-format";
import { showMessage } from "../../utils/utils-show-message";
import { LichSuTruyCapPopover } from "components/lich-su-truy-cap-popover/lich-su-truy-cap-popover";
import { STATUS_ENDED, UtilsPhoneNet } from "../../utils/utils-phonenet";
import ShowMoreText from "react-show-more-text";
import { apiTelesales } from "../../api/telesales";
import { CALL_OUT } from "../../components/callio-call-history/callio-call-history";
import IconLeftArrow from "../../assets/icons/icon-left-arrow.svg";
import IconRefresh from "../../assets/icons/icon-refresh.svg";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const KhachHangDetail = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [dataCall, setDataCall] = useState([]);

  const [searchMethod, setSearchMethod] = useState("uid");
  const [isFilter, setIsFilter] = useState(false);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const [suggestionPhone, setSuggestionPhone] = useState([]);
  const [suggestionDomain, setSuggestionDomain] = useState([]);
  const [suggestionAccount, setSuggestionAccount] = useState([]);
  const [suggestionData, setSuggestionData] = useState([]);

  const [visibleExportModal, setvisibleExportModal] = useState(false);
  const [phonenet, setPhonenet] = useState(false);
  const [visibleCallModal, setVisibleCallModal] = useState(false);
  const [statusCall, setStatusCall] = useState(null);
  const [time, setTime] = useState("00:00");
  const [intervalCount, setIntervalCount] = useState(null);
  const role = useSelector((state) => state.account.role);
  const stateCallio = useSelector(
    (state) => state.account.account?.stateCallio
  );
  const passExt = useSelector((state) => state.account.account?.passExt);
  const extCallio = useSelector((state) => state.account.account?.extCallio);
  const id = useParams().id;

  const DEFAULT_COLUMNS = [
    {
      title: "Gọi từ",
      dataIndex: "fromNumber",
      key: "fromNumber",
      render: (value, row) => (
        <>
          <div>{row.fromNumber}</div>
          <div>
            <span>Số nhánh:</span>&nbsp;<strong>{row.fromExt}</strong>
          </div>
          <div>
            <span>Email:</span>&nbsp;<strong>{row.fromUser?.email}</strong>
          </div>
        </>
      ),
    },
    {
      title: "Gọi tới",
      dataIndex: "toNumber",
      key: "toNumber",
      className: "text-nowrap",
    },
    {
      title: "Thời gian gọi",
      dataIndex: "startTime",
      key: "startTime",
      className: "text-nowrap",
      render: (value, row) => (
        <>
          <div>
            <span>Bắt đầu lúc:</span>&nbsp;
            <strong>
              {moment(row.startTime).format("DD/MM/YYYY HH:mm:ss")}
            </strong>
          </div>
          <div>
            <span>Kết thúc lúc:</span>&nbsp;
            <strong>{moment(row.endTime).format("DD/MM/YYYY HH:mm:ss")}</strong>
          </div>
        </>
      ),
    },
    {
      title: "Kết quả cuộc gọi",
      dataIndex: "duration",
      key: "duration",
      className: "text-nowrap",
      render: (value, row) => (
        <>
          <div>
            <span>Thời lượng:</span>&nbsp;
            <strong className={"text-success"}>
              {secondsFormatString(row.duration)}
            </strong>
            &nbsp;|&nbsp;
            <span>Thời gian đàm thoại:&nbsp;</span>
            <strong className={"text-danger"}>
              {secondsFormatString(row.billDuration)}
            </strong>
          </div>
          {row.audioQuality > 0 && (
            <>
              <div>
                <audio id={`audio-id-${row.id}`} controls>
                  <source src={row.url} type="audio/ogg" />
                  <source src={row.url} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
              <div>
                <span>Chất lượng cuộc gọi:</span>&nbsp;
                <Progress
                  percent={row.audioQuality}
                  style={{ width: 150 }}
                  size={"small"}
                  format={(percent) => <>({percent}%)</>}
                />
              </div>
            </>
          )}
          {row.hangupCause === "USER_BUSY" && <div>Máy bận</div>}
          {["ORIGINATOR_CANCEL", "NO_USER_RESPONSE"].includes(
            row.hangupCause
          ) && <div>Người gọi gác máy</div>}
          {row.hangupCause === "NORMAL_CLEARING" && (
            <div>
              <span>Gọi thành công</span>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Voice AI",
      dataIndex: "transcripts",
      key: "transcripts",
      width: 450,
      render: (value, row) => (
        <>
          {row.hangupCause === "NORMAL_CLEARING" &&
            row.transcripts?.length > 0 && (
              <div>
                <ShowMoreText
                  className="content-css"
                  lines={3}
                  more={"Xem thêm"}
                  less={"Thu gọn"}
                  expanded={false}
                  width={400}
                >
                  <span>Nội dung:&nbsp;</span>
                  {row.transcripts.join(". ")}
                </ShowMoreText>
              </div>
            )}
          {row.hangupCause === "NORMAL_CLEARING" &&
            row.transcripts?.length === 0 && (
              <>
                <span>Thiết bị không hỗ trợ AI</span>
              </>
            )}
          {["USER_BUSY", "ORIGINATOR_CANCEL", "NO_USER_RESPONSE"].includes(
            row.hangupCause
          ) && (
            <>
              <span>Kết nối thất bại</span>
            </>
          )}
        </>
      ),
    },
  ];

  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  useEffect(() => {
    if (!role) {
      return;
    }
    if (["ROOT_ADMIN", "ADMIN"].includes(role)) {
      return;
    }

    if (columns.filter((column) => column.key === "fromNumber")?.length > 0) {
      columns.splice(0, 1);
      setColumns(columns);
    }
  }, [role]);

  const getCallioHistoryCall = async () => {
    setLoading(true);
    const res = await apiTelesales.historyCallio({
      page: pageIndex,
      pageSize,
      direction: CALL_OUT,
      customerId: id,
    });
    if (res?.status?.code === "SUCCESS") {
      for (const record of res.data) {
        if (record.hangupCause === "NORMAL_CLEARING") {
          await getUrlRecording(record);
        }
      }
      setDataCall(res.data);
      setTotalPage(res.totalItem);
    } else {
      showMessage("error", res.message);
    }
    setLoading(false);
  };

  const getUrlRecording = async (record) => {
    const res = await apiTelesales.recordingCallio({ id: record.id });
    if (res?.status?.code === "SUCCESS") {
      record.url = res.data.url;
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  const getData = async () => {
    setLoading(true);
    const res = await apiData.getOne(id, false);
    setLoading(false);
    if (res.status.code === "SUCCESS") {
      const data = res.data;
      data.uid = data.phone;
      data.dataId = data.id;
      data.type = formatHomeNetwork(data.type);
      data.ngayTruyCap = data.visitTime?.slice(0, 10);
      data.gioTruyCap = data.visitTime?.slice(11);
      setData(data);
      getCallioHistoryCall();
    } else {
      showMessage("error", "Khách hàng không tồn tại");
      setTimeout(() => (window.location.href = "/khach-hang"), 2000);
    }
  };

  useEffect(() => {
    if (stateCallio === "active") {
      return;
    }
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        const phonenet = new UtilsPhoneNet(`${extCallio}`, passExt);
        phonenet.login(
          (e) => {
            setPhonenet(phonenet);
            // showMessage('success', 'Kết nối tổng đài thành công')
          },
          (e) => console.log(e)
        );
      })
      .catch(() =>
        showMessage(
          "error",
          "Máy tính không có mic hoặc không được cấp quyền dùng mic!"
        )
      );
  }, []);

  useEffect(() => {
    if (statusCall === STATUS_ENDED) {
      clearInterval(intervalCount);
      // setStatusCall(null);
    }
  }, [intervalCount, statusCall]);

  useEffect(() => {
    if (!data.id) {
      return;
    }
    getCallioHistoryCall();
  }, [pageIndex, pageSize]);

  return (
    <div className="khach-hang-detail-page position-relative min-height-100vh">
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <Form className="form-inline">
                  <Tooltip placement="top" title={"Quay lại"}>
                    <Link to={"/khach-hang"}>
                      <button className="btn btn-secondary btn-sm ml-2">
                        <img src={IconLeftArrow} alt="" width="11" />
                      </button>
                    </Link>
                  </Tooltip>
                </Form>
              </div>
              <h4 className="page-title">Chi tiết khách hàng</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card-box">
              <h4 className="header-title d-flex align-items-center flex-wrap justify-content-between">
                <span className="mb-2">Thông tin khách hàng</span>
              </h4>
              <div className="row">
                <div className="col-4">
                  <div className="row">
                    <div className="col-12">
                      <label>Số điện thoại: {data?.uid}</label>
                    </div>
                    <div className="col-12">
                      <label>Type: {data?.type}</label>
                    </div>
                    <div className="col-12">
                      <label>Location: {data?.location}</label>
                    </div>
                    <div className="col-12">
                      <label>Khách hàng: </label>&nbsp;
                      <a
                        // className="table-link"
                        href={`https://www.facebook.com/${data?.visitorFb}`}
                        target="_blank"
                      >
                        {data?.visitorName}
                      </a>
                    </div>
                    <div className="col-12">
                      <label>Link chi tiết:</label>&nbsp;
                      <a href={data?.url} target={"_blank"}>
                        {data?.url}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="row">
                    <div className="col-12">
                      <label>Ngày truy cập: {data?.ngayTruyCap}</label>
                    </div>
                    <div className="col-12">
                      <label>Giờ truy cập: {data?.gioTruyCap}</label>
                    </div>
                    <div className="col-12">
                      <label>IP: {data?.ip}</label>
                    </div>
                    <div className="col-12">
                      <label>
                        Lượt truy cập: <LichSuTruyCapPopover data={data} />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-box">
              <div className="page-title-right"></div>
              <h4 className="header-title d-flex align-items-center flex-wrap justify-content-between">
                <span className="mb-2">Lịch sử cuộc gọi</span>
                <Tooltip placement="top" title={"Làm mới"}>
                  <button
                    className="btn btn-secondary btn-sm ml-2 mb-2"
                    onClick={() => {
                      setPageIndex(1);
                      getCallioHistoryCall();
                    }}
                  >
                    <img src={IconRefresh} alt="" width="11" />
                  </button>
                </Tooltip>
              </h4>
              <Table
                loading={loading}
                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                dataSource={dataCall}
                columns={columns}
                pagination={false}
              />

              {dataCall.length !== 0 && (
                <div className="table-panigate d-flex align-items-center justify-content-between mt-3">
                  <div className="dataTables_info">
                    Trang {pageIndex} trong tổng số{" "}
                    {Math.ceil(totalPage / pageSize)} trang
                  </div>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="tool-item select d-flex align-items-center pagesize-select">
                      Hiển thị
                      <Select
                        value={pageSize}
                        onSelect={(value) => setPageSize(value)}
                      >
                        <Option value="10">10</Option>
                        <Option value="30">30</Option>
                        <Option value="50">50</Option>
                        <Option value="70">70</Option>
                        <Option value="100">100</Option>
                      </Select>
                      dữ liệu
                    </div>
                    <div className="table-pagination">
                      <Pagination
                        current={pageIndex}
                        pageSize={pageSize}
                        total={totalPage}
                        showSizeChanger={false}
                        onChange={(page) => setPageIndex(page)}
                        hideOnSinglePage={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
