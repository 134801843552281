import React from "react";
import "./guest-layout.scss";
import BgWelcome from "assets/images/bg-welcome.svg";

import Logo from "assets/images/logo.png";
import CheckMark from "assets/icons/icon-check-mark.svg";
import { Link } from "react-router-dom";

export const GuestLayout = ({ children }) => {
  return (
    <div className="guest-layout authentication-bg-pattern d-flex align-items-center justify-content-center">
      <header className="page-header d-flex align-items-center justify-content-between bg-white">
        <div className="header-item d-flex align-items-center">
          <div className="page-logo mr-2">
            <img src={Logo} alt="" />
          </div>
          Telegamez.org
        </div>
        <div className="header-item d-flex align-items-center">
          <Link
            to="/dang-nhap"
            className="btn btn-outline-primary waves-effect waves-light"
          >
            Đăng nhập
          </Link>
        </div>
      </header>

      <div className="welcome-item"></div>

      <div className="welcome-item">{children}</div>
    </div>
  );
};
