import { rest } from "./rest";

export const apiCampaign = {
  getAll: (pagination) => rest.get("/websites", pagination, null, true),
  create: (url, userId) =>
    rest.post("/website/register", { url, userId, isLimit: false }, null, true),
  createNoLimit: (url, userId) =>
    rest.post(
      "/website/register/new",
      { url, userId, isLimit: true },
      null,
      true
    ),
  activeStatus: (id) => rest.patch(`/website/${id}/active`, {}, null, true),
  getWebsiteStop: (pagination) =>
    rest.get("/website/stop", pagination, null, true),
  inactiveStatus: (id) => rest.delete(`/website/${id}`, {}, null, true),
  approveCampaign: (url, expiredDate, price) =>
    rest.put("/website/approve/new", { url, expiredDate, price }, null, true),
  backToCampaignLimit: (url, expiredDate, price) =>
    rest.put("/website/approve/limit", { url, expiredDate, price }, null, true),
  upgradeCampaign: (url) =>
    rest.put(`/website/up/unLimit?url=${url}`, {}, null, true),
  unLimitRollback: (url) =>
    rest.put(`/website/up/unLimitRollback?url=${url}`, {}, null, true),
  deleteAdminCampaign: (id) =>
    rest.delete(`/website/admin/${id}`, {}, null, true),
  deleteCampaign: (id) => rest.delete(`/website/user/${id}`, {}, null, true),

  // start apis keyword campaign
  getAllKeywordCampaign: (pagination) =>
    rest.get("/keywordCampaigns", pagination, null, true),
  getKeywordCampaign: (id) =>
    rest.get(`/keywordCampaigns/${id}`, {}, null, true),
  createKeywordCampaign: (userName, campaignName, keywords) =>
    rest.post(
      "/keywordCampaigns",
      { userName, campaignName, keywords },
      null,
      true
    ),
  updateKeywordCampaign: (id, campaignName, keywords) =>
    rest.put(`/keywordCampaigns/${id}`, { campaignName, keywords }, null, true),
  approveKeywordCampaign: (id, expiredDate, price, totalData) =>
    rest.put(
      `/keywordCampaigns/${id}/approve`,
      { expiredDate, price, totalData },
      null,
      true
    ),
  approveKeywords: (id) =>
    rest.put(`/keywordCampaigns/${id}/approveKeywords`, {}, null, true),
  rejectKeywordCampaign: (id) =>
    rest.put(`/keywordCampaigns/${id}/reject`, {}, null, true),
  deleteKeywordCampaign: (id) =>
    rest.delete(`/keywordCampaigns/${id}`, {}, null, true),
  exportDataKeyword: (id, from, to) =>
    rest.post(`/keywordCampaigns/${id}/exportData`, { from, to }, null, true),
  addDataId: (id, dataUrl) =>
    rest.put(`/keywordCampaigns/${id}/addDataId`, { dataUrl }, null, true),
};
