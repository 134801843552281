import React from "react";
import { Modal, Button } from "antd";
import "./get-code-modal.scss";

import IconCopy from "assets/icons/icon-copy.svg";
import { showMessage } from "utils/utils-show-message";

export const GetCodeModal = ({ visible, onCancel, scriptCode, widgetCode }) => {
  const copyScriptCode = () => {
    const el = document.createElement("textarea");
    el.value = scriptCode;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    showMessage("success", "Copy mã nhúng thành công");
    onCancel();
  };
  const copyWidgetCode = () => {
    const el = document.createElement("textarea");
    el.value = widgetCode;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    showMessage("success", "Copy mã nhúng thành công");
    onCancel();
  };
  return (
    <Modal
      title="Lấy mã nhúng"
      footer={null}
      visible={visible}
      wrapClassName="get-code-modal"
      onCancel={() => {
        onCancel();
      }}
    >
      <div className="get-code-wrap position-relative">
        <Button
          className="btn btn-success btn-block d-flex align-items-center copy-btn"
          onClick={() => copyScriptCode()}
        >
          <img src={IconCopy} alt="" />
          Copy
        </Button>
        <div className="form-control script-code">{scriptCode}</div>
      </div>
      <div className="plugins-wordpress">
        Nếu bạn đang sử dụng plugin{" "}
        <a href="https://wordpress.org/plugins/grab-call-code/" target="_blank">
          {" "}
          <b>`Grab Call Code`</b>{" "}
        </a>{" "}
        trên Wordpress vui lòng copy code bên dưới :
      </div>
      <div className="get-code-wrap position-relative">
        <Button
          className="btn btn-success btn-block d-flex align-items-center copy-btn"
          onClick={() => copyWidgetCode()}
        >
          <img src={IconCopy} alt="" />
          Copy
        </Button>
        <div className="form-control script-code">{widgetCode}</div>
      </div>
    </Modal>
  );
};
