import React, { useState } from "react";
import "./get-started.scss";
import { Button, Input, Form, Select, Steps } from "antd";
import { useHistory } from "react-router-dom";

import { isHttpsWww, isWebUrl } from "utils/utils-validation";
import { showMessage } from "utils/utils-show-message";
import { apiCampaign } from "api/campaign";
import ImageModal from "assets/images/laptop-ui-image.png";
import IconCopy from "assets/icons/icon-copy.svg";

const { Option } = Select;
const { Step } = Steps;
export const GetStarted = ({ userId }) => {
  const [url, setUrl] = useState({ value: "", error: "", help: "" });
  const [method, setMethod] = useState({ value: "https", error: "", help: "" });
  const [btnLoading, setBtnLoading] = useState(false);
  const [stepState, setStepState] = useState(0);
  const [scriptCode, setScriptCode] = useState("");
  const history = useHistory();

  const validateUrlWebsite = (target) => {
    // if (!isWebUrl(target.value)) {
    //   setUrl({
    //     ...target,
    //     error: "error",
    //     help: "Địa chỉ Website không hợp lệ",
    //   });
    //   return false;
    // }
    if (isHttpsWww(target.value)) {
      setUrl({
        ...target,
        error: "error",
        help: "Địa chỉ Website không bao gồm https, http và www",
      });
      return false;
    }
    setUrl({ value: target.value, error: "", help: "" });
    return true;
  };

  const copyScriptCode = () => {
    const el = document.createElement("textarea");
    el.value = scriptCode;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    showMessage("success", "Copy mã nhúng thành công");
  };

  const addChienDich = async () => {
    if (stepState === 0) {
      setStepState(stepState + 1);
    } else {
      if (validateUrlWebsite(url)) {
        setBtnLoading(true);
        const res = await apiCampaign.create(`${method.value}://${url.value}`);
        setBtnLoading(false);
        if (res?.status?.code === "SUCCESS") {
          setStepState(stepState + 1);
          setScriptCode(res.data.scriptCode);
        } else {
          showMessage("error", res.status.message);
        }
      }
    }
  };

  const rediectPage = () => {
    history.push("/chien-dich");
  };

  return (
    <div className="get-started-page">
      <h2 className="text-white text-center">
        Thiết lập kết nối Website cho lần đầu tiên
      </h2>
      <div className="modal-image">
        <img src={ImageModal} alt="" />
      </div>
      <Steps className="mt-2" current={stepState}>
        <Step title="Chọn giao thức" />
        <Step title="Nhập địa chỉ website" />
        <Step title="Gắn mã nhúng" />
      </Steps>
      <Form className="modal-form dark mt-3 mb-2">
        {stepState === 0 && (
          <div className="form-group select-giao-thuc">
            <Form.Item validateStatus={""} help={""}>
              <Select
                value={method.value}
                onChange={(value) => setMethod({ ...method, value })}
              >
                <Option value="https">https</Option>
                <Option value="http">http</Option>
              </Select>
            </Form.Item>
          </div>
        )}
        {stepState === 1 && (
          <div className="form-group input-web-url">
            <Form.Item validateStatus={url.error} help={url.help}>
              <Input
                className="form-control"
                placeholder="Địa chỉ website"
                value={url.value}
                onChange={(e) => setUrl({ ...url, value: e.target.value })}
              />
            </Form.Item>
          </div>
        )}
        {stepState === 2 && (
          <div className="get-code-wrap position-relative">
            <Button
              className="btn btn-success btn-block d-flex align-items-center copy-btn"
              onClick={() => copyScriptCode()}
            >
              <img src={IconCopy} alt="" />
              Copy
            </Button>
            <div className="form-control script-code">{scriptCode}</div>
          </div>
        )}
      </Form>
      <div className="btn-step-group d-flex justify-content-center">
        {stepState === 1 && (
          <Button
            className="btn btn-primary btn-block"
            type="submit"
            onClick={() => setStepState(stepState - 1)}
          >
            {" "}
            Quay lại{" "}
          </Button>
        )}
        {stepState !== 2 && (
          <Button
            className="btn btn-success btn-block"
            type="submit"
            loading={btnLoading}
            onClick={() => addChienDich()}
          >
            {" "}
            Tiếp tục{" "}
          </Button>
        )}
        {stepState === 2 && (
          <Button
            className="btn btn-success btn-block"
            type="submit"
            onClick={() => rediectPage()}
          >
            {" "}
            Đi tới Quản lý chiến dịch{" "}
          </Button>
        )}
      </div>
    </div>
  );
};
