import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Input, Form, Button } from "antd";
import { validateTextLength } from "utils/utils-validation";

import Logo from "assets/images/logo.png";

import { apiAuth } from "api/auth";
import { showMessage } from "utils/utils-show-message";

export const DoiMatKhau = () => {
  const history = useHistory();
  const { id } = useParams();
  const [password, setPassword] = useState({ value: "", error: "", help: "" });
  const [confirmPassword, setConfirmPassword] = useState({
    value: "",
    error: "",
    help: "",
  });
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    checkExpireToken();
  }, []);

  const validatePasswordField = (target) => {
    if (!validateTextLength(target.value, 8)) {
      setPassword({
        ...target,
        error: "error",
        help: "Mật khẩu phải lớn hơn hoặc bằng 8 ký tự",
      });
      return false;
    }
    setPassword({ value: target.value, error: "", help: "" });
    return true;
  };

  const validateConfirmPasswordField = (target) => {
    if (confirmPassword.value !== password.value) {
      setConfirmPassword({
        ...target,
        error: "error",
        help: "Mật khẩu nhập lại không trùng khớp",
      });
      return false;
    }
    setConfirmPassword({ value: target.value, error: "", help: "" });
    return true;
  };

  const changePasswordAction = async () => {
    if (
      validatePasswordField(password) &&
      validateConfirmPasswordField(confirmPassword)
    ) {
      setBtnLoading(true);
      const res = await apiAuth.resetPassword(id, password.value);
      setBtnLoading(false);
      if (res?.status?.code === "SUCCESS") {
        showMessage("success", "Đổi mật khẩu thành công");
        history.push("/dang-nhap");
      }
    }
  };

  const checkExpireToken = async () => {
    const res = await apiAuth.checkUserResetPassword({ tokenKey: id });
    if (res?.status?.code === "NOT_FOUND") {
      history.push("/404");
      return false;
    }
  };

  return (
    <div className="quen-mat-khau-page">
      <div className="authentication-bg authentication-bg-pattern pt-5">
        <div className="account-pages">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card bg-pattern">
                  <div className="card-body p-4">
                    <div className="text-center w-75 m-auto">
                      <div className="auth-logo">
                        <a
                          href="index.html"
                          className="data-tuoi-logo light mb-2"
                        >
                          <img src={Logo} alt="" height="22" />
                          Telegamez.org
                        </a>
                      </div>
                      <p className="text-muted mb-4 mt-3">
                        Vui lòng nhập mật khẩu mới bảo mật cho tài khoản của bạn
                      </p>
                    </div>

                    <Form
                      onSubmit={() => changePasswordAction()}
                      className="dark"
                    >
                      <div className="form-group mb-0">
                        <label>Mật khẩu</label>
                        <Form.Item
                          validateStatus={password.error}
                          help={password.help}
                          className="mb-0"
                        >
                          <Input.Password
                            className="form-control-password"
                            placeholder="Nhập password của bạn"
                            value={password.value}
                            onChange={(e) =>
                              setPassword({
                                ...password,
                                value: e.target.value,
                              })
                            }
                            onBlur={() => validatePasswordField(password)}
                          />
                        </Form.Item>
                      </div>

                      <div className="form-group mb-0">
                        <label>Nhập lại mật khẩu</label>
                        <Form.Item
                          validateStatus={confirmPassword.error}
                          help={confirmPassword.help}
                          className="mb-0"
                        >
                          <Input.Password
                            className="form-control-password"
                            placeholder="Nhập password của bạn"
                            value={confirmPassword.value}
                            onChange={(e) =>
                              setConfirmPassword({
                                ...confirmPassword,
                                value: e.target.value,
                              })
                            }
                            onBlur={() =>
                              validateConfirmPasswordField(confirmPassword)
                            }
                          />
                        </Form.Item>
                      </div>

                      <div className="form-group mb-0 text-center">
                        <Button
                          className="btn btn-primary btn-block"
                          type="submit"
                          onClick={() => changePasswordAction()}
                          loading={btnLoading}
                        >
                          {" "}
                          Đổi lại mật khẩu{" "}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12 text-center">
                    <p className="text-white-50">
                      Quay lại{" "}
                      <Link to="/dang-nhap" className="text-white ml-1">
                        <b>Đăng nhập</b>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
