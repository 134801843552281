import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Input, Form, Select, Steps } from "antd";

import "./import-contact-modal.scss";

import {
  isHttpsWww,
  isWebUrl,
  validateText,
  validateVietnamPhoneNumber,
} from "utils/utils-validation";
import { showMessage } from "utils/utils-show-message";
import { apiContact } from "api/contact";
import { parseToken } from "../../utils/utils-parse-token";
import { setAccountRole } from "../../store/account";
import { useDispatch, useSelector } from "react-redux";
import * as FileSaver from "file-saver";

export const ImportContactModal = ({ visibleModal, onCancel, onSuccess }) => {
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState({ value: "", error: "", help: "" });
  const [file, setFile] = useState({ value: null, error: "", help: "" });

  const [btnLoading, setBtnLoading] = useState(false);
  const roleIdUser = useSelector((state) => state.account.role);
  const ref = useRef();

  const getUserRole = () => {
    const role =
      parseToken(localStorage.getItem("token-datatuoi"))?.role || null;
    dispatch(setAccountRole(role));
  };
  useEffect(() => {
    getUserRole();
  }, []);
  const initModal = () => {
    setFile({ value: null, error: "", help: "" });
    setBtnLoading(false);
  };

  const handleSelectFile = (e) => {
    const files = e.target.files;
    return validateFileSelect({ ...file, value: files ? files[0] : null });
  };

  const validateFileSelect = (target) => {
    if (!target.value) {
      setFile({ ...file, error: "error", help: "File bắt buộc chọn" });
      return false;
    }
    if (!target.value.name.toLowerCase().endsWith(".xlsx")) {
      setFile({
        ...file,
        error: "error",
        help: "File được chọn không phải định dạng xlsx",
      });
      return false;
    }

    setFile({ value: target.value, error: "", help: "" });
    return true;
  };

  const downloadTemplate = async () => {
    const res = await apiContact.getTemplate();
    const responseContent = JSON.parse(
      decodeURIComponent(res.headers["content-response"])
    );
    if (responseContent.code === "SUCCESS") {
      const fileName = res.headers["content-disposition"].split("=")[1];
      FileSaver.saveAs(res.data, fileName);
    } else {
      showMessage("error", responseContent.message);
    }
  };

  const doImport = async () => {
    if (!validateFileSelect(file)) {
      return false;
    }
    const formData = new FormData();
    formData.append("file", file.value);
    const res = await apiContact.importExcel(formData);
    const responseContent = JSON.parse(
      decodeURIComponent(res.headers["content-response"])
    );
    const fileName = res.headers["content-disposition"].split("=")[1];
    if (responseContent.code === "SUCCESS") {
      showMessage("success", responseContent.message);
      initModal();
      onSuccess();
    } else {
      showMessage("error", responseContent.message);
    }
    ref.current.value = "";
    FileSaver.saveAs(res.data, fileName);
  };

  return (
    <Modal
      title="Tải lên danh bạ"
      footer={null}
      visible={visibleModal}
      wrapClassName="import-contact-modal"
      onCancel={() => {
        onCancel();
        ref.current.value = "";
        initModal();
      }}
    >
      <Form className="modal-form dark mt-3 mb-2">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <Button
                className="btn btn-secondary btn-block text-left"
                loading={btnLoading}
                type="button"
                onClick={() => downloadTemplate()}
              >
                {" "}
                Tải xuống biểu mẫu{" "}
              </Button>
            </div>
          </div>

          <div className="col-12">
            <div className="form-group">
              <label>Chọn file</label>
              <Form.Item validateStatus={file.error} help={file.help}>
                <input
                  className="form-control"
                  type="file"
                  accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  ref={ref}
                  onChange={(e) => handleSelectFile(e)}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
      <div className="btn-step-group d-flex justify-content-end">
        <Button
          className="btn btn-primary mr-1"
          type="button"
          onClick={() => {
            onCancel();
            ref.current.value = "";
            initModal();
          }}
        >
          {" "}
          Hủy{" "}
        </Button>
        <Button
          className="btn btn-blue"
          loading={btnLoading}
          type="submit"
          onClick={() => doImport()}
        >
          {" "}
          Tải lên{" "}
        </Button>
      </div>
    </Modal>
  );
};
