import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Input,
  Select,
  Table,
  Dropdown,
  Pagination,
  DatePicker,
  Form,
  Tooltip,
} from "antd";
import "./contact.scss";
import { apiContact } from "api/contact";
import { useSelector } from "react-redux";
import { reverseDate, formatId } from "utils/utils-format";

import { InputAutoComplete } from "components/input-autocomplete/input-autocomplete";

import IconPlusFill from "assets/icons/icon-plus-fill.svg";
import IconUpload from "assets/icons/icon-upload.svg";
import IconCancel from "assets/icons/icon-cancel.svg";
import IconInfo from "assets/icons/icon-info.svg";
import IconEdit from "assets/icons/icon-edit.svg";
import IconFilter from "assets/icons/icon-filter.svg";
import IconRefresh from "assets/icons/icon-refresh.svg";
import { showMessage } from "utils/utils-show-message";
import { ThemDanhBaModal } from "../../components/them-danh-ba-modal/them-danh-ba-modal";
import { ModalConfirm } from "../../components/modal-confirm/modal-confirm";
import { SelectAgency } from "../../components/select-dai-ly/select-dai-ly";
import { ImportContactModal } from "../../components/import-contact-modal/import-contact-modal";
import { DetailContactModal } from "../../components/detail-contact-modal/detail-contact-modal";
import IconPhoneCall from "../../assets/icons/icon-phone-call.svg";
import { Link } from "react-router-dom";
import {
  STATUS_ACCEPTED,
  STATUS_CONFIRMED,
  STATUS_ENDED,
  STATUS_FAILED,
  STATUS_PROGRESS,
  UtilsPhoneNet,
} from "../../utils/utils-phonenet";
import { apiData } from "../../api/data";
import { CallModal } from "../../components/call-modal/call-modal";
import { GROUP_ADMIN } from "../../constant/constant";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const Contact = () => {
  const [visibleModalCreate, setVisibleModalCreate] = useState(false);
  const [visibleModalUpload, setVisibleModalUpload] = useState(false);
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [visibleModalDetail, setVisibleModalDetail] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(10);
  const [dataContact, setDataContact] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState(null);

  const [state, setState] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchMethod, setSearchMethod] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [url, setUrl] = useState("");

  const [dataSource, setDataSource] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");

  const [accountName, setAccountName] = useState("");

  const [contactId, setContactId] = useState(null);

  const [phonenet, setPhonenet] = useState(false);
  const [visibleCallModal, setVisibleCallModal] = useState(false);
  const [statusCall, setStatusCall] = useState(null);
  const [time, setTime] = useState("00:00");
  const [intervalCount, setIntervalCount] = useState(null);
  const [isActiveButtonCall, setIsActiveButtonCall] = useState(false);

  const userName = useSelector((state) => state.account.account?.userName);
  const roleIdUser = useSelector((state) => state.account.role);
  const stateCallio = useSelector(
    (state) => state.account.account?.stateCallio
  );
  const passExt = useSelector((state) => state.account.account?.passExt);
  const extCallio = useSelector((state) => state.account.account?.extCallio);

  let columnsCampaign = [
    {
      title: "STT",
      dataIndex: "rowIndex",
      key: "rowIndex",
      width: 125,
    },
    {
      title: "Tên khách hàng",
      dataIndex: "fullName",
      key: "fullName",
      className: "width-150-px-in-1200-column",
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      className: "width-150-px-in-1200-column",
      ellipsis: true,
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
      className: "width-150-px-in-1200-column",
      ellipsis: true,
      render: (value) => (
        <a target="_blank" href={value}>
          {value}
        </a>
      ),
    },
    {
      title: "Kết quả",
      dataIndex: "result",
      key: "result",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Người tạo",
      dataIndex: "owner",
      key: "owner",
      width: 180,
      ellipsis: true,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (value, row) => (
        <div className="row">
          {isActiveButtonCall && (
            <Tooltip placement="top" title={"Gọi điện"}>
              <button
                type="button"
                className="btn btn-md btn-rounded btn-success waves-effect waves-light d-flex align-items-center btn-icon-call action"
                onClick={() => handleCall(row)}
                disabled={stateCallio !== "active"}
              >
                <img src={IconPhoneCall} width="12" alt="" />
              </button>
            </Tooltip>
          )}

          <Tooltip placement="top" title="Chi tiết">
            <Link to={`/contact/${row.id}`}>
              <button
                type="button"
                className="btn btn-md btn-rounded btn-info waves-effect waves-light d-flex align-items-center btn-icon-edit action"
              >
                <img src={IconInfo} width="12" alt="" />
              </button>
            </Link>
          </Tooltip>
          {row.owner === userName && (
            <Tooltip placement="top" title="Xóa">
              <button
                type="button"
                className="btn btn-md btn-rounded btn-danger waves-effect waves-light d-flex align-items-center ml-2 btn-icon-delete action"
                onClick={() => openConfirmDelete(row)}
              >
                <img src={IconCancel} width="12" alt="" />
              </button>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (stateCallio !== "active") {
      return;
    }
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        const phonenet = new UtilsPhoneNet(`${extCallio}`, passExt);
        phonenet.login(
          (e) => {
            setPhonenet(phonenet);
            setIsActiveButtonCall(true);
            // showMessage('success', 'Kết nối tổng đài thành công')
          },
          (e) => console.log(e)
        );
      })
      .catch((e) => {
        console.log(e);
        showMessage(
          "error",
          "Máy tính không có mic hoặc không được cấp quyền dùng mic!"
        );
      });
  }, [stateCallio]);

  const openConfirmDelete = async (row) => {
    setContact(row);
    setVisibleModalDelete(true);
  };

  const deleteContact = async (contact) => {
    const res = await apiContact.delete(contact.id);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Xóa khách hàng thành công");
      setVisibleModalDelete(false);
      await getAllContact();
    } else {
      showMessage("error", res?.status?.message);
    }
  };

  const resetSearchValue = () => {
    setUrl("");
    setAccountName("");
  };

  const checkIsFilter = () => {
    if (fullName !== "" || searchMethod !== "" || phone !== "") {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  };

  const deleteFilter = async () => {
    setPhone("");
    setFullName("");
    setSearchMethod("");
    setPageSize(10);
    setPageIndex(1);
    setIsFilter(false);
    await getAllContact();
  };

  useEffect(async () => {
    await getAllContact();
  }, [url, accountName]);

  useEffect(async () => {
    setPageIndex(1);
    await getAllContact();
  }, [fullName, phone, dataSource, company, searchMethod]);

  useEffect(async () => {
    await getAllContact();
  }, [pageIndex, pageSize]);

  useEffect(() => {
    checkIsFilter();
  }, [isFilter]);

  const getAllContact = async () => {
    checkIsFilter();
    setLoading(true);
    const res = await apiContact.getAll({
      pageIndex,
      pageSize,
      fullName,
      phone,
      searchMethod,
    });
    setLoading(false);
    if (res?.status?.code === "SUCCESS") {
      const data = res.data;
      data.forEach(
        (item, index) =>
          (item.rowIndex = (pageIndex - 1) * pageSize + index + 1)
      );
      setDataContact(data);
      setTotalPage(res.totalItem);
    } else {
      setDataContact([]);
      setTotalPage(0);
      showMessage("error", res.status.message);
    }
  };

  const handleCall = async (row) => {
    phonenet.makeACall(
      row.phone,
      onProgress,
      onAccepted,
      onConfirmed,
      onEnded,
      onFailed
    );
  };

  const onProgress = () => {
    setStatusCall(STATUS_PROGRESS);
    setVisibleCallModal(true);
  };

  const onAccepted = () => {
    setStatusCall(STATUS_ACCEPTED);
  };

  const onConfirmed = () => {
    setStatusCall(STATUS_CONFIRMED);
    let minute = 0,
      second = 0;
    const timeInterval = setInterval(() => {
      ++second;
      if (second === 59) {
        second = 0;
        ++minute;
      }
      setTime(
        `${minute.toString().padStart(2, "0")}:${second
          .toString()
          .padStart(2, "0")}`
      );
    }, 1000);
    setIntervalCount(timeInterval);
  };

  useEffect(() => {
    if (statusCall === STATUS_ENDED) {
      clearInterval(intervalCount);
      // setStatusCall(null);
    }
  }, [intervalCount, statusCall]);

  const onEnded = () => {
    setStatusCall(STATUS_ENDED);
    // setTimeout(() => setVisibleCallModal(false), 1000);
  };

  const onFailed = (e) => {
    setStatusCall(STATUS_FAILED);
    // setTimeout(() => setVisibleCallModal(false), 1000);
  };

  const cancelCalll = () => {
    setVisibleCallModal(false);
    setTime("00:00");
    phonenet.cancelCall();
  };

  return (
    <div className="contact-page position-relative min-height-100vh">
      <CallModal
        visibleModal={visibleCallModal}
        status={statusCall}
        time={time}
        onCancel={() => setVisibleCallModal(false)}
        onCancelCall={() => cancelCalll()}
      />
      <ThemDanhBaModal
        isCreate={true}
        visibleModal={visibleModalCreate}
        onCancel={() => setVisibleModalCreate(false)}
        onSuccess={() => getAllContact()}
      />
      <DetailContactModal
        contactId={contactId}
        visibleModal={visibleModalDetail}
        onCancel={() => setVisibleModalDetail(false)}
        onSuccess={() => getAllContact()}
      />
      <ImportContactModal
        visibleModal={visibleModalUpload}
        onCancel={() => setVisibleModalUpload(false)}
        onSuccess={() => {
          setVisibleModalUpload(false);
          setPageIndex(1);
          getAllContact();
        }}
      />
      <ModalConfirm
        title={"Xóa danh bạ"}
        messageConfirm={"Bạn có chắc chắn muốn xóa danh bạ này không?"}
        visibleModal={visibleModalDelete}
        onCancel={() => setVisibleModalDelete(false)}
        onOk={() => deleteContact(contact)}
      />
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <Form className="form-inline">
                  <button
                    className="btn btn-secondary btn-sm ml-2"
                    onClick={async () => {
                      resetSearchValue();
                      setFromDate("");
                      setToDate("");
                      await getAllContact();
                    }}
                  >
                    <img src={IconRefresh} alt="" width="11" />
                  </button>
                </Form>
              </div>
              <h4 className="page-title">Danh bạ</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card-box">
              <h4 className="header-title d-flex align-items-center flex-wrap justify-content-between">
                <span className="mb-2">Danh sách danh bạ</span>
                <div className="d-flex flex-wrap align-items-center">
                  {isFilter && (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger waves-effect waves-light mb-2 mr-2 d-flex align-items-center"
                      onClick={() => deleteFilter()}
                    >
                      <img
                        src={IconFilter}
                        width="12"
                        className="mr-1"
                        alt=""
                      />{" "}
                      Xoá bộ lọc
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-sm btn-success waves-effect mb-2 mr-1 waves-light float-right d-flex align-items-center"
                    onClick={() => setVisibleModalUpload(true)}
                  >
                    <img src={IconUpload} width="12" className="mr-1" alt="" />{" "}
                    Tải lên
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-blue waves-effect mb-2 waves-light float-right d-flex align-items-center"
                    onClick={() => setVisibleModalCreate(true)}
                  >
                    <img
                      src={IconPlusFill}
                      width="12"
                      className="mr-1"
                      alt=""
                    />{" "}
                    Thêm mới
                  </button>
                </div>
              </h4>
              <div className="table-tools d-flex align-items-center justify-content-between mb-3">
                <div className="tool-item filter d-flex align-items-center">
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                    Tên khách hàng:
                    <Input
                      className="form-control"
                      placeholder="Nhập tên khách hàng"
                      onChange={(e) => setFullName(e.target.value)}
                      value={fullName}
                    />
                  </div>
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                    Số điện thoại:
                    <Input
                      className="form-control"
                      placeholder="Nhập số điện thoại"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                    />
                  </div>
                  {GROUP_ADMIN.includes(roleIdUser) && (
                    <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                      Nâng cao:
                      <Select
                        value={searchMethod}
                        style={{ width: 150 }}
                        onSelect={(value) => {
                          setSearchMethod(value);
                        }}
                      >
                        <Option value="">Tất cả</Option>
                        <Option value="1">Dạnh bạ admin</Option>
                        <Option value="2">Lọc trùng</Option>
                      </Select>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className="table-tools d-flex align-items-center justify-content-between mb-3">
                <div className="tool-item filter d-flex align-items-center">
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                    Nguồn khách hàng:
                    <Input
                      className="form-control"
                      placeholder="Nhập nguồn khách"
                      onChange={(e) => setDataSource(e.target.value)}
                      value={dataSource}
                    />
                  </div>
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                    Công ty:
                    <Input
                      className="form-control"
                      placeholder="Nhập công ty"
                      onChange={(e) => setCompany(e.target.value)}
                      value={company}
                    />
                  </div>
                </div>
                <div className="tool-item flex-wrap d-flex align-items-center text-nowrap">
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select mr-2">
                    Trạng thái
                    <Select
                      value={state}
                      onSelect={(value) => {
                        setState(value);
                      }}
                    >
                      <Option value="">Tất cả</Option>
                      <Option value="ACTIVE">Hoạt động</Option>
                      <Option value="INACTIVE">Tạm dừng</Option>
                    </Select>
                  </div>
                  <RangePicker
                    className="tool-item search"
                    format="DD-MM-YYYY"
                    value={[
                      fromDate ? moment(reverseDate(fromDate)) : null,
                      toDate ? moment(reverseDate(toDate)) : null,
                    ]}
                    placeholder={["Từ ngày", "Đến ngày"]}
                    onChange={(date, dateString) =>
                      handleDatePicker(date, dateString)
                    }
                  />
                </div>
              </div>*/}

              <Table
                loading={loading}
                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                dataSource={dataContact}
                columns={
                  GROUP_ADMIN.includes(roleIdUser)
                    ? columnsCampaign
                    : columnsCampaign.filter((item) => item.key !== "owner")
                }
                pagination={false}
              />

              {dataContact.length !== 0 && (
                <div className="table-panigate d-flex align-items-center justify-content-between mt-3">
                  <div className="dataTables_info">
                    Trang {pageIndex} trong tổng số{" "}
                    {Math.ceil(totalPage / pageSize)} trang
                  </div>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="tool-item select d-flex align-items-center pagesize-select">
                      Hiển thị
                      <Select
                        value={pageSize}
                        onSelect={(value) => setPageSize(value)}
                      >
                        <Option value="10">10</Option>
                        <Option value="30">30</Option>
                        <Option value="50">50</Option>
                        <Option value="70">70</Option>
                        <Option value="100">100</Option>
                      </Select>
                      dữ liệu
                    </div>
                    <div className="table-pagination">
                      <Pagination
                        current={pageIndex}
                        pageSize={pageSize}
                        total={totalPage}
                        showSizeChanger={false}
                        onChange={(page) => setPageIndex(page)}
                        hideOnSinglePage={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
