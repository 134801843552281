import React, { useEffect, useState } from "react";
import moment from "moment";
import { Modal, Button, Input, Form, Select, DatePicker, Divider } from "antd";
const { Option } = Select;
import { PlusOutlined } from "@ant-design/icons";

import "./add-history-contact-modal.scss";

import {
  isHttpsWww,
  isWebUrl,
  validateOnlyNumber,
  validateText,
  validateVietnamPhoneNumber,
} from "utils/utils-validation";
import { showMessage } from "utils/utils-show-message";
import { apiContact } from "api/contact";
import { parseToken } from "../../utils/utils-parse-token";
import { setAccountRole } from "../../store/account";
import { useDispatch, useSelector } from "react-redux";
import {
  PRODUCT_ITEM,
  PROGRESS,
  RESULT,
  SUCCESS,
} from "../../constant/constant";
import IconPlusFill from "assets/icons/icon-plus-fill.svg";

export const AddHistoryContactModal = ({
  isCreate,
  contactHistory,
  contactId,
  visibleModal,
  onCancel,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const [careTime, setCareTime] = useState({ value: "", error: "", help: "" });
  const [progress, setProgress] = useState({ value: "", error: "", help: "" });
  const [result, setResult] = useState({ value: "", error: "", help: "" });
  const [price, setPrice] = useState({ value: "", error: "", help: "" });
  const [productItem, setProductItem] = useState({
    value: "",
    error: "",
    help: "",
  });
  const [note, setNote] = useState({ value: "", error: "", help: "" });

  const [progressList, setProgressList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [productItemList, setProductItemList] = useState([]);

  const [progressInput, setProgressInput] = useState("");
  const [resultInput, setResultInput] = useState("");
  const [productItemInput, setProductItemInput] = useState("");

  const [btnLoading, setBtnLoading] = useState(false);
  const roleIdUser = useSelector((state) => state.account.role);

  const getListConfig = async () => {
    const res = await apiContact.getContactConfig();
    if (res.status.code !== "SUCCESS") {
      showMessage("error", res.status.message);
      return;
    }
    const progressList = [];
    const resultList = [];
    const productItemList = [];
    res.data.forEach((item) => {
      switch (item.groupType) {
        case PROGRESS:
          progressList.push(item.name);
          break;
        case RESULT:
          resultList.push(item.name);
          break;
        case PRODUCT_ITEM:
          productItemList.push(item.name);
          break;
        default:
          break;
      }
    });
    setProgressList(progressList);
    setResultList(resultList);
    setProductItemList(productItemList);
  };

  const getUserRole = () => {
    const role =
      parseToken(localStorage.getItem("token-datatuoi"))?.role || null;
    dispatch(setAccountRole(role));
  };
  useEffect(() => {
    getUserRole();
    getListConfig();
  }, []);

  const initModal = () => {
    setCareTime({ value: "", error: "", help: "" });
    setProgress({ value: "", error: "", help: "" });
    setResult({ value: "", error: "", help: "" });
    setPrice({ value: "", error: "", help: "" });
    setProductItem({ value: "", error: "", help: "" });
    setNote({ value: "", error: "", help: "" });
    if (!isCreate && contactHistory) {
      setCareTime({ value: contactHistory.careTime, error: "", help: "" });
      setProgress({ value: contactHistory.progress, error: "", help: "" });
      setResult({ value: contactHistory.result, error: "", help: "" });
      setPrice({ value: contactHistory.price, error: "", help: "" });
      setProductItem({
        value: contactHistory.productItem,
        error: "",
        help: "",
      });
      setNote({ value: contactHistory.note, error: "", help: "" });
    }
    setBtnLoading(false);
  };
  useEffect(() => {
    console.log(isCreate);
    initModal();
  }, [isCreate]);

  const validateCareTimeField = (target) => {
    if (!validateText(target.value)) {
      setCareTime({
        ...target,
        error: "error",
        help: "Vui lòng nhập thời gian",
      });
      return false;
    }
    setCareTime({ value: target.value, error: "", help: "" });
    return true;
  };

  const validatePriceField = (target) => {
    if (target.value && !validateOnlyNumber(target.value)) {
      setPrice({
        ...target,
        error: "error",
        help: "Vui lòng nhập số",
      });
      return false;
    }
    setPrice({ value: target.value, error: "", help: "" });
    return true;
  };

  const handleDatePickerChange = (date, dateString) => {
    if (date) {
      const value = moment(date).format("DD-MM-YYYY HH:mm:ss");
      setCareTime({ ...careTime, value });
    } else {
      setCareTime({ ...careTime, value: "" });
    }
  };

  const saveOrUpdateContactHistory = async () => {
    if (!validateCareTimeField(careTime) || !validatePriceField(price)) {
      return;
    }
    setBtnLoading(true);
    let res;
    const obj = {
      careTime: careTime.value,
      progress: progress.value,
      result: result.value,
      price: price.value,
      productItem: productItem.value,
      note: note.value,
    };
    if (isCreate) {
      res = await apiContact.createCareHistory(contactId, obj);
    } else {
      obj.id = contactHistory.id;
      res = await apiContact.updateCareHistory(contactId, obj);
    }
    setBtnLoading(false);
    if (res?.status?.code === "SUCCESS") {
      const message = isCreate
        ? "Thêm mới lịch sử chăm sóc khách hàng thành công!"
        : "Cập nhật lịch sử chăm sóc khách hàng thành công!";
      showMessage("success", message);
      onCancel();
      onSuccess();
      initModal();
    } else {
      showMessage("error", res.status.message);
    }
  };

  const addContactConfig = async (key) => {
    const body = { groupType: key, name: "" };
    switch (key) {
      case PROGRESS:
        if (!progressInput) {
          return false;
        }
        if (
          productItemList.filter(
            (item) =>
              item.trim().toLowerCase() ===
              progressInput.trim().toLocaleLowerCase()
          ).length > 0
        ) {
          return false;
        }
        body.name = progressInput;
        break;
      case RESULT:
        if (!resultInput) {
          return false;
        }
        if (
          resultList.filter(
            (item) =>
              item.trim().toLowerCase() ===
              resultInput.trim().toLocaleLowerCase()
          ).length > 0
        ) {
          return false;
        }
        body.name = resultInput;
        break;
      case PRODUCT_ITEM:
        if (!productItemInput) {
          return;
        }
        if (
          productItemList.filter(
            (item) =>
              item.trim().toLowerCase() ===
              productItemInput.trim().toLocaleLowerCase()
          ).length > 0
        ) {
          return false;
        }
        body.name = productItemInput.trim();
        break;
      default:
        break;
    }
    const res = await apiContact.addContactConfig(body);
    if (res.status.code === SUCCESS) {
      switch (key) {
        case PROGRESS:
          setProgressList([...progressList, progressInput]);
          setProgressInput("");
          break;
        case RESULT:
          setResultList([...resultList, resultInput]);
          setResultInput("");
          break;
        case PRODUCT_ITEM:
          setProductItemList([...productItemList, productItemInput]);
          setProductItemInput("");
          break;
        default:
          break;
      }
    } else {
      showMessage("error", res.status.message);
    }
  };

  return (
    <Modal
      title={isCreate ? "Tạo mới lịch sử liên hệ" : "Cập nhật lịch sử liên hệ"}
      footer={null}
      visible={visibleModal}
      wrapClassName="add-contact-history-modal"
      onCancel={() => {
        onCancel();
        initModal();
      }}
    >
      <Form className="modal-form dark mt-3 mb-2">
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Ngày</label>
              <Form.Item validateStatus={careTime.error} help={careTime.help}>
                <DatePicker
                  showTime
                  className="form-control"
                  placeholder="Nhập ngày"
                  onChange={(date, dateString) =>
                    handleDatePickerChange(date, dateString)
                  }
                  value={
                    careTime.value
                      ? moment(careTime.value, "DD-MM-YYYY HH:mm:ss")
                      : ""
                  }
                  onBlur={() => validateCareTimeField(careTime)}
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Tiến độ</label>
              <Form.Item validateStatus={progress.error} help={progress.help}>
                {/*<Input
                  className="form-control"
                  placeholder="Nhập số tiến độ"
                  onChange={(e) =>
                    setProgress({ ...progress, value: e.target.value })
                  }
                  value={progress.value}
                />*/}
                <Select
                  showSearch
                  onChange={(value) => setProgress({ ...progress, value })}
                  value={progress.value}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          className="form-control input-group-sm"
                          value={progressInput}
                          placeholder="Nhập tiến độ"
                          onChange={(e) => setProgressInput(e.target.value)}
                        />
                        <a
                          type="button"
                          className="btn btn-sm btn-success text-white"
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={() => addContactConfig(PROGRESS)}
                        >
                          <img
                            src={IconPlusFill}
                            width="12"
                            className="mr-1"
                            alt=""
                          />{" "}
                          Thêm
                        </a>
                      </div>
                    </div>
                  )}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {progressList.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Kết quả</label>
              <Form.Item validateStatus={result.error} help={result.help}>
                <Select
                  showSearch
                  placeholder="Chọn kết quả"
                  optionFilterProp="children"
                  onChange={(value) => setResult({ ...result, value })}
                  value={result.value}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          className="form-control"
                          value={resultInput}
                          placeholder="Nhập kết quả"
                          onChange={(e) => setResultInput(e.target.value)}
                        />
                        <a
                          type="button"
                          className="btn btn-sm btn-success text-white"
                          style={{
                            flex: "none",
                            padding: "9px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={() => addContactConfig(RESULT)}
                        >
                          <img
                            src={IconPlusFill}
                            width="12"
                            className="mr-1"
                            alt=""
                          />{" "}
                          Thêm
                        </a>
                      </div>
                    </div>
                  )}
                >
                  {resultList.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Giá hợp đồng</label>
              <Form.Item validateStatus={price.error} help={price.help}>
                <Input
                  className="form-control"
                  placeholder="Nhập giá hợp đông"
                  onChange={(e) =>
                    setPrice({ ...price, value: e.target.value })
                  }
                  value={price.value}
                  onBlur={() => validatePriceField(price)}
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Sản phẩm</label>
              <Form.Item
                validateStatus={productItem.error}
                help={productItem.help}
              >
                <Select
                  showSearch
                  placeholder="Chọn sản phẩm"
                  optionFilterProp="children"
                  onChange={(value) =>
                    setProductItem({ ...productItem, value })
                  }
                  value={productItem.value}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          className="form-control"
                          value={productItemInput}
                          placeholder="Nhập sản phẩm"
                          onChange={(e) => setProductItemInput(e.target.value)}
                        />
                        <a
                          role="button"
                          className="btn btn-success"
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={() => addContactConfig(PRODUCT_ITEM)}
                        >
                          <img
                            src={IconPlusFill}
                            width="12"
                            className="mr-1"
                            alt=""
                          />{" "}
                          Thêm
                        </a>
                      </div>
                    </div>
                  )}
                >
                  {productItemList.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Ghi chú</label>
              <Form.Item validateStatus={note.error} help={note.help}>
                <Input
                  className="form-control"
                  placeholder="Nhập ghi chú"
                  onChange={(e) => setNote({ ...note, value: e.target.value })}
                  value={note.value}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
      <div className="btn-step-group d-flex justify-content-end">
        <Button
          className="btn btn-primary btn-block"
          type="button"
          onClick={() => onCancel()}
        >
          {" "}
          Hủy{" "}
        </Button>
        <Button
          className="btn btn-success btn-block"
          loading={btnLoading}
          type="submit"
          onClick={() => saveOrUpdateContactHistory()}
        >
          {" "}
          {isCreate ? "Tạo mới" : "Cập nhật"}{" "}
        </Button>
      </div>
    </Modal>
  );
};
