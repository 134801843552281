import React, { useEffect, useState } from "react";
import "./input-autocomplete.scss";
import { Input, Select } from "antd";

const { Option } = Select;

export const InputAutoComplete = ({
  placeholder,
  onSearch,
  dataSource,
  isFilter,
}) => {
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [value, setValue] = useState("");
  const [select, setSelect] = useState("");
  const [dataSearch, setDataSearch] = useState([]);

  const onDropdownVisibleChange = (open) => {
    setVisibleDropdown(open);
  };

  const onSelect = (value) => {
    setValue(value);
    setSelect(value);
    onSearch(value);
  };
  useEffect(() => {
    setVisibleDropdown(false);
  }, [select]);

  useEffect(() => {
    setDataSearch([]);
    changeValueFilter(value);
  }, [dataSource]);

  useEffect(() => {
    setValue("");
  }, [placeholder]);

  useEffect(() => {
    if (!isFilter) setValue("");
  }, [isFilter]);

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const changeValueFilter = (value) => {
    setValue(value);
    const currentDataSearch = [];
    dataSource.forEach((item, index) => {
      if (item.includes(value)) currentDataSearch.push(item);
    });
    setDataSearch(currentDataSearch.filter(onlyUnique));
  };

  return (
    <div
      className="input-autocomplete"
      onClick={() => setVisibleDropdown(true)}
    >
      <Input
        value={value}
        className="form-control"
        placeholder={placeholder}
        onPressEnter={() => onSearch(value)}
        onChange={(e) => changeValueFilter(e.target.value)}
      />
      <Select
        open={visibleDropdown}
        onDropdownVisibleChange={(open) => onDropdownVisibleChange(open)}
        onSelect={(value) => onSelect(value)}
      >
        {dataSearch.map((item, index) => (
          <Option key={index} value={item}>
            {item}
          </Option>
        ))}
      </Select>
    </div>
  );
};
