import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Input, Checkbox, Form, Button } from "antd";
import { Link, useHistory } from "react-router-dom";
import { setEmailRegister } from "store/auth";

import "./dang-nhap-form.scss";

import Logo from "assets/images/logo.png";
import { apiAuth } from "api/auth";
import { showMessage } from "../../utils/utils-show-message";

export const DangNhapForm = () => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberPassword, setRememberPassword] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [stateError, setStateError] = useState("");
  const history = useHistory();

  const loginAction = async () => {
    setBtnLoading(true);
    const res = await apiAuth.login(userName, password);
    setBtnLoading(false);
    if (res?.status?.code === "SUCCESS") {
      localStorage.setItem("token-datatuoi", res.data);
      history.push("/");
      return true;
    }
    if (res?.status?.code === "ACCESS_DENIED") {
      dispatch(setEmailRegister(res.data));
      history.push("/xac-thuc");
      return false;
    }
    if (res?.status?.code === "USER-01") {
      showMessage("error", res?.status?.message);
      return false;
    }
    if (res?.status?.code === "TOO_MANY_REQUESTS") {
      showMessage("error", res?.status?.message);
      return false;
    }
    if (res?.status === 500) {
      setStateError(res.message);
      return false;
    }
  };
  return (
    <div className="dang-nhap-form">
      <div className="card bg-pattern mt-4">
        <div className="card-body p-4">
          <div className="text-center m-auto">
            <div className="auth-logo">
              <a href="index.html" className="data-tuoi-logo dark mb-2">
                Đăng nhập
              </a>
            </div>
          </div>

          <Form className="light">
            <div className="form-group mb-3">
              <label htmlFor="emailaddress">Tài khoản</label>
              <Input
                className="form-control"
                placeholder="Nhập email của bạn"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                onPressEnter={() => loginAction()}
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="password">Mật khẩu</label>
              <Input.Password
                className="form-control-password"
                placeholder="Nhập mật khẩu"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onPressEnter={() => loginAction()}
              />
            </div>

            <div className="form-group mb-3">
              <div className="remember-password">
                <Checkbox
                  className="text-muted"
                  checked={rememberPassword}
                  onChange={() => setRememberPassword(!rememberPassword)}
                >
                  Nhớ mật khẩu
                </Checkbox>
              </div>
            </div>

            <p className="text-error-message">{stateError}</p>

            <div className="form-group mb-0 text-center">
              <Button
                className="btn btn-primary btn-block"
                type="submit"
                loading={btnLoading}
                onClick={() => loginAction()}
              >
                {" "}
                Đăng nhập{" "}
              </Button>
            </div>
          </Form>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12 text-center">
          <p>
            {" "}
            <Link to="/quen-mat-khau" className="text-white-50 ml-1">
              Quên mật khẩu ?
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
