const base64 = require("base-64");
export const parseToken = (accessToken) => {
  if (!accessToken) {
    return null;
  }
  let parts = accessToken.split(".");
  if (parts.length !== 3) {
    return null; // token seems to be invalid
  }

  return JSON.parse(base64.decode(parts[1]));
};
