import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Form, Select, DatePicker, Tabs } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { apiOrder } from "api/order";

import "./them-don-hang-modal.scss";
import ImageModal from "assets/images/image-data-modal.png";
import { showMessage } from "utils/utils-show-message";
import { validateText, validateOnlyNumber } from "utils/utils-validation";
import moment from "moment";
import { ThemDonHangTrackingFb } from "../them-don-hang-tracking-fb/them-don-hang-tracking-fb";

const { Option } = Select;
const { TabPane } = Tabs;

export const ThemDonHangModal = ({
  visibleModal,
  onCancel,
  onSuccess,
  userId,
  userName,
  oldExpiredDate,
  oldExpiredDateTrackingFb,
}) => {
  const [type, setType] = useState("package");
  const [btnLoading, setBtnLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [expiredDate, setExpiredDate] = useState(moment());
  const [numberData, setNumberData] = useState({
    value: "",
    error: "",
    help: "",
  });
  const [price, setPrice] = useState({
    value: "7000",
    error: "",
    help: "",
  });
  const [numberExpire, setNumberExpire] = useState({
    value: "",
    error: "",
    help: "",
  });

  const [orderDate, setOrderDate] = useState([
    "5",
    "10",
    "15",
    "20",
    "25",
    "30",
    "40",
    "50",
    "60",
    "90",
  ]);

  const packages = [
    // { value: "data-03", title: 'Gói 3 tháng' },
    // { value: "data-06", title: 'Gói 6 tháng' },
    // { value: "data-12", title: 'Gói 12 tháng' },
    { value: "100", title: "Gói 100 số" },
    { value: "300", title: "Gói 300 số" },
    { value: "500", title: "Gói 500 số" },
    { value: "1000", title: "Gói 1000 số" },
    { value: "1000", title: "Gói 1000 số" },
    { value: "1500", title: "Gói 1500 số" },
    { value: "2000", title: "Gói 2000 số" },
    { value: "2500", title: "Gói 2500 số" },
    { value: "3000", title: "Gói 3000 số" },
    { value: "3500", title: "Gói 3500 số" },
    { value: "4000", title: "Gói 4000 số" },
    { value: "4500", title: "Gói 4500 số" },
    { value: "5000", title: "Gói 5000 số" },
  ];

  useEffect(() => {
    initModal();
  }, [userId]);

  useEffect(() => {
    if (numberData.value === "data-03")
      setNumberExpire({ ...numberExpire, value: "90" });
    if (numberData.value === "data-06")
      setNumberExpire({ ...numberExpire, value: "180" });
    if (numberData.value === "data-12")
      setNumberExpire({ ...numberExpire, value: "360" });
  }, [numberData]);

  const initModal = () => {
    setIsOpen(false);
    setBtnLoading(false);
    setNumberData({ value: "", error: "", help: "" });
    setNumberExpire({ value: "", error: "", help: "" });
    setPrice({ value: "7000", error: "", help: "" });
    setType("package");
    setOrderDate(["5", "10", "15", "20", "25", "30", "40", "50", "60", "90"]);
    if (
      oldExpiredDate &&
      moment(oldExpiredDate, "DD-MM-YYYY").isAfter(moment())
    ) {
      setExpiredDate(moment(oldExpiredDate, "DD-MM-YYYY"));
    } else {
      setExpiredDate(moment());
    }
  };

  useEffect(() => {
    if (type === "package") {
      setNumberData({ value: "500", error: "", help: "" });
      setNumberExpire({ value: "30", error: "", help: "" });
    }
    if (type === "custom") {
      setNumberData({ value: "", error: "", help: "" });
      setNumberExpire({ value: "", error: "", help: "" });
    }
  }, [type]);

  const pricingCart = async () => {
    setIsOpen(false);
    if (
      validatePriceField(price) &&
      validateNumberDataField(numberData) &&
      validateNumberExpireField(numberExpire)
    ) {
      setBtnLoading(true);
      const res = await apiOrder.create(
        numberData.value,
        numberExpire.value,
        price.value,
        userId
      );
      setBtnLoading(false);
      if (res?.status?.code === "SUCCESS") {
        onCancel();
        showMessage("success", "Tạo đơn hàng thành công");
        initModal();
        onSuccess();
      } else {
        showMessage("error", res?.status?.message);
      }
    }
  };
  const validateNumberDataField = (target) => {
    if (!validateText(target.value) || !validateOnlyNumber(target.value)) {
      setNumberData({
        ...target,
        error: "error",
        help: "Vui lòng nhập chính xác số lượng Data",
      });
      return false;
    }
    setNumberData({ value: target.value, error: "", help: "" });
    return true;
  };
  const validatePriceField = (target) => {
    if (!validateText(target.value) || !validateOnlyNumber(target.value)) {
      setPrice({
        ...target,
        error: "error",
        help: "Vui lòng nhập chính xác đơn giá",
      });
      return false;
    }
    setPrice({ value: target.value, error: "", help: "" });
    return true;
  };
  const validateNumberExpireField = (target) => {
    if (!validateText(target.value) || !validateOnlyNumber(target.value)) {
      setNumberExpire({
        ...target,
        error: "error",
        help: "Vui lòng nhập chính xác số lượng ngày sử dụng",
      });
      return false;
    }
    const currentDate = moment().set({
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
    if (
      oldExpiredDate &&
      moment(oldExpiredDate, "DD-MM-YYYY")
        .add(target.value, "days")
        .isSameOrBefore(currentDate)
    ) {
      const expiredDateNew = moment(oldExpiredDate, "DD-MM-YYYY").add(
        target.value,
        "days"
      );
      setNumberExpire({
        ...target,
        error: "error",
        help: `Vui lòng thêm ${currentDate.diff(
          expiredDateNew,
          "days"
        )} ngày sử dụng để đến ngày hiện tại`,
      });
      return false;
    }
    setNumberExpire({ value: target.value, error: "", help: "" });
    return true;
  };

  const disabledDate = (current) => {
    const currentDate = moment();
    if (!oldExpiredDate) {
      return current && current < currentDate.endOf("day").add(-1, "days");
    }
    const expiredDateMoment = moment(oldExpiredDate, "DD-MM-YYYY");
    if (expiredDateMoment.isAfter(currentDate)) {
      return (
        current && current < expiredDateMoment.endOf("day").add(-1, "days")
      );
    }

    return current && current < currentDate.endOf("day").add(-1, "days");
  };

  const onDatePickerChange = (date) => {
    setIsOpen(false);
    // let sinceDate = moment().set({
    //   hours: 0,
    //   minutes: 0,
    //   seconds: 0,
    //   milliseconds: 0,
    // });
    date = moment(date).set({
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
    const oldExpiredDateMoment = moment(oldExpiredDate, "DD-MM-YYYY");
    // if (
    //   oldExpiredDateMoment.toDate().getTime() > sinceDate.toDate().getTime()
    // ) {
    //   sinceDate = oldExpiredDateMoment;
    // }
    const diffDate =
      moment.duration(date.diff(oldExpiredDateMoment)).asDays() + "";
    if (!orderDate.includes(diffDate)) {
      const orderDateNew = [...[diffDate], ...orderDate].sort(
        (a, b) => parseInt(a) - parseInt(b)
      );
      setOrderDate(orderDateNew);
    }
    setExpiredDate(date);
    setNumberExpire({ value: diffDate, error: "", help: "" });
  };

  return (
    <Modal
      title="Tạo mới đơn hàng"
      footer={null}
      visible={visibleModal}
      wrapClassName="them-chien-dich-modal them-don-hang-modal"
      onCancel={() => {
        initModal();
        setTimeout(() => onCancel(), 100);
      }}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Đơn hàng website" key="1">
          <div className="modal-image">
            <img src={ImageModal} alt="" />
          </div>
          <p className="text-center">
            Thao tác này sẽ tạo cho tài khoản <strong>{userName}</strong> đơn
            hàng
          </p>
          <Form className="modal-form dark mt-3 mb-2">
            <div className="row">
              <div className="col-6">
                <div className="form-group mb-2">
                  <label className="d-block">Chọn phương thức</label>
                  <Select
                    className="w-100"
                    value={type}
                    onClick={() => setIsOpen(false)}
                    onChange={(value) => setType(value)}
                  >
                    <Option value="package">Theo gói</Option>
                    <Option value="custom">Tuỳ chỉnh</Option>
                  </Select>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-2">
                  <label className="d-block">Đơn giá</label>
                  <Form.Item validateStatus={price.error} help={price.help}>
                    <Input
                      onBlur={() => validatePriceField(price)}
                      placeholder="Nhập đơn giá"
                      className="form-control"
                      value={price.value}
                      onClick={() => setIsOpen(false)}
                      onChange={(e) =>
                        setPrice({ ...price, value: e.target.value })
                      }
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-2">
                  <label className="d-block">Số lượng Data</label>
                  <Form.Item
                    validateStatus={numberData.error}
                    help={numberData.help}
                  >
                    {type === "custom" ? (
                      <Input
                        className="form-control"
                        placeholder="Nhập Số lượng Data"
                        value={numberData.value}
                        onChange={(e) =>
                          setNumberData({
                            ...numberData,
                            value: e.target.value,
                          })
                        }
                        onBlur={() => validateNumberDataField(numberData)}
                      />
                    ) : (
                      <Select
                        className="w-100"
                        value={numberData.value}
                        onClick={() => setIsOpen(false)}
                        onChange={(value) =>
                          setNumberData({ ...numberData, value })
                        }
                      >
                        {packages.map((item, index) => (
                          <Option key={index} value={item.value}>
                            {item.title}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-2 control-number-expired">
                  <label className="d-block">Số ngày sử dụng</label>
                  <Form.Item
                    validateStatus={numberExpire.error}
                    help={numberExpire.help}
                  >
                    {type === "custom" ? (
                      <>
                        <Input
                          className="form-control w-75"
                          placeholder="Nhập Số ngày sử dụng"
                          value={numberExpire.value}
                          onClick={() => setIsOpen(false)}
                          onChange={(e) =>
                            setNumberExpire({
                              ...numberExpire,
                              value: e.target.value,
                            })
                          }
                          onBlur={() => validateNumberExpireField(numberExpire)}
                        />
                        <Button
                          className="btn btn-blue w-auto btn-expired-picker"
                          type="button"
                          onClick={() => setIsOpen(!isOpen)}
                          disabled={["data-03", "data-06", "data-12"].includes(
                            numberData.value
                          )}
                          icon={<CalendarOutlined />}
                        />
                        <DatePicker
                          className={"hide-datepicker"}
                          disabledDate={disabledDate}
                          open={isOpen}
                          value={expiredDate}
                          onChange={onDatePickerChange}
                        />
                      </>
                    ) : (
                      <>
                        <Select
                          className="w-75"
                          value={numberExpire.value}
                          onClick={() => setIsOpen(false)}
                          onChange={(value) => {
                            setNumberExpire({ ...numberExpire, value });
                            setExpiredDate(moment().add(value, "days"));
                          }}
                          disabled={["data-03", "data-06", "data-12"].includes(
                            numberData.value
                          )}
                        >
                          {orderDate.map((item, index) => (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                        <Button
                          className="btn btn-blue w-auto btn-expired-picker"
                          type="button"
                          onClick={() => setIsOpen(!isOpen)}
                          disabled={["data-03", "data-06", "data-12"].includes(
                            numberData.value
                          )}
                          icon={<CalendarOutlined />}
                        />
                        <DatePicker
                          className={"hide-datepicker"}
                          disabledDate={disabledDate}
                          open={isOpen}
                          value={expiredDate}
                          onChange={onDatePickerChange}
                        />
                      </>
                    )}
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-success btn-block w-auto"
              type="submit"
              loading={btnLoading}
              onClick={() => pricingCart()}
            >
              {" "}
              Thanh toán{" "}
            </Button>
          </div>
        </TabPane>
        <TabPane tab="Đơn hàng facebook tracking" key="2">
          <ThemDonHangTrackingFb
            onCancel={onCancel}
            onSuccess={onSuccess}
            userId={userId}
            userName={userName}
            oldExpiredDate={oldExpiredDateTrackingFb}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};
