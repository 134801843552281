import axios from "axios";
import qs from "querystringify";
require("dotenv");

const baseUrl = process.env.REACT_APP_SERVER_ENDPOINT;
const TIMEOUT = 300000;

const getAuthToken = () => {
  return localStorage.getItem("token-datatuoi") || "";
};

const handleResponse = (r, isAuth) => {
  if (!r) {
    window.location.href = "/dang-nhap";
    return;
  }
  if (r?.status === 401 || r?.status === 403) {
    if (isAuth) window.location.href = "/dang-nhap";
    return r;
  } else if (r?.status === 500) {
    if (isAuth) window.location.href = "/500";
    return r;
  } else {
    return r;
  }
};

const redirectToSomethingWentWrongScreen = () => {
  return setTimeout(() => {
    window.location.href = "/500";
  }, TIMEOUT);
};

export const rest = {
  get: async (endpoint, params, timeout = TIMEOUT, isAuth) => {
    let options = {};
    if (isAuth) {
      const auth = getAuthToken();
      if (auth) {
        options = {
          headers: {
            "X-AUTH-TOKEN": auth,
          },
        };
      }
    }

    const handleTimeout = redirectToSomethingWentWrongScreen(timeout);
    try {
      const res = await axios.get(
        `${baseUrl + endpoint}?${qs.stringify({ ...params })}`,
        options.headers ? options : {}
      );
      clearTimeout(handleTimeout);
      return res.data;
    } catch (err) {
      clearTimeout(handleTimeout);
      return handleResponse(err?.response?.data, isAuth);
    }
  },
  getFile: async (endpoint, params, timeout = TIMEOUT) => {
    let options = {};
    const auth = getAuthToken();
    if (auth) {
      options = {
        headers: {
          "X-AUTH-TOKEN": auth,
        },
        responseType: "blob",
      };
    }

    const handleTimeout = redirectToSomethingWentWrongScreen(timeout);
    try {
      const res = await axios.get(
        `${baseUrl + endpoint}?${qs.stringify({ ...params })}`,
        options
      );
      clearTimeout(handleTimeout);
      return res;
    } catch (err) {
      clearTimeout(handleTimeout);
      return handleResponse(err?.response?.data, true);
    }
  },
  post: async (endpoint, params, timeout = TIMEOUT, isAuth) => {
    let options = {};
    if (isAuth) {
      const auth = getAuthToken();
      if (auth) {
        options = {
          headers: {
            "X-AUTH-TOKEN": auth,
          },
        };
      }
    }

    const handleTimeout = redirectToSomethingWentWrongScreen(timeout);
    try {
      const res = await axios.post(baseUrl + endpoint, { ...params }, options);
      clearTimeout(handleTimeout);
      return res.data;
    } catch (err) {
      clearTimeout(handleTimeout);
      return handleResponse(err?.response?.data, isAuth);
    }
  },
  postFile2: async (endpoint, params, timeout = TIMEOUT, isAuth, options) => {
    options = options ? options : {};
    if (isAuth) {
      const auth = getAuthToken();
      if (auth) {
        options = {
          ...options,
          headers: {
            "X-AUTH-TOKEN": auth,
          },
        };
      }
    }

    const handleTimeout = redirectToSomethingWentWrongScreen(timeout);
    try {
      const res = await axios.post(baseUrl + endpoint, { ...params }, options);
      clearTimeout(handleTimeout);
      return res;
    } catch (err) {
      clearTimeout(handleTimeout);
      return handleResponse(err?.response?.data, isAuth);
    }
  },
  postFile: async (endpoint, params, timeout = TIMEOUT) => {
    let options = {};
    const auth = getAuthToken();
    if (auth) {
      options = {
        headers: {
          "X-AUTH-TOKEN": auth,
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
        observe: "response",
      };
    }

    const handleTimeout = redirectToSomethingWentWrongScreen(timeout);
    try {
      const res = await axios.post(baseUrl + endpoint, params, options);
      clearTimeout(handleTimeout);
      return res;
    } catch (err) {
      clearTimeout(handleTimeout);
      return handleResponse(err?.response?.data, true);
    }
  },
  put: async (endpoint, params, timeout = TIMEOUT, isAuth) => {
    let options = {};
    if (isAuth) {
      const auth = getAuthToken();
      if (auth) {
        options = {
          headers: {
            "X-AUTH-TOKEN": auth,
          },
        };
      }
    }
    const handleTimeout = redirectToSomethingWentWrongScreen(timeout);
    try {
      const res = await axios.put(baseUrl + endpoint, { ...params }, options);
      clearTimeout(handleTimeout);
      return res.data;
    } catch (err) {
      clearTimeout(handleTimeout);
      return handleResponse(err?.response?.data, isAuth);
    }
  },
  patch: async (endpoint, params, timeout = TIMEOUT, isAuth) => {
    let options = {};
    if (isAuth) {
      const auth = getAuthToken();
      if (auth) {
        options = {
          headers: {
            "X-AUTH-TOKEN": auth,
          },
        };
      }
    }
    const handleTimeout = redirectToSomethingWentWrongScreen(timeout);
    try {
      const res = await axios.patch(baseUrl + endpoint, { ...params }, options);
      clearTimeout(handleTimeout);
      return res.data;
    } catch (err) {
      clearTimeout(handleTimeout);
      return handleResponse(err?.response?.data, isAuth);
    }
  },
  delete: async (endpoint, params, timeout = TIMEOUT, isAuth) => {
    let options = {};
    if (isAuth) {
      const auth = getAuthToken();
      if (auth) {
        options = {
          headers: {
            "X-AUTH-TOKEN": auth,
          },
        };
      }
    }
    const handleTimeout = redirectToSomethingWentWrongScreen(timeout);
    try {
      const res = await axios.delete(
        baseUrl + endpoint,
        options.headers ? options : {}
      );
      clearTimeout(handleTimeout);
      return res.data;
    } catch (err) {
      clearTimeout(handleTimeout);
      return handleResponse(err?.response?.data, isAuth);
    }
  },

  // postFormData: (endpoint, params, timeout = TIMEOUT, header) => {
  //   const options = {
  //     method: "POST",
  //     body: params.formData,
  //     headers: {
  //       ...header,
  //       auth,
  //     },
  //   };
  //   const handleTimeout = redirectToSomethingWentWrongScreen(timeout);
  //   return fetch(baseUrl + endpoint, options).then((r) => {
  //     clearTimeout(handleTimeout);
  //     return handleResponse(r);
  //   });
  // },

  // putFormData: (endpoint, params, header) => {
  //   const options = {
  //     method: "PUT",
  //     body: params.formData,
  //     headers: {
  //       ...header,
  //       auth,
  //     },
  //   };
  //   const handleTimeout = redirectToSomethingWentWrongScreen();
  //   return fetch(baseUrl + endpoint, options).then((r) => {
  //     clearTimeout(handleTimeout);
  //     return handleResponse(r);
  //   });
  // },
};
