import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Input, Form, Button } from "antd";
import { validateEmail } from "utils/utils-validation";
import "./quen-mat-khau.scss";

import Logo from "assets/images/logo.png";

import { apiAuth } from "api/auth";
import { showMessage } from "utils/utils-show-message";

export const QuenMatKhau = () => {
  const [email, setEmail] = useState({ value: "", error: "", help: "" });
  const [btnLoading, setBtnLoading] = useState(false);

  const validateEmailField = (target) => {
    if (!validateEmail(target.value)) {
      setEmail({ ...target, error: "error", help: "Email không hợp lệ" });
      return false;
    }
    setEmail({ value: target.value, error: "", help: "" });
    return true;
  };

  const forgotPasswordAction = async () => {
    if (validateEmailField(email)) {
      setBtnLoading(true);
      const res = await apiAuth.forgotPassword(email.value);
      setBtnLoading(false);
      if (res?.status?.code === "NOT_FOUND") {
        showMessage("error", "Email không tồn tại");
        return false;
      }
      if (res?.status?.code === "SUCCESS") {
        showMessage(
          "success",
          "Gửi thành công. Xin hay kiểm tra hộp thư của bạn."
        );
        return true;
      }
    }
  };
  return (
    <div className="quen-mat-khau-page">
      <div className="authentication-bg authentication-bg-pattern pt-5">
        <div className="account-pages">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card bg-pattern">
                  <div className="card-body p-4">
                    <div className="text-center w-75 m-auto">
                      <div className="auth-logo">
                        <a
                          href="index.html"
                          className="data-tuoi-logo light mb-2"
                        >
                          <img src={Logo} alt="" height="22" />
                          Telegamez.org
                        </a>
                      </div>
                      <p className="text-muted mb-4 mt-3">
                        Nhập email của bạn. Chúng tôi sẽ gửi cho bạn một email
                        với hướng dẫn để đặt lại mật khẩu.
                      </p>
                    </div>

                    <Form
                      onSubmit={() => forgotPasswordAction()}
                      className="dark"
                    >
                      <div className="form-group mb-0">
                        <label>Email</label>
                        <Form.Item
                          validateStatus={email.error}
                          help={email.help}
                          className="mb-0"
                        >
                          <Input
                            className="form-control"
                            placeholder="Nhập email của bạn"
                            value={email.value}
                            onChange={(e) =>
                              setEmail({ ...email, value: e.target.value })
                            }
                            onBlur={() => validateEmailField(email)}
                          />
                        </Form.Item>
                      </div>

                      <div className="form-group mb-0 text-center">
                        <Button
                          className="btn btn-primary btn-block"
                          type="submit"
                          onClick={() => forgotPasswordAction()}
                          loading={btnLoading}
                        >
                          {" "}
                          Gửi yêu cầu{" "}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12 text-center">
                    <p className="text-white-50">
                      Quay lại{" "}
                      <Link to="/dang-nhap" className="text-white ml-1">
                        <b>Đăng nhập</b>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
