import { rest } from "./rest";

export const apiFacebookTracking = {
  getAll: (searchParams) =>
    rest.get("/facebook-tracking", searchParams, null, true),
  create: (postRequest) =>
    rest.post("/facebook-tracking", postRequest, null, true),
  update: (postRequest) =>
    rest.put("/facebook-tracking", postRequest, null, true),
  getDetail: (id) => rest.get(`/facebook-tracking/${id}`, {}, null, true),
  delete: (id) => rest.delete(`/facebook-tracking/${id}`, {}, null, true),
  updateStatus: (post) =>
    rest.put(`/facebook-tracking/${post.id}/status`, post, null, true),
  getComments: (searchParams) =>
    rest.get(
      `/facebook-tracking/${searchParams.facebookTrackingId}/comments`,
      searchParams,
      null,
      true
    ),
  exportExcel: (requestData) =>
    rest.postFile2(
      "/facebook-tracking/comments/export",
      requestData,
      null,
      true,
      { responseType: "blob", observe: "response" }
    ),
};
