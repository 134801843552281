import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Form, Select, Steps } from "antd";

import "./them-chien-dich-modal.scss";

import {
  isHttpsWww,
  isWebUrl,
  validateText,
  isWebUrl2,
} from "utils/utils-validation";
import { showMessage } from "utils/utils-show-message";
import { apiCampaign } from "api/campaign";
import ImageModal from "assets/images/laptop-ui-image.png";
import IconCopy from "assets/icons/icon-copy.svg";
import { parseToken } from "../../utils/utils-parse-token";
import { setAccountRole } from "../../store/account";
import { useDispatch, useSelector } from "react-redux";

const { Option } = Select;
const { Step } = Steps;
export const ThemChienDichModal = ({
  visibleModal,
  onCancel,
  onSuccess,
  userId,
}) => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState({ value: "", error: "", help: "" });
  const [method, setMethod] = useState({ value: "https", error: "", help: "" });
  const [type, setType] = useState({ value: "LIMIT", error: "", help: "" });
  const [btnLoading, setBtnLoading] = useState(false);
  const [stepState, setStepState] = useState(0);
  const [scriptCode, setScriptCode] = useState("");
  const roleIdUser = useSelector((state) => state.account.role);
  const getUserRole = () => {
    const role =
      parseToken(localStorage.getItem("token-datatuoi"))?.role || null;
    dispatch(setAccountRole(role));
  };
  useEffect(() => {
    getUserRole();
  }, []);
  const initModal = () => {
    setUrl({ value: "", error: "", help: "" });
    setMethod({ value: "https", error: "", help: "" });
    setType({ value: "LIMIT", error: "", help: "" });
    setStepState(0);
    setScriptCode("");
    setBtnLoading(false);
  };

  const validateUrlWebsite = (target) => {
    if (!validateText(target.value)) {
      setUrl({
        ...target,
        error: "error",
        help: "Địa chỉ Website không được để trống",
      });
      return false;
    }
    // if (!isWebUrl(target.value)) {
    //   setUrl({
    //     ...target,
    //     error: "error",
    //     help: "Địa chỉ Website không hợp lệ",
    //   });
    //   return false;
    // }
    if (!isWebUrl2(target.value)) {
      setUrl({
        ...target,
        error: "error",
        help: "Địa chỉ Website không đúng định dạng.",
      });
      return false;
    }
    setUrl({ value: target.value, error: "", help: "" });
    return true;
  };

  const copyScriptCode = () => {
    const el = document.createElement("textarea");
    el.value = scriptCode;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    showMessage("success", "Copy mã nhúng thành công");
    onCancel();
    initModal();
  };

  const addChienDich = async () => {
    let res = null;
    // if (stepState === 0) {
    //   setStepState(stepState + 1);
    // } else {
    if (!validateUrlWebsite(url)) {
      return;
    }
    setBtnLoading(true);
    let urlValue = url.value.replace("www.", "");
    if (!urlValue.startsWith("http")) {
      urlValue = `https://${urlValue}`;
    }
    const targetUrl = new URL(`${urlValue}`);
    let typeCampaign = type.value;
    if (["ROOT_ADMIN", "ADMIN"].includes(roleIdUser)) {
      typeCampaign = type.value;
    } else {
      typeCampaign = "LIMIT";
    }
    if (typeCampaign === "LIMIT") {
      res = await apiCampaign.create(`https://${targetUrl.hostname}`, userId);
    } else {
      res = await apiCampaign.createNoLimit(
        `https://${targetUrl.hostname}`,
        userId
      );
      onSuccess();
      onCancel();
      initModal();
    }
    setBtnLoading(false);
    if (res?.status?.code === "SUCCESS") {
      setStepState(stepState + 1);
      setScriptCode(res.data.scriptCode);
      onSuccess();
    } else {
      showMessage("error", res.status.message);
    }
    // }
  };

  return (
    <Modal
      title="Tạo mới chiến dịch"
      footer={null}
      visible={visibleModal}
      wrapClassName="them-chien-dich-modal"
      onCancel={() => {
        onCancel();
        initModal();
      }}
    >
      <div className="modal-image">
        <img src={ImageModal} alt="" />
      </div>
      <Steps className="mt-2" progressDot size="small" current={stepState}>
        {/*<Step title="Chọn giao thức" />*/}
        <Step title="Nhập địa chỉ website" />
        <Step title="Gắn mã nhúng" />
      </Steps>
      <Form className="modal-form dark mt-3 mb-2">
        {/*{stepState === 0 && (*/}
        {/*  <div className="form-group select-giao-thuc">*/}
        {/*    <Form.Item validateStatus={""} help={""}>*/}
        {/*      <Select*/}
        {/*        value={method.value}*/}
        {/*        onChange={(value) => setMethod({ ...method, value })}*/}
        {/*      >*/}
        {/*        <Option value="https">https</Option>*/}
        {/*        <Option value="http">http</Option>*/}
        {/*      </Select>*/}
        {/*    </Form.Item>*/}
        {/*  </div>*/}
        {/*)}*/}
        {stepState === 0 && (
          <div className="form-group input-web-url">
            <Form.Item validateStatus={url.error} help={url.help}>
              <Input
                className="form-control"
                placeholder="Địa chỉ website"
                value={url.value}
                onChange={(e) => setUrl({ ...url, value: e.target.value })}
                onBlur={() => validateUrlWebsite(url)}
              />
            </Form.Item>
            <Form.Item validateStatus="" help="">
              <Select
                value={type.value}
                onChange={(value) => setType({ ...type, value })}
              >
                <Option value="LIMIT">Theo gói</Option>
                {["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) && (
                  <Option value="NO_LIMIT">Không giới hạn</Option>
                )}
              </Select>
            </Form.Item>
          </div>
        )}
        {stepState === 1 && (
          <div className="get-code-wrap position-relative">
            <Button
              className="btn btn-success btn-block d-flex align-items-center copy-btn"
              onClick={() => copyScriptCode()}
            >
              <img src={IconCopy} alt="" />
              Copy
            </Button>
            <div className="form-control script-code">{scriptCode}</div>
          </div>
        )}
      </Form>
      <div className="btn-step-group d-flex justify-content-center">
        {/*{stepState === 0 && (*/}
        {/*  <Button*/}
        {/*    className="btn btn-primary btn-block"*/}
        {/*    type="submit"*/}
        {/*    onClick={() => setStepState(stepState - 1)}*/}
        {/*  >*/}
        {/*    {" "}*/}
        {/*    Quay lại{" "}*/}
        {/*  </Button>*/}
        {/*)}*/}
        {stepState !== 1 && (
          <Button
            className="btn btn-success btn-block"
            type="submit"
            loading={btnLoading}
            onClick={() => addChienDich()}
          >
            {" "}
            Tiếp tục{" "}
          </Button>
        )}
        {stepState === 1 && (
          <Button
            className="btn btn-success btn-block"
            type="submit"
            onClick={() => {
              onCancel();
              initModal();
            }}
          >
            {" "}
            Hoàn thành{" "}
          </Button>
        )}
      </div>
    </Modal>
  );
};
