import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Form, Select, Steps } from "antd";

import "./them-danh-ba-modal.scss";

import {
  isHttpsWww,
  isWebUrl,
  validateText,
  validateVietnamPhoneNumber,
} from "utils/utils-validation";
import { showMessage } from "utils/utils-show-message";
import { apiContact } from "api/contact";
import { parseToken } from "../../utils/utils-parse-token";
import { setAccountRole } from "../../store/account";
import { useDispatch, useSelector } from "react-redux";

export const ThemDanhBaModal = ({
  visibleModal,
  isCreate,
  contact,
  onCancel,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState({ value: "", error: "", help: "" });
  const [phone, setPhone] = useState({ value: "", error: "", help: "" });
  const [zalo, setZalo] = useState({ value: "", error: "", help: "" });
  const [website, setWebsite] = useState({ value: "", error: "", help: "" });
  const [position, setPosition] = useState({ value: "", error: "", help: "" });
  const [company, setCompany] = useState({ value: "", error: "", help: "" });
  const [work, setWork] = useState({ value: "", error: "", help: "" });
  const [dataSource, setDataSource] = useState({
    value: "",
    error: "",
    help: "",
  });
  const [status, setStatus] = useState({ value: "", error: "", help: "" });
  const [note, setNote] = useState({ value: "", error: "", help: "" });
  const [location, setLocation] = useState({ value: "", error: "", help: "" });
  const [accountId, setAccountId] = useState({
    value: "",
    error: "",
    help: "",
  });

  const [btnLoading, setBtnLoading] = useState(false);
  const roleIdUser = useSelector((state) => state.account.role);
  const getUserRole = () => {
    const role =
      parseToken(localStorage.getItem("token-datatuoi"))?.role || null;
    dispatch(setAccountRole(role));
  };
  useEffect(() => {
    getUserRole();
  }, []);
  const initModal = () => {
    setFullName({ value: "", error: "", help: "" });
    setPhone({ value: "", error: "", help: "" });
    setZalo({ value: "", error: "", help: "" });
    setWebsite({ value: "", error: "", help: "" });
    setPosition({ value: "", error: "", help: "" });
    setCompany({ value: "", error: "", help: "" });
    setWork({ value: "", error: "", help: "" });
    setDataSource({ value: "", error: "", help: "" });
    setStatus({ value: "", error: "", help: "" });
    setNote({ value: "", error: "", help: "" });
    setLocation({ value: "", error: "", help: "" });
    setAccountId({ value: "", error: "", help: "" });
    if (!isCreate && contact) {
      setFullName({ value: contact.fullName, error: "", help: "" });
      setPhone({ value: contact.phone, error: "", help: "" });
      setZalo({ value: contact.zalo, error: "", help: "" });
      setWebsite({ value: contact.website, error: "", help: "" });
      setPosition({ value: contact.position, error: "", help: "" });
      setCompany({ value: contact.company, error: "", help: "" });
      setWork({ value: contact.work, error: "", help: "" });
      setDataSource({ value: contact.dataSource, error: "", help: "" });
      setStatus({ value: contact.status, error: "", help: "" });
      setNote({ value: contact.note, error: "", help: "" });
      setLocation({ value: contact.location, error: "", help: "" });
      setAccountId({ value: contact.accountId, error: "", help: "" });
    }
    setBtnLoading(false);
  };
  useEffect(() => {
    initModal();
  }, [isCreate]);

  const validateFullNameField = (target) => {
    if (!validateText(target.value)) {
      setFullName({
        ...target,
        error: "error",
        help: "Vui lòng nhập tên khách hàng",
      });
      return false;
    }
    setFullName({ value: target.value, error: "", help: "" });
    return true;
  };

  const validatePhoneField = (target) => {
    if (!validateText(target.value)) {
      setPhone({
        ...target,
        error: "error",
        help: "Vui lòng nhập số điện thoại",
      });
      return false;
    }
    if (!validateVietnamPhoneNumber(target.value)) {
      setPhone({
        ...target,
        error: "error",
        help: "Số điện thoại sai định dạng",
      });
      return false;
    }
    setPhone({ value: target.value, error: "", help: "" });
    return true;
  };

  const validateUrlWebsite = (target) => {
    // if (!isWebUrl(target.value)) {
    //   setUrl({
    //     ...target,
    //     error: "error",
    //     help: "Địa chỉ Website không hợp lệ",
    //   });
    //   return false;
    // }
    if (target.value && !isHttpsWww(target.value)) {
      setWebsite({
        ...target,
        error: "error",
        help: "Địa chỉ Website không bao gồm https, http và www",
      });
      return false;
    }
    setWebsite({ value: target.value, error: "", help: "" });
    return true;
  };

  const saveOrUpdateContact = async () => {
    if (
      !validateFullNameField(fullName) ||
      !validateUrlWebsite(website) ||
      !validatePhoneField(phone)
    ) {
      return;
    }
    setBtnLoading(true);
    const body = {
      fullName: fullName.value,
      phone: phone.value,
      company: company.value,
      position: position.value,
      work: work.value,
      location: location.value,
      zalo: zalo.value,
      website: website.value,
      dataSource: dataSource.value,
      status: status.value,
      note: note.value,
    };
    let res;
    if (isCreate) {
      res = await apiContact.create(body);
    } else {
      body.id = contact.id;
      res = await apiContact.update(body);
    }
    setBtnLoading(false);
    if (res?.status?.code === "SUCCESS") {
      showMessage(
        "success",
        isCreate
          ? "Thêm mới danh bạ thành công!"
          : "Cập nhật danh bạ thành công!"
      );
      onCancel();
      onSuccess();
      initModal();
    } else {
      showMessage("error", res.status.message);
    }
  };

  return (
    <Modal
      title={isCreate ? "Tạo mới danh bạ" : "Cập nhật"}
      footer={null}
      visible={visibleModal}
      wrapClassName="add-contact-modal"
      onCancel={() => {
        onCancel();
        initModal();
      }}
    >
      <Form className="modal-form dark mt-3 mb-2">
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Tên khách hàng</label>
              <Form.Item validateStatus={fullName.error} help={fullName.help}>
                <Input
                  className="form-control"
                  placeholder="Nhập tên khách hàng"
                  onChange={(e) =>
                    setFullName({ ...fullName, value: e.target.value })
                  }
                  value={fullName.value}
                  onBlur={() => validateFullNameField(fullName)}
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Số điện thoại</label>
              <Form.Item validateStatus={phone.error} help={phone.help}>
                <Input
                  className="form-control"
                  placeholder="Nhập số điện thoại"
                  onChange={(e) =>
                    setPhone({ ...phone, value: e.target.value })
                  }
                  value={phone.value}
                  onBlur={() => validatePhoneField(phone)}
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Công ty</label>
              <Form.Item validateStatus={company.error} help={company.help}>
                <Input
                  className="form-control"
                  placeholder="Nhập công ty"
                  onChange={(e) =>
                    setCompany({ ...company, value: e.target.value })
                  }
                  value={company.value}
                  // onBlur={() => validatePhoneField(phone)}
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Lĩnh vực hoạt động</label>
              <Form.Item validateStatus={work.error} help={work.help}>
                <Input
                  className="form-control"
                  placeholder="Nhập công việc"
                  onChange={(e) => setWork({ ...work, value: e.target.value })}
                  value={work.value}
                  // onBlur={() => validatePhoneField(phone)}
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Chức vụ</label>
              <Form.Item validateStatus={position.error} help={position.help}>
                <Input
                  className="form-control"
                  placeholder="Nhập chức vụ"
                  onChange={(e) =>
                    setPosition({ ...position, value: e.target.value })
                  }
                  value={position.value}
                  // onBlur={() => validatePhoneField(phone)}
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Tỉnh/Thành phố</label>
              <Form.Item validateStatus={location.error} help={location.help}>
                <Input
                  className="form-control"
                  placeholder="Nhập tỉnh/ thành phố"
                  onChange={(e) =>
                    setLocation({ ...location, value: e.target.value })
                  }
                  value={location.value}
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Zalo</label>
              <Form.Item validateStatus={zalo.error} help={zalo.help}>
                <Input
                  className="form-control"
                  placeholder="Nhập zalo"
                  onChange={(e) => setZalo({ ...zalo, value: e.target.value })}
                  value={zalo.value}
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Website</label>
              <Form.Item validateStatus={website.error} help={website.help}>
                <Input
                  className="form-control"
                  placeholder="Nhập công việc"
                  onChange={(e) =>
                    setWebsite({ ...website, value: e.target.value })
                  }
                  value={website.value}
                  onBlur={() => validateUrlWebsite(website)}
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Nguồn khách hàng</label>
              <Form.Item
                validateStatus={dataSource.error}
                help={dataSource.help}
              >
                <Input
                  className="form-control"
                  placeholder="Nhập nguồn khách hàng"
                  onChange={(e) =>
                    setDataSource({ ...dataSource, value: e.target.value })
                  }
                  value={dataSource.value}
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Trạng thái</label>
              <Form.Item validateStatus={status.error} help={status.help}>
                <Input
                  className="form-control"
                  placeholder="Nhập trạng thái"
                  onChange={(e) =>
                    setStatus({ ...status, value: e.target.value })
                  }
                  value={status.value}
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-12">
            <div className="form-group">
              <label>Ghi chú</label>
              <Form.Item validateStatus={note.error} help={note.help}>
                <Input
                  className="form-control"
                  placeholder="Nhập ghi chú"
                  onChange={(e) => setNote({ ...note, value: e.target.value })}
                  value={note.value}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
      <div className="btn-step-group d-flex justify-content-end">
        <Button
          className="btn btn-primary btn-block"
          type="button"
          onClick={() => {
            onCancel();
            initModal();
          }}
        >
          {" "}
          Hủy{" "}
        </Button>
        <Button
          className="btn btn-success btn-block"
          loading={btnLoading}
          type="submit"
          onClick={() => saveOrUpdateContact()}
        >
          {" "}
          {isCreate ? "Tạo mới" : "Cập nhật"}{" "}
        </Button>
      </div>
    </Modal>
  );
};
