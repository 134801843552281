const initialState = {
  emailRegister: "",
};

export const auth = (state = initialState, payload) => {
  const { type } = payload;
  switch (type) {
    case "SET_EMAIL_REGISTER":
      return (state = {
        ...state,
        emailRegister: payload.value,
      });
    default:
      return state;
  }
};

export const setEmailRegister = (value) => {
  return {
    type: "SET_EMAIL_REGISTER",
    value,
  };
};
