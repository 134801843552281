import React, { useEffect, useState } from "react";
import { DatePicker, Form, Pagination, Select, Table, Tooltip } from "antd";
import "./facebook-comment-tracking.scss";
import { apiFacebookTracking } from "api/facebook-tracking";
import { useSelector } from "react-redux";
import IconPhoneCall from "assets/icons/icon-phone-call.svg";
import { showMessage } from "utils/utils-show-message";
import { ModalConfirm } from "../../components/modal-confirm/modal-confirm";
import { Link, useParams } from "react-router-dom";
import IconLeftArrow from "../../assets/icons/icon-left-arrow.svg";
import { AddHistoryContactModal } from "../../components/add-history-contact-modal/add-history-contact-modal";
import {
  STATUS_ACCEPTED,
  STATUS_CONFIRMED,
  STATUS_ENDED,
  STATUS_FAILED,
  STATUS_PROGRESS,
  UtilsPhoneNet,
} from "../../utils/utils-phonenet";
import { CallModal } from "../../components/call-modal/call-modal";
import IconRefresh from "../../assets/icons/icon-refresh.svg";
import IconFilter from "../../assets/icons/icon-filter.svg";
import { InputAutoComplete } from "../../components/input-autocomplete/input-autocomplete";

const { Option } = Select;

import moment from "moment";
import * as FileSaver from "file-saver";
import IconExcel from "../../assets/icons/icon-excel.svg";

export const FacebookCommentTracking = () => {
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [visibleModalDeleteContact, setVisibleModalDeleteContact] = useState(
    false
  );
  // const [visibleModalUpdate, setVisibleModalUpdate] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [modeCreate, setModeCreate] = useState(true);

  const [sourceId, setSourceId] = useState("");
  const [phone, setPhone] = useState("");
  const [sourceName, setSourceName] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(10);

  const [dataComment, setDataComment] = useState([]);
  const [loading, setLoading] = useState(false);

  const [contactHistory, setContactHistory] = useState(null);

  const roleIdUser = useSelector((state) => state.account.role);

  const [phonenet, setPhonenet] = useState(false);
  const [visibleCallModal, setVisibleCallModal] = useState(false);
  const [statusCall, setStatusCall] = useState(null);
  const [time, setTime] = useState("00:00");
  const [intervalCount, setIntervalCount] = useState(null);
  const [isActiveButtonCall, setIsActiveButtonCall] = useState(false);

  const [isFilter, setIsFilter] = useState(false);
  const [searchMethod, setSearchMethod] = useState("sourceId");
  const [suggestionSourceId, setSuggestionSourceId] = useState([]);
  const [suggestionName, setSuggestionName] = useState([]);
  const [suggestionPhone, setSuggestionPhone] = useState([]);

  const [btnLoading, setBtnLoading] = useState(false);

  const userName = useSelector((state) => state.account.account?.userName);
  const stateCallio = useSelector(
    (state) => state.account.account?.stateCallio
  );
  const passExt = useSelector((state) => state.account.account?.passExt);
  const extCallio = useSelector((state) => state.account.account?.extCallio);

  const facebookTrackingId = useParams().id;

  /*const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    // getCheckboxProps: (record: any) => ({
    //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
    //   name: record.name,
    // }),
  };*/

  const columns = [
    {
      title: "Facebook Name",
      dataIndex: "sourceId",
      key: "sourceId",
      width: 200,
      render: (value, row) => {
        const url = `https://facebook.com/${value}`;
        return (
          <a href={url} target="_blank">
            {" "}
            {row.sourceName}
          </a>
        );
      },
    },
    {
      title: "Số điên thoại",
      dataIndex: "phone",
      key: "phone",
      className: "width-150-px-in-1200-column",
      ellipsis: true,
    },
    {
      title: "Thời gian",
      dataIndex: "commentDate",
      key: "commentDate",
      className: "width-150-px-in-1200-column",
      ellipsis: true,
      render: (value) =>
        value ? moment(value * 1000).format("DD/MM/YYYY HH:mm:ss") : "",
    },
    {
      title: "Comment",
      dataIndex: "description",
      key: "description",
      className: "width-200-px-in-1200-column",
      ellipsis: true,
      render: (value, row) => (
        <a href={row.url} target={"_blank"}>
          {value}
        </a>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (value, row) => (
        <div className="row">
          {row.phone && isActiveButtonCall && (
            <Tooltip placement="top" title="Gọi điện">
              <button
                type="button"
                className="btn btn-md btn-rounded btn-success waves-effect waves-light d-flex align-items-center btn-icon-edit action"
                onClick={() => handleCall(row)}
              >
                <img src={IconPhoneCall} width="12" alt="" />
              </button>
            </Tooltip>
          )}
          {/*<Tooltip placement="top" title="Xóa">*/}
          {/*  <button*/}
          {/*    type="button"*/}
          {/*    className="btn btn-md btn-rounded btn-danger waves-effect waves-light d-flex align-items-center ml-2 btn-icon-delete action"*/}
          {/*    onClick={() => openConfirmDelete(row)}*/}
          {/*  >*/}
          {/*    <img src={IconCancel} width="12" alt="" />*/}
          {/*  </button>*/}
          {/*</Tooltip>*/}
        </div>
      ),
    },
  ];

  useEffect(async () => {
    await getAllSuggestion();
  }, []);

  useEffect(async () => {
    getAllSuggestion();
  }, [facebookTrackingId, searchMethod]);

  const getAllSuggestion = async () => {
    const res = await apiFacebookTracking.getComments({
      facebookTrackingId,
      pageIndex: 0,
      pageSize: 100,
    });
    if (res?.status?.code === "SUCCESS") {
      const data = res.data;
      const suggestionSourceId = [];
      const suggestionName = [];
      const suggestionPhone = [];
      data.forEach((item) => {
        suggestionSourceId.push(item.sourceId);
        suggestionName.push(item.sourceName);
        if (item.phone) {
          suggestionPhone.push(item.phone);
        }
      });
      setSuggestionSourceId(suggestionSourceId);
      setSuggestionName(suggestionName);
      setSuggestionPhone(suggestionPhone);
    }
  };

  useEffect(() => {
    if (stateCallio !== "active") {
      return;
    }
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        const phonenet = new UtilsPhoneNet(`${extCallio}`, passExt);
        phonenet.login(
          (e) => {
            setPhonenet(phonenet);
            setIsActiveButtonCall(true);
            // showMessage('success', 'Kết nối tổng đài thành công')
          },
          (e) => console.log(e)
        );
      })
      .catch((e) => {
        showMessage(
          "error",
          "Máy tính không có mic hoặc không được cấp quyền dùng mic!"
        );
      });
  }, [stateCallio]);

  const handleCall = async (row) => {
    const { phone } = row;
    phonenet.makeACall(
      phone.toString().startsWith("84") ? phone.replace("84", "0") : phone,
      onProgress,
      onAccepted,
      onConfirmed,
      onEnded,
      onFailed
    );
  };

  const onProgress = () => {
    setStatusCall(STATUS_PROGRESS);
    setVisibleCallModal(true);
  };

  const onAccepted = () => {
    setStatusCall(STATUS_ACCEPTED);
  };

  const onConfirmed = () => {
    setStatusCall(STATUS_CONFIRMED);
    let minute = 0,
      second = 0;
    const timeInterval = setInterval(() => {
      ++second;
      if (second === 59) {
        second = 0;
        ++minute;
      }
      setTime(
        `${minute.toString().padStart(2, "0")}:${second
          .toString()
          .padStart(2, "0")}`
      );
    }, 1000);
    setIntervalCount(timeInterval);
  };

  useEffect(() => {
    if (statusCall === STATUS_ENDED) {
      clearInterval(intervalCount);
      // setStatusCall(null);
    }
  }, [intervalCount, statusCall]);

  const onEnded = () => {
    setStatusCall(STATUS_ENDED);
    // setTimeout(() => setVisibleCallModal(false), 1000);
  };

  const onFailed = (e) => {
    setStatusCall(STATUS_FAILED);
    // setTimeout(() => setVisibleCallModal(false), 1000);
  };

  const cancelCall = () => {
    setVisibleCallModal(false);
    setTime("00:00");
    phonenet.cancelCall();
  };

  const openConfirmDelete = async (row) => {
    setContactHistory(row);
    setVisibleModalDelete(true);
  };

  const deleteContact = async () => {
    // const res = await apiFacebookTracking.delete(facebookTrackingId);
    // if (res?.status?.code === "SUCCESS") {
    //   showMessage("success", "Xóa lịch sử chăm sóc khách hàng thành công");
    //   setVisibleModalDeleteContact(false);
    //   history.back();
    // }
  };

  const deleteContactHistory = async (contactHistory) => {
    // const res = await apiFacebookTracking.deleteCareHistoryOfContact(
    //   facebookTrackingId,
    //   contactHistory.id
    // );
    // if (res?.status?.code === "SUCCESS") {
    //   showMessage("success", "Xóa lịch sử chăm sóc khách hàng thành công");
    //   setVisibleModalDelete(false);
    //   await getAllContactHistory();
    // }
  };

  useEffect(() => {
    setPageIndex(1);
    getAllComment();
  }, [facebookTrackingId, sourceId, sourceName, phone, searchMethod]);

  useEffect(() => {
    getAllComment();
  }, [pageSize, pageIndex]);

  const getAllComment = async () => {
    checkIsFilter();
    setLoading(true);
    const res = await apiFacebookTracking.getComments({
      facebookTrackingId,
      sourceId,
      sourceName,
      phone,
      pageIndex: pageIndex - 1,
      pageSize,
    });
    setLoading(false);
    if (res?.status?.code === "SUCCESS") {
      const data = res.data;
      data.forEach(
        (item, index) =>
          (item.rowIndex = (pageIndex - 1) * pageSize + index + 1)
      );
      setDataComment(data);
      setTotalPage(res.totalItem);
    }
  };

  const resetSearchValue = () => {
    setSourceName("");
    setSourceId("");
    setPhone("");
  };

  const deleteFilter = () => {
    resetSearchValue();
    setIsFilter(false);
  };

  const checkIsFilter = () => {
    if (phone !== "" || sourceName !== "" || sourceId !== "") {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  };

  const onSearch = (value) => {
    setPageIndex(1);
    if (searchMethod === "sourceId") {
      setSourceName("");
      setSourceId(value);
      setPhone("");
    }
    if (searchMethod === "name") {
      setSourceId("");
      setPhone("");
      setSourceName(value);
    }
    if (searchMethod === "phone") {
      setSourceName("");
      setSourceId("");
      setPhone(value);
    }
  };

  const renderSuggestion = () => {
    switch (searchMethod) {
      case "sourceId":
        return suggestionSourceId;
      case "name":
        return suggestionName;
      case "phone":
        return suggestionPhone;
      default:
        return [];
    }
  };

  const renderPlaceholder = () => {
    switch (searchMethod) {
      case "sourceId":
        return "Nhập id facebook";
      case "phone":
        return "Nhập phone cần tìm";
      case "name":
        return "Nhập Tên cần tìm";
      default:
        return "";
    }
  };

  const exportExcel = async () => {
    setBtnLoading(true);
    const res = await apiFacebookTracking.exportExcel({
      facebookTrackingId,
      sourceId,
      sourceName,
      phone,
    });
    const responseContent = JSON.parse(
      decodeURIComponent(res.headers["content-response"])
    );
    const fileName = res.headers["content-disposition"].split("=")[1];
    if (responseContent.code === "SUCCESS") {
      showMessage("success", responseContent.message);
    } else {
      showMessage("error", responseContent.message);
    }
    setBtnLoading(false);
    FileSaver.saveAs(res.data, fileName);
  };

  return (
    <div className="contact-detail-page position-relative min-height-100vh">
      <CallModal
        visibleModal={visibleCallModal}
        status={statusCall}
        time={time}
        onCancel={() => setVisibleCallModal(false)}
        onCancelCall={() => cancelCall()}
      />
      <AddHistoryContactModal
        isCreate={modeCreate}
        contactId={facebookTrackingId}
        contactHistory={contactHistory}
        visibleModal={visibleModal}
        onCancel={() => {
          setContactHistory(null);
          setVisibleModal(false);
          setModeCreate(true);
        }}
        onSuccess={() => getAllComment()}
      />
      <ModalConfirm
        title={"Xóa lịch sử chăm sóc"}
        messageConfirm={"Bạn có chắc chắn muốn xóa lịch sử chăm sóc này không?"}
        visibleModal={visibleModalDelete}
        onCancel={() => setVisibleModalDelete(false)}
        onOk={() => deleteContactHistory(contactHistory)}
      />
      <ModalConfirm
        title={"Xóa danh bạ"}
        messageConfirm={"Bạn có chắc chắn muốn xóa danh bạ này không?"}
        visibleModal={visibleModalDeleteContact}
        onCancel={() => setVisibleModalDeleteContact(false)}
        onOk={() => deleteContact()}
      />
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <Form className="form-inline">
                  <Tooltip placement="top" title={"Quay lại"}>
                    <Link to={"/facebook-tracking"}>
                      <button className="btn btn-secondary btn-sm ml-2">
                        <img src={IconLeftArrow} alt="" width="11" />
                      </button>
                    </Link>
                  </Tooltip>
                  <Tooltip placement="top" title={"Làm mới"}>
                    <button
                      className="btn btn-secondary btn-sm ml-2"
                      onClick={async () => {
                        resetSearchValue();
                        await getAllComment();
                      }}
                    >
                      <img src={IconRefresh} alt="" width="11" />
                    </button>
                  </Tooltip>
                </Form>
              </div>
              <h4 className="page-title">
                <span>Facebook tracking</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card-box">
              <h4 className="header-title d-flex align-items-center flex-wrap justify-content-between">
                <span className="mb-2">Danh sách bình luận</span>
                <div className="d-flex flex-wrap align-items-center">
                  {isFilter && (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger waves-effect waves-light mb-2 mr-2 d-flex align-items-center"
                      onClick={() => deleteFilter()}
                    >
                      <img
                        src={IconFilter}
                        width="12"
                        className="mr-1"
                        alt=""
                      />{" "}
                      Xoá bộ lọc
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-sm btn-success waves-effect waves-light d-flex align-items-center mb-2"
                    loading={btnLoading}
                    onClick={() => exportExcel()}
                  >
                    <img src={IconExcel} width="12" className="mr-1" alt="" />{" "}
                    Xuất Excel
                  </button>
                </div>
              </h4>

              <div className="table-tools d-flex align-items-center justify-content-between mb-3">
                <div className="tool-item filter d-flex align-items-center">
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                    Kiểu tìm kiếm
                    <Select
                      value={searchMethod}
                      onSelect={(value) => {
                        resetSearchValue();
                        setSearchMethod(value);
                      }}
                    >
                      <Option value="sourceId">Id facebook</Option>
                      <Option value="name">Tên tài khoản</Option>
                      <Option value="phone">Số điện thoại</Option>
                    </Select>
                  </div>
                  <div className="tool-item search d-flex align-items-center text-nowrap">
                    Tìm kiếm:
                    <InputAutoComplete
                      isFilter={isFilter}
                      dataSource={renderSuggestion()}
                      placeholder={renderPlaceholder()}
                      onSearch={(value) => onSearch(value)}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <Table
                    loading={loading}
                    className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                    dataSource={dataComment}
                    columns={columns}
                    pagination={false}
                  />

                  {dataComment.length !== 0 && (
                    <div className="table-panigate d-flex align-items-center justify-content-between mt-3">
                      <div className="dataTables_info">
                        Trang {pageIndex} trong tổng số{" "}
                        {Math.ceil(totalPage / pageSize)} trang
                      </div>
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="tool-item select d-flex align-items-center pagesize-select">
                          Hiển thị
                          <Select
                            value={pageSize}
                            onSelect={(value) => setPageSize(value)}
                          >
                            <Option value="10">10</Option>
                            <Option value="30">30</Option>
                            <Option value="50">50</Option>
                            <Option value="70">70</Option>
                            <Option value="100">100</Option>
                          </Select>
                          dữ liệu
                        </div>
                        <div className="table-pagination">
                          <Pagination
                            current={pageIndex}
                            pageSize={pageSize}
                            total={totalPage}
                            showSizeChanger={false}
                            onChange={(page) => setPageIndex(page)}
                            hideOnSinglePage={true}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
