import React, { useEffect, useState } from "react";
import "./input-autocomplete.scss";
import { Input, Select } from "antd";

const { Option } = Select;

export const InputAutoComplete = ({
  placeholder,
  onSearch,
  dataSource,
  isFilter,
  bindLabel,
  bindValue,
}) => {
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [value, setValue] = useState("");
  const [inputValue, setInputLabel] = useState("");
  const [select, setSelect] = useState("");
  const [dataSearch, setDataSearch] = useState([]);

  const onDropdownVisibleChange = (open) => {
    setVisibleDropdown(open);
  };

  const onSelect = (value) => {
    setValue(value);
    setInputLabel(
      dataSearch.filter((item) => item[bindValue] === value)[0][bindLabel]
    );
    setSelect(value);
    onSearch(value);
  };
  useEffect(() => {
    setVisibleDropdown(false);
  }, [select]);

  useEffect(() => {
    setDataSearch([]);
    changeValueFilter(inputValue);
  }, [dataSource]);

  useEffect(() => {
    setValue("");
  }, [placeholder]);

  useEffect(() => {
    if (!isFilter) setValue("");
  }, [isFilter]);

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const changeValueFilter = (label) => {
    setInputLabel(label);
    const currentDataSearch = [];
    dataSource.forEach((item, index) => {
      if (item[bindLabel]?.includes(label)) currentDataSearch.push(item);
    });
    setDataSearch(currentDataSearch.filter(onlyUnique));
  };

  return (
    <div
      className="input-autocomplete"
      onClick={() => setVisibleDropdown(true)}
    >
      <Input
        value={inputValue}
        className="form-control"
        placeholder={placeholder}
        onPressEnter={() => changeValueFilter(inputValue)}
        onChange={(e) => changeValueFilter(e.target.value)}
      />
      <Select
        open={visibleDropdown}
        onDropdownVisibleChange={(open) => onDropdownVisibleChange(open)}
        onSelect={(value) => onSelect(value)}
      >
        {dataSearch.map((item, index) => (
          <Option key={index} value={item[bindValue]}>
            {item[bindLabel]}
          </Option>
        ))}
      </Select>
    </div>
  );
};
