import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Form, Select, Checkbox } from "antd";
import { apiAccount } from "api/account";

import "./them-user-modal.scss";
import ImageModal from "assets/images/image-user-modal.png";
import { showMessage } from "utils/utils-show-message";
import {
  validateText,
  validateEmail,
  validateVietnamPhoneNumber,
  validateTextLength,
} from "utils/utils-validation";
import { useSelector } from "react-redux";

const { Option } = Select;

export const ThemUserModal = ({
  visibleModal,
  onCancel,
  onSuccess,
  user,
  userNameTarget,
  type,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [userName, setUserName] = useState({ value: "", error: "", help: "" });
  const [email, setEmail] = useState({ value: "", error: "", help: "" });
  const [phone, setPhone] = useState({ value: "", error: "", help: "" });
  const [telegramId, setTelegramId] = useState({
    value: "",
    error: "",
    help: "",
  });
  const [name, setName] = useState({ value: "", error: "", help: "" });
  const [password, setPassword] = useState({ value: "", error: "", help: "" });
  const [roleId, setRoleId] = useState({ value: "", error: "", help: "" });
  const [providerId, setProviderId] = useState({
    value: null,
    error: "",
    help: "",
  });
  const [receiptByEmail, setReceiptByEmail] = useState(false);
  const roleIdUser = useSelector((state) => state.account.role);

  const initModal = () => {
    setUserName({ value: user.username, error: "", help: "" });
    setEmail({ value: user.email, error: "", help: "" });
    setPhone({ value: user.uid, error: "", help: "" });
    setName({ value: user.name, error: "", help: "" });
    setTelegramId({
      value: [-1, 0, 1].includes(user.telegramId) ? "" : user.telegramId,
      error: "",
      help: "",
    });
    setRoleId({ value: 4, error: "", help: "" });
    setProviderId({ value: user.providerId, error: "", help: "" });
    setReceiptByEmail(user.receiptByEmail === true);
  };

  useEffect(() => {
    if (visibleModal) {
      initModal();
    }
  }, [visibleModal]);

  const closeModal = () => {
    setBtnLoading(false);
    setUserName({ value: "", error: "", help: "" });
    setEmail({ value: "", error: "", help: "" });
    setPhone({ value: "", error: "", help: "" });
    setTelegramId({ value: "", error: "", help: "" });
    setName({ value: "", error: "", help: "" });
    setPassword({ value: "", error: "", help: "" });
    setRoleId({ value: "", error: "", help: "" });
    setProviderId({ value: null, error: "", help: "" });
    setReceiptByEmail(false);
  };

  const validateUserNameField = (target) => {
    if (!validateText(target.value)) {
      setUserName({
        ...target,
        error: "error",
        help: "Vui lòng nhập tên tài khoản",
      });
      return false;
    }
    setUserName({ value: target.value, error: "", help: "" });
    return true;
  };

  const validateNameField = (target) => {
    if (!validateText(target.value)) {
      setName({ ...target, error: "error", help: "Vui lòng nhập đầy đủ tên" });
      return false;
    }
    setName({ value: target.value, error: "", help: "" });
    return true;
  };

  const validatePasswordField = (target) => {
    if (!validateTextLength(target.value, 8)) {
      setPassword({
        ...target,
        error: "error",
        help: "Mật khẩu phải lớn hơn hoặc bằng 8 ký tự",
      });
      return false;
    }
    setPassword({ value: target.value, error: "", help: "" });
    return true;
  };

  const validatePhoneField = (target) => {
    if (!validateVietnamPhoneNumber(target.value)) {
      setPhone({
        ...target,
        error: "error",
        help: "Số điện thoại không hợp lệ",
      });
      return false;
    }
    setPhone({ value: target.value, error: "", help: "" });
    return true;
  };

  const validateEmailField = (target) => {
    if (!validateEmail(target.value)) {
      setEmail({ ...target, error: "error", help: "Email không hợp lệ" });
      return false;
    }
    setEmail({ value: target.value, error: "", help: "" });
    return true;
  };

  const updateUser = async () => {
    if (
      validateUserNameField(userName) &&
      validatePhoneField(phone) &&
      validateEmailField(email)
    ) {
      setBtnLoading(true);

      const res = await apiAccount.updateUser(
        user.id,
        userName.value,
        email.value,
        phone.value,
        telegramId.value ? telegramId.value : 0,
        name.value,
        undefined,
        receiptByEmail,
        ["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) ? providerId.value : null
      );
      setBtnLoading(false);
      if (res?.status?.code === "SUCCESS") {
        showMessage("success", "Cập nhật thông tin thành công");
        closeModal();
        onCancel();
        onSuccess();
      } else if (res?.status?.code) {
        showMessage("error", res.status.message);
      }
    }
  };

  const updateUserRole = async () => {
    setBtnLoading(true);

    const res = await apiAccount.updateUserRole(user.id, [roleId.value]);
    setBtnLoading(false);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Cập nhật phân quyền thành công");
      closeModal();
      onCancel();
      onSuccess();
    }
  };

  const createUser = async () => {
    if (
      validateUserNameField(userName) &&
      validateNameField(name) &&
      validateEmailField(email) &&
      validatePasswordField(password) &&
      validatePhoneField(phone)
    ) {
      setBtnLoading(true);
      const res = await apiAccount.createUser(
        name.value,
        userName.value,
        password.value,
        email.value,
        phone.value,
        [roleId.value],
        receiptByEmail,
        ["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) ? providerId.value : null
      );
      if (res?.status?.code === "SUCCESS") {
        activeUser(res.data.id);
      } else {
        setBtnLoading(false);
        showMessage("error", res.status.message);
      }
    }
  };

  const activeUser = async (id) => {
    const res = await apiAccount.activeUser(id);
    setBtnLoading(false);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Tạo mới User thành công");
      closeModal();
      onCancel();
      onSuccess();
    } else {
      showMessage("error", res.status.message);
    }
  };

  return (
    <Modal
      title={
        type === "create"
          ? "Tạo mới User"
          : type === "edit"
          ? "Cập nhật thông tin"
          : "Phân quyền người dùng"
      }
      centered
      footer={null}
      visible={visibleModal}
      wrapClassName="them-chien-dich-modal them-don-hang-modal them-user-modal"
      onCancel={() => {
        closeModal();
        onCancel();
      }}
    >
      <div className="modal-image">
        <img src={ImageModal} alt="" />
      </div>
      {type === "edit" && (
        <p className="text-center">
          Thao tác này sẽ sửa thông tin cho người dùng{" "}
          <strong>{userNameTarget}</strong>
        </p>
      )}
      {type === "role" && (
        <p className="text-center">
          Thao tác này sẽ thay đổi quyền của người dùng{" "}
          <strong>{userNameTarget}</strong>
        </p>
      )}
      <Form className="dark">
        <div className="row">
          {(type === "create" || type === "edit") && (
            <div className="col-6">
              <div className="form-group">
                <label>Tài khoản</label>
                <Form.Item validateStatus={userName.error} help={userName.help}>
                  <Input
                    className="form-control"
                    placeholder="Nhập Username"
                    onChange={(e) =>
                      setUserName({ ...userName, value: e.target.value })
                    }
                    value={userName.value}
                    onBlur={() => validateUserNameField(userName)}
                  />
                </Form.Item>
              </div>
            </div>
          )}
          {(type === "create" || type === "edit") && (
            <div className="col-6">
              <div className="form-group">
                <label>Tên đầy đủ</label>
                <Form.Item validateStatus={name.error} help={name.help}>
                  <Input
                    className="form-control"
                    placeholder="Nhập Tên đầy đủ"
                    onChange={(e) =>
                      setName({ ...name, value: e.target.value })
                    }
                    value={name.value}
                    onBlur={() => validateNameField(name)}
                  />
                </Form.Item>
              </div>
            </div>
          )}
          {(type === "create" || type === "edit") && (
            <div className="col-6">
              <div className="form-group">
                <label>Email</label>
                <Form.Item validateStatus={email.error} help={email.help}>
                  <Input
                    className="form-control"
                    placeholder="Nhập Email"
                    onChange={(e) =>
                      setEmail({ ...email, value: e.target.value })
                    }
                    value={email.value}
                    onBlur={() => validateEmailField(email)}
                  />
                </Form.Item>
              </div>
            </div>
          )}
          {type === "create" && (
            <div className="col-6">
              <div className="form-group">
                <label>Mật khẩu</label>
                <Form.Item validateStatus={password.error} help={password.help}>
                  <Input.Password
                    className="form-control-password"
                    placeholder="Nhập Mật khẩu"
                    onChange={(e) =>
                      setPassword({ ...password, value: e.target.value })
                    }
                    value={password.value}
                    onBlur={() => validatePasswordField(password)}
                  />
                </Form.Item>
              </div>
            </div>
          )}
          {(type === "create" || type === "edit") && (
            <div className="col-6">
              <div className="form-group">
                <label>Số điện thoại</label>
                <Form.Item validateStatus={phone.error} help={phone.help}>
                  <Input
                    className="form-control"
                    placeholder="Nhập Số điện thoại"
                    value={phone.value}
                    onChange={(e) =>
                      setPhone({ ...phone, value: e.target.value })
                    }
                    onBlur={() => validatePhoneField(phone)}
                  />
                </Form.Item>
              </div>
            </div>
          )}
          {(type === "create" || type === "role") && (
            <div className="col-6">
              <div className="form-group">
                <label>Phân quyền</label>
                <Form.Item validateStatus={roleId.error} help={roleId.help}>
                  <Select
                    value={roleId.value}
                    onChange={(value) => setRoleId({ ...roleId, value })}
                  >
                    {["ROOT_ADMIN"].includes(roleIdUser) && (
                      <Option value={1}>Super Admin</Option>
                    )}
                    {["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) && (
                      <Option value={5}>Admin</Option>
                    )}
                    {["ROOT_ADMIN", "ADMIN", "PARTNER"].includes(
                      roleIdUser
                    ) && <Option value={3}>Partner</Option>}
                    {["ROOT_ADMIN", "ADMIN", "PARTNER", "AGENCY"].includes(
                      roleIdUser
                    ) && <Option value={2}>Agency</Option>}
                    {["ROOT_ADMIN"].includes(roleIdUser) && (
                      <Option value={6}>Sale</Option>
                    )}
                    <Option value={4}>User</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
          )}
          {type === "edit" && (
            <div className="col-6">
              <div className="form-group">
                <label>Telegram ID</label>
                <Form.Item
                  validateStatus={telegramId.error}
                  help={telegramId.help}
                >
                  <Input
                    className="form-control"
                    placeholder="Nhập Telegram ID"
                    onChange={(e) =>
                      setTelegramId({ ...telegramId, value: e.target.value })
                    }
                    value={telegramId.value}
                  />
                </Form.Item>
              </div>
            </div>
          )}
          {["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) && (
            <div className="col-6">
              <div className="form-group">
                <label>Nhà cung cấp</label>
                <Form.Item
                  validateStatus={providerId.error}
                  help={providerId.help}
                >
                  <Select
                    placeholder={"Chọn nhà cung cấp"}
                    value={providerId.value}
                    onChange={(value) =>
                      setProviderId({ ...providerId, value })
                    }
                  >
                    <Option value={0}>NA</Option>
                    <Option value={1}>TMP</Option>
                    <Option value={2}>SPC</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <Form.Item>
                <Checkbox
                  checked={receiptByEmail}
                  onChange={(e) => {
                    setReceiptByEmail(e.target.checked);
                  }}
                >
                  Nhận tin qua email
                </Checkbox>
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="form-group d-flex justify-content-end"></div>
      </Form>
      <div className="d-flex justify-content-center">
        {type === "edit" ? (
          <Button
            className="btn btn-primary btn-block w-auto"
            type="submit"
            loading={btnLoading}
            onClick={() => updateUser()}
          >
            {" "}
            Cập nhật{" "}
          </Button>
        ) : type === "role" ? (
          <Button
            className="btn btn-primary btn-block w-auto"
            type="submit"
            loading={btnLoading}
            onClick={() => updateUserRole()}
          >
            {" "}
            Cập nhật{" "}
          </Button>
        ) : (
          <Button
            className="btn btn-success btn-block w-auto"
            type="submit"
            loading={btnLoading}
            onClick={() => createUser()}
          >
            {" "}
            Tạo mới{" "}
          </Button>
        )}
      </div>
    </Modal>
  );
};
