import { rest } from "./rest";

export const apiTelesales = {
  register: () =>
    rest.post("/RegisterCallio", { state: "inactive" }, null, true),
  listapproveRegisterCallio: (params) =>
    rest.get("/listapproveRegisterCallio", params, null, true),
  historyCallio: (requestBody) =>
    rest.post("/historyCallio", requestBody, null, true),
  approveRegisterCallio: (requestBody) =>
    rest.post("/approveRegisterCallio", requestBody, null, true),
  recordingCallio: (requestBody) =>
    rest.post("/recordingCallio", requestBody, null, true),
  activeOrInactiveUserCallio: (id, state) =>
    rest.put(`/userCallio/${id}`, { state }, null, true),
  getUsers: (params) => rest.get(`/callio/user`, params, null, true),
};
