import React, {useState, useEffect} from "react";
import {Header} from "components/header/header";
import {Footer} from "components/footer/footer";
import {useHistory} from "react-router-dom";
import {apiAccount} from "api/account";
import {LeftSidebar} from "../../components/left-sidebar/left-sidebar";

export const AuthLayout = ({children}) => {
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const checkAuthenticated = async () => {
    const token = localStorage.getItem("token-datatuoi");
    if (token) {
      const res = await apiAccount.getOne();
      if (res?.status?.code === "SUCCESS") {
        setIsAuthenticated(true);
      }
    } else {
      history.push("/dang-nhap");
    }
  };

  useEffect(() => {
    checkAuthenticated();
  }, []);
  return (
    isAuthenticated && (
      <div id="wrapper">
        <Header/>
        <LeftSidebar/>
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              {children}
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    )
  );
};
