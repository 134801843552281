import { rest } from "./rest";

export const apiAccount = {
  getOne: () => rest.get("/user/info", {}, null, true),
  getAll: (pagination) => rest.get("/users", pagination, null, true),
  changePassword: (currentPassword, newPassword) =>
    rest.patch(
      "/user/changePassword",
      { currentPassword, newPassword },
      null,
      true
    ),
  activeUser: (id) => rest.put(`/user/${id}/active`, {}, null, true),
  activeAllWebsite: (id) => rest.put(`/website/${id}/active`, {}, null, true),
  inactiveUser: (id) => rest.put(`/user/${id}/inactive`, {}, null, true),
  createUser: (
    name,
    userName,
    password,
    email,
    phone,
    roleIds,
    receiptByEmail,
    providerId
  ) =>
    rest.post(
      "/user",
      {
        name,
        userName,
        password,
        email,
        phone,
        roleIds,
        receiptByEmail,
        providerId,
      },
      null,
      true
    ),
  updateUser: (
    id,
    username,
    email,
    phone,
    telegramId,
    name,
    password,
    receiptByEmail,
    providerId
  ) =>
    rest.put(
      `/user/${id}`,
      {
        username,
        email,
        phone,
        telegramId,
        name,
        password,
        receiptByEmail: receiptByEmail,
        providerId,
      },
      null,
      true
    ),
  updateUserRole: (id, roleIds) =>
    rest.patch(`/user/${id}`, { roleIds }, null, true),
  getUsersByRole: (pagination) =>
    rest.get("/usersByRole", pagination, null, true),
};
