import React, {useEffect, useState} from "react";
import {DatePicker, Form, Select} from "antd";
import "./telesales.scss";
import {apiTelesales} from "../../api/telesales";
import {apiAccount} from "../../api/account";
import {useDispatch, useSelector} from "react-redux";

import {setAccountInfo} from "../../store/account";

import {CallioCallHistory} from "../../components/callio-call-history/callio-call-history";

import IconPlusFill from "assets/icons/icon-plus-fill.svg";
import IconRefresh from "assets/icons/icon-refresh.svg";
import {showMessage} from "../../utils/utils-show-message";

const {Option} = Select;
const {RangePicker} = DatePicker;

export const Telesales = () => {
  const dispatch = useDispatch();
  const [isFilter, setIsFilter] = useState(false);

  const roleIdUser = useSelector((state) => state.account.role);
  const stateCallio = useSelector(
    (state) => state.account.account?.stateCallio
  );

  const register = async () => {
    let res = await apiTelesales.register();
    if (res.status?.code === "SUCCESS") {
      showMessage("success", "Đăng ký dịch vụ telesales thành công");
      res = await apiAccount.getOne();
      if (res?.status?.code === "SUCCESS") {
        dispatch(setAccountInfo(res.data));
      }
    } else {
      showMessage("error", res.status.message);
    }
  };

  return (
    <>
      {["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) ? (
        <CallioCallHistory/>
      ) : stateCallio === null || stateCallio === "inactive" ? (
        <div className="chien-dich-page position-relative min-height-100vh">
          <>
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <Form className="form-inline">
                      <button className="btn btn-secondary btn-sm ml-2">
                        <img src={IconRefresh} alt="" width="11"/>
                      </button>
                    </Form>
                  </div>
                  <h4 className="page-title">Telesales</h4>
                </div>
              </div>
              <h4 className="page-title">Telesales</h4>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <h4 className="header-title d-flex align-items-center flex-wrap justify-content-between">
                    {/*<span className="mb-2">Danh sách chiến dịch</span>*/}
                    <div className="d-flex flex-wrap align-items-center">
                      {stateCallio === null && (
                        <button
                          type="button"
                          className="btn btn-sm btn-blue waves-effect mb-2 waves-light float-right d-flex align-items-center"
                          onClick={() => register()}
                        >
                          <img
                            src={IconPlusFill}
                            width="12"
                            className="mr-1"
                            alt=""
                          />{" "}
                          Đăng ký
                        </button>
                      )}
                      {stateCallio === "inactive" &&
                      "Đã đăng ký. Đang chờ duyệt"}
                    </div>
                  </h4>
                </div>
              </div>
            </div>
          </>
        </div>
      ) : (
        <CallioCallHistory/>
      )}
    </>
  );
};
