import { rest } from "./rest";

export const apiData = {
  getAll: (pagination) => rest.get("/datas", pagination, null, true),
  getOne: (id, showPhone) => rest.get(`/data/${id}`, {showPhone}, null, true),
  sendData: (from, to) => rest.post("/data/mailer", { from, to }, null, true),
  facebookScan: (text) => rest.post("/data/scan", text, null, true),
  getFacebookScanAll: (pagination) =>
    rest.get("/facebook/history/all", pagination, null, true),
  visitorHistory: (text) => rest.post("/data/historyVisitor", text, null, true),
};
