const initialState = {
  account: {},
  role: null,
};

export const account = (state = initialState, payload) => {
  const { type } = payload;
  switch (type) {
    case "SET_ACCOUNT_INFO":
      return (state = {
        ...state,
        account: payload.value,
      });
    case "SET_ACCOUNT_ROLE":
      return (state = {
        ...state,
        role: payload.value,
      });
    default:
      return state;
  }
};

export const setAccountInfo = (value) => {
  return {
    type: "SET_ACCOUNT_INFO",
    value,
  };
};

export const setAccountRole = (value) => {
  return {
    type: "SET_ACCOUNT_ROLE",
    value,
  };
};
