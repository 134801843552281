import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Form } from "antd";
import { apiAccount } from "api/account";

import "./doi-mat-khau-modal.scss";
import ImageModal from "assets/images/image-user-change-password-modal.svg";
import { showMessage } from "utils/utils-show-message";
import { validateTextLength } from "utils/utils-validation";

export const DoiMatKhauModal = ({
  visibleModal,
  onCancel,
  userId,
  onSuccess,
  user,
  userNameTarget,
  type,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [password, setPassword] = useState({ value: "", error: "", help: "" });

  const initModal = () => {
    setPassword({ value: "", error: "", help: "" });
  };

  useEffect(() => {
    if (visibleModal) {
      initModal();
    }
  }, [visibleModal]);

  const closeModal = () => {
    setBtnLoading(false);
    setPassword({ value: "", error: "", help: "" });
  };

  const validatePasswordField = (target) => {
    if (!validateTextLength(target.value, 6)) {
      setPassword({
        ...target,
        error: "error",
        help: "Mật khẩu phải lớn hơn hoặc bằng 6 ký tự",
      });
      return false;
    }
    setPassword({ value: target.value, error: "", help: "" });
    return true;
  };

  async function updateUser() {
    const res = await apiAccount.updateUser(
      userId,
      null,
      null,
      null,
      null,
      null,
      password.value
    );
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Huỷ kích hoạt thành công");
      closeModal();
      onCancel();
    } else if (res?.status?.code) {
      showMessage("error", res?.status?.message);
    } else {
      showMessage("error", res.message);
    }
  }

  return (
    <Modal
      title="Đổi mật khẩu User"
      centered
      footer={null}
      visible={visibleModal}
      wrapClassName="them-chien-dich-modal them-don-hang-modal them-user-modal"
      onCancel={() => {
        closeModal();
        onCancel();
      }}
    >
      <div className="modal-image mb-4">
        <img src={ImageModal} alt="" />
      </div>
      <p className="text-center">
        Thao tác này sẽ đổi mật khẩu cho tài khoản{" "}
        <strong>{userNameTarget}</strong>
      </p>
      <Form className="dark">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>Mật khẩu mới</label>
              <Form.Item validateStatus={password.error} help={password.help}>
                <Input
                  className="form-control"
                  placeholder="Nhập mật khẩu mới"
                  onChange={(e) =>
                    setPassword({ ...password, value: e.target.value })
                  }
                  value={password.value}
                  onBlur={() => validatePasswordField(password)}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="form-group d-flex justify-content-end"></div>
      </Form>
      <div className="d-flex justify-content-center">
        <Button
          className="btn btn-primary btn-block w-auto"
          type="submit"
          loading={btnLoading}
          onClick={() => updateUser()}
        >
          {" "}
          Cập nhật{" "}
        </Button>
      </div>
    </Modal>
  );
};
