import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button } from "antd";

import "./xac-thuc.scss";

import Logo from "assets/images/logo.png";
import iconMail from "assets/icons/icon-mail.svg";
import { apiAuth } from "api/auth";
import { showMessage } from "utils/utils-show-message";

export const XacThuc = () => {
  const authState = useSelector((state) => state.auth);
  const history = useHistory();
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    if (!authState.emailRegister) {
      history.push("/404");
    } else {
      resendActiveEmail();
    }
  }, []);

  const resendActiveEmail = async () => {
    setBtnLoading(true);
    const res = await apiAuth.resendActiveUser(authState.emailRegister);
    setBtnLoading(false);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Gửi thành công");
    } else {
      showMessage("error", res.status.message);
    }
  };

  return (
    <div className="xac-thuc-page">
      <div className="authentication-bg authentication-bg-pattern pt-5">
        <div className="account-pages">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card bg-pattern">
                  <div className="card-body p-4">
                    <div className="text-center w-75 m-auto">
                      <div className="auth-logo">
                        <a
                          href="index.html"
                          className="data-tuoi-logo light mb-2"
                        >
                          <img src={Logo} alt="" height="22" />
                          Telegamez.org
                        </a>
                      </div>
                    </div>

                    <div className="mt-3 text-center">
                      <img width="70" src={iconMail} alt="" />
                      <h3>Kích hoạt tài khoản !</h3>
                      <p className="text-muted font-14 mt-2">
                        {" "}
                        Một email đã được gửi đến{" "}
                        <b>{authState.emailRegister}</b>. Xin hãy kiểm tra hộp
                        thư và nhấp vào liên kết đi kèm để đặt lại mật khẩu. Quá
                        trình này có thể diễn ra vài phút.
                      </p>
                      <p className="text-muted font-14">
                        Không nhận được email ?
                      </p>

                      <Button
                        className="btn btn-block btn-pink waves-effect waves-light"
                        loading={btnLoading}
                        onClick={() => resendActiveEmail()}
                      >
                        Gửi lại email xác nhận
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
