import { message } from "antd";

const duration = 3;

export const showMessage = (type, content) => {
  if (type === "success") {
    message.success({
      content,
      className: "custom-show-message",
      duration,
    });
  }
  if (type === "error") {
    message.error({
      content,
      className: "custom-show-message",
      duration,
    });
  }
};
