import * as JsSIP from "jssip";
import md5 from "crypto-js/md5";

export const STATUS_PROGRESS = 0;
export const STATUS_ACCEPTED = 1;
export const STATUS_CONFIRMED = 2;
export const STATUS_ENDED = 3;
export const STATUS_FAILED = 4;

export class UtilsPhoneNet {
  host = "grabcall.phonenet.io";
  ext;
  password;
  contact_uri;
  phone = null;
  call = null;

  constructor(ext, password) {
    this.ext = ext;
    this.password = password;
    this.contact_uri = `sip:${ext}@${Math.random()
      .toString(36)
      .substring(2, 15)}.invalid;transport=ws`;
  }

  callOptions = {
    mediaConstraints: {
      audio: true,
      video: false,
    },
    pcConfig: {
      iceTransportPolicy: "relay",
      iceServers: [
        {
          urls: ["turn:103.56.157.238", "turn:turn-vn-01.phonenet.io"],
          username: "callio",
          credential: "collia",
        },
      ],
    },
  };

  login = (onRegistered, onUnRegistered) => {
    this.phone = new JsSIP.UA({
      sockets: [new JsSIP.WebSocketInterface(`wss://${this.host}:7443`)],
      uri: "sip:" + this.ext + "@" + this.host,
      realm: this.host,
      ha1: md5(`${this.ext}:${this.host}:${this.password}`),
      contact_uri: this.contact_uri,
      session_timers: false,
    });
    this.phone.start();
    this.phone.on("registered", (e) => onRegistered(e));
    this.phone.on("unregistered", (e) => onUnRegistered(e));
    this.phone.on("disconnected", (e) => {
      //Connected
      console.log("Phone disconnected");
    });
  };

  makeACall = (
    phoneNumber,
    onProgress,
    onAccepted,
    onConfirmed,
    onEnded,
    onFailed
  ) => {
    // this.phone.call(phoneNumber, this.callOptions);
    this.call = this.phone.call(phoneNumber, this.callOptions);
    this.call.connection.onaddstream = function (e) {
      const remoteAudio = document.createElement("audio");
      remoteAudio.srcObject = e.stream;
      remoteAudio.play();
    };
    this.call.on("icecandidate", (e) => {
      onProgress();
      setTimeout(() => {
        e.ready();
      }, 3000);
    });
    this.call.on("progress", () => onProgress());
    this.call.on("accepted", () => onAccepted());
    this.call.on("confirmed", () => onConfirmed());
    this.call.on("ended", () => {
      onEnded();
      this.call = null;
    });
    this.call.on("failed", (e) => {
      //Thất bại
      // $('#status').text('Cuộc gọi thất bại');
      console.log("Cuộc gọi thất bại");
      console.log(e);
      onFailed(e);
      this.call = null;
    });
  };

  cancelCall = () => {
    if (this.call) {
      this.call.terminate();
    }
  };

  isConnected() {
    return this.phone.isConnected();
  }
}
