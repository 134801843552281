import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  Input,
  Select,
  Table,
  Dropdown,
  Pagination,
  DatePicker,
  Form,
} from "antd";
import "./thanh-vien.scss";
import { apiAccount } from "api/account";
import { reverseDate } from "utils/utils-format";

import { ThemChienDichModal } from "components/them-chien-dich-modal/them-chien-dich-modal";
import { ThemDonHangModal } from "components/them-don-hang-modal/them-don-hang-modal";
import { ThemUserModal } from "components/them-user-modal/them-user-modal";
import { DoiMatKhauModal } from "components/doi-mau-khau-modal/doi-mau-khau-modal";
import { InputAutoComplete } from "components/input-autocomplete/input-autocomplete";
import { SelectAgency } from "components/select-dai-ly/select-dai-ly";

import IconPlusFill from "assets/icons/icon-plus-fill.svg";
import IconRefresh from "assets/icons/icon-refresh.svg";
import IconVerticalDots from "assets/icons/icon-vertical-dots.svg";
import IconStop from "assets/icons/icon-stop.svg";
import IconFilter from "assets/icons/icon-filter.svg";
import IconWebsiteGray from "assets/icons/icon-website-gray.svg";
import IconEdit from "assets/icons/icon-edit.svg";
import IconCheckMarkGray from "assets/icons/icon-check-mark-gray.svg";
import IconCartGray from "assets/icons/icon-cart-gray.svg";
import IconCheckMarkGray2 from "assets/icons/icon-check-mark-light.svg";
import IconPasswordLight from "assets/icons/icon-password-light.svg";
import { showMessage } from "utils/utils-show-message";
import IconUser from "assets/icons/icon-user-light.svg";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const ThanhVien = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalData, setVisibleModalData] = useState(false);
  const [visibleModalChangePassword, setVisibleModalChangePassword] = useState(
    false
  );
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(10);
  const [dataUser, setDataUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchMethod, setSearchMethod] = useState("uid");
  const [isFilter, setIsFilter] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userNameTarget, setUserNameTarget] = useState(null);
  const [expiredDate, setExpiredDate] = useState(null);
  const [expiredDateTrackingFb, setExpiredDateTrackingFb] = useState(null);
  const [visibleUserModal, setVisibleUserModal] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [userModalType, setUserModalType] = useState("");
  const [suggestionAccount, setSuggestionAccount] = useState([]);
  const [suggestionUid, setSuggestionUid] = useState([]);
  const [suggestionEmail, setSuggestionEmail] = useState([]);
  const roleIdUser = useSelector((state) => state.account.role);
  const [agencyIdSelected, setAgencyIdSelected] = useState(0);

  const actionTableDropdown = (value, row, index) => {
    return (
      <div className="account-dropdown">
        {["SALE"].includes(roleIdUser) === false ? (
          <div
            key="1"
            className="dropdown-item d-flex align-items-center"
            onClick={() => {
              setUserInfo(row);
              setUserNameTarget(row.name);
              setUserModalType("edit");
              setVisibleUserModal(true);
            }}
          >
            <img src={IconEdit} alt="" />
            Sửa
          </div>
        ) : (
          <></>
        )}
        <div
          key="2"
          className="dropdown-item d-flex align-items-center"
          onClick={() => {
            setVisibleModalChangePassword(true);
            setUserId(row.id);
            setUserInfo(row);
            setUserNameTarget(row.name);
          }}
        >
          <img src={IconPasswordLight} alt="" />
          Đổi mật khẩu
        </div>
        {["SALE"].includes(roleIdUser) === false ? (
          <div
            key="3"
            className="dropdown-item d-flex align-items-center"
            onClick={() => {
              setUserId(row.id);
              setUserNameTarget(row.name);
              setExpiredDate(row.ngayHetHan);
              setExpiredDateTrackingFb(row.ngayHetHanTrackingFb);
              setVisibleModalData(true);
            }}
          >
            <img src={IconCartGray} alt="" />
            Thêm đơn hàng
          </div>
        ) : (
          <></>
        )}
        <div
          key="4"
          className="dropdown-item d-flex align-items-center"
          onClick={() => {
            setVisibleModal(true);
            setUserId(row.id);
          }}
        >
          <img src={IconWebsiteGray} alt="" />
          Thêm website
        </div>
        {row.trangThai === "ACTIVE" && (
          <div
            key="5"
            className="dropdown-item d-flex align-items-center"
            onClick={() => inactiveUser(row.id)}
          >
            <img src={IconStop} alt="" />
            Huỷ kích hoạt
          </div>
        )}
        {["WAIT_ACTIVE", "INACTIVE"].includes(row.trangThai) && (
          <div
            key="6"
            className="dropdown-item d-flex align-items-center"
            onClick={() => activeUser(row.id)}
          >
            <img src={IconCheckMarkGray2} alt="" />
            Kích hoạt
          </div>
        )}
        <div
          key="7"
          className="dropdown-item d-flex align-items-center"
          onClick={() => activeAllWebsite(row.id)}
        >
          <img src={IconCheckMarkGray} alt="" />
          Kích hoạt toàn bộ website
        </div>
        {["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) && (
          <div
            key="8"
            className="dropdown-item d-flex align-items-center"
            onClick={() => {
              setUserInfo(row);
              setUserNameTarget(row.name);
              setUserModalType("role");
              setVisibleUserModal(true);
            }}
          >
            <img src={IconUser} alt="" />
            Phân quyền
          </div>
        )}
      </div>
    );
  };

  const columnsUser = [
    {
      title: "#",
      dataIndex: "stt",
      key: "stt",
      width: 50,
    },
    {
      title: "UID",
      dataIndex: "uid",
      key: "uid",
      className: "text-nowrap",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className: "text-nowrap",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      className: "text-nowrap",
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      className: "width-125-px-in-1200-column",
      render: (value, row, index) => {
        if (value === "ACTIVE")
          return <span className="badge badge-success">Đã kích hoạt</span>;
        if (value === "WAIT_ACTIVE")
          return (
            <span className="badge bg-warning text-danger">Chờ kích hoạt</span>
          );
        if (value === "INACTIVE")
          return (
            <span className="badge bg-danger text-white">Chưa kích hoạt</span>
          );
      },
    },
    {
      title: "Sản lượng",
      dataIndex: "sanLuong",
      key: "sanLuong",
      className: "width-125-px-in-1200-column",
    },
    {
      title: "Ngày hết hạn",
      dataIndex: "ngayHetHan",
      key: "ngayHetHan",
      className: "width-125-px-in-1200-column",
    },
    {
      title: "Nhà cung cấp",
      dataIndex: "providerId",
      key: "providerId",
      className: "text-nowrap",
      render: (value, row, index) => {
        return <>{value === 0 ? "NA" : (value === 1 ? "TMP" : (value === 2 ? "SPC" : "")) }</>;
      },
    },
    {
      title: "Số lượng link facebook",
      dataIndex: "sanLuongTrackingFb",
      key: "sanLuongTrackingFb",
      className: "width-125-px-in-1200-column",
    },
    {
      title: "Ngày hết hạn tracking facebook",
      dataIndex: "ngayHetHanTrackingFb",
      key: "ngayHetHanTrackingFb",
      className: "width-125-px-in-1200-column",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (value, row, index) => (
        <Dropdown
          overlay={actionTableDropdown(value, row, index)}
          trigger={["click"]}
          placement="bottomRight"
          className="table-action-btn dropdown-toggle arrow-none btn btn-light btn-sm"
        >
          <div className="table-action-icon">
            <img width="13" src={IconVerticalDots} alt="" />
          </div>
        </Dropdown>
      ),
    },
  ];

  const getAllSuggestion = async () => {
    const res = await apiAccount.getAll({
      pageIndex: 1,
      pageSize: 100,
      agencyId: agencyIdSelected,
    });
    if (res?.status?.code === "SUCCESS") {
      const data = res.data;
      const suggestionUid = [];
      const suggestionAccount = [];
      const suggestionEmail = [];
      data.forEach((item) => {
        suggestionUid.push(item.phone);
        suggestionAccount.push(item.userName);
        suggestionEmail.push(item.email);
      });
      setSuggestionUid(suggestionUid);
      setSuggestionAccount(suggestionAccount);
      setSuggestionEmail(suggestionEmail);
    }
  };

  const renderSuggestion = () => {
    switch (searchMethod) {
      case "uid":
        return suggestionUid;
      case "userName":
        return suggestionAccount;
      case "email":
        return suggestionEmail;
      default:
        return [];
    }
  };

  useEffect(() => {
    getAllSuggestion();
  }, []);

  useEffect(() => {
    getAllUser();
  }, [phone, userName, email, agencyIdSelected]);

  const onSearch = (value) => {
    setPageIndex(1);
    if (searchMethod === "uid") {
      setEmail("");
      setUserName("");
      setPhone(value);
    }
    if (searchMethod === "userName") {
      setEmail("");
      setUserName(value);
      setPhone("");
    }
    if (searchMethod === "email") {
      setEmail(value);
      setUserName("");
      setPhone("");
    }
  };

  const renderPlaceholder = () => {
    switch (searchMethod) {
      case "uid":
        return "Nhập UID cần tìm";
      case "userName":
        return "Nhập Username cần tìm";
      case "email":
        return "Nhập Email cần tìm";
      default:
        return "";
    }
  };

  const resetSearchValue = () => {
    setUserName("");
    setEmail("");
    setPhone("");
  };

  const checkIsFilter = () => {
    if (
      searchMethod !== "uid" ||
      fromDate !== "" ||
      toDate !== "" ||
      email !== "" ||
      phone !== "" ||
      state !== "" ||
      userName !== ""
    ) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  };

  const deleteFilter = () => {
    setState("");
    setFromDate("");
    setToDate("");
    setEmail("");
    setPhone("");
    setUserName("");
    setIsFilter(false);
    setSearchMethod("uid");
    setPageIndex(1);
    getAllUser();
  };

  const handleDatePicker = (date, dateString) => {
    const startDate = dateString[0];
    const endDate = dateString[1];
    setPageIndex(1);
    setFromDate(startDate);
    setToDate(endDate);
  };

  useEffect(() => {
    getAllUser();
  }, [pageIndex, pageSize, state, toDate]);

  useEffect(() => {
    setPageIndex(1);
  }, [pageSize, state, toDate]);

  const inactiveUser = async (id) => {
    const res = await apiAccount.inactiveUser(id);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Huỷ kích hoạt thành công");
      getAllUser();
    } else {
      showMessage("error", res.message);
    }
  };
  const activeUser = async (id) => {
    const res = await apiAccount.activeUser(id);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Kích hoạt thành công");
      getAllUser();
    } else {
      showMessage("error", res.status.message);
    }
  };
  const activeAllWebsite = async (id) => {
    const res = await apiAccount.activeAllWebsite(id);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Kích hoạt thành công");
      getAllUser();
    } else {
      showMessage("error", res.status.message);
    }
  };

  const getAllUser = async () => {
    checkIsFilter();
    setLoading(true);
    const res = await apiAccount.getAll({
      pageIndex,
      pageSize,
      userName,
      email,
      phone,
      state,
      fromDate,
      toDate,
      agencyId: agencyIdSelected,
    });
    setLoading(false);
    if (res?.status?.code === "SUCCESS") {
      const data = [];
      res.data.forEach((item, index) => {
        data.push({
          stt: index + 1,
          id: item.id,
          name: item.name,
          username: item.userName,
          email: item.email,
          uid: item.phone,
          trangThai: item.status,
          sanLuong: `${item.usedData} / ${item.totalOfData}`,
          ngayHetHan: item.expiredDate,
          telegramId: item.telegramId,
          receiptByEmail: item.receiptByEmail,
          sanLuongTrackingFb: item.totalOfDataTrackingFb
            ? `${item.usedDataTrackingFb} / ${item.totalOfDataTrackingFb}`
            : "",
          ngayHetHanTrackingFb: item.expiredDateTrackingFb,
          providerId: item.providerId,
        });
      });
      setDataUser(data);
      setTotalPage(res.totalItem);
    }
  };

  const handleAgencyChange = (agencyId) => {
    setAgencyIdSelected(agencyId);
  };

  return (
    <div className="thanh-vien-page position-relative min-height-100vh">
      <DoiMatKhauModal
        visibleModal={visibleModalChangePassword}
        userId={userId}
        userNameTarget={userNameTarget}
        user={userInfo}
        onSuccess={() => {}}
        onCancel={() => {
          setVisibleModalChangePassword(false);
          setUserId(null);
          setUserNameTarget(null);
          setUserInfo({});
        }}
      />
      <ThemUserModal
        visibleModal={visibleUserModal}
        userId={userId}
        userNameTarget={userNameTarget}
        user={userInfo}
        onSuccess={() => getAllUser()}
        type={userModalType}
        onCancel={() => {
          setVisibleUserModal(false);
          setUserId(null);
          setUserNameTarget(null);
          setUserInfo({});
        }}
      />
      <ThemChienDichModal
        visibleModal={visibleModal}
        userId={userId}
        onSuccess={() => {}}
        onCancel={() => {
          setVisibleModal(false);
          setUserId(null);
        }}
      />

      <ThemDonHangModal
        visibleModal={visibleModalData}
        userId={userId}
        userName={userNameTarget}
        oldExpiredDate={expiredDate}
        oldExpiredDateTrackingFb={expiredDateTrackingFb}
        onSuccess={() => getAllUser()}
        onCancel={() => {
          setVisibleModalData(false);
          setUserId(null);
          setUserNameTarget(null);
          setExpiredDate(null);
          setExpiredDateTrackingFb(null);
        }}
      />
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <Form className="form-inline">
                  <button
                    className="btn btn-secondary btn-sm ml-2"
                    onClick={() => {
                      resetSearchValue();
                      setFromDate("");
                      setToDate("");
                      getAllUser();
                    }}
                  >
                    <img src={IconRefresh} alt="" width="11" />
                  </button>
                </Form>
              </div>
              <h4 className="page-title">Thành viên</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card-box">
              <h4 className="header-title d-flex align-items-center flex-wrap justify-content-between">
                <span className="mb-2">Quản lý thành viên</span>
                <div className="d-flex flex-wrap align-items-center">
                  {isFilter && (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger waves-effect waves-light d-flex align-items-center mb-2 mr-2"
                      onClick={() => deleteFilter()}
                    >
                      <img
                        src={IconFilter}
                        width="12"
                        className="mr-1"
                        alt=""
                      />{" "}
                      Xoá bộ lọc
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-sm btn-success mb-2 waves-effect waves-light float-right d-flex align-items-center"
                    onClick={() => {
                      setUserInfo({
                        name: "",
                        username: "",
                        password: "",
                        email: "",
                        phone: "",
                      });
                      setUserModalType("create");
                      setVisibleUserModal(true);
                    }}
                  >
                    <img
                      src={IconPlusFill}
                      width="12"
                      className="mr-1"
                      alt=""
                    />{" "}
                    Thêm thành viên
                  </button>
                </div>
              </h4>
              {["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) && (
                <div className="table-tools d-flex align-items-center justify-content-between mb-3">
                  <div className="tool-item filter d-flex align-items-center">
                    <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                      Đại lý:
                      <SelectAgency
                        placeholder="Tất cả"
                        onChange={(value) => handleAgencyChange(value)}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="table-tools d-flex align-items-center justify-content-between mb-3">
                <div className="tool-item filter d-flex align-items-center">
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                    Kiểu tìm kiếm
                    <Select
                      value={searchMethod}
                      onSelect={(value) => {
                        resetSearchValue();
                        setSearchMethod(value);
                      }}
                    >
                      <Option value="uid">UID</Option>
                      <Option value="userName">User Name</Option>
                      <Option value="email">Email</Option>
                    </Select>
                  </div>
                  <div className="tool-item search d-flex align-items-center text-nowrap">
                    Tìm kiếm:
                    <InputAutoComplete
                      isFilter={isFilter}
                      dataSource={renderSuggestion()}
                      placeholder={renderPlaceholder()}
                      onSearch={(value) => onSearch(value)}
                    />
                  </div>
                </div>
                <div className="d-flex flex-wrap align-items-center">
                  <div className="tool-item search d-flex align-items-center mr-2 text-nowrap search-method-select">
                    Trạng thái
                    <Select value={state} onSelect={(value) => setState(value)}>
                      <Option value="">Tất cả</Option>
                      <Option value="ACTIVE">Kích hoạt</Option>
                      <Option value="WAIT_ACTIVE">Chờ kích hoạt</Option>
                      <Option value="INACTIVE">Chưa kích hoạt</Option>
                    </Select>
                  </div>
                  <div className="tool-item search d-flex align-items-center text-nowrap">
                    <RangePicker
                      format="DD-MM-YYYY"
                      value={[
                        fromDate ? moment(reverseDate(fromDate)) : null,
                        toDate ? moment(reverseDate(toDate)) : null,
                      ]}
                      placeholder={["Từ ngày", "Đến ngày"]}
                      onChange={(date, dateString) =>
                        handleDatePicker(date, dateString)
                      }
                    />
                  </div>
                </div>
              </div>

              <Table
                loading={loading}
                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                dataSource={dataUser}
                columns={columnsUser}
                pagination={false}
              />

              {dataUser.length !== 0 && (
                <div className="table-panigate d-flex align-items-center justify-content-between mt-3">
                  <div className="dataTables_info">
                    Trang {pageIndex} trong tổng số{" "}
                    {Math.ceil(totalPage / pageSize)} trang
                  </div>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="tool-item select d-flex align-items-center pagesize-select">
                      Hiển thị
                      <Select
                        value={pageSize}
                        onSelect={(value) => setPageSize(value)}
                      >
                        <Option value="10">10</Option>
                        <Option value="30">30</Option>
                        <Option value="50">50</Option>
                        <Option value="70">70</Option>
                        <Option value="100">100</Option>
                      </Select>
                      dữ liệu
                    </div>
                    <div className="table-pagination">
                      <Pagination
                        current={pageIndex}
                        pageSize={pageSize}
                        total={totalPage}
                        showSizeChanger={false}
                        onChange={(page) => setPageIndex(page)}
                        hideOnSinglePage={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
