import React, { useEffect, useState } from "react";
import moment from "moment";
import { DatePicker, Form, Pagination, Progress, Select, Table } from "antd";
import ShowMoreText from "react-show-more-text";

import { InputAutoComplete } from "../input-autocomplete-object/input-autocomplete";

import IconRefresh from "../../assets/icons/icon-refresh.svg";
import IconFilter from "../../assets/icons/icon-filter.svg";

import "./callio-call-history.scss";
import { apiTelesales } from "../../api/telesales";

import { reverseDate, secondsFormatString } from "../../utils/utils-format";
import { useSelector } from "react-redux";
import { showMessage } from "../../utils/utils-show-message";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const CALL_OUT = 2; // goi ra

export const CallioCallHistory = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(10);
  const [dataCall, setDataCall] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [idUserCallio, setIdUserCallio] = useState("");

  const [fromDate, setFromDate] = useState(
    moment().add(-7, "days").format("DD-MM-YYYY")
  );
  const [toDate, setToDate] = useState(moment().format("DD-MM-YYYY"));
  const [isFilter, setIsFilter] = useState(false);
  const role = useSelector((state) => state.account.role);

  const DEFAULT_COLUMNS = [
    {
      title: "Gọi từ",
      dataIndex: "fromNumber",
      key: "fromNumber",
      render: (value, row) => (
        <>
          <div>{row.fromNumber}</div>
          <div>
            <span>Số nhánh:</span>&nbsp;<strong>{row.fromExt}</strong>
          </div>
          <div>
            <span>Email:</span>&nbsp;<strong>{row.fromUser?.email}</strong>
          </div>
        </>
      ),
    },
    {
      title: "Gọi tới",
      dataIndex: "toNumber",
      key: "toNumber",
      className: "text-nowrap",
    },
    {
      title: "Thời gian gọi",
      dataIndex: "startTime",
      key: "startTime",
      className: "text-nowrap",
      render: (value, row) => (
        <>
          <div>
            <span>Bắt đầu lúc:</span>&nbsp;
            <strong>
              {moment(row.startTime).format("DD/MM/YYYY HH:mm:ss")}
            </strong>
          </div>
          <div>
            <span>Kết thúc lúc:</span>&nbsp;
            <strong>{moment(row.endTime).format("DD/MM/YYYY HH:mm:ss")}</strong>
          </div>
        </>
      ),
    },
    {
      title: "Kết quả cuộc gọi",
      dataIndex: "duration",
      key: "duration",
      className: "text-nowrap",
      render: (value, row) => (
        <>
          <div>
            <span>Thời lượng:</span>&nbsp;
            <strong className={"text-success"}>
              {secondsFormatString(row.duration)}
            </strong>
            &nbsp;|&nbsp;
            <span>Thời gian đàm thoại:&nbsp;</span>
            <strong className={"text-danger"}>
              {secondsFormatString(row.billDuration)}
            </strong>
          </div>
          {row.audioQuality > 0 && (
            <>
              <div>
                <audio id={`audio-id-${row.id}`} controls>
                  <source src={row.url} type="audio/ogg" />
                  <source src={row.url} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
              <div>
                <span>Chất lượng cuộc gọi:</span>&nbsp;
                <Progress
                  percent={row.audioQuality}
                  style={{ width: 150 }}
                  size={"small"}
                  format={(percent) => <>({percent}%)</>}
                />
              </div>
            </>
          )}
          {row.hangupCause === "USER_BUSY" && <div>Máy bận</div>}
          {["ORIGINATOR_CANCEL", "NO_USER_RESPONSE"].includes(
            row.hangupCause
          ) && <div>Người gọi gác máy</div>}
          {row.hangupCause === "NORMAL_CLEARING" && (
            <div>
              <span>Gọi thành công</span>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Voice AI",
      dataIndex: "transcripts",
      key: "transcripts",
      width: 450,
      render: (value, row) => (
        <>
          {row.hangupCause === "NORMAL_CLEARING" &&
            row.transcripts?.length > 0 && (
              <div>
                <ShowMoreText
                  className="content-css"
                  lines={3}
                  more={"Xem thêm"}
                  less={"Thu gọn"}
                  expanded={false}
                  width={400}
                >
                  <span>Nội dung:&nbsp;</span>
                  {row.transcripts.join(". ")}
                </ShowMoreText>
              </div>
            )}
          {row.hangupCause === "NORMAL_CLEARING" &&
            row.transcripts?.length === 0 && (
              <>
                <span>Thiết bị không hỗ trợ AI</span>
              </>
            )}
          {["USER_BUSY", "ORIGINATOR_CANCEL", "NO_USER_RESPONSE"].includes(
            row.hangupCause
          ) && (
            <>
              <span>Kết nối thất bại</span>
            </>
          )}
        </>
      ),
    },
  ];

  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  const onSearch = (value) => {
    setPageIndex(1);
    setIdUserCallio(value);
  };

  const checkIsFilter = () => {
    if (fromDate !== "" || idUserCallio !== "" || toDate !== "") {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  };

  const deleteFilter = () => {
    setFromDate("");
    setToDate("");
    setIdUserCallio("");
    setIsFilter(false);
    setPageIndex(1);
    getCallioHistoryCall();
  };

  const handleDatePicker = (date, dateString) => {
    const startDate = dateString[0];
    const endDate = dateString[1];
    setPageIndex(1);
    setFromDate(startDate);
    setToDate(endDate);
  };

  useEffect(() => {
    if (!role) {
      return;
    }
    if (["ROOT_ADMIN", "ADMIN"].includes(role)) {
      getAllSuggestion();
      return;
    }

    if (columns.filter((column) => column.key === "fromNumber")?.length > 0) {
      columns.splice(0, 1);
      setColumns(columns);
    }
  }, [role]);

  useEffect(() => {
    getCallioHistoryCall();
  }, [idUserCallio]);

  useEffect(() => {
    getCallioHistoryCall();
  }, [pageIndex, pageSize, toDate]);

  useEffect(() => {
    setPageIndex(1);
  }, [pageSize, role, toDate]);

  const getAllSuggestion = async () => {
    const res = await apiTelesales.getUsers({ page: 0, pageSize: 1000 });
    if (res?.status?.code === "SUCCESS") {
      setUsers(res.data);
    } else {
      showMessage("error", res.status?.message);
    }
  };

  const getCallioHistoryCall = async () => {
    checkIsFilter();
    setLoading(true);
    const res = await apiTelesales.historyCallio({
      page: pageIndex,
      pageSize,
      idUserCallio,
      direction: CALL_OUT,
      from: moment(fromDate, "DD-MM-YYYY")
        .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
        .valueOf(),
      to: moment(toDate, "DD-MM-YYYY")
        .set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })
        .valueOf(),
    });
    if (res?.status?.code === "SUCCESS") {
      for (const record of res.data) {
        if (record.hangupCause === "NORMAL_CLEARING") {
          await getUrlRecording(record);
        }
      }
      setDataCall(res.data);
      setTotalPage(res.totalItem);
    }
    setLoading(false);
  };

  const getUrlRecording = async (record) => {
    const res = await apiTelesales.recordingCallio({ id: record.id });
    if (res?.status?.code === "SUCCESS") {
      record.url = res.data.url;
    }
  };

  return (
    <div className="callio-call-history-page position-relative min-height-100vh">
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <Form className="form-inline">
                  <button
                    className="btn btn-secondary btn-sm ml-2"
                    onClick={() => {
                      setFromDate(
                        moment().add(-7, "days").format("DD-MM-YYYY")
                      );
                      setToDate(moment().format("DD-MM-YYYY"));
                      getCallioHistoryCall();
                    }}
                  >
                    <img src={IconRefresh} alt="" width="11" />
                  </button>
                </Form>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card-box">
              <h4 className="header-title d-flex align-items-center flex-wrap justify-content-between">
                <span className="mb-2">Lịch sử cuộc gọi</span>
                <div className="d-flex flex-wrap align-items-center">
                  {isFilter && (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger waves-effect waves-light d-flex align-items-center mb-2 mr-2"
                      onClick={() => deleteFilter()}
                    >
                      <img
                        src={IconFilter}
                        width="12"
                        className="mr-1"
                        alt=""
                      />{" "}
                      Xoá bộ lọc
                    </button>
                  )}
                </div>
              </h4>

              <div className="table-tools d-flex align-items-center justify-content-between mb-3">
                {["ROOT_ADMIN", "ADMIN"].includes(role) && (
                  <div className="tool-item filter d-flex align-items-center">
                    <div className="tool-item search d-flex align-items-center text-nowrap">
                      Tìm kiếm:
                      <InputAutoComplete
                        isFilter={isFilter}
                        dataSource={users}
                        placeholder={"Nhập email"}
                        bindLabel={"email"}
                        bindValue={"_id"}
                        onSearch={(value) => onSearch(value)}
                      />
                    </div>
                  </div>
                )}
                <div className="d-flex flex-wrap align-items-center">
                  <div className="tool-item search d-flex align-items-center text-nowrap">
                    <RangePicker
                      format="DD-MM-YYYY"
                      value={[
                        fromDate ? moment(reverseDate(fromDate)) : null,
                        toDate ? moment(reverseDate(toDate)) : null,
                      ]}
                      placeholder={["Từ ngày", "Đến ngày"]}
                      onChange={(date, dateString) =>
                        handleDatePicker(date, dateString)
                      }
                    />
                  </div>
                </div>
              </div>

              <Table
                loading={loading}
                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                dataSource={dataCall}
                columns={columns}
                pagination={false}
              />

              {dataCall.length !== 0 && (
                <div className="table-panigate d-flex align-items-center justify-content-between mt-3">
                  <div className="dataTables_info">
                    Trang {pageIndex} trong tổng số{" "}
                    {Math.ceil(totalPage / pageSize)} trang
                  </div>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="tool-item select d-flex align-items-center pagesize-select">
                      Hiển thị
                      <Select
                        value={pageSize}
                        onSelect={(value) => setPageSize(value)}
                      >
                        <Option value="10">10</Option>
                        <Option value="30">30</Option>
                        <Option value="50">50</Option>
                        <Option value="70">70</Option>
                        <Option value="100">100</Option>
                      </Select>
                      dữ liệu
                    </div>
                    <div className="table-pagination">
                      <Pagination
                        current={pageIndex}
                        pageSize={pageSize}
                        total={totalPage}
                        showSizeChanger={false}
                        onChange={(page) => setPageIndex(page)}
                        hideOnSinglePage={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
