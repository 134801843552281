import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Modal,
  Button,
  Input,
  Form,
  Select,
  DatePicker,
  Timeline,
  Table,
  Pagination,
  Tooltip,
} from "antd";
const { Option } = Select;

import "./detail-contact-modal.scss";

import {
  isHttpsWww,
  isWebUrl,
  validateOnlyNumber,
  validateText,
  validateVietnamPhoneNumber,
} from "utils/utils-validation";
import { showMessage } from "utils/utils-show-message";
import { apiContact } from "api/contact";
import { parseToken } from "../../utils/utils-parse-token";
import { setAccountRole } from "../../store/account";
import { useDispatch, useSelector } from "react-redux";
import IconEdit from "../../assets/icons/icon-edit.svg";
import IconCancel from "../../assets/icons/icon-cancel.svg";
import { numberWithCommas } from "../../utils/utils-format";

export const DetailContactModal = ({
  contactId,
  visibleModal,
  onCancel,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const [visibleModalUpdate, setVisibleModalUpdate] = useState(false);
  const [contact, setContact] = useState({});

  const [fullName, setFullName] = useState({ value: "", error: "", help: "" });
  const [phone, setPhone] = useState({ value: "", error: "", help: "" });
  const [zalo, setZalo] = useState({ value: "", error: "", help: "" });
  const [website, setWebsite] = useState({ value: "", error: "", help: "" });
  const [position, setPosition] = useState({ value: "", error: "", help: "" });
  const [company, setCompany] = useState({ value: "", error: "", help: "" });
  const [work, setWork] = useState({ value: "", error: "", help: "" });
  const [dataSource, setDataSource] = useState({
    value: "",
    error: "",
    help: "",
  });
  const [status, setStatus] = useState({ value: "", error: "", help: "" });
  const [note, setNote] = useState({ value: "", error: "", help: "" });
  const [location, setLocation] = useState({ value: "", error: "", help: "" });
  const [accountId, setAccountId] = useState({
    value: "",
    error: "",
    help: "",
  });

  const [pageIndex, setPageIndex] = useState(1);
  const [loading, setLoading] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(10);
  const [dataContactHistory, setDataContactHistory] = useState([]);

  const [contactHistories, setContactHistories] = useState(false);
  const [
    contractHistoryTotalRecords,
    setContractHistoryTotalRecords,
  ] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [pendingHistory, setPendingHistory] = useState(false);

  const roleIdUser = useSelector((state) => state.account.role);
  const getUserRole = () => {
    const role =
      parseToken(localStorage.getItem("token-datatuoi"))?.role || null;
    dispatch(setAccountRole(role));
  };

  const columns = [
    {
      title: "Ngày",
      dataIndex: "careTime",
      key: "careTime",
      width: 125,
    },
    {
      title: "Kết quả",
      dataIndex: "result",
      key: "result",
      className: "width-150-px-in-1200-column",
      ellipsis: true,
    },
    {
      title: "Tiến độ",
      dataIndex: "progress",
      key: "progress",
      className: "width-150-px-in-1200-column",
      ellipsis: true,
    },
    /*{
      title: "Giá trị hợp đồng",
      dataIndex: "price",
      key: "price",
      className: "width-150-px-in-1200-column",
      render: (value) => value ? numberWithCommas(value) : '',
      ellipsis: true,
    },*/
    {
      title: "Sản phẩm",
      dataIndex: "productItem",
      key: "productItem",
      className: "width-150-px-in-1200-column",
      render: (value, row) =>
        value ? `${row.productItem}- ${numberWithCommas(row.price)}` : "",
      ellipsis: true,
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
      className: "width-150-px-in-1200-column",
      ellipsis: true,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (value, row, index) => (
        <div className="row">
          {/*<Tooltip placement='top' title="Chi tiết">
            <button
              type="button"
              className="btn btn-md btn-rounded btn-success waves-effect waves-light d-flex align-items-center btn-icon-edit action"
              onClick={() => detailContact(row)}
            >
              <img
                src={IconEdit}
                width="12"
                alt=""
              />
            </button>
          </Tooltip>

          <Tooltip placement='top' title="Xóa">
            <button
              type="button"
              className="btn btn-md btn-rounded btn-danger waves-effect waves-light d-flex align-items-center ml-2 btn-icon-delete action"
              onClick={() => openConfirmDelete(row)}
            >
              <img
                src={IconCancel}
                width="12"
                alt=""
              />
            </button>
          </Tooltip>*/}
        </div>
        /*<Dropdown
          overlay={actionTableDropdown(value, row, index)}
          trigger={["click"]}
          placement="bottomRight"
          className="table-action-btn dropdown-toggle arrow-none btn btn-light btn-sm"
        >
          <div className="table-action-icon">
            <img width="13" src={IconVerticalDots} alt=""/>
          </div>
        </Dropdown>*/
      ),
    },
  ];

  useEffect(() => {
    getUserRole();
  }, []);

  useEffect(() => {
    if (!contactId) {
      return;
    }
    getContactDetail();
    getContactHistory();
  }, contactId);

  const initModal = () => {
    setBtnLoading(false);
  };

  const getContactDetail = async () => {
    const res = await apiContact.getById(contactId);
    if (res?.status?.code === "SUCCESS") {
      setContact(res.data);
    } else {
      showMessage("error", res.status.message);
    }
  };

  const updateContactHistory = async () => {
    // if (!validateCareTimeField(careTime)
    //   || !validatePriceField(price)
    // ) {
    //   return;
    // }
    setBtnLoading(true);
    const obj = {
      id: contact.id,
      fullName: fullName.value,
      phone: phone.value,
      company: company.value,
      position: position.value,
      work: work.value,
      location: location.value,
      zalo: zalo.value,
      website: website.value,
    };
    const res = await apiContact.update(obj);

    if (res?.status?.code === "SUCCESS") {
      const message = "Cập nhật danh bạ thành công!";
      showMessage("success", message);
      onSuccess();
      initModal();
    } else {
      showMessage("error", res.status.message);
    }
  };

  const getContactHistory = async () => {
    setLoading(true);
    const res = await apiContact.getAllCareHistoryOfContact(5, 0, 10);
    if (res.status.code === "SUCCESS") {
      setDataContactHistory(res.data);
      setTotalPage(res.totalItem);
    } else {
      showMessage("error", res.status.message);
    }
    setLoading(false);
  };

  const LoadMoreHistory = () => {
    if (contactHistories.length >= totalPage) {
      return <></>;
    }
    return (
      <div className="d-flex justify-content-center">
        <Button
          className="btn btn-info btn-block w-auto"
          disabled={pendingHistory}
          onClick={() => {
            setPageIndex((pageIndex) => pageIndex + 1);
          }}
        >
          {" "}
          Xem thêm{" "}
        </Button>
      </div>
    );
  };

  return (
    <Modal
      title="Chi tiết danh bạ"
      footer={null}
      visible={visibleModal}
      wrapClassName="detail-contact-modal"
      width="95%"
      onCancel={() => {
        onCancel();
        initModal();
      }}
    >
      <div className="row mb-3">
        <div className="col-4">
          <div className="col-12 mb-3">Thông tin khách hàng</div>
          <div className="col-12">
            <div className="col-12">
              <label>{contact?.fullName}</label>
            </div>
          </div>
        </div>
        <div className="col-8">
          <div className="col-12 mb-3">Lịch sử chăm sóc khách hàng</div>
          <Table
            loading={loading}
            className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
            dataSource={dataContactHistory}
            columns={columns}
            pagination={false}
          />

          {dataContactHistory.length !== 0 && (
            <div className="table-panigate d-flex align-items-center justify-content-between mt-3">
              <div className="dataTables_info">
                Trang {pageIndex} trong tổng số{" "}
                {Math.ceil(totalPage / pageSize)} trang
              </div>
              <div className="d-flex flex-wrap align-items-center">
                <div className="tool-item select d-flex align-items-center pagesize-select">
                  Hiển thị
                  <Select
                    value={pageSize}
                    onSelect={(value) => setPageSize(value)}
                  >
                    <Option value="10">10</Option>
                    <Option value="30">30</Option>
                    <Option value="50">50</Option>
                    <Option value="70">70</Option>
                    <Option value="100">100</Option>
                  </Select>
                  dữ liệu
                </div>
                <div className="table-pagination">
                  <Pagination
                    current={pageIndex}
                    pageSize={pageSize}
                    total={totalPage}
                    showSizeChanger={false}
                    onChange={(page) => setPageIndex(page)}
                    hideOnSinglePage={true}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/*<Form className="modal-form dark mt-3 mb-2">
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Ngày</label>
              <Form.Item validateStatus={careTime.error} help={careTime.help}>
                <DatePicker
                  className="form-control"
                  placeholder="Nhập ngày"
                  onChange={(e) =>
                    setCareTime({...careTime, value: e.target.value})
                  }
                  value={careTime.value ? moment(careTime.value) : null}
                  onBlur={() => validateCareTimeField(careTime)}
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Tiến độ</label>
              <Form.Item validateStatus={progress.error} help={progress.help}>
                <Input
                  className="form-control"
                  placeholder="Nhập số điện thoại"
                  onChange={(e) =>
                    setProgress({...progress, value: e.target.value})
                  }
                  value={progress.value}
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Kết quả</label>
              <Form.Item validateStatus={result.error} help={result.help}>
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Chọn kết quả"
                  optionFilterProp="children"
                  onChange={(e) => setResult({...result, value: e.target.value})}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Không nghe máy">Không nghe máy</Option>
                  <Option value="Từ chối">Từ chối</Option>
                  <Option value="Hẹn gọi lại">Hẹn gọi lại</Option>
                  <Option value="Sai số">Sai số</Option>
                  <Option value="Thuê bao">Thuê bao</Option>
                  <Option value="Zalo Tư vấn">Zalo Tư vấn</Option>
                  <Option value="Gặp Tư vấn">Gặp Tư vấn</Option>
                  <Option value="Hẹn ký Hợp Đồng">Hẹn ký Hợp Đồng</Option>
                  <Option value="Ký Hợp Đồng">Ký Hợp Đồng</Option>
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Giá hợp đồng</label>
              <Form.Item validateStatus={price.error} help={price.help}>
                <Input
                  className="form-control"
                  placeholder="Nhập giá hợp đông"
                  onChange={(e) =>
                    setPrice({...price, value: e.target.value})
                  }
                  value={price.value}
                  onBlur={() => validatePriceField(price)}
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Sản phẩm</label>
              <Form.Item validateStatus={productItem.error} help={productItem.help}>
                <Input
                  className="form-control"
                  placeholder="Nhập sản phẩm"
                  onChange={(e) =>
                    setProductItem({...productItem, value: e.target.value})
                  }
                  value={productItem.value}
                  // onBlur={() => validatePhoneField(phone)}
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Ghi chú</label>
              <Form.Item validateStatus={note.error} help={note.help}>
                <Input
                  className="form-control"
                  placeholder="Nhập ghi chú"
                  onChange={(e) =>
                    setNote({...note, value: e.target.value})
                  }
                  value={note.value}
                />
              </Form.Item>
            </div>
          </div>

        </div>
      </Form>*/}
      <div className="btn-step-group d-flex justify-content-end">
        <Button
          className="btn btn-primary btn-block"
          type="button"
          onClick={() => onCancel()}
        >
          {" "}
          Đóng{" "}
        </Button>
      </div>
    </Modal>
  );
};
