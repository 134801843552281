import React, {useState, useEffect} from "react";
import moment from "moment";
import {
  Input,
  Select,
  Table,
  Dropdown,
  Pagination,
  DatePicker,
  Form,
} from "antd";
import "./website-stop.scss";
import {apiCampaign} from "api/campaign";
import {useSelector} from "react-redux";
import {reverseDate, formatId} from "utils/utils-format";

import {InputAutoComplete} from "components/input-autocomplete/input-autocomplete";

import IconPlusFill from "assets/icons/icon-plus-fill.svg";
import IconVerticalDots from "assets/icons/icon-vertical-dots.svg";
import IconPlay from "assets/icons/icon-play.svg";
import IconFilter from "assets/icons/icon-filter.svg";
import IconRefresh from "assets/icons/icon-refresh.svg";
import {showMessage} from "utils/utils-show-message";

const {Option} = Select;
const {RangePicker} = DatePicker;

export const WebsiteStop = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalCode, setVisibleModalCode] = useState(false);
  const [visibleModalData, setVisibleModalData] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(10);
  const [dataCampaign, setDataCampaign] = useState([]);
  const [loading, setLoading] = useState(false);
  const [domainUrl, setDomainUrl] = useState("");

  const [state, setState] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchMethod, setSearchMethod] = useState("tenMien");
  const [isFilter, setIsFilter] = useState(false);
  const [url, setUrl] = useState("");
  const [accountName, setAccountName] = useState("");

  const [suggestionAccount, setSuggestionAccount] = useState([]);
  const [suggestionDomain, setSuggestionDomain] = useState([]);

  const roleIdUser = useSelector((state) => state.account.role);

  const actionTableDropdown = (value, row, index) => {
    return (
      <div className="account-dropdown">
        <div
          key="1"
          className="dropdown-item d-flex align-items-center"
          onClick={() => activeCampaign(row)}
        >
          <img src={IconPlay} alt=""/>
          Mở chiến dịch
        </div>
      </div>
    );
  };

  const columnsCampaign = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 125,
    },
    {
      title: "Chiến dịch",
      dataIndex: "chienDich",
      key: "chienDich",
      className: "width-150-px-in-1200-column",
      ellipsis: true,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (value, row, index) => (
        <Dropdown
          overlay={actionTableDropdown(value, row, index)}
          trigger={["click"]}
          placement="bottomRight"
          className="table-action-btn dropdown-toggle arrow-none btn btn-light btn-sm"
        >
          <div className="table-action-icon">
            <img width="13" src={IconVerticalDots} alt=""/>
          </div>
        </Dropdown>
      ),
    },
  ];

  const renderPlaceholder = () => {
    switch (searchMethod) {
      case "tenMien":
        return "Nhập Tên miền cần tìm";
      case "accountName":
        return "Nhập Tên Tài khoản cần tìm";
      default:
        return "";
    }
  };

  const activeCampaign = async (target) => {
    const res = await apiCampaign.activeStatus(target.chienDichId);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Kích hoạt chiến dịch thành công");
      await getAllCampaign();
    }
  };

  const closeModal = () => {
    setVisibleModalCode(false);
  };

  const resetSearchValue = () => {
    setUrl("");
    setAccountName("");
  };

  const checkIsFilter = () => {
    if (
      url !== "" ||
      searchMethod !== "tenMien" ||
      fromDate !== "" ||
      toDate !== "" ||
      state !== ""
    ) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  };

  const deleteFilter = async () => {
    setUrl("");
    setState("");
    setFromDate("");
    setToDate("");
    setAccountName("");
    setPageIndex(1);
    setIsFilter(false);
    setSearchMethod("tenMien");
    await getAllCampaign();
  };

  const handleDatePicker = (date, dateString) => {
    const startDate = dateString[0];
    const endDate = dateString[1];
    setPageIndex(1);
    setFromDate(startDate);
    setToDate(endDate);
  };

  const getAllSuggestion = async () => {
    const res = await apiCampaign.getAll({pageIndex: 1, pageSize: 100});
    if (res?.status?.code === "SUCCESS") {
      const data = res.data;
      const suggestionDomain = [];
      const suggestionAccount = [];
      data.forEach((item) => {
        suggestionDomain.push(item.url);
        suggestionAccount.push(item.user.userName);
      });
      setSuggestionDomain(suggestionDomain);
      setSuggestionAccount(suggestionAccount);
    }
  };

  const renderSuggestion = () => {
    if (searchMethod === "tenMien") {
      return suggestionDomain;
    }
    if (searchMethod === "accountName") {
      return suggestionAccount;
    }
    return [];
  };

  useEffect(async () => {
    await getAllSuggestion();
  }, []);

  useEffect(async () => {
    await getAllCampaign();
  }, [url, accountName]);

  const onSearch = (value) => {
    setPageIndex(1);
    if (searchMethod === "tenMien") {
      setAccountName("");
      setUrl(value);
    }
    if (searchMethod === "accountName") {
      setUrl("");
      setAccountName(value);
    }
  };

  useEffect(async () => {
    await getAllCampaign();
  }, [pageIndex, pageSize, state, toDate]);

  useEffect(() => {
    renderPlaceholder();
    checkIsFilter();
  }, [searchMethod, isFilter]);

  const getAllCampaign = async () => {
    checkIsFilter();
    setLoading(true);
    const res = await apiCampaign.getWebsiteStop({
      pageIndex,
      pageSize,
      state,
      url,
      fromDate,
      toDate,
      userName: accountName,
    });
    setLoading(false);
    if (res?.status?.code === "SUCCESS") {
      const data = [];
      res.data.forEach((item, index) => {
        const createdDate = new Date(reverseDate(item.createdDate));
        const record = {
          id: formatId(createdDate, item.id),
          chienDichId: item.id,
          chienDich: item.url,
        };
        data.push(record);
      });
      setDataCampaign(data);
      setTotalPage(res.data.length);
    }
  };

  return (
    <div className="chien-dich-page position-relative min-height-100vh">
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <Form className="form-inline">
                  <button
                    className="btn btn-secondary btn-sm ml-2"
                    onClick={async () => {
                      resetSearchValue();
                      setFromDate("");
                      setToDate("");
                      await getAllCampaign();
                    }}
                  >
                    <img src={IconRefresh} alt="" width="11"/>
                  </button>
                </Form>
              </div>
              <h4 className="page-title">Chiến dịch</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card-box">
              <h4 className="header-title d-flex align-items-center flex-wrap justify-content-between">
                <span className="mb-2">Danh sách chiến dịch</span>
                <div className="d-flex flex-wrap align-items-center">
                  {isFilter && (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger waves-effect waves-light mb-2 mr-2 d-flex align-items-center"
                      onClick={() => deleteFilter()}
                    >
                      <img
                        src={IconFilter}
                        width="12"
                        className="mr-1"
                        alt=""
                      />{" "}
                      Xoá bộ lọc
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-sm btn-blue waves-effect mb-2 waves-light float-right d-flex align-items-center"
                    onClick={() => setVisibleModal(true)}
                  >
                    <img
                      src={IconPlusFill}
                      width="12"
                      className="mr-1"
                      alt=""
                    />{" "}
                    Tạo chiến dịch
                  </button>
                </div>
              </h4>

              <div className="table-tools d-flex align-items-center justify-content-between mb-3">
                <div className="tool-item filter d-flex align-items-center">
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                    Kiểu tìm kiếm
                    <Select
                      value={searchMethod}
                      onSelect={(value) => {
                        resetSearchValue();
                        setSearchMethod(value);
                      }}
                    >
                      <Option value="tenMien">Tên miền</Option>
                      {["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) && (
                        <Option value="accountName">Tài khoản</Option>
                      )}
                    </Select>
                  </div>
                  <div className="tool-item search d-flex align-items-center text-nowrap">
                    Tìm kiếm:
                    <InputAutoComplete
                      isFilter={isFilter}
                      dataSource={renderSuggestion()}
                      placeholder={renderPlaceholder()}
                      onSearch={(value) => onSearch(value)}
                    />
                  </div>
                </div>
                <div className="tool-item flex-wrap d-flex align-items-center text-nowrap">
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select mr-2">
                    Trạng thái
                    <Select
                      value={state}
                      onSelect={(value) => {
                        setState(value);
                      }}
                    >
                      <Option value="">Tất cả</Option>
                      <Option value="ACTIVE">Hoạt động</Option>
                      <Option value="INACTIVE">Tạm dừng</Option>
                    </Select>
                  </div>
                  <RangePicker
                    className="tool-item search"
                    format="DD-MM-YYYY"
                    value={[
                      fromDate ? moment(reverseDate(fromDate)) : null,
                      toDate ? moment(reverseDate(toDate)) : null,
                    ]}
                    placeholder={["Từ ngày", "Đến ngày"]}
                    onChange={(date, dateString) =>
                      handleDatePicker(date, dateString)
                    }
                  />
                </div>
              </div>

              <Table
                loading={loading}
                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                dataSource={dataCampaign}
                columns={columnsCampaign}
                pagination={false}
              />

              {dataCampaign.length !== 0 && (
                <div className="table-panigate d-flex align-items-center justify-content-between mt-3">
                  <div className="dataTables_info">
                    Trang {pageIndex} trong tổng số{" "}
                    {Math.ceil(totalPage / pageSize)} trang
                  </div>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="tool-item select d-flex align-items-center pagesize-select">
                      Hiển thị
                      <Select
                        value={pageSize}
                        onSelect={(value) => setPageSize(value)}
                      >
                        <Option value="10">10</Option>
                        <Option value="30">30</Option>
                        <Option value="50">50</Option>
                        <Option value="70">70</Option>
                        <Option value="100">100</Option>
                      </Select>
                      dữ liệu
                    </div>
                    <div className="table-pagination">
                      <Pagination
                        current={pageIndex}
                        pageSize={pageSize}
                        total={totalPage}
                        showSizeChanger={false}
                        onChange={(page) => setPageIndex(page)}
                        hideOnSinglePage={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
