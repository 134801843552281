import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import moment from "moment";
import {DatePicker, Dropdown, Form, Pagination, Select, Table} from "antd";

const {Option} = Select;
const {RangePicker} = DatePicker;

import {InputAutoComplete} from "../../components/input-autocomplete/input-autocomplete";
import {SelectAgency} from "components/select-dai-ly/select-dai-ly";

import IconRefresh from "../../assets/icons/icon-refresh.svg";
import IconVerticalDots from "../../assets/icons/icon-vertical-dots.svg";
import IconFilter from "../../assets/icons/icon-filter.svg";
import IconCheckMarkGray from "../../assets/icons/icon-check-mark-gray.svg";
import IconStop from "../../assets/icons/icon-stop.svg";
import IconCheckMarkGray2 from "../../assets/icons/icon-check-mark-light.svg";

import "./thanh-vien-callio.scss";

import {apiTelesales} from "../../api/telesales";
import {apiAccount} from "../../api/account";

import {reverseDate} from "../../utils/utils-format";
import {showMessage} from "../../utils/utils-show-message";

export const ThanhVienCallio = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalData, setVisibleModalData] = useState(false);
  const [visibleModalChangePassword, setVisibleModalChangePassword] =
    useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(10);
  const [dataUser, setDataUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchMethod, setSearchMethod] = useState("email");
  const [isFilter, setIsFilter] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userNameTarget, setUserNameTarget] = useState(null);
  const [visibleUserModal, setVisibleUserModal] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [userModalType, setUserModalType] = useState("");
  const [suggestionAccount, setSuggestionAccount] = useState([]);
  const [suggestionUid, setSuggestionUid] = useState([]);
  const [suggestionEmail, setSuggestionEmail] = useState([]);
  const [agencyId, setAgencyId] = useState(0);
  const roleIdUser = useSelector((state) => state.account.role);

  const actionTableDropdown = (value, row, index) => {
    return (
      <div className="account-dropdown">
        {row.state === "inactive" && (
          <div
            key="1"
            className="dropdown-item d-flex align-items-center"
            onClick={() => approveUserCallio(row.email)}
          >
            <img src={IconCheckMarkGray} alt=""/>
            Phê duyệt đăng ký Callio
          </div>
        )}
        {row.state === "active" && (
          <div
            key="2"
            className="dropdown-item d-flex align-items-center"
            onClick={() => inActiveOrActiveUser(row.idUserCallio, false)}
          >
            <img src={IconStop} alt=""/>
            Tạm ngừng dịch vụ
          </div>
        )}
        {row.state === "lock" && (
          <div
            key="3"
            className="dropdown-item d-flex align-items-center"
            onClick={() => inActiveOrActiveUser(row.idUserCallio, true)}
          >
            <img src={IconCheckMarkGray2} alt=""/>
            Kích hoạt
          </div>
        )}
      </div>
    );
  };

  const columnsUser = [
    {
      title: "#",
      dataIndex: "stt",
      key: "stt",
      width: 50,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className: "text-nowrap",
    },
    {
      title: "UID",
      dataIndex: "phone",
      key: "phone",
      className: "text-nowrap",
    },
    {
      title: "Trạng thái",
      dataIndex: "state",
      key: "state",
      className: "text-nowrap",
      render: (value) => {
        if (value === "active")
          return <span className="badge badge-success">Đã kích hoạt</span>;
        if (value === "lock")
          return (
            <span className="badge bg-danger text-white">Tạm dừng dịch vụ</span>
          );
        if (value === "inactive")
          return (
            <span className="badge bg-info text-white">Chờ kích hoạt</span>
          );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (value, row, index) => (
        <Dropdown
          overlay={actionTableDropdown(value, row, index)}
          trigger={["click"]}
          placement="bottomRight"
          className="table-action-btn dropdown-toggle arrow-none btn btn-light btn-sm"
        >
          <div className="table-action-icon">
            <img width="13" src={IconVerticalDots} alt=""/>
          </div>
        </Dropdown>
      ),
    },
  ];

  const getAllSuggestion = async () => {
    const res = await apiAccount.getAll({pageIndex: 1, pageSize: 100});
    if (res?.status?.code === "SUCCESS") {
      const data = res.data;
      // const suggestionUid = [];
      // const suggestionAccount = [];
      const suggestionEmail = [];
      data.forEach((item) => {
        // suggestionUid.push(item.phone);
        // suggestionAccount.push(item.userName);
        suggestionEmail.push(item.email);
      });
      // setSuggestionUid(suggestionUid);
      // setSuggestionAccount(suggestionAccount);
      setSuggestionEmail(suggestionEmail);
    }
  };

  useEffect(() => {
    getAllSuggestion();
  }, []);

  useEffect(() => {
    listapproveRegisterCallio();
  }, [phone, userName, email, agencyId]);

  const onSearch = (value) => {
    setPageIndex(1);
    setEmail(value);
  };

  const resetSearchValue = () => {
    setEmail("");
    setState("");
  };

  const checkIsFilter = () => {
    if (searchMethod !== "email" || state !== "" || email !== "") {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  };

  const deleteFilter = () => {
    setEmail("");
    setState("");
    setIsFilter(false);
    setSearchMethod("email");
    setPageIndex(1);
    listapproveRegisterCallio();
  };

  const handleDatePicker = (date, dateString) => {
    const startDate = dateString[0];
    const endDate = dateString[1];
    setPageIndex(1);
    setFromDate(startDate);
    setToDate(endDate);
  };

  useEffect(() => {
    listapproveRegisterCallio();
  }, [pageIndex, pageSize, state, toDate]);

  useEffect(() => {
    setPageIndex(1);
  }, [pageSize, state, toDate]);

  const approveUserCallio = async (email) => {
    const res = await apiTelesales.approveRegisterCallio({
      email: email,
      state: "active",
    });
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Phê duyệt đăng ký thành công");
      listapproveRegisterCallio();
    } else {
      showMessage("error", res.status.message);
    }
  };

  const inActiveOrActiveUser = async (idUserCallio, state) => {
    const res = await apiTelesales.activeOrInactiveUserCallio(
      idUserCallio,
      state
    );
    if (res?.status?.code === "SUCCESS") {
      const content = state
        ? "Hủy kích hoạt thành công"
        : "Kích hoạt thành công";
      showMessage("success", content);
      listapproveRegisterCallio();
    } else {
      showMessage("error", res.status.message);
    }
  };

  const listapproveRegisterCallio = async () => {
    checkIsFilter();
    setLoading(true);
    const res = await apiTelesales.listapproveRegisterCallio({
      pageIndex,
      pageSize,
      email,
      phone,
      state,
      fromDate,
      toDate,
      agencyId,
    });
    setLoading(false);
    if (res?.status?.code === "SUCCESS") {
      const data = res.data;
      data.forEach(
        (item, index) => (item.stt = pageIndex * pageSize + index + 1)
      );
      setDataUser(data);
      setTotalPage(res.totalItem);
    }
  };

  const handleAgencyChange = (agencyId) => {
    setAgencyId(agencyId);
  };

  return (
    <div className="thanh-vien-page position-relative min-height-100vh">
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <Form className="form-inline">
                  <button
                    className="btn btn-secondary btn-sm ml-2"
                    onClick={() => {
                      resetSearchValue();
                      listapproveRegisterCallio();
                    }}
                  >
                    <img src={IconRefresh} alt="" width="11"/>
                  </button>
                </Form>
              </div>
              <h4 className="page-title">Người dùng Callio</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card-box">
              <h4 className="header-title d-flex align-items-center flex-wrap justify-content-between">
                <span className="mb-2">Quản lý người dùng Callio</span>
                <div className="d-flex flex-wrap align-items-center">
                  {isFilter && (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger waves-effect waves-light d-flex align-items-center mb-2 mr-2"
                      onClick={() => deleteFilter()}
                    >
                      <img
                        src={IconFilter}
                        width="12"
                        className="mr-1"
                        alt=""
                      />{" "}
                      Xoá bộ lọc
                    </button>
                  )}
                </div>
              </h4>

              {["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) && (
                <div className="table-tools d-flex align-items-center justify-content-between mb-3">
                  <div className="tool-item filter d-flex align-items-center">
                    <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                      Đại lý:
                      <SelectAgency
                        placeholder="Tất cả"
                        onChange={(value) => handleAgencyChange(value)}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="table-tools d-flex align-items-center justify-content-between mb-3">
                <div className="tool-item filter d-flex align-items-center">
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                    Kiểu tìm kiếm
                    <Select
                      value={searchMethod}
                      onSelect={(value) => {
                        resetSearchValue();
                        setSearchMethod(value);
                      }}
                    >
                      <Option value="email">Email</Option>
                    </Select>
                  </div>
                  <div className="tool-item search d-flex align-items-center text-nowrap">
                    Tìm kiếm:
                    <InputAutoComplete
                      isFilter={isFilter}
                      dataSource={suggestionEmail}
                      placeholder={"Nhập Email cần tìm"}
                      onSearch={(value) => onSearch(value)}
                    />
                  </div>
                </div>
                <div className="d-flex flex-wrap align-items-center">
                  <div className="tool-item search d-flex align-items-center mr-2 text-nowrap search-method-select">
                    Trạng thái
                    <Select
                      value={state}
                      onSelect={(value) => setState(value)}
                    >
                      <Option value="">Tất cả</Option>
                      <Option value="inactive">Chờ kích hoạt</Option>
                      <Option value="active">Kích hoạt</Option>
                      <Option value="lock">Tạm dừng dịch vụ</Option>
                    </Select>
                  </div>
                </div>
                {/*<div className='tool-item search d-flex align-items-center text-nowrap'>*/}
                {/*  <RangePicker*/}
                {/*    format='DD-MM-YYYY'*/}
                {/*    value={[*/}
                {/*      fromDate ? moment(reverseDate(fromDate)) : null,*/}
                {/*      toDate ? moment(reverseDate(toDate)) : null,*/}
                {/*    ]}*/}
                {/*    placeholder={['Từ ngày', 'Đến ngày']}*/}
                {/*    onChange={(date, dateString) =>*/}
                {/*      handleDatePicker(date, dateString)*/}
                {/*    }*/}
                {/*  />*/}
                {/*</div>*/}
                {/*</div>*/}
              </div>

              <Table
                loading={loading}
                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                dataSource={dataUser}
                columns={columnsUser}
                pagination={false}
              />

              {dataUser.length !== 0 && (
                <div className="table-panigate d-flex align-items-center justify-content-between mt-3">
                  <div className="dataTables_info">
                    Trang {pageIndex} trong tổng số{" "}
                    {Math.ceil(totalPage / pageSize)} trang
                  </div>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="tool-item select d-flex align-items-center pagesize-select">
                      Hiển thị
                      <Select
                        value={pageSize}
                        onSelect={(value) => setPageSize(value)}
                      >
                        <Option value="10">10</Option>
                        <Option value="30">30</Option>
                        <Option value="50">50</Option>
                        <Option value="70">70</Option>
                        <Option value="100">100</Option>
                      </Select>
                      dữ liệu
                    </div>
                    <div className="table-pagination">
                      <Pagination
                        current={pageIndex}
                        pageSize={pageSize}
                        total={totalPage}
                        showSizeChanger={false}
                        onChange={(page) => setPageIndex(page)}
                        hideOnSinglePage={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
